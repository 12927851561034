import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CardText(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "20",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M17.75 0.5C18.2141 0.5 18.6592 0.684375 18.9874 1.01256C19.3156 1.34075 19.5 1.78587 19.5 2.25V13.755C19.5 14.2191 19.3156 14.6642 18.9874 14.9924C18.6592 15.3206 18.2141 15.505 17.75 15.505H2.25C2.02019 15.505 1.79262 15.4597 1.5803 15.3718C1.36798 15.2838 1.17506 15.1549 1.01256 14.9924C0.850061 14.8299 0.721157 14.637 0.633211 14.4247C0.545265 14.2124 0.5 13.9848 0.5 13.755V2.25C0.5 1.78587 0.684374 1.34075 1.01256 1.01256C1.34075 0.684375 1.78587 0.5 2.25 0.5H17.75Z"
        stroke={primaryColor}
      />
      <rect
        x="3.93359"
        y="5"
        width="12.1333"
        height="2"
        rx="1"
        fill={primaryColor}
      />
      <rect
        x="3.25"
        y="12.25"
        width="13.5"
        height="0.5"
        rx="0.25"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}
