import { Document } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  PdfUploader,
  PDFUploaderProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export interface SmartPdfFieldProps<T>
  extends SmartFieldCommonProps<T>,
    Omit<PDFUploaderProps, "pdf" | "onPdfChange"> {}

export function SmartPdfField<T>(props: SmartPdfFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <PdfUploader
      {...props}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      pdf={extractValueFromPath(form, props.name)}
      onPdfChange={(pdf: Document) => {
        handleFormChange({
          ...updateObject(form, props.name, pdf),
        });
      }}
      onPdfDelete={props.onPdfDelete}
    />
  );
}
