import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Share(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4961 10.0998C17.7165 10.0999 17.9293 10.1808 18.094 10.3273C18.2588 10.4738 18.364 10.6756 18.3898 10.8945L18.3961 10.9998V16.3997C18.3961 17.3233 18.0411 18.2116 17.4046 18.8808C16.768 19.55 15.8986 19.949 14.9762 19.9951L14.7962 19.9996H7.59992C6.67632 19.9996 5.78806 19.6446 5.11885 19.0081C4.44963 18.3716 4.05068 17.5022 4.0045 16.5797L4 16.3997V10.9998C4.00025 10.7704 4.08809 10.5498 4.24556 10.383C4.40303 10.2162 4.61824 10.1158 4.84724 10.1024C5.07623 10.0889 5.30171 10.1634 5.47762 10.3107C5.65352 10.4579 5.76657 10.6667 5.79366 10.8945L5.79996 10.9998V16.3997C5.80005 16.8537 5.97165 17.2908 6.2804 17.6236C6.58916 17.9564 7.01225 18.1602 7.46493 18.1943L7.59992 18.1997H14.7962C15.2501 18.1996 15.6873 18.028 16.0201 17.7192C16.3529 17.4105 16.5567 16.9874 16.5907 16.5347L16.5961 16.3997V10.9998C16.5961 10.7611 16.691 10.5322 16.8597 10.3634C17.0285 10.1947 17.2574 10.0998 17.4961 10.0998ZM6.06006 6.76091L10.5573 2.26371C10.7121 2.10862 10.9182 2.01537 11.137 2.00145C11.3557 1.98753 11.572 2.0539 11.7452 2.18811L11.8298 2.26281L16.3324 6.76001C16.4942 6.92182 16.5882 7.13914 16.5954 7.36779C16.6026 7.59644 16.5225 7.81926 16.3713 7.99096C16.2201 8.16265 16.0093 8.27033 15.7815 8.2921C15.5538 8.31388 15.3263 8.24812 15.1454 8.10819L15.0608 8.03349L12.0962 5.07345V13.9293C12.0962 14.1497 12.0153 14.3625 11.8688 14.5272C11.7223 14.6919 11.5205 14.7972 11.3015 14.8229L11.1962 14.8292C10.9758 14.8292 10.7631 14.7483 10.5983 14.6018C10.4336 14.4553 10.3284 14.2535 10.3026 14.0346L10.2963 13.9293V5.06895L7.33263 8.03349C7.17766 8.18844 6.97147 8.28153 6.75275 8.29528C6.53404 8.30903 6.31781 8.24251 6.14465 8.10819L6.06006 8.03349C5.9051 7.87852 5.81201 7.67233 5.79826 7.45361C5.78451 7.23489 5.85103 7.01867 5.98536 6.84551L6.06006 6.76091L10.5573 2.26371L6.06096 6.76001L6.06006 6.76091Z"
        fill="#666879"
      />
    </svg>
  );
}
