import {
  Investment,
  ProjectHolderProfile,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const projectHolderProfilesEndpoints = (request: Request) => ({
  createProjectHolderProfile: (
    body: ProjectHolderProfile,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ProjectHolderProfile>, void>({
      path: "/api/v1/project-holder-profiles",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  deleteProjectHolderProfile: (
    body: ProjectHolderProfile,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ProjectHolderProfile>, void>({
      path: `/api/v1/project-holder-profiles/${body.id}`,
      method: "DELETE",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getProjectHolderInvestors: (
    projectHolderProfileId: ProjectHolderProfile["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment[]>, void>({
      path: `/api/v1/project-holder-profiles/${projectHolderProfileId}/investors`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
