import {
  CMSBlock,
  CMSBlockData,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  extractValueFromPath,
  InputDateDescription,
  updateObject,
} from "@tudigo-monorepo/web-feature-form";
import { InputDate } from "@tudigo-monorepo/web-tudigo-components";

type EditorPluginInputProps = {
  input: InputDateDescription<CMSBlockData>;
  path?: string;
  blockContent: CMSBlock;
  setBlockContent: (blockContent: CMSBlock) => void;
};

export const EditorDateInput = (props: EditorPluginInputProps) => {
  const { input, path, blockContent, setBlockContent } = props;

  return (
    <InputDate
      range={false}
      label={input.label}
      value={new Date(extractValueFromPath(blockContent, path + input.name))}
      onChange={(value) => {
        setBlockContent({
          ...updateObject(
            blockContent,
            path + input.name,
            value?.toDateString(),
          ),
        });
      }}
    />
  );
};
