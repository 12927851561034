/* eslint-disable-next-line */
import { FormEvent } from "react";

import { TextField } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

type SmartTextFieldProps<T> = SmartFieldCommonProps<T>;

export function SmartTextField<T>(props: SmartTextFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <TextField
      {...props}
      value={extractValueFromPath(form, props.name) || ""}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onChange={(event: FormEvent<HTMLInputElement>) => {
        handleFormChange({
          ...updateObject(
            form,
            props.name,
            props?.type === "number"
              ? parseInt(event.currentTarget.value)
              : event.currentTarget.value,
          ),
        });
      }}
    />
  );
}

export default SmartTextField;
