import {
  getProjectEndDate,
  isFinishedProject,
  isLastDaysProject,
  isPrefinishedProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  daysLeftFromNow,
  hoursLeftFromNow,
} from "@tudigo-monorepo/core-tudigo-utils";

const TWO_WEEKS_IN_DAYS = 14;
const ONE_DAY_IN_HOURS = 24;

export function useProjectBusiness(project: Project | undefined) {
  const endDate = getProjectEndDate(project);
  const daysLeft =
    daysLeftFromNow(new Date(endDate ?? "")) >= 1
      ? daysLeftFromNow(new Date(endDate ?? ""))
      : 0;
  const hoursLeft = hoursLeftFromNow(new Date(endDate ?? ""));

  const isLastDays =
    (project?.status === "started" || project?.status === "prefinished") &&
    isLastDaysProject(project) &&
    daysLeft <= TWO_WEEKS_IN_DAYS;

  const isLastHours =
    !!(isLastDays && endDate) &&
    hoursLeftFromNow(new Date(endDate)) < ONE_DAY_IN_HOURS;

  const isEndingSoon =
    !isFinishedProject(project) &&
    !isPrefinishedProject(project) &&
    (isLastHours || isLastDays);

  return {
    endDate,
    daysLeft,
    hoursLeft,
    isLastDays,
    isLastHours,
    isEndingSoon,
  };
}
