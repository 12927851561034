import { Project } from "@tudigo-monorepo/core-tudigo-api-models";

import { Badge } from "../badge/badge";
import { useProjectFlagProps } from "./use-project-flag-props";

export function ProjectFlag(props: { project?: Project }) {
  const { label, variant } = useProjectFlagProps(props.project);

  if (!variant || !label) {
    return null;
  }

  return (
    <Badge asFlag variant={variant} className="absolute left-0 top-[30px]">
      {label}
    </Badge>
  );
}
