import { mockInvestments } from "../investments";
import { mockOrganizations } from "../organizations";
import { mockProjects } from "../projects";
import { CreditorRepayment } from "./models";

export const creditRepaymentMock: CreditorRepayment = {
  id: "1",
  project: mockProjects[0],
  investment: mockInvestments[0],
  organization: mockOrganizations[0],
  dueDate: "2025-01-01",
  debtorRepayment: null,
  capitalAmountInCents: 33,
  interestAmountInCents: 11,
  penaltyAmountInCents: 22,
  status: "paid",
  transactionId: null,
  createdAt: null,
  taxResult: {
    detailsInCents: {},
    totalInCents: 0,
  },
  grossTotalAmountInCents: 900,
  netTotalAmountInCents: 900,
  transferDate: null,
  frenchResidence: true,
  errorCode: null,
};
