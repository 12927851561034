import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Cross(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#666879",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_416_22424)">
        <path
          fill={primaryColor}
          d="M18.2761 5.55823L18.3638 5.63601C18.5369 5.80923 18.6405 6.03998 18.6548 6.28449C18.6692 6.529 18.5933 6.77027 18.4415 6.96254L18.3638 7.05022L13.414 12L18.3638 16.9497C18.5369 17.1229 18.6405 17.3537 18.6548 17.5982C18.6692 17.8427 18.5933 18.084 18.4415 18.2763L18.3638 18.3639C18.1905 18.5371 17.9598 18.6407 17.7153 18.655C17.4708 18.6694 17.2295 18.5935 17.0372 18.4417L16.9495 18.3639L11.9998 13.4142L7.05005 18.3639C6.87683 18.5371 6.64608 18.6407 6.40157 18.655C6.15706 18.6694 5.91578 18.5935 5.72351 18.4417L5.63583 18.3639C5.46266 18.1907 5.35909 17.96 5.34475 17.7155C5.33041 17.4709 5.40631 17.2297 5.55805 17.0374L5.63583 16.9497L10.5856 12L5.63583 7.05022C5.46266 6.87701 5.35909 6.64626 5.34475 6.40174C5.33041 6.15723 5.40631 5.91596 5.55805 5.72369L5.63583 5.63601C5.80905 5.46284 6.0398 5.35926 6.28431 5.34492C6.52883 5.33059 6.7701 5.40649 6.96237 5.55823L7.05005 5.63601L11.9998 10.5858L16.9495 5.63601C17.1228 5.46284 17.3535 5.35926 17.598 5.34492C17.8425 5.33059 18.0838 5.40649 18.2761 5.55823L18.3638 5.63601L18.2761 5.55823Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_416_22424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
