import * as React from "react";
import { ReactNode } from "react";
import { useDropdownToggle } from "@restart/ui";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export interface DropdownToggleProps {
  className?: string;
  value?: unknown;
  children?: ReactNode;
}

const DropdownToggle = (props: DropdownToggleProps) => {
  const { children, className } = props;

  const [toggleProps] = useDropdownToggle();

  return (
    <div className={cn("cursor-pointer", className)} {...toggleProps}>
      {children}
    </div>
  );
};

DropdownToggle.displayName = "DropdownToggle";

export default DropdownToggle;
