import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function GeometricPlanetIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_31_5359)">
        <path
          d="M3 12.1719C3 13.3538 3.23279 14.5241 3.68508 15.6161C4.13738 16.708 4.80031 17.7001 5.63604 18.5359C6.47177 19.3716 7.46392 20.0345 8.55585 20.4868C9.64778 20.9391 10.8181 21.1719 12 21.1719C13.1819 21.1719 14.3522 20.9391 15.4442 20.4868C16.5361 20.0345 17.5282 19.3716 18.364 18.5359C19.1997 17.7001 19.8626 16.708 20.3149 15.6161C20.7672 14.5241 21 13.3538 21 12.1719C21 9.78496 20.0518 7.49577 18.364 5.80794C16.6761 4.12012 14.3869 3.17191 12 3.17191C9.61305 3.17191 7.32387 4.12012 5.63604 5.80794C3.94821 7.49577 3 9.78496 3 12.1719Z"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59998 9.17191H20.4"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59998 15.1719H20.4"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 3.17191C9.81538 5.87152 8.92224 8.98977 8.92224 12.1719C8.92224 15.354 9.81538 18.4723 11.5 21.1719"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 3.17191C14.1847 5.87152 15.0778 8.98977 15.0778 12.1719C15.0778 15.354 14.1847 18.4723 12.5 21.1719"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_31_5359">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
            transform="translate(0 0.171906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
