import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function EvolutionDown(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "XXS",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_904_5319)">
        <path
          d="M6 9.5L6 2.5"
          stroke={primaryColor}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 6.5L6 9.5"
          stroke={primaryColor}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 6.5L6 9.5"
          stroke={primaryColor}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_904_5319">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
            transform="matrix(-1 0 0 -1 12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
