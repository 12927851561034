import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  clubV2QueryFields,
  QUERY_KEYS,
} from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryDetailKey } from "../../../index";

type GetCircleByIdQueryParams = {
  clubId: string;
  fields?: string;
};

export type GetCircleByIdQueryOptions = Omit<
  UseQueryOptions<ClubV2, TudigoError>,
  "queryKey"
>;

export const getClubV2ById = async (params: GetCircleByIdQueryParams) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubV2ById({
    clubId: params.clubId,
    query: {
      fields: params?.fields ?? "",
    },
  });

  return getResourceFromTudigoResponse(response.data);
};

const getCircleByIdQueryKey = (params: GetCircleByIdQueryParams) => {
  return getQueryDetailKey({
    key: QUERY_KEYS.CLUBS,
    id: params.clubId,
    fields: params.fields ?? clubV2QueryFields,
  });
};

export const useInvalidateCircle = () => {
  const queryClient = useQueryClient();

  return async (params: GetCircleByIdQueryParams) => {
    return queryClient.invalidateQueries({
      queryKey: getCircleByIdQueryKey(params),
    });
  };
};

export const useGetCircleById = (
  params: GetCircleByIdQueryParams,
  options?: GetCircleByIdQueryOptions,
) => {
  return useQuery({
    queryKey: getCircleByIdQueryKey(params),
    queryFn: () => getClubV2ById(params),
    ...options,
  });
};
