import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Delete(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C13.4988 3 14.7237 4.17315 14.8064 5.65131L14.8108 5.81081H19.3514C19.7096 5.81081 20 6.10122 20 6.45946C20 6.78785 19.756 7.05924 19.4394 7.10219L19.3514 7.10811H18.6629L17.5558 18.368C17.441 19.5347 16.4959 20.4357 15.3406 20.5087L15.1888 20.5135H8.81118C7.63878 20.5135 6.64965 19.6612 6.46385 18.5186L6.44423 18.368L5.33622 7.10811H4.64865C4.32026 7.10811 4.04887 6.86408 4.00592 6.54748L4 6.45946C4 6.13107 4.24402 5.85968 4.56063 5.81673L4.64865 5.81081H9.18919C9.18919 4.25844 10.4476 3 12 3ZM17.3596 7.10811H6.63957L7.73529 18.241C7.7861 18.7575 8.19488 19.1594 8.70127 19.2107L8.81118 19.2162H15.1888C15.7078 19.2162 16.1478 18.8487 16.2484 18.3498L16.2647 18.241L17.3596 7.10811ZM13.5135 9.48649C13.8419 9.48649 14.1133 9.73051 14.1562 10.0471L14.1622 10.1351V16.1892C14.1622 16.5474 13.8718 16.8378 13.5135 16.8378C13.1851 16.8378 12.9137 16.5938 12.8708 16.2772L12.8649 16.1892V10.1351C12.8649 9.7769 13.1553 9.48649 13.5135 9.48649ZM10.4865 9.48649C10.8149 9.48649 11.0863 9.73051 11.1292 10.0471L11.1351 10.1351V16.1892C11.1351 16.5474 10.8447 16.8378 10.4865 16.8378C10.1581 16.8378 9.88671 16.5938 9.84376 16.2772L9.83784 16.1892V10.1351C9.83784 9.7769 10.1282 9.48649 10.4865 9.48649ZM12 4.2973C11.2059 4.2973 10.5546 4.90885 10.4915 5.68668L10.4865 5.81081H13.5135C13.5135 4.97492 12.8359 4.2973 12 4.2973Z"
        fill={primaryColor}
      />
    </svg>
  );
}
