import React from "react";

import { PopperContext } from "./context";
import { PopperContent } from "./popper-content";
import { PopperTrigger } from "./popper-trigger";

type PopperProps = {
  isOpen?: boolean;
  clickable?: boolean;
  onOpenChange?(value: boolean): void;
};

function Popper(props: React.PropsWithChildren<PopperProps>) {
  const {
    children,
    isOpen: isOpenProp = false,
    clickable = false,
    onOpenChange,
  } = props;

  const [isOpen, setIsOpen] = React.useState<boolean>(isOpenProp);
  const [refElement, setRefElement] = React.useState<HTMLElement | null>(null);

  const handleIsOpen = React.useCallback(
    (value: boolean) => {
      setIsOpen(value);
      if (onOpenChange) {
        onOpenChange(value);
      }
    },
    [onOpenChange],
  );

  React.useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  const popperCtx = React.useMemo(() => {
    return {
      isOpen,
      refElement,
      setIsOpen: handleIsOpen,
      setRefElement,
      clickable,
    };
  }, [isOpen, refElement, handleIsOpen, clickable]);

  return (
    <PopperContext.Provider value={popperCtx}>
      {children}
    </PopperContext.Provider>
  );
}

Popper.Trigger = PopperTrigger;
Popper.Content = PopperContent;

export { Popper };
