import {
  isSharesProject,
  type BondsProject,
  type Project,
  type SharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { Icon, Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { BadgeRebrand } from "../ui/badge-rebrand/badge-rebrand";

const Setting = (props: {
  label: string;
  value: string | number | string[] | number[];
  tooltipDescription?: string;
}) => {
  const { label, value } = props;
  const { isQualifiedOrWma } = useUserBusiness();

  return (
    <li className="flex flex-wrap items-center gap-2">
      <p className="text-black-900 text-xs">{label}</p>
      <Tooltip placement="top" description={props.tooltipDescription}>
        <Icon name="Info" size="XS" primaryColor={themeColors["black-900"]} />
      </Tooltip>
      {!isQualifiedOrWma ? (
        <Icon
          name="Cadenas"
          size="XS"
          primaryColor={themeColors["black-primary"]}
        />
      ) : (
        <p className="text-black-primary text-xs font-semibold">{value}</p>
      )}
    </li>
  );
};

const MaxInPercentSetting = (props: {
  project: SharesProject;
  isLimitedAccess?: boolean;
}) => {
  const { t } = useTranslation();
  const potentialReturnInYears =
    props.project?.sharesMechanicSettings?.potentialReturnPeriodInMonth / 12;
  const displayReturnInYears =
    potentialReturnInYears % 1 === 0
      ? Math.round(potentialReturnInYears)
      : potentialReturnInYears.toFixed(1);
  const targetedYield = props.project?.sharesMechanicSettings?.targetedYield;

  if (!targetedYield) {
    return null;
  }

  return (
    <Setting
      label={t("component.project_card.list.item.targeted_exit")}
      value={`x${
        targetedYield.maxInPercent / 100
      } à ${displayReturnInYears} ans`}
    />
  );
};

const ProjectCardSharesMechanicSettings = (props: {
  project: SharesProject;
}) => {
  const { project } = props;
  const { sharesMechanicSettings, taxExemptionMechanics } = project;

  const { t } = useTranslation();

  if (!sharesMechanicSettings) {
    return null;
  }

  const { targetedYield } = sharesMechanicSettings;

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <>
          {!!project?.riskInPercent && (
            <Setting
              label={t("component.project_card.list.item.risk")}
              value={`${project.riskInPercent / 10} /10`}
            />
          )}
          {!!targetedYield?.maxInPercent && (
            <MaxInPercentSetting project={project} />
          )}
          {project.impactScoreInPercent !== null && (
            <Setting
              label={t("component.project_card.list.item.impact")}
              value={`${project.impactScoreInPercent / 10} /10`}
            />
          )}
        </>
      )}

      <div className="flex flex-wrap items-center gap-2">
        <div className="flex flex-wrap gap-1">
          <div className="flex items-center gap-1">
            <p className="text-black-900 text-xs">
              {t("component.project_card.list.item.tax_exemption")}
            </p>
            <Tooltip
              placement="top"
              description={t(
                "component.project_card.list.item.tax_exemption.info",
              )}
            >
              <Icon
                name="Info"
                size="XS"
                primaryColor={themeColors["black-900"]}
              />
            </Tooltip>
          </div>
          {taxExemptionMechanics?.map((tax, index) => (
            <BadgeRebrand
              key={index}
              variant={
                tax === "JEIR" || tax === "JEIC" ? "primary" : "tertiary"
              }
            >
              {t(`component.project_card.list.item.tax_exemption.${tax}`)}
            </BadgeRebrand>
          ))}
        </div>
      </div>
    </>
  );
};

const ProjectCardBondsMechanicSettings = (props: { project: BondsProject }) => {
  const { project } = props;
  const { bondsMechanicSettings } = project;

  const { t } = useTranslation();

  if (!bondsMechanicSettings) {
    return null;
  }

  const {
    isConvertible,
    nonConversionBonusNoDecimalRate,
    conversionHorizonInMonth,
    annualInterestNoDecimalRate,
    durationInMonth,
    repaymentType,
  } = bondsMechanicSettings;

  if (isConvertible) {
    return (
      <>
        <Setting
          label={t(
            "component.project_card.list.item.non_conversion_bonus_rate",
          )}
          value={`${nonConversionBonusNoDecimalRate / 100} %`}
        />
        {typeof conversionHorizonInMonth === "number" && (
          <Setting
            label={t(
              "component.project_card.list.item.conversion_horizon_in_month",
            )}
            value={`${conversionHorizonInMonth} ${t(
              "component.project_card.list.item.months",
            )}`}
          />
        )}
        {project.impactScoreInPercent !== null && (
          <Setting
            label={t("component.project_card.list.item.impact")}
            value={`${project.impactScoreInPercent / 10} /10`}
          />
        )}
      </>
    );
  }

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <>
          {!!project.riskInPercent && (
            <Setting
              label={t("component.project_card.list.item.risk")}
              value={`${project.riskInPercent / 10} /10`}
            />
          )}

          <Setting
            label={t("component.project_card.list.item.interest_rate")}
            value={`${annualInterestNoDecimalRate / 100} %`}
          />
        </>
      )}

      <Setting
        label={t("component.project_card.list.item.duration")}
        value={`${durationInMonth}
          ${t("component.project_card.list.item.months")}`}
      />
      <Setting
        label={t("component.project_card.list.item.repayment")}
        value={t(`repayment_type.${repaymentType}`)}
      />
      {!!project.impactScoreInPercent && (
        <Setting
          label={t("component.project_card.list.item.impact")}
          value={`${project.impactScoreInPercent / 10} /10`}
        />
      )}
    </>
  );
};

export const ProjectMechanicSettingsCard = ({
  project,
}: {
  project: Project;
}) => {
  return (
    <div className="bg-black-400 border-black-500 mb-6 flex h-36 w-full flex-col gap-2 rounded-md border px-4 py-4">
      {isSharesProject(project) ? (
        <ProjectCardSharesMechanicSettings project={project} />
      ) : (
        <ProjectCardBondsMechanicSettings project={project} />
      )}
    </div>
  );
};
