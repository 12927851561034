import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { IconElementsProps } from "../icon-elements-props.type";

export function TudigoLogoSmallIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = themeColors.primary,
    secondaryColor = themeColors.secondary,
    width = 62,
    height = 28,
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <mask
        id="mask0_6141_605"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <path d="M61.5 0H0.5V28H61.5V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_6141_605)">
        <path
          d="M56.3459 2.77879C54.1919 0.90945 50.8555 0 46.1439 0C41.4323 0 38.0951 0.90945 35.9419 2.77879C33.8868 4.59349 31.809 6.38156 29.7654 8.21028L30.0722 0.585747C27.5139 0.18217 25.2731 0 22.8869 0C17.621 0 13.9617 0.882824 11.6973 2.69752C9.65139 4.46597 7.6291 6.26525 5.59083 8.0449C3.48174 9.85263 2.49573 12.6048 2.49573 16.677C2.49573 20.7491 3.52667 23.6428 5.74083 25.4548C7.97478 27.2835 11.5671 28.1719 16.7233 28.1719C19.6235 28.1719 22.1681 27.8888 24.4812 27.3073C25.8304 26.0937 27.2169 24.9097 28.5753 23.7045C28.9864 24.3408 29.4707 24.904 30.0349 25.3931C32.1881 27.2625 35.5254 28.1719 40.2369 28.1719C44.9485 28.1719 48.285 27.2625 50.439 25.3931C52.4072 23.6667 54.3754 21.9389 56.3429 20.2125C58.4642 18.3711 59.4951 15.5195 59.4951 11.4949C59.4951 7.47037 58.4642 4.62012 56.3429 2.77739L56.3459 2.77879Z"
          fill={secondaryColor}
        />
        <path
          d="M11.6983 2.69752C9.44839 4.501 8.40222 7.29661 8.40222 11.4963C8.40222 15.6961 9.43316 18.4623 11.6473 20.2741C13.8813 22.1028 17.4736 22.9912 22.6298 22.9912C25.53 22.9912 28.0746 22.7082 30.3877 22.1267V11.4627H23.6364V14.9828H22.9526C21.4451 14.9828 19.5134 13.7903 19.5134 11.6939C19.5134 8.99643 22.077 8.20747 24.6993 8.20747C25.9678 8.20747 27.8652 8.29998 29.7382 8.75255L30.0725 0.585747C27.5134 0.18217 25.2726 0 22.8864 0C17.6205 0 13.9612 0.882824 11.6983 2.69752Z"
          fill={primaryColor}
        />
        <path
          d="M56.3452 2.77879C54.1912 0.90945 50.8547 0 46.1432 0C41.4309 0 38.0944 0.90945 35.9411 2.77879C33.8199 4.62012 32.7889 7.47177 32.7889 11.4963C32.7889 15.5209 33.8199 18.3711 35.9411 20.2139C38.0944 22.0832 41.4309 22.9927 46.1432 22.9927C50.8547 22.9927 54.1912 22.0832 56.3452 20.2139C58.4657 18.3726 59.4974 15.5209 59.4974 11.4963C59.4974 7.47177 58.4657 4.62152 56.3452 2.77879ZM46.1432 16.3407C44.0364 16.3407 44.0364 13.2031 44.0364 11.4949C44.0364 9.78677 44.0364 6.64921 46.1432 6.64921C48.25 6.64921 48.25 9.78677 48.25 11.4949C48.25 13.2031 48.25 16.3407 46.1432 16.3407Z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}
