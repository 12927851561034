// -------------- Qualification (Investor Profile) --------
export const UI_INVESTOR_PROFILE_STARTED = "ui.investor_profile.started";

export const UI_INVESTOR_PROFILE_STEP_PAGE_VIEW =
  "ui.investor_profile_step_page.view";

export const UI_INVESTOR_PROFILE_STEP_COMPLETED_VIEW =
  "ui.investor_profile_step_completed.view";

// -------------- Investment -----------------------------
export const UI_INVESTMENT_STARTED = "ui.investment.started";

export const UI_INVESTMENT_CONFIRMATION_PAGE_VIEW =
  "ui.investment_confirmation_page.view";
