import { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";

import { Faq } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ExpansionPanel,
  Icon,
  TextArea,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

export type FaqElementProps = {
  faq: Faq;
  index: number;
  deleteFaq: (index: number) => void;
  setFaq: (faq: Faq, index: number) => void;
};

const DragHandle = SortableHandle(() => (
  <div className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border">
    <Icon name="Drag" size="S" primaryColor={themeColors["dark-2"]} />
  </div>
));

export function FaqElement(props: FaqElementProps) {
  const { faq, index, deleteFaq, setFaq } = props;

  const { t } = useTranslation();

  const [localExpendedValue, setLocaleExpendedValue] = useState<boolean>(true);

  return (
    <ExpansionPanel
      title={`${faq.question}`}
      dragHandle={<DragHandle />}
      canBeDeleted
      expendedValue={localExpendedValue}
      setExpendedValue={setLocaleExpendedValue}
      deleteAction={() => {
        deleteFaq(index);
      }}
      className={cn("w-full bg-white")}
      headerClassName="justify-between"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex w-full flex-col gap-4 md:w-[423px]">
          <TextField
            required
            label="Question"
            value={faq.question}
            onChange={(event) => {
              setFaq(
                {
                  ...faq,
                  question: event.target.value,
                },
                index,
              );
            }}
          />
          <TextArea
            required
            label="Réponse"
            value={faq.answer}
            onChange={(event) => {
              setFaq(
                {
                  ...faq,
                  answer: event.target.value,
                },
                index,
              );
            }}
          />
        </div>
      </div>
    </ExpansionPanel>
  );
}
