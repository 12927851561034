import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CircleTeam(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        fill={primaryColor}
        stroke={primaryColor}
      />
      <path
        d="M14.8751 12.3993C15.0228 12.3992 15.169 12.4254 15.3055 12.4762C15.442 12.527 15.566 12.6016 15.6705 12.6956C15.775 12.7896 15.8578 12.9012 15.9144 13.0241C15.9709 13.1469 16 13.2786 16 13.4115V13.8247C15.9999 14.0828 15.9103 14.3345 15.7435 14.5444C14.9706 15.518 13.7081 16 11.9983 16C10.2874 16 9.02594 15.5175 8.25498 14.5431C8.08916 14.3334 8.00003 14.0825 8 13.8252V13.4111C7.99993 13.2781 8.02897 13.1464 8.08545 13.0236C8.14193 12.9007 8.22474 12.7891 8.32916 12.695C8.43358 12.601 8.55756 12.5264 8.69401 12.4755C8.83047 12.4246 8.97673 12.3984 9.12443 12.3984H14.8746L14.8751 12.3993ZM11.9983 7C12.6613 7 13.2971 7.2371 13.7659 7.65914C14.2347 8.08118 14.4981 8.65359 14.4981 9.25045C14.4981 9.84731 14.2347 10.4197 13.7659 10.8418C13.2971 11.2638 12.6613 11.5009 11.9983 11.5009C11.3353 11.5009 10.6994 11.2638 10.2306 10.8418C9.76178 10.4197 9.49841 9.84731 9.49841 9.25045C9.49841 8.65359 9.76178 8.08118 10.2306 7.65914C10.6994 7.2371 11.3353 7 11.9983 7Z"
        fill="white"
      />
      <circle cx="20.5" cy="17.5" r="3" fill="white" stroke={primaryColor} />
    </svg>
  );
}
