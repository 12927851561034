import { ReactNode } from "react";
import { NavLink, type NavLinkProps } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

// add hardLink prop to NavLinkProps
export const NavbarLink = (props: NavLinkProps & { hardLink?: boolean }) => {
  const { hardLink, ...rest } = props;
  const getClassName = ({ isActive }: { isActive: boolean }) =>
    cn(
      "w-fit",
      "group flex items-center gap-3",
      "hover:text-primary-rebrand text-black-primary font-roobert rounded-lg border border-transparent transition-all",
      {
        "text-primary-rebrand": props.to !== "" && isActive,
      },
      props.className,
    );

  if (hardLink) {
    return (
      <a
        href={String(props.to ?? "")}
        className={getClassName({ isActive: false })}
      >
        {props.children as ReactNode}
      </a>
    );
  }

  return (
    <NavLink {...rest} className={getClassName}>
      {props.children}
    </NavLink>
  );
};
