import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function News(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9145 5.54209C21.9744 5.741 22 5.94856 22 6.15612V17.1913C22 18.3848 21.0427 19.3534 19.8632 19.3534C19.6581 19.3534 19.453 19.3275 19.2564 19.2669L14.0171 17.6929C13.094 19.6042 10.812 20.3912 8.93162 19.4572C7.69231 18.8432 6.88034 17.5978 6.81197 16.1968V16.0065V15.5222L4.53846 14.839C3.62393 14.5623 3 13.7234 3 12.7634V10.5927C3 9.63274 3.62393 8.79385 4.52991 8.51711L19.2564 4.08918C20.3846 3.7519 21.5812 4.40052 21.9145 5.54209ZM8.22222 15.946V15.9979C8.22222 17.3297 9.28205 18.4021 10.5983 18.4021C11.4188 18.4021 12.188 17.9697 12.6154 17.2692L8.22222 15.946ZM19.6667 5.46426L4.93162 9.89218C4.63248 9.98732 4.4188 10.2641 4.4188 10.584V12.7548C4.4188 13.0748 4.62393 13.3602 4.93162 13.4466L19.6581 17.8746C20.0342 17.987 20.4359 17.7708 20.547 17.3903C20.5641 17.3211 20.5726 17.2519 20.5726 17.1827V6.15612C20.5726 5.7583 20.2564 5.43831 19.8632 5.43831C19.7949 5.43831 19.7265 5.44696 19.6581 5.46426H19.6667Z"
        fill={primaryColor}
      />
    </svg>
  );
}
