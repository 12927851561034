import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function List(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25004 5.58331H7.41671C6.93048 5.58331 6.46416 5.77647 6.12034 6.12028C5.77653 6.4641 5.58337 6.93042 5.58337 7.41665V18.4166C5.58337 18.9029 5.77653 19.3692 6.12034 19.713C6.46416 20.0568 6.93048 20.25 7.41671 20.25H16.5834C17.0696 20.25 17.5359 20.0568 17.8797 19.713C18.2236 19.3692 18.4167 18.9029 18.4167 18.4166V7.41665C18.4167 6.93042 18.2236 6.4641 17.8797 6.12028C17.5359 5.77647 17.0696 5.58331 16.5834 5.58331H14.75"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 5.58333C9.25 5.0971 9.44315 4.63079 9.78697 4.28697C10.1308 3.94315 10.5971 3.75 11.0833 3.75H12.9167C13.4029 3.75 13.8692 3.94315 14.213 4.28697C14.5568 4.63079 14.75 5.0971 14.75 5.58333C14.75 6.06956 14.5568 6.53588 14.213 6.8797C13.8692 7.22351 13.4029 7.41667 12.9167 7.41667H11.0833C10.5971 7.41667 10.1308 7.22351 9.78697 6.8797C9.44315 6.53588 9.25 6.06956 9.25 5.58333Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 12H14.75"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 15.6667H14.75"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
