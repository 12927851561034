import { useNavigate, useSearchParams } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import {
  SpaceEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";
import { ButtonVariant } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectContext } from "./use-project-context";

export const useCanInvest = () => {
  const project = useProjectContext();
  const authClient = getAuthClient();
  const authUrl = authClient.authorizeUrl();

  const navigate = useNavigate();
  const { user, isConnected, isQualified } = useUserBusiness();
  const { currentSpace } = useSpaceBusiness();

  const [searchParams] = useSearchParams();
  const code = searchParams?.get("code");

  const handleInvestClick = () => {
    if (user) {
      navigate(`/projects/${project?.id}/invest/`);
    } else {
      localStorage.setItem("target-url", `/projects/${project?.content?.slug}`);
      window.location.replace(authUrl);
    }
  };

  const handleWmaClick = () => {
    if (user) {
      navigate(`/wma/investments/create?project-id=${project?.id}`);
    } else {
      localStorage.setItem("target-url", `/projects/${project?.content?.slug}`);
      window.location.replace(authUrl);
    }
  };

  const canInvestStatus = {
    label: "Investir",
    displayButton: true,
    disabled: false,
    message: "",
    buttonVariant: "green" as ButtonVariant,
    handleClick: handleInvestClick,
  };

  if (!project) return canInvestStatus;

  // SI LE PROJET N'EST PAS ENCORE OUVERT À L'INVESTISSEMENT
  if (project?.status === "draft") {
    canInvestStatus.disabled = true;
    canInvestStatus.message =
      "Ce projet n'est pas encore ouvert à l'investissement.";
  }

  // SI L'UTILISATEUR N'EST PAS QUALIFIÉ
  if (isConnected && !isQualified) {
    canInvestStatus.disabled = true;
    canInvestStatus.message =
      "Vous devez compléter votre profil investisseur pour investir.";
  }

  // Permet aux utilisateurs ayant déjà investi de completer leur investissement
  // Sinon on cache le bouton
  if (project?.status === "prefinished") {
    canInvestStatus.message = "";
    canInvestStatus.displayButton = !!project?.myOngoingInvestment;
    canInvestStatus.disabled = false;
  }

  // SI L'UTILISATEUR EST UN CGP
  if (currentSpace === SpaceEnum.WMA) {
    canInvestStatus.buttonVariant = "primary";
    canInvestStatus.label = "Souscrire";
    canInvestStatus.handleClick = handleWmaClick;
  }

  // SI L'UTILISATEUR A UN CODE D'ACCÈS
  if (code === "9m6a8f1y8jfwm7lnjs") {
    canInvestStatus.message = "";
    canInvestStatus.displayButton = true;
    canInvestStatus.disabled = false;
  }

  // SI LE PROJET EST TERMINÉ ON CACHE LE BOUTON DANS TOUS LES CAS
  if (project?.status === "finished") {
    canInvestStatus.displayButton = false;
  }

  // SI LE PROJET A ATTEINT SON OBJECTIF PLAFOND
  if (
    project?.status !== "finished" &&
    project.goal &&
    project.totalAmountCollectedInCents >=
      project.goal.maxInCents + project.goal.maxInCents * 0.05
  ) {
    canInvestStatus.disabled = true;
    canInvestStatus.message =
      "Le plafond a été atteint, vous ne pouvez plus investir sur cette collecte";
  }

  return canInvestStatus;
};
