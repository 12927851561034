import React, { useRef } from "react";
import FileSaver from "file-saver";
import { toast } from "react-toastify";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Alert,
  Button,
  buttonVariants,
  Icon,
  Tooltip,
} from "@tudigo-monorepo/web-tudigo-components";

import { useEditorContext } from "../store/editor-context";
import { EditorPreviewMode } from "../types";

type EditorActionsBarProps = {
  onSave?: () => void;
};

export const EditorActionsBar = ({ onSave }: EditorActionsBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { content, setContent, settings, setSettings, resetEditor } =
    useEditorContext();

  const setEditMode = (value: boolean) => {
    setSettings((prev) => ({ ...prev, editMode: value, previewMode: false }));
  };

  const setPreviewMode = (value: EditorPreviewMode) => {
    setSettings((prev) => ({ ...prev, previewMode: value, editMode: false }));
  };

  const closeEditor = () =>
    setSettings((prev) => ({ ...prev, visible: false }));

  const saveEditorContent = () => {
    onSave?.();
    closeEditor();
  };

  const exportEditorContent = () => {
    const blob = new Blob([JSON.stringify(content)], {
      type: "application/json",
    });
    FileSaver.saveAs(blob, "editor-content.json");
  };

  const importEditorContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const newContent = new FileReader();

    newContent.onload = (e) => {
      try {
        const content = JSON.parse(e.target?.result as string);
        if (!Array.isArray(content)) throw new Error();
        setContent(content);
      } catch (e) {
        toast(() => {
          return (
            <Alert
              icon="Error"
              variant="error"
              size="S"
              title={"Le fichier n'est pas valide"}
            />
          );
        });
      } finally {
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
    };
    newContent.readAsText(file);
  };

  const uniqueId = React?.useId();

  return (
    <header className="editor-navigation border-light-2 h-[104px] border-b bg-white px-6 py-8">
      <div className="flex items-center justify-between">
        <Button
          onClick={resetEditor}
          label="Annuler"
          iconLeft="ArrowLeft"
          variant="tertiary"
        />
        <div className="flex items-center gap-x-12">
          <Button
            onClick={() => setEditMode(true)}
            singleIcon="Pen"
            variant="tertiary"
            className={cn({ "bg-accent-super-light": settings.editMode })}
            iconColor={settings.editMode ? "accent" : undefined}
          />
          <div className="flex items-center gap-x-3">
            <Button
              onClick={() => setPreviewMode("desktop")}
              singleIcon="Desktop"
              variant="tertiary"
              className={cn({
                "bg-accent-super-light": settings.previewMode === "desktop",
              })}
              iconColor={
                settings.previewMode === "desktop" ? "accent" : undefined
              }
            />
            <Button
              onClick={() => setPreviewMode("mobile")}
              singleIcon="Phone"
              variant="tertiary"
              className={cn({
                "bg-accent-super-light": settings.previewMode === "mobile",
              })}
              iconColor={
                settings.previewMode === "mobile" ? "accent" : undefined
              }
            />
          </div>
        </div>
        <div className="flex gap-2">
          <Tooltip description="Importer du contenu">
            <>
              <label
                htmlFor={uniqueId}
                className={cn(
                  "flex h-11 w-11 cursor-pointer items-center justify-center",
                  buttonVariants({ variant: "tertiary" }),
                )}
              >
                <Icon name="Plus" primaryColor={themeColors["dark-2"]} />
              </label>
              <input
                ref={inputRef}
                type="file"
                accept=".json"
                hidden
                id={uniqueId}
                onChange={importEditorContent}
              />
            </>
          </Tooltip>

          <Tooltip description="Exporter le contenu">
            <Button
              singleIcon="Export"
              onClick={exportEditorContent}
              variant="tertiary"
            />
          </Tooltip>

          <Button
            onClick={saveEditorContent}
            label="Sauvegarder"
            className="ml-6"
          />
        </div>
      </div>
    </header>
  );
};
