import { Bank } from "./models";

export interface BankShape extends Omit<Bank, "id"> {
  id: string | null;
}

export const bankShape: BankShape = {
  agencies: [],
  id: null,
  name: "",
  online: false,
  templateCommitmentLetter: "",
  templateSubscriptionForm: "",
};
