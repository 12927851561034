import { ContentType, Request, RequestParams } from "../api";

export const signaturesEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getSignatureRequestSync
   * @summary get signature request sync by id
   * @request GET:/api/v1/signature-requests/:signatureRequestId/sync-requests
   * @secure
   */
  getSignatureRequestSync: (
    signatureRequestId: string,
    params: RequestParams = {},
  ) =>
    request<void, void>({
      path: `/api/v1/signature-requests/${signatureRequestId}/sync-requests`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
