import { OrganizationPersonDocument } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const organizationPersonDocumentsEndpoints = (request: Request) => ({
  createOrganizationPersonDocument: (
    body: OrganizationPersonDocument,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationPersonDocument>, void>({
      path: `/api/v1/organization-person-documents`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  deleteOrganizationPersonDocument: (
    body: OrganizationPersonDocument,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationPersonDocument>, void>({
      path: `/api/v1/organization-person-documents/${body.id}`,
      method: "DELETE",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
