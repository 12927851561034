import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient } from "../../..";

type RequestSubscriptionSyncParam = {
  subscriptionId: string;
};

export const requestSubscriptionSync = async (
  params: RequestSubscriptionSyncParam,
) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.requestSubscriptionSync(params);

  return response.data;
};

export const useRequestSubscriptionSync = (
  options?: UseMutationOptions<
    TudigoResponse<ClubMember>,
    TudigoError,
    RequestSubscriptionSyncParam
  >,
) =>
  useMutation({
    mutationFn: (params) => requestSubscriptionSync(params),
    ...options,
  });
