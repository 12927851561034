import {
  BSRegenerationRequest,
  CancelInvestmentRequest,
  ChangeInvestmentStatusRequest,
  Document,
  EquitySavingPlan,
  Investment,
  InvestmentFeed,
  InvestmentStatusChange,
  Organization,
  Project,
  RetryInvestmentTransferRequest,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateInvestmentIntentRequestBody {
  project: {
    id: Project["id"];
  };
  totalAmountInCents?: number;
  user?: {
    id: User["id"];
  };
  paymentMethodType?: Investment["paymentMethodType"];
  investorOrganization?: {
    id: Organization["id"];
  };
  externalSignedSubscriptionForm?: Document;
  autoConsentProjectAgreementsAndSecurity?: boolean;
  equitySavingPlan?: {
    id: EquitySavingPlan["id"];
  };
}

export interface UpdateInvestmentBody {
  totalAmountInCents?: number;
  paymentMethodType?: Investment["paymentMethodType"];
  paymentConfirm?: boolean;
  investorOrganization?: {
    id: Organization["id"];
  };
}

export type CertificateTitleType = "share" | "bond" | "convertible_bond";

export const investmentsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getInvestmentById
   * @summary get investment by id
   * @request GET:/api/v1/investments/:investmentId
   * @secure
   */
  getInvestmentById: (
    investmentId: Investment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments/${investmentId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getUserInvestments: (
    userId: User["id"] | "me",
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment[]>, void>({
      path: `/api/v1/users/${userId}/investments`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getUserInvestmentFeed: (
    userId: User["id"] | "me",
    projectId: Project["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<InvestmentFeed[]>, void>({
      path: `/api/v1/users/${userId}/projects/${projectId}/investment-feed`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getInvestments
   * @summary get investments
   * @request GET:/api/v1/investments/:investmentId/feed
   * @secure
   */
  getInvestmentFeedByInvestmentId: (
    investmentId: Investment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<InvestmentFeed[]>, void>({
      path: `/api/v1/investments/${investmentId}/feed`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getInvestments
   * @summary get investments
   * @request GET:/api/v1/investments
   * @secure
   */
  getInvestments: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment[]>, void>({
      path: `/api/v1/investments`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createInvestmentIntent: (
    body: CreateInvestmentIntentRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateInvestment: (
    investmentId: Investment["id"],
    body: UpdateInvestmentBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments/${investmentId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createCancelInvestmentRequest: (
    body: CancelInvestmentRequest,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments/cancel-investment-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createBSRegenerationRequest: (
    investmentId: Investment["id"],
    body: BSRegenerationRequest,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/investments/${investmentId}/regenerate-subscription-form`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  retryInvestmentTransferRequest: (
    body: RetryInvestmentTransferRequest,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments/retry-transfer-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createInvestmentsExport: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Document>, void>({
      path: "/api/v1/investments/_export",
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createInvestorExport: (
    projectHolderProfileId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Document>, void>({
      path: `/api/v1/project-holder-profiles/${projectHolderProfileId}/investors/_export`,
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  changeInvestmentStatusRequest: (
    investmentId: Investment["id"],
    body: ChangeInvestmentStatusRequest,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment>, void>({
      path: `/api/v1/investments/${investmentId}/change-status-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getInvestmentStatusChanges: (
    investmentId: Investment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<InvestmentStatusChange[]>, void>({
      path: `/api/v1/investments/${investmentId}/status-changes`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  generateInvestmentAccountCertificate: (
    type: CertificateTitleType,
    pathParams: {
      investorId: string;
      holdingId: string;
    },
    requestParams: RequestParams = {},
  ) => {
    const { investorId, holdingId } = pathParams;

    // const endpoint =
    //   type === "share" ? "shareholder-certificate" : "bond-certificate";

    return request<Blob, void>({
      ...requestParams,
      path: `/api/v1/organizations/${investorId}/vehicle/${holdingId}/${type}`,
      method: "POST",
      body: {},
      secure: true,
      type: ContentType.Json,
      format: "blob",
    });
  },
});
