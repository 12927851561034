import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function QuoteRight(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: width || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4429 1.37598C14.0535 1.07064 14.6533 0.917968 15.2422 0.917968C16.0928 0.917968 16.9652 1.15788 17.8594 1.6377C18.7318 2.11751 19.4733 2.86995 20.084 3.89502C20.6947 4.92008 21 6.19596 21 7.72266C21 9.83822 20.3348 11.8774 19.0044 13.8403C17.6522 15.8032 15.0786 17.2863 11.2837 18.2895L10.5967 16.0649C12.6032 15.5415 14.0972 14.7454 15.0786 13.6768C16.0382 12.6299 16.5181 11.5285 16.5181 10.3726C16.5181 9.43473 16.1582 8.61686 15.4385 7.91894C14.7188 7.22103 13.8027 6.87207 12.6904 6.87207C12.4723 6.87207 12.1452 6.89388 11.709 6.9375C11.4036 6.13053 11.251 5.45443 11.251 4.90918C11.251 4.0804 11.4582 3.36068 11.8726 2.75C12.2869 2.16113 12.8104 1.70312 13.4429 1.37598ZM3.17041 1.37598C3.78109 1.07064 4.38086 0.917967 4.96973 0.917967C5.82031 0.917967 6.69271 1.15788 7.58692 1.63769C8.45931 2.11751 9.20085 2.86995 9.81152 3.89502C10.4004 4.92008 10.6948 6.19596 10.6948 7.72266C10.6948 9.83821 10.0296 11.8774 8.69922 13.8403C7.34701 15.8032 4.77344 17.2863 0.978516 18.2895L0.258789 16.0649C2.2653 15.5415 3.75928 14.7454 4.74072 13.6768C5.70036 12.6299 6.18018 11.5285 6.18018 10.3726C6.18018 9.41292 5.83122 8.58415 5.1333 7.88623C4.41358 7.21012 3.49756 6.87207 2.38525 6.87207C2.18897 6.87207 1.87272 6.89388 1.43652 6.9375C1.13119 6.13053 0.978517 5.45443 0.978517 4.90918C0.978517 4.0804 1.18571 3.36068 1.6001 2.75C2.01449 2.16113 2.53793 1.70312 3.17041 1.37598Z"
        fill={primaryColor}
      />
    </svg>
  );
}
