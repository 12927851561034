export enum AlgoliaRefinementAttributes {
  INSTRUMENT = "instrument",
  TAX_EXEMPTION = "taxExemptionMechanics",
  STATE = "states",
  MATURITY = "maturity.code",
  CATEGORY = "categories.name",
}

export type RefinementAttributesKey = keyof typeof AlgoliaRefinementAttributes;

export type RefinementAttributesValue = `${AlgoliaRefinementAttributes}`;

export const refinementValueToKey: Record<
  RefinementAttributesValue,
  RefinementAttributesKey
> = {
  instrument: "INSTRUMENT",
  taxExemptionMechanics: "TAX_EXEMPTION",
  states: "STATE",
  "maturity.code": "MATURITY",
  "categories.name": "CATEGORY",
} as const;
