import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Video(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 4H17.75C18.5801 3.99995 19.3788 4.31755 19.9822 4.88767C20.5856 5.45779 20.948 6.23719 20.995 7.066L21 7.25V16.75C21.0001 17.5801 20.6824 18.3788 20.1123 18.9822C19.5422 19.5856 18.7628 19.948 17.934 19.995L17.75 20H6.25C5.41986 20.0001 4.62117 19.6824 4.01777 19.1123C3.41437 18.5422 3.052 17.7628 3.005 16.934L3 16.75V7.25C2.99995 6.41986 3.31755 5.62117 3.88767 5.01777C4.45779 4.41437 5.23719 4.052 6.066 4.005L6.25 4ZM10.053 9.585C10.018 9.65483 9.99985 9.73189 10 9.81V14.192C10 14.2773 10.0218 14.3611 10.0633 14.4356C10.1049 14.51 10.1648 14.5726 10.2373 14.6174C10.3099 14.6622 10.3927 14.6877 10.4779 14.6915C10.563 14.6953 10.6478 14.6772 10.724 14.639L15.106 12.449C15.1892 12.4075 15.2592 12.3437 15.3081 12.2646C15.357 12.1856 15.383 12.0945 15.383 12.0015C15.383 11.9085 15.357 11.8174 15.3081 11.7384C15.2592 11.6593 15.1892 11.5955 15.106 11.554L10.724 9.363C10.6054 9.3036 10.4682 9.29372 10.3423 9.33554C10.2165 9.37736 10.1124 9.46745 10.053 9.586V9.585Z"
        fill={primaryColor}
      />
    </svg>
  );
}
