import { mockUsers } from "../users";
import { WMACustomer, WMACustomerStatusEnum } from "./models";

export const mockWMACustomersInvitations: WMACustomer[] = [
  {
    id: "1",
    wmaUser: mockUsers.WMA,
    customerUser: mockUsers.INVESTOR,
    status: WMACustomerStatusEnum.INVITED,
  },
  {
    id: "2",
    wmaUser: mockUsers.WMA,
    customerUser: mockUsers.INVESTOR,
    status: WMACustomerStatusEnum.ACCEPTED,
  },
  {
    id: "3",
    wmaUser: mockUsers.WMA,
    customerUser: mockUsers.INVESTOR,
    status: WMACustomerStatusEnum.REJECTED,
  },
  {
    id: "4",
    wmaUser: mockUsers.WMA,
    customerUser: mockUsers.INVESTOR,
    status: WMACustomerStatusEnum.CANCELED,
  },
];
