import {
  isSharesProject,
  type Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

export function useProjectCardBadges(project: Project) {
  const { t } = useTranslation();

  const getProjectMechanicLabel = () => {
    if (isSharesProject(project)) {
      return t("component.project_card.chips.shares");
    }
    if (project.bondsMechanicSettings?.isConvertible) {
      return t("component.project_card.chips.bonds_convertible");
    }

    return t("component.project_card.chips.bonds");
  };

  const tag = project.tags && project.tags.length > 0 ? project.tags[0] : null;

  const totalExtraCount =
    (project.tags ? project.tags.length - 1 : 0) +
    (project.categories ? project.categories.length : 0);

  return {
    mechanic: getProjectMechanicLabel(),
    tag,
    totalExtraCount,
  };
}
