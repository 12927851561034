import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function ReloadIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill={primaryColor}
        d="M12 4.9219C10.5301 4.92196 9.09486 5.36884 7.88472 6.20328C6.67457 7.03772 5.74666 8.22029 5.22403 9.59419C4.70139 10.9681 4.60873 12.4684 4.95832 13.8962C5.30792 15.3239 6.08325 16.6117 7.18152 17.5887C8.27978 18.5657 9.64908 19.1858 11.1078 19.3668C12.5666 19.5477 14.0459 19.281 15.3496 18.6019C16.6533 17.9228 17.7198 16.8635 18.4076 15.5644C19.0954 14.2653 19.3721 12.7879 19.201 11.3279C19.133 10.7399 19.559 10.1719 20.151 10.1719C20.666 10.1719 21.119 10.5299 21.181 11.0419C21.443 13.169 20.9583 15.321 19.8096 17.1303C18.661 18.9396 16.9198 20.294 14.8834 20.9619C12.847 21.6299 10.6419 21.57 8.64478 20.7926C6.64765 20.0151 4.98244 18.5683 3.93366 16.6993C2.88489 14.8304 2.51763 12.6552 2.89463 10.5455C3.27163 8.4358 4.36949 6.52247 6.00065 5.13239C7.63181 3.74232 9.69504 2.96178 11.8378 2.92411C13.9806 2.88645 16.07 3.59401 17.749 4.9259V4.4219C17.749 4.15655 17.8544 3.90207 18.042 3.71444C18.2297 3.52681 18.4842 3.4214 18.7495 3.4214C19.0149 3.4214 19.2693 3.52681 19.457 3.71444C19.6446 3.90207 19.75 4.15655 19.75 4.4219V7.1199L19.784 7.1719H19.75V7.4219C19.75 7.68712 19.6447 7.94147 19.4571 8.12901C19.2696 8.31655 19.0152 8.4219 18.75 8.4219H15.75C15.4848 8.4219 15.2304 8.31655 15.0429 8.12901C14.8554 7.94147 14.75 7.68712 14.75 7.4219C14.75 7.15669 14.8554 6.90233 15.0429 6.7148C15.2304 6.52726 15.4848 6.4219 15.75 6.4219H16.416C15.1505 5.44709 13.5974 4.91955 12 4.9219Z"
      />
    </svg>
  );
}
