import { datadogRum } from "@datadog/browser-rum";

import { User } from "@tudigo-monorepo/core-tudigo-api-models";

export function datadogIdentify(user: User | null) {
  if (!user) return;

  datadogRum.setUser({ ...user });
}

export function updateDatadogUserProperty(key: string, value: string) {
  datadogRum.setUserProperty(key, value);
}
