import { forwardRef, type ComponentPropsWithoutRef } from "react";

import { ProjectMechanicSettingsCard } from "../../project-mechanic-settings-card";
import {
  CProjectCard,
  CProjectCardAmount,
  CProjectCardAvatar,
  CProjectCardBadges,
  CProjectCardClubAvatar,
  CProjectCardContent,
  CProjectCardCover,
  CProjectCardDescription,
  CProjectCardFooter,
  CProjectCardHeader,
  CProjectCardImage,
  CProjectCardImageFallback,
  CProjectCardLabel,
  CProjectCardPercentage,
  CProjectCardStatusBadge,
} from "../project-card.composable";

export const ProjectCardComposed = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof CProjectCard>
>(({ project, to, className, ...props }, ref) => {
  return (
    <CProjectCard
      ref={ref}
      project={project}
      to={to}
      className={className}
      {...props}
    >
      <CProjectCardCover>
        <CProjectCardImage />
        <CProjectCardImageFallback />
        <CProjectCardStatusBadge />
        <CProjectCardClubAvatar />
      </CProjectCardCover>
      <CProjectCardContent>
        <CProjectCardHeader>
          <CProjectCardAvatar />
          <CProjectCardLabel />
        </CProjectCardHeader>
        <CProjectCardBadges />
        <CProjectCardDescription />
        <ProjectMechanicSettingsCard project={project} />
        <CProjectCardFooter>
          <CProjectCardAmount />
          <CProjectCardPercentage />
        </CProjectCardFooter>
      </CProjectCardContent>
    </CProjectCard>
  );
});
