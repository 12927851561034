import type { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export const RemainingItemsList = <T,>(props: {
  limit: number;
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex items-center", props.className)}>
      {props.items.slice(0, props.limit).map((item, index) => {
        const element = props.renderItem(item, index);

        if (typeof element === "string") {
          return element.concat(props.items.length > index + 1 ? ", " : "");
        }

        return element;
      })}

      {props.items.length > props.limit && (
        <span className="ml-0.5 block">
          + {props.items.length - props.limit}
        </span>
      )}
    </div>
  );
};
