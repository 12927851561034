import {
  RadioGroup,
  RadioGroupProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
  useFormContext,
} from "../../index";

export type SmartRadioGroupFieldProps<T, O> = RadioGroupProps<T> &
  SmartFieldCommonProps<O>;

export function SmartRadioGroupField<T, O>(
  props: SmartRadioGroupFieldProps<T, O>,
) {
  const { form, handleFormChange } = useFormContext();

  return (
    <RadioGroup
      {...props}
      selectedValue={extractValueFromPath(form, props.name)}
      onChange={(item) => {
        handleFormChange({
          ...updateObject(form, props.name, item),
        });
      }}
    />
  );
}

export default SmartRadioGroupField;
