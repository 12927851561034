import { useRefinementList } from "react-instantsearch";

import {
  algoliaRefinementAttributes,
  type AlgoliaRefinementAttributesKey,
} from "../models/algolia-refinement-attributes";

export const useCanRefineList = () => {
  const { items: instrumentsRefinements } = useRefinementList({
    attribute: algoliaRefinementAttributes.instrument,
  });
  const { items: taxExemptionsRefinements } = useRefinementList({
    attribute: algoliaRefinementAttributes.tax_exemption,
  });
  const { items: riskRefinements } = useRefinementList({
    attribute: algoliaRefinementAttributes.risk,
  });
  const { items: categoriesRefinements } = useRefinementList({
    attribute: algoliaRefinementAttributes.category,
  });
  const { items: maturitiesRefinements } = useRefinementList({
    attribute: algoliaRefinementAttributes.maturity,
  });

  return (key: AlgoliaRefinementAttributesKey) => {
    if (key === "instrument") {
      return instrumentsRefinements.length > 0;
    }
    if (key === "tax_exemption") {
      return taxExemptionsRefinements.length > 0;
    }
    if (key === "risk") {
      return riskRefinements.length > 0;
    }
    if (key === "category") {
      return categoriesRefinements.length > 0;
    }
    if (key === "maturity") {
      return maturitiesRefinements.length > 0;
    }

    return false;
  };
};
