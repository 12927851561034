import { useGetWebflowNavbarAboutData } from "@tudigo-monorepo/web-tudigo-api-client";

import { formatNavbarCardWebflowItem } from "./format-navbar-card-weblow-item.util";
import {
  NavbarCard,
  NavbarCardDescription,
  NavbarCardDescriptionContent,
  NavbarCardDescriptionHeading,
  NavbarCardHeading,
  NavbarCardImage,
  NavbarCardLink,
} from "./navbar-card";
import { NavbarCardSkeleton } from "./navbar-card.skeleton";

export const NavbarCardAbout = () => {
  const { data, isLoading } = useGetWebflowNavbarAboutData();
  const featuredItem = formatNavbarCardWebflowItem(data?.items);

  if (isLoading) {
    return <NavbarCardSkeleton heading="Notre prochain webinaire" />;
  }

  return (
    <NavbarCard>
      <NavbarCardHeading>Notre prochain webinaire</NavbarCardHeading>
      <NavbarCardImage src={featuredItem.coverUrl} />
      <NavbarCardDescription>
        <NavbarCardDescriptionHeading>
          {featuredItem.name}
        </NavbarCardDescriptionHeading>
        <NavbarCardDescriptionContent>
          {String(featuredItem.description)}
        </NavbarCardDescriptionContent>
      </NavbarCardDescription>
      <NavbarCardLink
        to={`https://tudigo.co/blog/webinaires/${featuredItem.slug}`}
      >
        En savoir plus
      </NavbarCardLink>
    </NavbarCard>
  );
};
