/* eslint-disable-next-line */
import { FormEvent } from "react";

import { TextArea } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export function SmartTextAreaField<T>(props: SmartFieldCommonProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <TextArea
      {...props}
      value={extractValueFromPath(form, props.name)}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onChange={(event: FormEvent<HTMLTextAreaElement>) => {
        handleFormChange({
          ...updateObject(form, props.name, event.currentTarget.value),
        });
      }}
    />
  );
}
