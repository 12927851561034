import {
  isComingProject,
  isFinishedProject,
  isNewProject,
  isPrefinishedProject,
  type Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { getDate } from "@tudigo-monorepo/core-tudigo-utils";
import { useProjectBusiness } from "@tudigo-monorepo/web-feature-projects";

import type { ProjectCardStatusBadgeVariant } from "../project-card.composable";

const TRANSLATION_BASE_KEY = "component.project_card.header.flag";

export function useProjectCardStatusBadge(project?: Project): {
  label: string | null;
  variant: ProjectCardStatusBadgeVariant;
} {
  const label = useProjectCardStatusBadgeLabel(project);
  const variant = useProjectCardStatusBadgeVariant(project);

  return { label, variant };
}

function useProjectCardStatusBadgeLabel(project?: Project): string | null {
  const { t } = useTranslation();
  const {
    isLastDays,
    isLastHours,
    endDate,
    daysLeft,
    hoursLeft,
    isEndingSoon,
  } = useProjectBusiness(project);

  if (isNewProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.new`);
  }

  if (isComingProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.coming_soon_rebrand`);
  }

  if (isPrefinishedProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.financed`);
  }

  if (isFinishedProject(project) && endDate) {
    return t(`${TRANSLATION_BASE_KEY}.financed_in`, {
      year: getDate(endDate).getFullYear(),
    });
  }

  if (isEndingSoon && isLastHours) {
    return t(`${TRANSLATION_BASE_KEY}.hours_left`, { hoursLeft });
  }

  if (isEndingSoon && isLastDays) {
    return t(`${TRANSLATION_BASE_KEY}.days_left`, { daysLeft });
  }

  if (!project || !project.marketingSettings?.flag) {
    return t(`${TRANSLATION_BASE_KEY}.in_progress`);
  }

  return null;
}

function useProjectCardStatusBadgeVariant(
  project?: Project,
): ProjectCardStatusBadgeVariant {
  const { isLastDays, isLastHours, isEndingSoon } = useProjectBusiness(project);

  if (isNewProject(project)) {
    return "new";
  }

  if (isComingProject(project)) {
    return "coming";
  }

  if (isFinishedProject(project) || isPrefinishedProject(project)) {
    return "success";
  }

  if (isEndingSoon && isLastHours) {
    return "destructive";
  }

  if (isEndingSoon && isLastDays) {
    return "warning";
  }

  if (!project || !project.marketingSettings?.flag) {
    return "progress";
  }

  return "default";
}
