import colors from "./lib/config/colors.json";

import "./lib/styles/main.css";

export * from "./lib/utils/tailwind";
export * from "./lib/utils/z-index";

type Colors = Record<keyof typeof colors, string>;

export const themeColors: Colors = { ...colors };
