import React, { forwardRef, InputHTMLAttributes } from "react";

import { InputGroup, InputGroupProps } from "../input-group/input-group";

type TextFieldProps = {
  validated?: boolean;
} & InputGroupProps &
  InputHTMLAttributes<HTMLInputElement>;

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props: TextFieldProps, ref) => {
    const {
      id,
      label,
      type,
      className,
      autoComplete,
      name,
      value = "",
      pattern,
      placeholder,
      autoFocus = false,
      disabled = false,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
      groupRef,
      iconRight,
      valid,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    return (
      <InputGroup
        ref={ref}
        id={inputId}
        className={className}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated ?? valid}
        groupRef={groupRef}
        iconRight={iconRight}
        renderInput={(inputProps) => {
          return (
            <input
              type={type ?? "text"}
              id={inputId}
              value={value}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              name={name}
              onKeyDown={onKeyDown}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              pattern={pattern}
              onFocus={onFocus}
              onChange={onChange}
              {...inputProps}
            />
          );
        }}
      />
    );
  },
);
