import { useLocation } from "react-router-dom";

import type { SubscribeClubStep } from "../models/subscribe-club-step";
import { getSubscribeClubStepFromPath } from "../utils/get-subscribe-club-step-from-path";

const useGetSubscribeClubStepFromUrl = (): SubscribeClubStep | null => {
  const { pathname: urlPathname } = useLocation();
  const indexOfSteps = urlPathname.indexOf("steps");

  if (indexOfSteps === -1) {
    return null;
  }

  const stepPath = urlPathname.slice(indexOfSteps).replace("steps/", "");

  return getSubscribeClubStepFromPath(stepPath);
};

export { useGetSubscribeClubStepFromUrl };
