import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

export const NavbarMenuResources = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink to="https://tudigo.co/blog" onClick={props.onLinkClick}>
          Le Blog de Tudigo
          <NavbarMenuLinkLegend>
            Retrouvez tous nos articles, guides et webinaires
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/comment-investir"
          onClick={props.onLinkClick}
        >
          Apprendre à investir
          <NavbarMenuLinkLegend>
            La checklist avant de commencer à investir
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to="https://tudigo.co/defiscalisation"
          onClick={props.onLinkClick}
        >
          Défiscalisation
          <NavbarMenuLinkLegend>
            Le guide complet pour réduire ses impôts grâce au non coté
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to="https://tudigo.co/defiscalisation"
          onClick={props.onLinkClick}
        >
          Stratégie d’investissement
          <NavbarMenuLinkLegend>
            Alliez rendement, diversification et impact
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/crowdfunding"
          onClick={props.onLinkClick}
        >
          Crowdfunding
          <NavbarMenuLinkLegend>
            {`Comprendre l'investissement non coté`}
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink to="https://tudigo.co/faq" onClick={props.onLinkClick}>
          FAQ
          <NavbarMenuLinkLegend>
            Les réponses à toutes vos questions sur Tudigo et le non coté
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
