import React from "react";
import {
  ContentBlock,
  ContentState,
  DraftDecoratorComponentProps,
} from "draft-js";

const Link = (props: DraftDecoratorComponentProps) => {
  if (!props.entityKey) return null;
  const { url } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <a href={url} className="link">
      {props.children}
    </a>
  );
};

export const linkTool = {
  strategy: (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();

      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  },
  component: Link,
};
