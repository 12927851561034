import * as React from "react";
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from "embla-carousel-react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../old/icons/icon";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  scrollTo: (index: number) => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  activeSnap: number;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [activeSnap, setActiveSnap] = React.useState(0);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setActiveSnap(api.selectedScrollSnap());
      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const scrollTo = React.useCallback(
      (index: number) => {
        api?.scrollTo(index);
      },
      [api],
    );

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          scrollTo,
          activeSnap,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className="overflow-hidden">
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
          className,
        )}
        {...props}
      />
    </div>
  );
});

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        "min-w-0 shrink-0 grow-0 basis-full",
        orientation === "horizontal" ? "pl-4" : "pt-4",
        className,
      )}
      {...props}
    />
  );
});

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    onSlideChange?: (newSlide: number) => void;
  }
>(({ className, onSlideChange, ...props }, ref) => {
  const { api, scrollPrev, canScrollPrev } = useCarousel();
  const currentSlide = api?.selectedScrollSnap() ?? 0;

  const handlePrevClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    scrollPrev();
    onSlideChange?.(currentSlide - 1);
  };

  return (
    <button
      ref={ref}
      className={cn(
        "h-8 w-8 rounded-full border bg-white",
        "flex items-center justify-center",
        !canScrollPrev && "cursor-not-allowed opacity-50",
        className,
      )}
      disabled={!canScrollPrev}
      onClick={handlePrevClick}
      {...props}
    >
      <Icon name="Previous" size="XS" primaryColor={themeColors["dark-2"]} />
      <span className="sr-only">Previous slide</span>
    </button>
  );
});

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    onSlideChange?: (newSlide: number) => void;
  }
>(({ className, onSlideChange, ...props }, ref) => {
  const { api, scrollNext, canScrollNext } = useCarousel();
  const currentSlide = api?.selectedScrollSnap() ?? 0;

  const handleNextClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    scrollNext();
    onSlideChange?.(currentSlide + 1);
  };

  return (
    <button
      ref={ref}
      className={cn(
        "h-8 w-8 rounded-full border bg-white",
        "flex items-center justify-center",
        !canScrollNext && "cursor-not-allowed opacity-50",
        className,
      )}
      disabled={!canScrollNext}
      onClick={handleNextClick}
      {...props}
    >
      <Icon name="Next" size="XS" primaryColor={themeColors["dark-2"]} />
      <span className="sr-only">Next slide</span>
    </button>
  );
});

const FloatingCarouselPrevious = React.forwardRef<HTMLButtonElement, any>(
  ({ className, ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <button
        ref={ref}
        className={cn(
          "shadow-card absolute h-8 w-8 rounded-full bg-white",
          "flex items-center justify-center",
          orientation === "horizontal"
            ? "-left-4 top-1/2 -translate-y-1/2"
            : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
          !canScrollPrev && "cursor-not-allowed opacity-50",
          className,
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <Icon name="Previous" size="XS" primaryColor={themeColors["dark-2"]} />
        <span className="sr-only">Previous slide</span>
      </button>
    );
  },
);

const FloatingCarouselNext = React.forwardRef<HTMLButtonElement, any>(
  ({ className, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
      <button
        ref={ref}
        className={cn(
          "shadow-card absolute h-8 w-8 rounded-full bg-white",
          "flex items-center justify-center",
          orientation === "horizontal"
            ? "-right-4 top-1/2 -translate-y-1/2"
            : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
          !canScrollNext && "cursor-not-allowed opacity-50",
          className,
        )}
        disabled={!canScrollNext}
        onClick={() => {
          console.log("scrollNext");

          scrollNext();
        }}
        {...props}
      >
        <Icon name="Next" size="XS" primaryColor={themeColors["dark-2"]} />
        <span className="sr-only">Next slide</span>
      </button>
    );
  },
);

type CarouselThumbProps = {
  slideIndex: number;
  onClick?: () => void;
} & Omit<React.ComponentProps<"button">, "onClick">;

const CarouselThumb = ({
  className,
  children,
  slideIndex,
  onClick,
  ...props
}: CarouselThumbProps) => {
  const { scrollTo, activeSnap } = useCarousel();

  const handleThumbClick = () => {
    if (onClick) {
      onClick();
    } else {
      scrollTo(slideIndex);
    }
  };

  return (
    <button
      {...props}
      className={cn(
        "rounded-lg border border-transparent transition-colors",
        {
          "border-accent-medium border": activeSnap === slideIndex,
        },
        className,
      )}
      onClick={handleThumbClick}
    >
      {children}
    </button>
  );
};

const CarouselThumbList = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "hidden auto-cols-auto grid-cols-4 gap-3 sm:grid",
        className,
      )}
    >
      {children}
    </div>
  );
};

const CarouselRemainingThumbsCount = (props: {
  itemsCount: number;
  currentIndex: number;
  className?: string;
}) => {
  const { itemsCount, currentIndex, className } = props;
  const isVisible = itemsCount > 4 && currentIndex === 3;

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={cn(
        "absolute left-[calc(50%-16px)] top-[calc(50%-16px)] h-8 w-8",
        "font-montserrat text-dark-2 text-center text-xs font-bold",
        "shadow-card rounded-full bg-white p-2 opacity-80",
        className,
      )}
    >
      +{itemsCount - (currentIndex + 1)}
    </div>
  );
};

export {
  useCarousel,
  type CarouselApi,
  Carousel as CarouselRoot,
  CarouselContent,
  CarouselItem,
  FloatingCarouselPrevious,
  FloatingCarouselNext,
  CarouselThumb,
  CarouselThumbList,
  CarouselPrevious,
  CarouselNext,
  CarouselRemainingThumbsCount,
};
