import React from "react";

import { usePopperContext } from "./context";

export function PopperTrigger(
  props: React.PropsWithChildren<{ className?: string }>,
) {
  const { children, className } = props;
  const { setIsOpen, setRefElement, clickable } = usePopperContext();

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setIsOpen(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const containerProps = clickable
    ? { onClick }
    : {
        onMouseEnter: () => setIsOpen(true),
        onMouseLeave: () => setIsOpen(false),
      };

  return (
    <div ref={setRefElement} className={className} {...containerProps}>
      {children}
    </div>
  );
}
