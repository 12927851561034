import { PropsWithChildren, type ComponentProps } from "react";

import { NavigationMenuTrigger } from "../../../ui/navigation-menu";
import { NavbarLabel } from "../navbar-label";

export interface HorizontalMenuTriggerProps
  extends PropsWithChildren,
    ComponentProps<typeof NavigationMenuTrigger> {
  labelClassName?: string;
}

export const HorizontalMenuTrigger = (props: HorizontalMenuTriggerProps) => {
  const { labelClassName, children, ...rest } = props;

  return (
    <NavigationMenuTrigger
      {...rest}
      overrideClassName
      className="group/item h-[72px] px-2"
    >
      <NavbarLabel className={labelClassName}>{children}</NavbarLabel>
    </NavigationMenuTrigger>
  );
};
