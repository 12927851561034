import {
  CalendarContainer,
  type CalendarContainerProps,
  type ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import { FormattedDate } from "react-intl";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { Title } from "../title";
import { Typography } from "../typography";

const CalendarContainerElement = (props: CalendarContainerProps) => {
  return (
    <CalendarContainer className={props.className}>
      {props.children}
    </CalendarContainer>
  );
};

const CustomHeaderElement = (props: ReactDatePickerCustomHeaderProps) => {
  const {
    decreaseYear,
    decreaseMonth,
    increaseYear,
    increaseMonth,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
    monthDate,
  } = props;

  return (
    <div className="flex items-center justify-center">
      <button
        className="p-3"
        onClick={(e) => {
          decreaseYear();
          e.preventDefault();
          e.stopPropagation();
        }}
        disabled={prevYearButtonDisabled}
      >
        <Icon
          name="FirstPagination"
          primaryColor={themeColors["dark-2"]}
          size="XS"
        />
      </button>
      <button
        className="p-3"
        onClick={(e) => {
          decreaseMonth();
          e.preventDefault();
          e.stopPropagation();
        }}
        disabled={prevMonthButtonDisabled}
      >
        <Icon
          name="ChevronRight"
          primaryColor={themeColors["dark-2"]}
          size="XS"
          className="rotate-180"
        />
      </button>
      <Title h3Brand="brand-2" level="h3" className="text-primary">
        <FormattedDate value={monthDate} month="short" year="numeric" />
      </Title>
      <button
        className="p-3"
        onClick={(e) => {
          increaseMonth();
          e.preventDefault();
          e.stopPropagation();
        }}
        disabled={nextMonthButtonDisabled}
      >
        <Icon
          name="ChevronRight"
          primaryColor={themeColors["dark-2"]}
          size="XS"
        />
      </button>
      <button
        className="p-3 "
        onClick={(e) => {
          increaseYear();
          e.preventDefault();
          e.stopPropagation();
        }}
        disabled={nextYearButtonDisabled}
      >
        <Icon
          name="LastPagination"
          primaryColor={themeColors["dark-2"]}
          size="XS"
        />
      </button>
    </div>
  );
};

const DayContentElement = (dayOfMonth: number) => {
  return <span className="z-10">{dayOfMonth}</span>;
};

const FormattedWeekdayElement = (dayOfWeek: string) => {
  switch (dayOfWeek) {
    case "Sunday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-24")} />
        </Typography>
      );
    case "Monday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-25")} />
        </Typography>
      );
    case "Tuesday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-26")} />
        </Typography>
      );
    case "Wednesday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-27")} />
        </Typography>
      );
    case "Thursday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-28")} />
        </Typography>
      );
    case "Friday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-29")} />
        </Typography>
      );
    case "Saturday":
      return (
        <Typography variant="body3-semibold">
          <FormattedDate weekday="narrow" value={new Date("2023-12-30")} />
        </Typography>
      );
  }
};

export {
  CalendarContainerElement,
  CustomHeaderElement,
  DayContentElement,
  FormattedWeekdayElement,
};
