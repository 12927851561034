/* eslint-disable-next-line */
import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
  useFormContext,
} from "@tudigo-monorepo/web-feature-form";
import {
  InputSelect,
  InputSelectProps,
} from "@tudigo-monorepo/web-tudigo-components";

export type SmartSelectFieldProps<T, U, O> = InputSelectProps<T, U> &
  SmartFieldCommonProps<O>;

export function SmartSelectField<T, U, O>(
  props: SmartSelectFieldProps<T, U, O>,
) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <InputSelect<T, U>
      {...props}
      label={props.label}
      selectedValue={extractValueFromPath(form, props.name)}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onChange={(item: U | U[]) => {
        handleFormChange({
          ...updateObject(form, props.name, item),
        });
      }}
    />
  );
}

export default SmartSelectField;
