const SITE_URL = import.meta.env["VITE_WEBSITE_URL"];
const url = new URL(`${SITE_URL}/nos-pepites`).href;

const HighlightsCard = () => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="@container flex h-[99px] w-full items-center overflow-hidden rounded-lg border bg-white"
    >
      <div
        className="@md:hidden block h-[99px] w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/pepites-mobile.png')",
        }}
      />
      <div
        className="@md:block hidden h-[99px] w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/pepites.png')",
        }}
      />
    </a>
  );
};

export { HighlightsCard };
