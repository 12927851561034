import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import {
  EquitySavingPlan,
  equitySavingPlanShape,
  EquitySavingPlanType,
  Organization,
} from "@tudigo-monorepo/core-tudigo-api-models";

export type AvailableAccountType = "company" | EquitySavingPlanType;

interface InvestmentAccountContextValue {
  error: TudigoError | null;
  equitySavingPlan: EquitySavingPlan;
  isModalOpen: boolean;
  organization?: Organization;
  selectedAccountType: AvailableAccountType | undefined;
  onModalOpenChange: (open: boolean) => void;
  setSelectedAccountType: (type: AvailableAccountType | undefined) => void;
  setEquitySavingPlan: (data: EquitySavingPlan) => void;
  setError: (error: TudigoError | null) => void;
  setIsModalOpen: (open: boolean) => void;
}

export const InvestmentAccountContext =
  createContext<InvestmentAccountContextValue>({
    error: null,
    equitySavingPlan: equitySavingPlanShape,
    isModalOpen: false,
    selectedAccountType: undefined,
    onModalOpenChange: () => {},
    setEquitySavingPlan: () => {},
    setError: () => {},
    setIsModalOpen: () => {},
    setSelectedAccountType: () => {},
  });

export const useInvestmentAccountContext = () => {
  return useContext(InvestmentAccountContext);
};

type InvestmentAccountProviderProps = PropsWithChildren<{
  equitySavingPlan?: EquitySavingPlan;
  organization?: Organization;
}>;

export function InvestmentAccountProvider({
  children,
  equitySavingPlan: equitySavingPlanProp,
  organization,
}: InvestmentAccountProviderProps) {
  const [error, setError] = useState<TudigoError | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [equitySavingPlan, setEquitySavingPlan] = useState<EquitySavingPlan>(
    equitySavingPlanShape,
  );
  const [selectedAccountType, setSelectedAccountType] = useState<
    AvailableAccountType | undefined
  >(undefined);

  useEffect(() => {
    if (equitySavingPlanProp) {
      setEquitySavingPlan(equitySavingPlanProp);
    }
  }, [equitySavingPlanProp, setEquitySavingPlan]);

  const resetStates = () => {
    setIsModalOpen(false);
    setSelectedAccountType(undefined);
    setEquitySavingPlan(equitySavingPlanShape);
  };

  const onModalOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        return setIsModalOpen(true);
      }

      if (equitySavingPlanProp) {
        return setIsModalOpen(false);
      }

      resetStates();
    },
    [equitySavingPlanProp],
  );

  const value = useMemo(() => {
    return {
      error,
      equitySavingPlan,
      isModalOpen,
      selectedAccountType,
      organization,
      onModalOpenChange,
      setSelectedAccountType,
      setEquitySavingPlan,
      setError,
      setIsModalOpen,
    };
  }, [
    error,
    equitySavingPlan,
    isModalOpen,
    selectedAccountType,
    organization,
    onModalOpenChange,
  ]);

  return (
    <InvestmentAccountContext.Provider value={value}>
      {children}
    </InvestmentAccountContext.Provider>
  );
}
