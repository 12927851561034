import {
  ClubMember,
  ClubMemberStatusEnum,
  SignatureRequestStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";

import {
  SubscribeClubStepPage,
  subscribeClubStepsConfig,
  type SubscribeClubStep,
} from "../models/subscribe-club-step";

const getAvailableClubSubscriptionSteps = (
  clubMember?: ClubMember,
): SubscribeClubStep[] => {
  if (!clubMember) {
    return [];
  }

  const isOngoingSub =
    !!clubMember.status &&
    [
      ClubMemberStatusEnum.DRAFT.toString(),
      ClubMemberStatusEnum.FOLLOWING.toString(),
    ].includes(clubMember.status);

  const isActiveSub =
    clubMember.status === ClubMemberStatusEnum.ACTIVE.toString();

  const isSignedContract =
    clubMember?.contract?.signatureRequest?.status ===
    SignatureRequestStatusEnum.DONE.toString();

  if (isOngoingSub && !isSignedContract) {
    return [
      subscribeClubStepsConfig[SubscribeClubStepPage.STEP_1_OFFER],
      subscribeClubStepsConfig[SubscribeClubStepPage.STEP_2_SIGN],
    ];
  }

  if (isOngoingSub && isSignedContract) {
    return [subscribeClubStepsConfig[SubscribeClubStepPage.STEP_3_PAYMENT]];
  }

  if (isActiveSub) {
    return [subscribeClubStepsConfig[SubscribeClubStepPage.STEP_4_SUCCESS]];
  }

  console.log("Invalid club member status :", clubMember.status);

  return [];
};

export { getAvailableClubSubscriptionSteps };
