import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CardTextWithImage(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "20",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M17.75 0.5C18.2141 0.5 18.6592 0.684375 18.9874 1.01256C19.3156 1.34075 19.5 1.78587 19.5 2.25V13.755C19.5 14.2191 19.3156 14.6642 18.9874 14.9924C18.6592 15.3206 18.2141 15.505 17.75 15.505H2.25C2.02019 15.505 1.79262 15.4597 1.5803 15.3718C1.36798 15.2838 1.17506 15.1549 1.01256 14.9924C0.850061 14.8299 0.721157 14.637 0.633211 14.4247C0.545265 14.2124 0.5 13.9848 0.5 13.755V2.25C0.5 1.78587 0.684374 1.34075 1.01256 1.01256C1.34075 0.684375 1.78587 0.5 2.25 0.5H17.75Z"
        stroke={primaryColor}
      />
      <path
        d="M9.76667 7.76355L9.80356 7.732C9.85986 7.69095 9.9276 7.66857 9.99728 7.66801C10.067 7.66745 10.1351 7.68874 10.192 7.72889L10.2333 7.76355L13.3124 10.7858C13.085 10.9262 12.8229 11.0003 12.5556 11H7.44445C7.17718 11.0005 6.91508 10.9264 6.68756 10.7862L9.76667 7.76355L9.80356 7.732L9.76667 7.76311V7.76355ZM12.5556 3C12.9386 3 13.306 3.15218 13.5769 3.42307C13.8478 3.69395 14 4.06135 14 4.44444V9.55555C14 9.83422 13.9209 10.0947 13.7844 10.3156L10.7004 7.28755L10.6436 7.236C10.4639 7.08492 10.2368 7.00186 10.002 7.00137C9.76729 7.00088 9.53985 7.083 9.35956 7.23333L9.29956 7.288L6.21556 10.3151C6.07423 10.087 5.99957 9.82389 6 9.55555V4.44444C6 4.06135 6.15218 3.69395 6.42307 3.42307C6.69396 3.15218 7.06136 3 7.44445 3H12.5556ZM11.6676 4.33333C11.5361 4.33333 11.406 4.35922 11.2845 4.40952C11.1631 4.45982 11.0528 4.53355 10.9598 4.62649C10.8669 4.71943 10.7932 4.82976 10.7429 4.9512C10.6926 5.07263 10.6667 5.20278 10.6667 5.33422C10.6667 5.46566 10.6926 5.59581 10.7429 5.71724C10.7932 5.83868 10.8669 5.94902 10.9598 6.04196C11.0528 6.1349 11.1631 6.20862 11.2845 6.25892C11.406 6.30922 11.5361 6.33511 11.6676 6.33511C11.933 6.33511 12.1876 6.22966 12.3753 6.04196C12.563 5.85425 12.6684 5.59967 12.6684 5.33422C12.6684 5.06877 12.563 4.81419 12.3753 4.62649C12.1876 4.43878 11.933 4.33333 11.6676 4.33333ZM11.6676 5C11.7114 5 11.7549 5.00864 11.7955 5.02544C11.836 5.04224 11.8729 5.06686 11.9039 5.09789C11.9349 5.12893 11.9595 5.16577 11.9763 5.20632C11.9931 5.24687 12.0018 5.29033 12.0018 5.33422C12.0018 5.37811 11.9931 5.42157 11.9763 5.46212C11.9595 5.50267 11.9349 5.53952 11.9039 5.57055C11.8729 5.60159 11.836 5.62621 11.7955 5.643C11.7549 5.6598 11.7114 5.66844 11.6676 5.66844C11.5789 5.66844 11.4939 5.63323 11.4312 5.57055C11.3685 5.50787 11.3333 5.42286 11.3333 5.33422C11.3333 5.24558 11.3685 5.16057 11.4312 5.09789C11.4939 5.03521 11.5789 5 11.6676 5Z"
        fill={primaryColor}
      />
      <rect
        x="3.25"
        y="12.25"
        width="13.5"
        height="0.5"
        rx="0.25"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}
