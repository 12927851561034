import React, { forwardRef, TextareaHTMLAttributes, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { InputGroup, InputGroupProps } from "../input-group/input-group";

type TextAreaProps = {
  validated?: boolean;
} & InputGroupProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props: TextAreaProps, ref) => {
    const {
      id,
      label,
      className,
      autoComplete,
      name,
      value = "",
      placeholder,
      autoFocus = false,
      disabled = false,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
      groupRef,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    const inputRef = React.useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.style.height = "0px";
        const scrollHeight = inputRef.current.scrollHeight;
        inputRef.current.style.height = scrollHeight + "px";
      }
    }, [inputRef, value]);

    return (
      <InputGroup
        groupRef={groupRef}
        ref={mergeRefs([ref, inputRef])}
        id={inputId}
        className={className}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated}
        renderTextarea={(inputProps) => (
          <textarea
            id={inputId}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            name={name}
            onKeyDown={onKeyDown}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            onFocus={onFocus}
            onChange={onChange}
            style={{
              minHeight: "149px",
            }}
            className={cn(inputProps.className, "resize-none")}
            ref={inputProps.ref}
          />
        )}
      />
    );
  },
);
