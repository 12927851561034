import React from "react";
import { useIntl } from "react-intl";

import { Article } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Chip } from "@tudigo-monorepo/web-tudigo-components";

interface ArticleCardProps {
  article: Article;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const { formatDate } = useIntl();

  return (
    <a
      href={`https://www.tudigo.co/blog/articles/${article.fieldData?.slug}`}
      target="_blank noopener noreferrer"
      className="group relative block h-[300px] w-full flex-1 overflow-hidden rounded-lg"
    >
      <article className="flex h-full">
        <img
          src={
            article.fieldData?.thumbnailImage?.url ??
            article.fieldData?.imageDeCover?.url
          }
          alt="Hello"
          className="absolute h-full w-full transform object-cover transition-transform group-hover:scale-110"
        />
        <div className="absolute h-full w-full bg-gradient-to-t from-[rgba(0,0,0,0.8)] to-[rgba(0,0,0,0.2)]" />
        <div className="z-10  flex h-full w-full flex-col justify-end p-8 text-white">
          <div className="mb-2 flex flex-col justify-between gap-4 xl:flex-row">
            <div
              className={cn("flex flex-wrap items-center gap-2", {
                hidden:
                  !article.fieldData?.thematiquesAssociees ||
                  article.fieldData?.thematiquesAssociees?.length === 0,
              })}
            >
              {article.fieldData?.thematiquesAssociees?.map(
                (tag, index) =>
                  index <= 3 && <Chip key={tag} label={tag} type="outlined" />,
              )}
            </div>
            <p>
              {formatDate(article.fieldData?.dateDePublicationAffichee, {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </p>
          </div>
          <h3 className="line-clamp-3 text-lg font-semibold">
            {article.fieldData?.name}
          </h3>
        </div>
      </article>
    </a>
  );
};
