import {
  Document,
  Organization,
  OrganizationBankAccount,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateOrganizationBankAccountRequestBody {
  organization: {
    id: Organization["id"];
  };
  bankAccountNumber: string;
  holderName: string;
  document: Document;
}

export const organizationBankAccountsEndpoints = (request: Request) => ({
  createOrganizationBankAccount: (
    body: CreateOrganizationBankAccountRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationBankAccount>, void>({
      path: `/api/v1/organization-bank-accounts`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
