import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Invest(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 4.25C5 4.05109 4.92098 3.86032 4.78033 3.71967C4.63968 3.57902 4.44891 3.5 4.25 3.5C4.05109 3.5 3.86032 3.57902 3.71967 3.71967C3.57902 3.86032 3.5 4.05109 3.5 4.25V18.75C3.5 19.716 4.284 20.5 5.25 20.5H19.75C19.9489 20.5 20.1397 20.421 20.2803 20.2803C20.421 20.1397 20.5 19.9489 20.5 19.75C20.5 19.5511 20.421 19.3603 20.2803 19.2197C20.1397 19.079 19.9489 19 19.75 19H5.25C5.1837 19 5.12011 18.9737 5.07322 18.9268C5.02634 18.8799 5 18.8163 5 18.75V4.25ZM14 6.75C14 6.55109 14.079 6.36032 14.2197 6.21967C14.3603 6.07902 14.5511 6 14.75 6H19.752C19.9509 6 20.1417 6.07902 20.2823 6.21967C20.423 6.36032 20.502 6.55109 20.502 6.75V11.755C20.502 11.9539 20.423 12.1447 20.2823 12.2853C20.1417 12.426 19.9509 12.505 19.752 12.505C19.5531 12.505 19.3623 12.426 19.2217 12.2853C19.081 12.1447 19.002 11.9539 19.002 11.755V8.559L13.78 13.78C13.6394 13.9205 13.4488 13.9993 13.25 13.9993C13.0512 13.9993 12.8606 13.9205 12.72 13.78L10.75 11.81L7.53 15.03C7.38783 15.1625 7.19978 15.2346 7.00548 15.2312C6.81118 15.2277 6.62579 15.149 6.48838 15.0116C6.35097 14.8742 6.27225 14.6888 6.26883 14.4945C6.2654 14.3002 6.33752 14.1122 6.47 13.97L10.22 10.22C10.3606 10.0795 10.5512 10.0007 10.75 10.0007C10.9488 10.0007 11.1394 10.0795 11.28 10.22L13.25 12.19L17.94 7.5H14.75C14.5511 7.5 14.3603 7.42098 14.2197 7.28033C14.079 7.13968 14 6.94891 14 6.75Z"
        fill={primaryColor}
      />
    </svg>
  );
}
