import { useCallback, useMemo } from "react";
import { Table as TanstackTable } from "@tanstack/react-table";
import _ from "lodash";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { Typography } from "../typography";

type PaginationProps<I> = {
  table: TanstackTable<I>;
  interval?: number;
  className?: string | undefined;
};

export function Pagination<I>(props: PaginationProps<I>) {
  const { table, className } = props;

  const interval = props.interval || 1;
  const currentPage = table.getState().pagination.pageIndex + 1;
  const pageCount = table.getPageCount();

  const visiblePageSelector = useMemo(() => {
    const min = Math.max(currentPage - interval, 1);
    const max = Math.min(currentPage + interval, pageCount);

    return _.range(min, max + 1);
  }, [currentPage, interval, pageCount]);

  const gotToFirstPage = () => {
    table.setPageIndex(0);
  };

  const goToPreviousPage = () => {
    if (table.getCanPreviousPage()) {
      table.setPageIndex(table.getState().pagination.pageIndex - 1);
    }
  };
  const goToNextPage = () => {
    if (table.getCanNextPage()) {
      table.setPageIndex(table.getState().pagination.pageIndex + 1);
    }
  };
  const goToLastPage = () => {
    table.setPageIndex(table.getPageCount() - 1);
  };

  const goTo = useCallback(
    (page: number) => {
      table.setPageIndex(page);
    },
    [table],
  );

  const defaultStyle = cn(
    `text-dark-2 flex h-[32px] w-[32px] items-center justify-center rounded-full`,
  );
  const hoverStyle = "cursor-pointer hover:border hover:border-accent";

  const renderPageSelector = useCallback(
    (page: number, key: string | number) => {
      return (
        <div
          onClick={() => goTo(page - 1)}
          key={key}
          className={cn(`${defaultStyle} ${hoverStyle}`, {
            "bg-accent-super-light border-accent border": currentPage === page,
          })}
        >
          <Typography
            className={cn({
              "text-accent": currentPage === page,
            })}
            variant="body3-medium"
          >
            {page}
          </Typography>
        </div>
      );
    },
    [currentPage, defaultStyle, goTo],
  );

  if (table.getPageCount() === 0) return;

  return (
    <div className={`${className} flex flex-row gap-6`}>
      <div
        className={`${defaultStyle} ${currentPage > 1 ? hoverStyle : ""}`}
        onClick={() => gotToFirstPage()}
      >
        <Icon
          name="FirstPagination"
          primaryColor={
            currentPage > 1 ? themeColors["dark-2"] : themeColors["dark-4"]
          }
          size="XS"
          height={16}
          width={16}
        />
      </div>
      <div
        className={`rotate-180 ${defaultStyle} ${
          table.getCanPreviousPage() ? hoverStyle : ""
        }`}
        onClick={() => goToPreviousPage()}
      >
        <Icon
          name="ChevronRight"
          primaryColor={
            table.getCanPreviousPage()
              ? themeColors["dark-2"]
              : themeColors["dark-4"]
          }
          size="XS"
          height={16}
          width={16}
        />
      </div>
      {!visiblePageSelector.includes(1) && renderPageSelector(1, "first-page")}
      {table.getPageCount() >= 2 && !visiblePageSelector.includes(2) && (
        <div className={`${defaultStyle}`}>
          <Typography variant="body3-medium">...</Typography>
        </div>
      )}
      {visiblePageSelector.map((page, index) =>
        renderPageSelector(page, index),
      )}
      {table.getPageCount() > 1 &&
        !visiblePageSelector.includes(table.getPageCount() - 1) && (
          <div className={`${defaultStyle}`}>
            <Typography variant="body3-medium">...</Typography>
          </div>
        )}
      {!visiblePageSelector.includes(table.getPageCount()) &&
        renderPageSelector(table.getPageCount(), "last-page")}
      <div
        className={`${defaultStyle} ${
          table.getCanNextPage() ? hoverStyle : ""
        }`}
        onClick={() => goToNextPage()}
      >
        <Icon
          name="ChevronRight"
          primaryColor={
            table.getCanNextPage()
              ? themeColors["dark-2"]
              : themeColors["dark-4"]
          }
          size="XS"
          height={16}
          width={16}
        />
      </div>
      <div
        className={`${defaultStyle} ${
          currentPage < table.getPageCount() ? hoverStyle : ""
        }`}
        onClick={() => goToLastPage()}
      >
        <Icon
          name="LastPagination"
          primaryColor={
            currentPage < table.getPageCount()
              ? themeColors["dark-2"]
              : themeColors["dark-4"]
          }
          size="XS"
          height={16}
          width={16}
        />
      </div>
    </div>
  );
}
