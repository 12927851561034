/* eslint-disable-next-line */
import { FormEvent, InputHTMLAttributes } from "react";

import { InputRichText } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

type RichTextFieldProps<T> = SmartFieldCommonProps<T> &
  InputHTMLAttributes<HTMLInputElement>;

export function SmartRichTextField<T>(props: RichTextFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <InputRichText
      {...props}
      value={extractValueFromPath(form, props.name)}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onChange={(content: string) => {
        handleFormChange({
          ...updateObject(form, props.name, content),
        });
      }}
    />
  );
}

export default SmartRichTextField;
