import { useEffect, useState, type ReactNode } from "react";

import { Icon } from "../../../../old/icons/icon";

export interface VerticalMenuTriggerProps {
  children:
    | ReactNode
    | ((props: {
        isOpen: boolean;
        setIsOpen: (value: boolean) => void;
      }) => ReactNode);
}

export const VerticalMenuTrigger = (props: VerticalMenuTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <button
        onClick={() => setIsOpen?.(!isOpen)}
        className="ml-auto lg:hidden"
      >
        <Icon name={isOpen ? "Cross" : "Hamburger"} />
      </button>

      {typeof props.children === "function"
        ? props.children({ isOpen, setIsOpen })
        : props.children}
    </>
  );
};
