import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Faq(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 8.01799C10.9953 8.21377 10.9142 8.39994 10.7741 8.53672C10.6339 8.67351 10.4458 8.75005 10.25 8.74999C9.5 8.74999 9.5 7.99899 9.5 7.99899V7.98999C9.50053 7.94522 9.5032 7.9005 9.508 7.85599C9.55224 7.4697 9.69706 7.10179 9.928 6.78899C10.382 6.17599 11.198 5.72699 12.513 5.74999C13.463 5.76699 14.306 6.16499 14.834 6.81999C15.371 7.48699 15.552 8.38999 15.196 9.27899C14.834 10.184 14.015 10.544 13.544 10.75L13.494 10.773C13.214 10.896 13.081 10.96 13.001 11.024L13 11.025V11.749C13.0001 11.9479 12.9212 12.1387 12.7807 12.2795C12.6401 12.4202 12.4494 12.4994 12.2505 12.4995C12.0516 12.4996 11.8608 12.4207 11.72 12.2802C11.5793 12.1396 11.5001 11.9489 11.5 11.75V11C11.5 10.477 11.752 10.103 12.063 9.85299C12.313 9.65299 12.628 9.51499 12.849 9.41699L12.887 9.39999C13.429 9.16099 13.687 9.01299 13.804 8.72099C13.8727 8.564 13.8962 8.39094 13.8718 8.22133C13.8474 8.05171 13.7761 7.89228 13.666 7.76099C13.444 7.48599 13.037 7.25899 12.487 7.24999C11.552 7.23399 11.242 7.53499 11.134 7.68199C11.0625 7.77907 11.0164 7.89253 11 8.01199V8.01799ZM12.25 15.5C12.5152 15.5 12.7696 15.3946 12.9571 15.2071C13.1446 15.0196 13.25 14.7652 13.25 14.5C13.25 14.2348 13.1446 13.9804 12.9571 13.7929C12.7696 13.6053 12.5152 13.5 12.25 13.5C11.9848 13.5 11.7304 13.6053 11.5429 13.7929C11.3554 13.9804 11.25 14.2348 11.25 14.5C11.25 14.7652 11.3554 15.0196 11.5429 15.2071C11.7304 15.3946 11.9848 15.5 12.25 15.5Z"
        fill={primaryColor}
      />
      <path
        d="M4 4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2H18C18.3283 2 18.6534 2.06466 18.9567 2.1903C19.26 2.31594 19.5356 2.50009 19.7678 2.73223C19.9999 2.96438 20.1841 3.23998 20.3097 3.54329C20.4353 3.84661 20.5 4.1717 20.5 4.5V18.75C20.5 18.9489 20.421 19.1397 20.2803 19.2803C20.1397 19.421 19.9489 19.5 19.75 19.5H5.5C5.5 19.7652 5.60536 20.0196 5.79289 20.2071C5.98043 20.3946 6.23478 20.5 6.5 20.5H19.75C19.9489 20.5 20.1397 20.579 20.2803 20.7197C20.421 20.8603 20.5 21.0511 20.5 21.25C20.5 21.4489 20.421 21.6397 20.2803 21.7803C20.1397 21.921 19.9489 22 19.75 22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5ZM5.5 4.5V18H19V4.5C19 4.23478 18.8946 3.98043 18.7071 3.79289C18.5196 3.60536 18.2652 3.5 18 3.5H6.5C6.23478 3.5 5.98043 3.60536 5.79289 3.79289C5.60536 3.98043 5.5 4.23478 5.5 4.5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
