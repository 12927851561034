import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CircleProjects(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        fill={primaryColor}
        stroke={primaryColor}
      />
      <circle cx="20.5" cy="17.5" r="3" fill="white" stroke={primaryColor} />
      <path
        d="M9.19599 14.0003L8.01794 18.7125C7.89327 19.2099 8.50863 19.5499 8.86397 19.1799L16.8603 10.8469C16.9283 10.7762 16.974 10.6871 16.9917 10.5907C17.0095 10.4943 16.9985 10.3948 16.9601 10.3045C16.9218 10.2143 16.8577 10.1373 16.776 10.0832C16.6942 10.0291 16.5983 10.0002 16.5003 10.0002H13.5268L14.6415 6.65803C14.6666 6.58284 14.6734 6.50279 14.6614 6.42445C14.6494 6.34612 14.619 6.27175 14.5727 6.20749C14.5263 6.14322 14.4653 6.09089 14.3948 6.05482C14.3242 6.01874 14.2461 5.99995 14.1669 6H9.5C9.39135 6.00002 9.28567 6.03543 9.19894 6.10087C9.11221 6.16631 9.04915 6.25821 9.01931 6.36268L7.01923 13.363C6.99798 13.4374 6.99428 13.5157 7.00844 13.5918C7.02259 13.6679 7.05421 13.7396 7.10081 13.8014C7.1474 13.8632 7.2077 13.9133 7.27696 13.9478C7.34621 13.9823 7.42253 14.0003 7.49992 14.0003H9.19599ZM12.3595 10.3422C12.3344 10.4173 12.3276 10.4973 12.3395 10.5756C12.3515 10.6539 12.3819 10.7282 12.4281 10.7924C12.4744 10.8567 12.5353 10.909 12.6058 10.9452C12.6762 10.9813 12.7543 11.0001 12.8335 11.0002H15.3269L9.444 17.1311L10.3214 13.6216C10.3398 13.548 10.3412 13.471 10.3255 13.3967C10.3097 13.3224 10.2773 13.2527 10.2306 13.1928C10.1838 13.1329 10.1241 13.0844 10.0558 13.0511C9.98759 13.0177 9.91264 13.0003 9.83668 13.0003H8.16328L9.87668 7.00004H13.4728L12.3595 10.3422Z"
        fill="white"
      />
    </svg>
  );
}
