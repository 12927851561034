import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Document(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "24",
    height = "24",
    size = "XS",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9999 3H13.2454C16.1804 3 17.648 3 18.6671 3.71805C18.9591 3.92379 19.2184 4.16777 19.437 4.4426C20.1999 5.4018 20.1999 6.78302 20.1999 9.54545V11.8364C20.1999 14.5032 20.1999 15.8366 19.7779 16.9016C19.0994 18.6137 17.6645 19.9642 15.8454 20.6028C14.7138 21 13.2971 21 10.4635 21C8.84438 21 8.0348 21 7.3882 20.773C6.34871 20.4081 5.52878 19.6364 5.14107 18.6581C4.8999 18.0495 4.8999 17.2876 4.8999 15.7636V4"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2 12C20.2 13.6569 18.8568 15 17.2 15C16.6007 15 15.8943 14.895 15.3117 15.0511C14.7941 15.1898 14.3898 15.5941 14.2511 16.1118C14.095 16.6944 14.2 17.4008 14.2 18C14.2 19.6569 12.8568 21 11.2 21"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
