import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Hide(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.197762 0.197409C0.0834444 0.3117 0.0139381 0.46322 0.00188114 0.62442C-0.0101758 0.785621 0.0360172 0.945795 0.132061 1.07582L0.197762 1.15142L3.82841 4.78297C2.05811 6.02375 0.794367 7.86105 0.268863 9.95804C0.228343 10.1307 0.257377 10.3123 0.349688 10.4637C0.442 10.6151 0.590176 10.7241 0.762192 10.7671C0.934208 10.8101 1.11625 10.7838 1.26897 10.6937C1.4217 10.6036 1.53284 10.457 1.57838 10.2856C2.0454 8.42442 3.19561 6.80705 4.80042 5.75498L6.42944 7.384C5.77873 8.06396 5.42016 8.97169 5.43054 9.91279C5.44092 10.8539 5.81942 11.7535 6.48498 12.4189C7.15053 13.0844 8.05021 13.4627 8.9913 13.4729C9.9324 13.4831 10.8401 13.1244 11.5199 12.4736L16.8471 17.8016C16.9675 17.9224 17.1292 17.9931 17.2997 17.9995C17.4701 18.0059 17.6367 17.9476 17.7658 17.8362C17.895 17.7248 17.9772 17.5686 17.996 17.3991C18.0147 17.2296 17.9685 17.0592 17.8668 16.9223L17.8011 16.8467L12.2993 11.3441L12.3002 11.3423L11.2202 10.264L8.63717 7.68101H8.63897L6.04694 5.09167L6.04784 5.08987L5.02812 4.07286L1.15177 0.197409C1.02521 0.0710016 0.853646 0 0.674768 0C0.49589 0 0.324326 0.0710016 0.197762 0.197409ZM7.38346 8.33802L10.565 11.5205C10.1406 11.9303 9.57227 12.1571 8.98232 12.152C8.39237 12.1469 7.82804 11.9102 7.41086 11.493C6.99369 11.0759 6.75706 10.5115 6.75193 9.92159C6.7468 9.33164 6.97359 8.76238 7.38346 8.33802ZM8.99988 3.14945C8.09986 3.14945 7.22685 3.28265 6.39974 3.53195L7.51306 4.64437C9.43868 4.26308 11.4372 4.63634 13.0954 5.68697C14.7536 6.73759 15.9446 8.38524 16.4223 10.2892C16.4689 10.4593 16.5802 10.6043 16.7324 10.6934C16.8846 10.7824 17.0656 10.8083 17.2366 10.7657C17.4077 10.723 17.5553 10.6151 17.6478 10.465C17.7404 10.3149 17.7705 10.1346 17.7318 9.96254C17.2437 8.01622 16.1195 6.28874 14.5375 5.05439C12.9555 3.82004 11.0064 3.14957 8.99988 3.14945ZM9.17538 6.30849L12.5963 9.72853C12.5521 8.83563 12.1774 7.99107 11.5452 7.359C10.9129 6.72693 10.0683 6.35251 9.17538 6.30849Z"
        fill={primaryColor}
      />
    </svg>
  );
}
