import { SortableHandle } from "react-sortable-hoc";

import type { Image, Media } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { ExpansionPanel } from "../expansion-panel";
import { Icon } from "../icons/icon";
import { ImageUploader } from "../image-uploader";
import { TextField } from "../text-field";
import { Toggle } from "../toggle/toggle";

export type MediaElementProps = {
  media: Media;
  index: number;
  initialExpendedState?: boolean;
  onSwitchMediaType: (index: number) => void;
  deleteMedia: (index: number) => void;
  setImage: (image: Image, index: number) => void;
  setVideo: (url: string, index: number) => void;
};

const DragHandle = SortableHandle(() => (
  <div className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border">
    <Icon name="Drag" size="S" primaryColor={themeColors["dark-2"]} />
  </div>
));

export function MediaElement(props: MediaElementProps) {
  const {
    media,
    initialExpendedState,
    index,
    deleteMedia,
    setImage,
    setVideo,
    onSwitchMediaType,
  } = props;

  const { t } = useTranslation();

  return (
    <ExpansionPanel
      title={t(
        "projects.back_office.edit_project_page.content.medias.expanded_panel.title",
      )}
      dragHandle={<DragHandle />}
      canBeDeleted
      expendedValue={!!initialExpendedState}
      deleteAction={() => {
        deleteMedia(index);
      }}
      className={cn("w-full bg-white sm:w-[441px]")}
      headerClassName="justify-between"
    >
      <div className="flex flex-col gap-y-6">
        <Toggle
          name="name"
          leftText="Image"
          rightText="Video"
          blueStyle
          checked={media.type === "video"}
          onChange={() => {
            onSwitchMediaType(index);
          }}
        />
        {media.type === "image" && (
          <ImageUploader
            required
            label={t(
              "projects.back_office.edit_project_page.content.medias.expanded_panel.image_field.label",
            )}
            ratio="802x450"
            image={
              media.data ?? {
                id: null,
                url: null,
                base64: null,
                resizedImages: null,
                originalFilename: null,
              }
            }
            onImageChange={(image: Image) => {
              setImage(image, index);
            }}
          />
        )}
        {media.type === "video" && (
          <TextField
            required
            label={t(
              "projects.back_office.edit_project_page.content.medias.expanded_panel.video_field.label",
            )}
            placeholder={t(
              "projects.back_office.edit_project_page.content.medias.video_field.placeholder",
            )}
            value={media.data.url}
            onChange={(event) => {
              setVideo(event.target.value, index);
            }}
          />
        )}
      </div>
    </ExpansionPanel>
  );
}
