import { type PropsWithChildren, type ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const NavbarCardHeading = (
  props: PropsWithChildren<{ className?: string }>,
) => (
  <h4
    className={cn("font-roobert text-black-primary text-lg", props.className)}
  >
    {props.children}
  </h4>
);

const NavbarCardImage = (props: { src?: string; className?: string }) => {
  return (
    <div
      className={cn(
        "h-[160px] max-w-[300px] overflow-hidden rounded",
        props.className,
      )}
    >
      {props.src ? (
        <img
          src={props.src}
          className="block h-full w-full object-cover transition hover:scale-110"
        />
      ) : (
        <div className="bg-black-900/10 min-h-[160px] w-full rounded" />
      )}
    </div>
  );
};

const NavbarCardDescription = (
  props: PropsWithChildren<{ className?: string }>,
) => (
  <div className={cn("flex w-[300px] flex-col gap-y-2", props.className)}>
    {props.children}
  </div>
);

const NavbarCardDescriptionHeading = (
  props: PropsWithChildren<{ className?: string }>,
) => (
  <h5
    className={cn(
      "font-roobert text-black-primary font-medium",
      props.className,
    )}
  >
    {props.children}
  </h5>
);

const NavbarCardDescriptionContent = (
  props: PropsWithChildren<{ className?: string }>,
) => (
  <p className={cn("font-roobert text-black-900", props.className)}>
    {props.children}
  </p>
);

const NavbarCardLink = (
  props: PropsWithChildren<{
    className?: string;
    to?: string;
    target?: HTMLAnchorElement["target"];
  }>,
) => (
  <a
    href={props.to}
    target={props.target ?? "_blank"}
    className={cn(
      "font-roobert text-black-primary text-[15px] font-medium underline",
      props.className,
    )}
  >
    {props.children}
  </a>
);

const NavbarCard = (props: { children: ReactNode; className?: string }) => {
  return (
    <article
      className={cn(
        "bg-black-800 flex flex-col gap-y-4 rounded-lg p-6",
        props.className,
      )}
    >
      {props.children}
    </article>
  );
};

export {
  NavbarCard,
  NavbarCardHeading,
  NavbarCardImage,
  NavbarCardDescription,
  NavbarCardDescriptionHeading,
  NavbarCardDescriptionContent,
  NavbarCardLink,
};
