import { PropsWithChildren, type ComponentProps } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { NavigationMenuContent } from "../../../ui/navigation-menu";

export interface HorizontalMenuContentProps
  extends PropsWithChildren,
    ComponentProps<typeof NavigationMenuContent> {}

export const HorizontalMenuContent = (props: HorizontalMenuContentProps) => {
  const { children, className, ...rest } = props;

  return (
    <NavigationMenuContent
      {...rest}
      className={cn("!w-screen justify-between ", className)}
    >
      <div className="mx-auto flex w-full max-w-screen-2xl p-8">{children}</div>
    </NavigationMenuContent>
  );
};
