import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import {
  CMSBlock,
  CMSBlockCommon,
  CMSBlockData,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { reorder } from "@tudigo-monorepo/core-tudigo-utils";
import {
  extractValueFromPath,
  InputMultiGroupDescription,
  updateObject,
} from "@tudigo-monorepo/web-feature-form";
import {
  Button,
  ExpansionPanel,
  Icon,
} from "@tudigo-monorepo/web-tudigo-components";

import { EditorPlugin } from "../../types";
import { EditorPluginInput } from "../editor-plugin-input";

type EditorPluginInputProps = {
  input: InputMultiGroupDescription<CMSBlockData>;
  path?: string;
  blockContent: CMSBlock;
  setBlockContent: (blockContent: CMSBlock) => void;
  selectedPlugin: EditorPlugin<any, CMSBlockCommon, any>;
};

export const EditorMultiGroupInput = (props: EditorPluginInputProps) => {
  const {
    input: groupInput,
    path,
    blockContent,
    setBlockContent,
    selectedPlugin,
  } = props;

  const content = blockContent as any;
  const array =
    (extractValueFromPath(content, path + groupInput.name) as any[]) ?? [];

  const [inputGroupsExpends, setInputGroupsExpends] = useState<boolean[]>(
    array?.map(() => false) ?? [],
  );

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === "input-group") {
      setInputGroupsExpends(
        reorder(inputGroupsExpends, source.index, destination.index),
      );
      setBlockContent({
        ...updateObject(blockContent, path + groupInput.name, [
          ...reorder(array, source.index, destination.index),
        ]),
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="input-group" direction="vertical">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="editor-multi-group-input flex flex-col gap-y-4"
          >
            {array &&
              array.map((item: any, index: number) => {
                const title = groupInput.titlePath
                  ? extractValueFromPath(
                      content,
                      path +
                        groupInput.name +
                        "." +
                        index +
                        "." +
                        groupInput.titlePath,
                    )
                  : "";

                return (
                  <Draggable
                    key={index}
                    draggableId={"id" + index}
                    index={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <ExpansionPanel
                          className="max-w-[324px]"
                          textsContainerClassName="max-w-[130px]"
                          expandedPanelClassName="px-4"
                          title={title}
                          subTitle={groupInput.subtitle}
                          dragHandle={
                            <div
                              className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border"
                              {...provided.dragHandleProps}
                            >
                              <Icon
                                name="Drag"
                                size="S"
                                primaryColor={themeColors["dark-2"]}
                              />
                            </div>
                          }
                          expendedValue={inputGroupsExpends[index]}
                          setExpendedValue={(value) => {
                            setInputGroupsExpends([
                              ...inputGroupsExpends.slice(0, index),
                              value,
                              ...inputGroupsExpends.slice(index + 1),
                            ]);
                          }}
                          canBeDeleted
                          deleteAction={() => {
                            setBlockContent({
                              ...updateObject(
                                blockContent,
                                path + groupInput.name,
                                [...array.filter((_, i) => i !== index)],
                              ),
                            });
                          }}
                        >
                          <div className="flex flex-col gap-y-4 pb-10">
                            {groupInput.items.map((input: any) => {
                              return (
                                <EditorPluginInput
                                  key={input.name}
                                  input={input}
                                  path={`${path}${groupInput.name}.${index}${
                                    input.name ? "." : ""
                                  }`}
                                />
                              );
                            })}
                          </div>
                        </ExpansionPanel>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button
        label="Ajouter un element"
        iconRight="Plus"
        variant="tertiary"
        className="w-fit"
        onClick={() => {
          const newItems = JSON.parse(
            JSON.stringify(
              extractValueFromPath(
                selectedPlugin.shape,
                path + groupInput.name,
              ),
            ),
          );
          setBlockContent({
            ...updateObject(blockContent, path + groupInput.name, [
              ...array,
              ...newItems,
            ]),
          });
        }}
      />
    </DragDropContext>
  );
};
