import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Money(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 16.5165C13.5196 16.8349 12.9004 17 12.121 17C11.2028 17 10.4662 16.717 9.88968 16.1627C9.31317 15.6085 8.92883 14.8066 8.74733 13.7689H8V12.4953H8.58719C8.58719 12.3892 8.57651 12.2476 8.57651 12.0943C8.57651 12.0118 8.57651 11.8349 8.59786 11.5401H8V10.2783H8.76868C8.99288 9.24057 9.41993 8.43868 10.0391 7.86085C10.6584 7.28302 11.4377 7 12.3559 7C13.0285 7 13.5836 7.11792 13.9893 7.36557V9.11085C13.5302 8.7217 13.0178 8.52123 12.4413 8.52123C11.9929 8.52123 11.5979 8.68632 11.2669 9.00472C10.9359 9.32311 10.6904 9.74764 10.5409 10.2783H13.1352V11.5401H10.3594C10.3381 11.7995 10.3381 11.9882 10.3381 12.0943C10.3381 12.2123 10.3381 12.3538 10.3488 12.4953H13.1352V13.7689H10.5302C10.8399 14.9009 11.4377 15.467 12.3559 15.467C12.9324 15.467 13.4769 15.2665 13.9893 14.8656V16.5165H14Z"
        fill={primaryColor}
      />
      <path
        d="M12 21.9893C6.49089 21.9893 2 17.5091 2 11.9893C2 6.46945 6.48017 2 12 2C17.5198 2 22 6.48017 22 12C22 17.5198 17.5198 22 12 22V21.9893ZM12 3.60772C7.36977 3.60772 3.60772 7.36977 3.60772 12C3.60772 16.6302 7.36977 20.3923 12 20.3923C16.6302 20.3923 20.3923 16.6302 20.3923 12C20.3923 7.36977 16.6195 3.60772 12 3.60772Z"
        fill={primaryColor}
      />
    </svg>
  );
}
