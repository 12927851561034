import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Cadenas(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 19 23"
      fill="none"
    >
      <path
        d="M9.5 12.8097C9.13846 12.8055 8.78431 12.9101 8.48521 13.1093C8.18611 13.3085 7.95643 13.5928 7.82707 13.9239C7.69771 14.255 7.67489 14.617 7.76168 14.9612C7.84847 15.3054 8.0407 15.6152 8.3125 15.8491V17.4677C8.3125 17.7766 8.43761 18.0728 8.66031 18.2912C8.88301 18.5096 9.18506 18.6323 9.5 18.6323C9.81494 18.6323 10.117 18.5096 10.3397 18.2912C10.5624 18.0728 10.6875 17.7766 10.6875 17.4677V15.8491C10.9593 15.6152 11.1515 15.3054 11.2383 14.9612C11.3251 14.617 11.3023 14.255 11.1729 13.9239C11.0436 13.5928 10.8139 13.3085 10.5148 13.1093C10.2157 12.9101 9.86154 12.8055 9.5 12.8097ZM15.4375 8.15161V5.82258C15.4375 4.27834 14.8119 2.79734 13.6984 1.70539C12.5849 0.613449 11.0747 0 9.5 0C7.92528 0 6.41505 0.613449 5.30155 1.70539C4.18806 2.79734 3.5625 4.27834 3.5625 5.82258V8.15161C2.61767 8.15161 1.71153 8.51968 1.04343 9.17485C0.375334 9.83002 0 10.7186 0 11.6452V19.7968C0 20.7233 0.375334 21.6119 1.04343 22.2671C1.71153 22.9223 2.61767 23.2903 3.5625 23.2903H15.4375C16.3823 23.2903 17.2885 22.9223 17.9566 22.2671C18.6247 21.6119 19 20.7233 19 19.7968V11.6452C19 10.7186 18.6247 9.83002 17.9566 9.17485C17.2885 8.51968 16.3823 8.15161 15.4375 8.15161ZM5.9375 5.82258C5.9375 4.89603 6.31283 4.00744 6.98093 3.35227C7.64903 2.6971 8.55517 2.32903 9.5 2.32903C10.4448 2.32903 11.351 2.6971 12.0191 3.35227C12.6872 4.00744 13.0625 4.89603 13.0625 5.82258V8.15161H5.9375V5.82258ZM16.625 19.7968C16.625 20.1056 16.4999 20.4018 16.2772 20.6202C16.0545 20.8386 15.7524 20.9613 15.4375 20.9613H3.5625C3.24756 20.9613 2.94551 20.8386 2.72281 20.6202C2.50011 20.4018 2.375 20.1056 2.375 19.7968V11.6452C2.375 11.3363 2.50011 11.0401 2.72281 10.8217C2.94551 10.6033 3.24756 10.4806 3.5625 10.4806H15.4375C15.7524 10.4806 16.0545 10.6033 16.2772 10.8217C16.4999 11.0401 16.625 11.3363 16.625 11.6452V19.7968Z"
        fill={primaryColor}
      />
    </svg>
  );
}
