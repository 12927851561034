import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { DotSize } from "./dots-loader";

type DotStyle = Record<
  DotSize,
  {
    dimensions: string;
    translate: string;
  }
>;

type AnimateDotProps = {
  size: DotSize;
  blink: boolean;
  up: boolean;
};

const styles: DotStyle = {
  XS: {
    dimensions: "w-1 h-1",
    translate: "-translate-y-1",
  },
  S: {
    dimensions: "w-2 h-2",
    translate: "-translate-y-2",
  },
  M: {
    dimensions: "w-3 h-3",
    translate: "-translate-y-3",
  },
};

export function AnimateDot(props: AnimateDotProps) {
  const { size, blink, up } = props;

  return (
    <span
      className={cn(
        "bg-secondary block rounded-full duration-300 ease-in-out",
        styles[size].dimensions,
        {
          "bg-primary": blink,
          [styles[size].translate]: up,
        },
      )}
    />
  );
}
