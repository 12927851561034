import { EquitySavingPlan } from "./models";

export const equitySavingPlanShape: EquitySavingPlan = {
  type: "pea",
  accountNumber: "",
  bank: null,
  bankAgency: null,
  bankContactInformation: null,
  bic: "",
  iban: "",
  invalidatedAt: null,
};
