import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Play(props: IconElementsProps) {
  const { primaryColor = "#666879", width, height, size = "S" } = props;

  return (
    <svg
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51176 6.37347C7.84511 5.99252 7.01562 6.47389 7.01562 7.24171V13.5185C7.01562 14.2864 7.84511 14.7677 8.51176 14.3868L14.004 11.2484C14.6758 10.8645 14.6758 9.89577 14.004 9.51188L8.51176 6.37347Z"
        fill={primaryColor}
      />
    </svg>
  );
}
