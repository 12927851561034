import { DependencyList, useEffect, useState } from "react";

export const useTrack = (
  trackFunction: () => boolean,
  deps: DependencyList,
) => {
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (!tracked) {
      if (trackFunction()) {
        setTracked(true);
      }
    }
  }, [trackFunction, tracked, deps]);
};
