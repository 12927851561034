import React from "react";

import { ChipCount, Typography } from "@tudigo-monorepo/web-tudigo-components";

export type TutorialItem = { label: string; demo: React.ReactNode };

const tutorialItems: TutorialItem[] = [
  {
    label: "Ajouter des blocs par glisser déposer depuis la liste à gauche",
    demo: null,
  },
  {
    label: "Sélectionner un élément au centre pour pouvoir l’éditer ici",
    demo: null,
  },
  {
    label: "Glisser déposer les blocs au centre pour les réorganiser",
    demo: null,
  },
  {
    label:
      "Utilisez les boutons de prévisualisation situés en haut pour vérifier le rendu",
    demo: null,
  },
];

export const EditorTutorial = () => {
  return (
    <ul className="flex w-full flex-col gap-y-4">
      {tutorialItems.map((item, index) => (
        <li
          key={index}
          className="border-light-3 flex items-center gap-x-2.5 rounded-lg border p-4"
        >
          <ChipCount label={index + 1} className="min-w-6 " />
          <Typography className="text-dark-3">{item.label}</Typography>
        </li>
      ))}
    </ul>
  );
};
