export enum SignatureRequestStatusEnum {
  DRAFT = "draft",
  ONGOING = "ongoing",
  DONE = "done",
  DELETED = "deleted",
}

export type SignatureRequestStatusType = `${SignatureRequestStatusEnum}`;

export interface SignatureRequest {
  id: string;
  status: SignatureRequestStatusType;
  mySignatureLink: string | null;
  signers: Signer[] | [];
}

export interface Signer {
  id: string;
  userId?: string;
  signatureLink?: string;
}
