import {
  SubscribeClubStepPage,
  subscribeClubStepsConfig,
  type SubscribeClubStep,
  type SubscribeClubStepPath,
} from "../models/subscribe-club-step";

const getSubscribeClubStepFromPath = (
  path: SubscribeClubStepPath | string,
): SubscribeClubStep => {
  switch (path) {
    case "offer":
      return subscribeClubStepsConfig[SubscribeClubStepPage.STEP_1_OFFER];
    case "sign":
      return subscribeClubStepsConfig[SubscribeClubStepPage.STEP_2_SIGN];
    case "payment":
      return subscribeClubStepsConfig[SubscribeClubStepPage.STEP_3_PAYMENT];
    case "success":
      return subscribeClubStepsConfig[SubscribeClubStepPage.STEP_4_SUCCESS];
    default:
      return subscribeClubStepsConfig[SubscribeClubStepPage.STEP_1_OFFER];
  }
};

export { getSubscribeClubStepFromPath };
