import { Link, Outlet, useParams } from "react-router-dom";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  Button,
  Stepper,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import FaireMoveLogo from "./fair-move-logo.svg";
import Tudigo from "./tudigo.svg";

const getStepIndex = (step: string) => {
  if (step === "advantages") {
    return 0;
  }
  if (step === "membership-form") {
    return 1;
  }
  if (step === "payment") {
    return 2;
  }
  if (step === "success") {
    return 3;
  }
};

export const FunnelClubLayout = () => {
  const { step } = useParams() as Record<"step" | "clubId", string>;
  const { t } = useTranslation();

  const activeStepIndex = getStepIndex(step as string);

  const steps = [
    {
      label: t("my_account.wep_app.clubs.advantages.step_name"),
    },
    {
      label: t("my_account.wep_app.clubs.membership_form.step_name"),
    },
    {
      label: t("my_account.wep_app.clubs.payment.step_name"),
    },
  ];

  return (
    <div className="p-4">
      <div className="flex flex-col gap-4">
        <div className="flex justify-center">
          <img src={FaireMoveLogo} alt="Faire move" />
          <Typography className="mx-3 self-center">by</Typography>
          <img src={Tudigo} alt="Tudigo" />
        </div>

        <div className="relative h-16">
          <Link to="/my-account/clubs">
            <Button
              singleIcon="ArrowLeft"
              variant="tertiary"
              className="absolute"
            />
          </Link>
          <div className="m-auto max-w-[250px] sm:max-w-[400px] md:max-w-[600px]">
            <Stepper activeStepIndex={activeStepIndex} steps={steps} />
          </div>

          <hr className="-mx-5 my-8" />

          <Outlet />
        </div>
      </div>
    </div>
  );
};
