import * as React from "react";
import { useLayoutEffect, useMemo } from "react";
import { beforeWrite } from "@popperjs/core/lib/enums";
import { Modifier } from "@popperjs/core/lib/types";
import { useDropdownMenu } from "@restart/ui";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export interface DropdownMenuProps {
  className?: string;
  children: React.ReactNode;
  role?: string;
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const { className, children, role = "menu" } = props;

  const setMinWithModifier = useMemo(() => {
    return {
      enabled: true,
      phase: beforeWrite,
      name: "setPopperMinWidth",
      requires: ["computeStyles"],
      fn: ({ state }) => {
        state.styles.popper.minWidth = `${state.rects.reference.width}px`;
      },
    } as Modifier<"setPopperMinWidth", any>;
  }, []);

  const [dropdownMenuProps, { show, popper }] = useDropdownMenu({
    flip: true,
    offset: [0, 8],
    popperConfig: {
      modifiers: [setMinWithModifier],
    },
  });

  useLayoutEffect(() => {
    if (show && popper) {
      popper.update();
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <div
      {...dropdownMenuProps}
      role={role}
      style={dropdownMenuProps.style}
      className={cn(
        className,
        show ? "flex flex-col" : "hidden",
        "border-accent-medium z-10 gap-1 overflow-y-scroll rounded-lg border bg-white p-3",
      )}
    >
      {children}
    </div>
  );
};

DropdownMenu.displayName = "DropdownMenu";

export default DropdownMenu;
