import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Iframe(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size] / 2}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        stroke={primaryColor}
      />
      <path
        d="M11.712 14.904L6 12.732V11.172L11.712 9V10.5L6.564 12.372V11.532L11.712 13.404V14.904Z"
        fill={primaryColor}
      />
      <path
        d="M13.1836 14.904V13.404L18.3316 11.532V12.372L13.1836 10.5V9L18.8956 11.172V12.732L13.1836 14.904Z"
        fill={primaryColor}
      />
    </svg>
  );
}
