import { useRef } from "react";
import {
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { mergeRefs } from "react-merge-refs";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useEditorContext } from "../store/editor-context";
import { EditorBlock } from "./editor-block";

export const EditorPreview = () => {
  const {
    content,
    settings: { previewMode },
  } = useEditorContext();

  const ref = useRef<HTMLDivElement>(null);

  const isDraggingStyle = (
    style: DraggingStyle | NotDraggingStyle | undefined,
  ): style is DraggingStyle => {
    return (style as DraggingStyle).top !== undefined;
  };

  return (
    <Droppable droppableId="preview" direction="vertical">
      {(provided) => (
        <div
          ref={mergeRefs([provided.innerRef, ref])}
          {...provided.droppableProps}
          className={cn(
            "editor-preview",
            "h-full flex-1",
            "px-6 py-[30px]",
            "sm:mx-auto",
            "overflow-y-scroll",
            "@container",
            {
              "w-[322px] max-w-[322px] flex-none": previewMode === "mobile",
              "w-[882px] max-w-[882px] flex-none": previewMode === "desktop",
            },
          )}
        >
          <div className="flex min-h-screen flex-col gap-y-6 bg-white px-5 py-9">
            {content.map((block, index) => (
              <Draggable
                key={block.id}
                draggableId={block.id}
                index={index}
                isDragDisabled={previewMode !== false}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="bg-white "
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      top:
                        isDraggingStyle(provided.draggableProps?.style) &&
                        ref?.current
                          ? provided.draggableProps.style.top +
                            ref?.current.scrollTop -
                            ref?.current?.offsetTop
                          : undefined,
                      left:
                        isDraggingStyle(provided.draggableProps?.style) &&
                        ref?.current
                          ? provided.draggableProps.style.left -
                            ref?.current?.offsetLeft
                          : undefined,
                    }}
                  >
                    <EditorBlock key={block.id} {...block} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};
