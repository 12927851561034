import { ComponentProps } from "react";
import { ToastContentProps } from "react-toastify";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

type PaymentErrorAlertProps = {
  isError: boolean;
  content?: ToastContentProps<unknown>;
  size?: ComponentProps<typeof Alert>["size"];
};

const PaymentErrorAlert = ({
  isError,
  size = "F",
  content,
}: PaymentErrorAlertProps) => {
  const { t } = useTranslation();

  if (!isError) {
    return null;
  }

  return (
    <Alert
      close={content?.closeToast}
      icon="Cross"
      variant="error"
      size={size}
      title={t("subscribe_club_page.step_3_payment.alert.error.title")}
      description={t("subscribe_club_page.step_3_payment.alert.error.info")}
    />
  );
};

export { PaymentErrorAlert };
