import { IconElementsProps } from "../icon-elements-props.type";

export function StarTruspilot(props: IconElementsProps) {
  const { className, primaryColor, width, height, size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        id="Shape"
        d="M7 10.9792L10.0417 10.2083L11.3125 14.125L7 10.9792ZM14 5.91667H8.64583L7 0.875L5.35417 5.91667H0L4.33334 9.04167L2.6875 14.0833L7.02083 10.9583L9.6875 9.04167L14 5.91667Z"
        fill={primaryColor}
      />
    </svg>
  );
}
