import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialLinkedin(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{ minWidth: width, minHeight: height }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      fill={primaryColor}
    >
      <g clipPath="url(#clip0_3949_1950)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 22H18.1V14.3011C18.1 12.1891 17.1683 11.011 15.4974 11.011C13.6791 11.011 12.6 12.2386 12.6 14.3011V22H8.2V7.7H12.6V9.3082C12.6 9.3082 13.9805 6.886 17.0913 6.886C20.2032 6.886 22.5 8.7846 22.5 12.7138C22.5 16.6419 22.5 22 22.5 22ZM3.1862 5.4131C1.7023 5.4131 0.5 4.2009 0.5 2.706C0.5 1.2122 1.7023 0 3.1862 0C4.669 0 5.8713 1.2122 5.8713 2.706C5.8724 4.2009 4.669 5.4131 3.1862 5.4131ZM0.5 22H6V7.7H0.5V22Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3949_1950">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill={primaryColor}
            transform="translate(0 0.0839844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
