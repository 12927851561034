import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CardUser(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "20",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M17.75 0.5C18.2141 0.5 18.6592 0.684375 18.9874 1.01256C19.3156 1.34075 19.5 1.78587 19.5 2.25V13.755C19.5 14.2191 19.3156 14.6642 18.9874 14.9924C18.6592 15.3206 18.2141 15.505 17.75 15.505H2.25C2.02019 15.505 1.79262 15.4597 1.5803 15.3718C1.36798 15.2838 1.17506 15.1549 1.01256 14.9924C0.850061 14.8299 0.721157 14.637 0.633211 14.4247C0.545265 14.2124 0.5 13.9848 0.5 13.755V2.25C0.5 1.78587 0.684374 1.34075 1.01256 1.01256C1.34075 0.684375 1.78587 0.5 2.25 0.5H17.75Z"
        stroke={primaryColor}
      />
      <path
        d="M12.8751 7.39928C13.0228 7.39922 13.169 7.42536 13.3055 7.4762C13.442 7.52705 13.566 7.6016 13.6705 7.6956C13.775 7.78961 13.8578 7.90121 13.9144 8.02406C13.9709 8.1469 14 8.27856 14 8.41153V8.82472C13.9999 9.08282 13.9103 9.33445 13.7435 9.54441C12.9706 10.518 11.7081 11 9.99825 11C8.28736 11 7.02594 10.5175 6.25498 9.54306C6.08916 9.33344 6.00003 9.08251 6 8.82516V8.41108C5.99993 8.27811 6.02897 8.14644 6.08545 8.02357C6.14193 7.90071 6.22474 7.78906 6.32916 7.69502C6.43358 7.60098 6.55756 7.52637 6.69401 7.47548C6.83047 7.42458 6.97673 7.39838 7.12443 7.39838H12.8746L12.8751 7.39928ZM9.99825 2C10.6613 2 11.2971 2.2371 11.7659 2.65914C12.2347 3.08118 12.4981 3.65359 12.4981 4.25045C12.4981 4.84731 12.2347 5.41972 11.7659 5.84176C11.2971 6.2638 10.6613 6.5009 9.99825 6.5009C9.33525 6.5009 8.69941 6.2638 8.23059 5.84176C7.76178 5.41972 7.49841 4.84731 7.49841 4.25045C7.49841 3.65359 7.76178 3.08118 8.23059 2.65914C8.69941 2.2371 9.33525 2 9.99825 2Z"
        fill={primaryColor}
      />
      <rect
        x="3.25"
        y="12.25"
        width="13.5"
        height="0.5"
        rx="0.25"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}
