import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon, Typography } from "@tudigo-monorepo/web-tudigo-components";

type Advantage = {
  text: string;
  subText?: string;
};

export const ClubAdvantages = ({
  advantages,
  className,
}: {
  advantages: Advantage[];
  className?: string;
}) => (
  <div className={cn("flex flex-col gap-5 rounded border p-4 ", className)}>
    {advantages?.map((advantage, index) => (
      <div className="flex gap-3" key={`advantage-${index}`}>
        <div className="bg-success-light flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full">
          <Icon name="Checked" size="S" primaryColor={themeColors.success} />
        </div>
        <div className="flex flex-col gap-1 self-center">
          <Typography className="text-primary font-semibold" variant="body2">
            {advantage.text}
          </Typography>
          {advantage.subText && (
            <Typography className="text-primary" variant="body2">
              {advantage.subText}
            </Typography>
          )}
        </div>
      </div>
    ))}
  </div>
);
