import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function ChevronLeft(props: IconElementsProps) {
  const { primaryColor = "#00083C", width, height, size = "S" } = props;

  return (
    <svg
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1305_5834)">
        <path
          d="M14.9998 18L8.99979 12L14.9998 6"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1305_5834">
          <rect
            width={width || iconSizes[size]}
            height={height || iconSizes[size]}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
