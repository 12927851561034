import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";

import { PremiumAdvantage } from "@tudigo-monorepo/core-tudigo-api-models";

import {
  PremiumAdvantageElement,
  PremiumAdvantageElementProps,
} from "./premium-advantage-element";

type PremiumAdvantageItemsProps = {
  premiumAdvantages: PremiumAdvantage[];
  setPremiumAdvantages: (premiumAdvantages: PremiumAdvantage[]) => void;
  deletePremiumAdvantage: (index: number) => void;
  setPremiumAdvantage: (
    premiumAdvantage: PremiumAdvantage,
    index: number,
  ) => void;
};

const SortableItem = SortableElement<
  PremiumAdvantageElementProps & { position: number }
>((props: PremiumAdvantageElementProps & { position: number }) => (
  <PremiumAdvantageElement {...props} index={props.position} />
));

const SortableList = SortableContainer<PremiumAdvantageItemsProps>(
  (props: PremiumAdvantageItemsProps) => {
    return (
      <div className="flex flex-row flex-wrap gap-6 overflow-y-auto">
        {props.premiumAdvantages?.map((premiumAdvantage, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            premiumAdvantage={{
              ...premiumAdvantage,
            }}
            {...props}
          />
        ))}
      </div>
    );
  },
);

export function PremiumAdvantageItems(props: PremiumAdvantageItemsProps) {
  const { setPremiumAdvantages, premiumAdvantages } = props;

  return (
    <div>
      <SortableList
        {...props}
        useDragHandle
        axis="y"
        onSortEnd={(sort) => {
          setPremiumAdvantages(
            arrayMove(premiumAdvantages, sort.oldIndex, sort.newIndex),
          );
        }}
      />
    </div>
  );
}
