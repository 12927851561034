import { Document } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  MultiTypeUploader,
  MultiTypeUploaderProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export interface SmartMultiTypeUploaderFieldProps<T>
  extends SmartFieldCommonProps<T>,
    Omit<MultiTypeUploaderProps, "document" | "onDocumentChange"> {}

export function SmartMultiTypeUploaderField<T>(
  props: SmartMultiTypeUploaderFieldProps<T>,
) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <MultiTypeUploader
      {...props}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      document={extractValueFromPath(form, props.name)}
      onDocumentChange={(document: Document) => {
        handleFormChange({
          ...updateObject(form, props.name, document),
        });
      }}
      onDocumentDelete={props.onDocumentDelete}
    />
  );
}
