import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { IconName } from "../icons/icon-name.type";

type StepMarkersProps = {
  max: number;
  step: number;
  label?: string;
  icon?: IconName | string;
};

const generateStepRanges = (max: number, step: number): number[] => {
  const steps = [];
  for (let i = 0; i <= max; i++) {
    if (i % step === 0) {
      steps.push(i);
    }
  }

  return steps;
};

export function StepMarkers(props: StepMarkersProps) {
  const { max, step } = props;
  const stepMarkers = generateStepRanges(max, step);

  return (
    <div className="range-slider-step-markers pointer-events-none absolute left-0 top-[6px] z-0 flex w-full items-center justify-between">
      {stepMarkers.map((value, index) => (
        <span
          key={value}
          className={cn("relative z-50 h-2 w-2 rounded-full bg-white", {
            "bg-transparent": index === 0 || index === stepMarkers.length - 1,
          })}
        />
      ))}
    </div>
  );
}
