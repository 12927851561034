import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Timer(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clipPath="url(#clip0_2506_1857)">
        <path
          d="M3.24984 0.577474C3.24984 0.324343 3.45504 0.119141 3.70817 0.119141H7.37484C7.62797 0.119141 7.83317 0.324343 7.83317 0.577474C7.83317 0.830604 7.62797 1.03581 7.37484 1.03581H3.70817C3.45504 1.03581 3.24984 0.830604 3.24984 0.577474ZM5.08317 6.99414C5.08317 7.24727 5.28837 7.45247 5.5415 7.45247C5.79463 7.45247 5.99984 7.24727 5.99984 6.99414V4.24414C5.99984 3.99101 5.79463 3.78581 5.5415 3.78581C5.28837 3.78581 5.08317 3.99101 5.08317 4.24414V6.99414ZM5.5415 1.95247C3.26333 1.95247 1.4165 3.7993 1.4165 6.07747C1.4165 8.35565 3.26333 10.2025 5.5415 10.2025C7.81968 10.2025 9.6665 8.35565 9.6665 6.07747C9.6665 3.7993 7.81968 1.95247 5.5415 1.95247ZM2.33317 6.07747C2.33317 4.30556 3.76959 2.86914 5.5415 2.86914C7.31342 2.86914 8.74984 4.30556 8.74984 6.07747C8.74984 7.84939 7.31342 9.28581 5.5415 9.28581C3.76959 9.28581 2.33317 7.84939 2.33317 6.07747ZM9.80218 3.65126C9.98146 3.82995 10.2717 3.82948 10.4504 3.65019C10.6291 3.4709 10.6286 3.1807 10.4493 3.00201L9.53111 2.08686C9.35182 1.90816 9.06162 1.90864 8.88293 2.08793C8.70423 2.26721 8.70471 2.55741 8.884 2.73611L9.80218 3.65126Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2506_1857">
          <rect
            width="11"
            height="11"
            fill="white"
            transform="translate(0.5 0.119141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
