import React, { ForwardedRef, type InputHTMLAttributes } from "react";
import DatePicker, { type ReactDatePickerProps } from "react-datepicker";
import { useIntl } from "react-intl";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroup, type InputGroupProps } from "../input-group";
import {
  CalendarContainerElement,
  CustomHeaderElement,
  DayContentElement,
  FormattedWeekdayElement,
} from "./input-date-elements";
import { formatDateToUTC, isRange } from "./input-date-utils";

export type SimpleInputDateProps = {
  range: false;
  onChange?: (value?: Date) => void;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
} & InputGroupProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">;

export type InputDateRangeProps = {
  range: true;
  value: [start?: Date, end?: Date];
  onChange?: (dates: [start?: Date, end?: Date]) => void;
} & InputGroupProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">;

export type InputDateProps = (InputDateRangeProps | SimpleInputDateProps) & {
  popperPlacement?: ReactDatePickerProps["popperPlacement"];
  showTimeSelect?: boolean;
};

const RangeDatePicker = (
  props: InputDateRangeProps & {
    inputProps: {
      ref: ForwardedRef<HTMLInputElement>;
      inputId: string;
      className?: string;
    };
    popperPlacement?: ReactDatePickerProps["popperPlacement"];
  },
) => {
  const intl = useIntl();
  const { inputId, ...inputPropsRest } = props.inputProps;

  return (
    <DatePicker
      popperPlacement={props.popperPlacement}
      selected={props.value[0]}
      value={
        "From " +
        (props.value[0]
          ? intl.formatDate(props.value[0], {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "                  ") +
        " → To " +
        (props.value[1]
          ? intl.formatDate(props.value[1], {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "")
      }
      customInput={<input type="text" id={inputId} {...inputPropsRest} />}
      startDate={props.value[0]}
      endDate={props.value[1]}
      onChange={(values) => {
        props.onChange?.([values[0] ?? undefined, values[1] ?? undefined]);
      }}
      calendarContainer={CalendarContainerElement}
      dateFormat="dd/MM/yyyy"
      monthsShown={2}
      wrapperClassName="w-full inline-flex"
      dayClassName={() => "inline-flex justify-center items-center"}
      selectsRange
      renderDayContents={DayContentElement}
      renderCustomHeader={CustomHeaderElement}
      calendarIconClassname="end-[19px] top-[10px] !p-0"
      formatWeekDay={FormattedWeekdayElement}
    />
  );
};

const SingleDatePicker = (
  props: SimpleInputDateProps & {
    inputProps: {
      ref: ForwardedRef<HTMLInputElement>;
      inputId: string;
      className?: string;
    };
    popperPlacement?: ReactDatePickerProps["popperPlacement"];
    showTimeSelect?: boolean;
  },
) => {
  const { inputId, ...inputPropsRest } = props.inputProps;

  return (
    <DatePicker
      popperPlacement={props.popperPlacement}
      selected={
        props.value
          ? formatDateToUTC(props.value, props.showTimeSelect)
          : undefined
      }
      customInput={<input type="text" id={inputId} {...inputPropsRest} />}
      startDate={props.value}
      onChange={(values) => {
        props.onChange?.(
          values ? formatDateToUTC(values, props.showTimeSelect) : undefined,
        );
      }}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/MM/yyyy"
      wrapperClassName="w-full inline-flex"
      calendarContainer={CalendarContainerElement}
      monthsShown={1}
      dayClassName={() => "inline-flex justify-center items-center"}
      selectsRange={false}
      renderDayContents={DayContentElement}
      renderCustomHeader={CustomHeaderElement}
      calendarIconClassname="end-[19px] top-[10px] !p-0"
      formatWeekDay={FormattedWeekdayElement}
      showTimeSelect={props.showTimeSelect}
      timeIntervals={15}
      minDate={props.minDate}
      maxDate={props.maxDate}
    />
  );
};

export function InputDate(props: InputDateProps) {
  const {
    id,
    label,
    className,
    value = "",
    disabled = false,
    required = false,
    errors,
    groupRef,
    popperPlacement,
    showTimeSelect,
    valid,
  } = props;

  const randId = React.useId();
  const inputId = id ?? randId;

  return (
    <InputGroup
      id={inputId}
      className={className}
      disabled={disabled}
      label={label}
      required={required}
      errors={errors}
      hasValue={!!value}
      labelPosition="top"
      groupRef={groupRef}
      valid={valid}
      iconRight={
        <Icon
          name="Calendar"
          primaryColor={themeColors["dark-2"]}
          size="S"
          height={18}
          width={18}
        />
      }
      renderInput={(inputProps) =>
        isRange(props) ? (
          <RangeDatePicker
            {...props}
            inputProps={{ ...inputProps, inputId }}
            popperPlacement={popperPlacement}
          />
        ) : (
          <SingleDatePicker
            {...props}
            inputProps={{ ...inputProps, inputId }}
            popperPlacement={popperPlacement}
            showTimeSelect={showTimeSelect}
          />
        )
      }
    />
  );
}
