import React, { ReactNode } from "react";

import { useUserBusiness } from "../hooks/use-user-business";
import {
  getPagePathsBySpace,
  pathsByPagesAndSpaces,
} from "../navigation/navigation";
import { PageEnum, SpaceEnum, SpaceType } from "../types";

export interface SpaceProviderProps {
  children: ReactNode;
  currentSpace: SpaceType;
}

type SpaceContextType = {
  currentSpace: SpaceType;
  currentPagePaths: Record<PageEnum, string>;
  pagePaths: Record<PageEnum, Record<SpaceEnum, string>>;
  canSwitchSpace: boolean;
};

export const SpaceContext = React.createContext<SpaceContextType>({
  currentSpace: `${SpaceEnum.INVESTOR}`,
  currentPagePaths: getPagePathsBySpace(`${SpaceEnum.INVESTOR}`),
  pagePaths: pathsByPagesAndSpaces,
  canSwitchSpace: false,
});

export function SpaceProvider(props: SpaceProviderProps) {
  const { currentSpace } = props;

  const { user } = useUserBusiness();

  return (
    <SpaceContext.Provider
      value={{
        currentSpace,
        currentPagePaths: getPagePathsBySpace(currentSpace),
        pagePaths: pathsByPagesAndSpaces,
        canSwitchSpace: Boolean(user && user.appRoles?.length > 0),
      }}
    >
      {props.children}
    </SpaceContext.Provider>
  );
}

export function useSpaceBusiness() {
  return React.useContext(SpaceContext);
}
