import {
  User,
  WMACustomer,
  WMACustomerStatusEnum,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export type CreateWmaCustomerRequestBody = {
  customerUser: {
    email: User["email"];
  };
};

export type SendWmaCustomerQualificationLinkRequest = {
  wmaCustomerId: string;
};

export type SendWmaCustomerInvitationLinkRequest = {
  wmaCustomerId: string;
};

export const wmaCustomersEndpoints = (request: Request) => ({
  getWMACustomersInvitations: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer[]>, void>({
      path: "/api/v1/wma-invitations",
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getWMACustomerById: (
    wmaCustomerId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer>, void>({
      path: `/api/v1/wma-customers/${wmaCustomerId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getWMACustomersByWmaUserId: (
    wmaUserId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer[]>, void>({
      path: `/api/v1/wma/${wmaUserId}/wma-customers`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createWMACustomer: (
    body: CreateWmaCustomerRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer>, void>({
      path: "/api/v1/wma-customers",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateWMACustomerInvitation: (
    wmaCustomerId: string,
    body: { status: WMACustomerStatusEnum },
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer>, void>({
      path: `/api/v1/wma-invitations/${wmaCustomerId}`,
      method: "PUT",
      query,
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  sendWMACustomerQualificationLink: (
    wmaCustomerId: string,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer>, void>({
      path: `/api/v1/wma-customers/${wmaCustomerId}/qualification-link/send`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  sendWMACustomerInvitationLink: (
    wmaCustomerId: string,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/wma-customers/${wmaCustomerId}/invitation-link/send`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
