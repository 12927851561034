import { ReactNode } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { Title } from "../title";
import { Typography } from "../typography";

type AlertSize = "S" | "M" | "F";

type AlertVariant = "info" | "success" | "warning" | "error" | "waiting";

export type AlertProps = {
  className?: string;
  contentClassName?: string;
  title?: string;
  description?: ReactNode;
  variant?: AlertVariant;
  size?: AlertSize;
  icon?: IconName;
  close?: () => void;
};

export function Alert(props: AlertProps) {
  const {
    close,
    variant = "info",
    title,
    description,
    size = "S",
    icon,
    className,
    contentClassName,
  } = props;

  const colorValueByVariant: Record<AlertVariant, string> = {
    info: themeColors["primary"],
    success: themeColors["success"],
    warning: themeColors["warning"],
    error: themeColors["error"],
    waiting: themeColors["waiting"],
  };

  const bgColorByVariant: Record<AlertVariant, keyof typeof themeColors> = {
    info: "light-3",
    success: "success-light",
    warning: "warning-super-light",
    error: "error-light",
    waiting: "waiting-super-light",
  };

  return (
    <div
      className={cn(
        "border-error flex flex-row items-center rounded-lg border border-solid p-4",
        {
          "border-primary": variant === "info",
          "border-success": variant === "success",
          "border-warning": variant === "warning",
          "border-error": variant === "error",
          "border-waiting": variant === "waiting",
        },
        className,
      )}
    >
      {icon && (
        <div
          className={`flex h-[34px] w-[34px] items-center justify-center rounded p-[10px] bg-${bgColorByVariant[variant]}`}
        >
          <Icon
            name={icon}
            primaryColor={colorValueByVariant[variant]}
            width={24}
            height={24}
          />
        </div>
      )}
      <div
        className={cn(
          `mx-4 flex w-[231px] flex-col`,
          {
            "w-[231px]": size === "S",
            "lg:w-[654px]": size === "M",
            "w-full": size === "F",
          },
          contentClassName,
        )}
      >
        <Title className="text-primary" level="h6" h3Brand="brand-2">
          {title}
        </Title>
        {description ? (
          <Typography className="text-primary pt-1" variant="caption1-medium">
            {description}
          </Typography>
        ) : null}
      </div>
      {close && (
        <div onClick={() => close()} className="cursor-pointer">
          <Icon
            name="Cross"
            width={14}
            height={14}
            primaryColor={themeColors["dark-2"]}
          />
        </div>
      )}
    </div>
  );
}
