import { useMemo } from "react";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import type { Step } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { useGetCurrentClubMember } from "../../../hooks/use-get-current-club-member";
import { useGetSubscribeClubStepFromUrl } from "../../../hooks/use-get-subscribe-club-step-from-url";
import {
  SubscribeClubStepPage,
  subscribeClubStepsConfig,
  type SubscribeClubStep,
} from "../../../models/subscribe-club-step";
import { getAvailableClubSubscriptionSteps } from "../../../utils/get-available-club-subscription-steps";

type StepperResult = {
  steps: Step[];
  activeStep: SubscribeClubStep;
  isLastStep: boolean;
};

const initialSteps = Object.values(subscribeClubStepsConfig);
const totalInitialStepsCount = initialSteps.length;

const useSubscribeClubNavigationStepper = (): StepperResult => {
  const { t } = useTranslation();

  const club = useGetClubV2UsingIdPathParams();
  const clubId = club?.id ?? "";

  const { clubMember } = useGetCurrentClubMember({
    clubId,
    clubMemberId: club?.myMembership?.id,
  });

  const urlStep = useGetSubscribeClubStepFromUrl();
  const availableSteps = getAvailableClubSubscriptionSteps(clubMember);
  const isLastStep =
    availableSteps.map(({ page }) => page).includes(totalInitialStepsCount) &&
    urlStep?.page === totalInitialStepsCount;
  const activeStep = urlStep
    ? subscribeClubStepsConfig[urlStep.page]
    : subscribeClubStepsConfig[1];

  const steps = useMemo(() => {
    return initialSteps
      .filter(({ page }) => page !== SubscribeClubStepPage.STEP_4_SUCCESS)
      .map(({ page, path }) => {
        const step = {
          label: t(`subscribe_club_page.navigation_stepper.step.${path}`),
          navigateTo:
            isLastStep || page === urlStep?.page
              ? undefined
              : `members/${clubMember?.id}/steps/${path}`,
          isAvailable:
            availableSteps.map(({ page }) => page).includes(page) || isLastStep,
        };

        return step;
      });
  }, [availableSteps, clubMember, isLastStep, urlStep, t]);

  return { steps, activeStep, isLastStep };
};

export { useSubscribeClubNavigationStepper };
