import { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export const ThinLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <div className={cn("@lg:mr-[14%] mr-0", className)}>{children}</div>;
};
