import React from "react";

type PopperContextType = {
  isOpen: boolean;
  refElement: HTMLElement | null;
  clickable: boolean;
  setIsOpen(value: boolean): void;
  setRefElement(elem: any): void;
};

const PopperContext = React.createContext<PopperContextType>({
  isOpen: false,
  refElement: null,
  clickable: false,
  setIsOpen: () => {},
  setRefElement: () => {},
});

const usePopperContext = () => React.useContext(PopperContext);

export { PopperContext, usePopperContext };
