import { RefObject, useRef } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { IconName } from "../icons/icon-name.type";

type UtilityClassesArgs = {
  active?: boolean;
  classes?: string;
  disabled?: boolean;
  errors?: string[];
  icon?: IconName;
  validated?: boolean;
  value?: string | number | boolean;
};

export const useFocus = (): [
  RefObject<HTMLInputElement>,
  (focus?: boolean) => void,
] => {
  const htmlElRef = useRef<HTMLInputElement>(null);
  const setFocus = (focus?: boolean) => {
    if (htmlElRef && htmlElRef.current) {
      if (focus === undefined || focus) {
        htmlElRef.current.focus();
      } else {
        htmlElRef.current.blur();
      }
    }
  };

  return [htmlElRef, setFocus];
};

export const noBorderInputClasses = `py-[9.5px] font-montserrat text-[13px] leading-[17px] font-normal text-dark-1 !px-0 bg-transparent border-0 focus:ring-0 focus:border-0 focus:outline-none w-full`;

export function getInputClasses(args: UtilityClassesArgs): string {
  const hasErrors = args.errors && args.errors.length > 0;

  const base =
    "h-[38px] !py-1 text-[13px] text-dark-2 bg-white rounded-lg border border-dark-4 outline-none transition-all";

  const focus = "focus:border-accent-medium";

  const borderColor: Record<string, boolean> = {
    "border-dark-2": !!args.value,
    "border-dark-3": !!args.disabled,
    "border-error": !!hasErrors,
    "border-success": !!args.validated,
  };

  const options: Record<string, boolean> = {
    "!px-4": !args.icon,
    "!pl-4 !pr-[40px]": !!args.icon,
    "text-dark-1": !!args.value,
    "bg-light-1 text-dark-4": !!args.disabled,
    "bg-accent-super-light": !!args.active,
    ...borderColor,
  };

  return cn(base, focus, options, args.classes);
}
