import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type TrackProps = {
  disabled: boolean;
  percentageWidth: number;
};

export function Track(props: TrackProps) {
  const { disabled, percentageWidth } = props;

  return (
    <>
      <div
        className={cn(
          "range-slider-track bg-light-2 pointer-events-none absolute left-0 top-2 z-0 h-1 w-full",
          { "bg-light-1": disabled },
        )}
      />
      <div
        style={{ width: `${percentageWidth}%` }}
        className={cn(
          "range-slider-track-current bg-accent-medium pointer-events-none absolute left-0 top-2 z-0 h-1",
          {
            "bg-dark-3": disabled,
          },
        )}
      />
    </>
  );
}
