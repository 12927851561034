import type { PropsWithChildren } from "react";
import { XIcon } from "lucide-react";
import { useInstantSearch } from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { Button } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectsRefinements } from "../hooks/use-projects-refinements";
import { ResetFilters } from "./reset-filters";

type ProjectsPageMenuMobileProps = PropsWithChildren<{
  visible: boolean;
}>;

const ProjectsPageMenuMobile = ({
  children,
  visible,
}: ProjectsPageMenuMobileProps) => {
  return (
    <div
      className={cn(
        "z-dialog fixed left-0 top-0 hidden h-full min-h-screen w-screen flex-col overflow-y-auto bg-white lg:hidden",
        { flex: visible },
      )}
    >
      {children}
    </div>
  );
};

const ProjectsPageMenuMobileHead = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="border-medium-2 flex min-h-12 items-center justify-between border-b">
      <h3 className="text-primary py-1.5 pl-4 font-sans text-lg font-bold">
        Filtrer et trier
      </h3>
      <button onClick={onClose} className="h-full px-4">
        <XIcon className="text-dark-2 h-6 w-6" />
      </button>
    </div>
  );
};

const ProjectsPageMenuMobileActions = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-full flex-col gap-y-6 p-4 pb-40">{children}</div>
  );
};

const ProjectsPageMenuMobileFooter = ({ onClose }: { onClose: () => void }) => {
  const { results } = useInstantSearch();
  const { hasRefinements } = useProjectsRefinements();

  return (
    <div className="border-medium-2 fixed bottom-0 left-0 flex w-full items-center justify-center gap-x-4 border-t bg-white p-4">
      <ResetFilters className="w-1/2" />

      <Button
        label={`${results.nbHits} levées`}
        iconRight="ArrowRight"
        className={cn("flex w-full items-center justify-center", {
          "w-1/2": hasRefinements,
        })}
        onClick={onClose}
      />
    </div>
  );
};

ProjectsPageMenuMobile.Head = ProjectsPageMenuMobileHead;
ProjectsPageMenuMobile.Actions = ProjectsPageMenuMobileActions;
ProjectsPageMenuMobile.Footer = ProjectsPageMenuMobileFooter;

export { ProjectsPageMenuMobile };
