import { useEffect, useRef } from "react";

const trustpilotUrl = new URL("https://fr.trustpilot.com/review/tudigo.co")
  .href;

export function TrustpilotMicroStarWidget() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // @ts-expect-error: Unreachable code error
    // todo: add Trustpilot global instead
    if (window.Trustpilot) {
      // @ts-expect-error: Unreachable code error
      window.Trustpilot.loadFromElement(ref.current, true);
    }

    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="fr-FR"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="603fcdd0daa4640001a49f97"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a href={trustpilotUrl} target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  );
}
