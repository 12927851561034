import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function OrderedList(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 16H4.5V16.5H3.5V17.5H4.5V18H2.5V19H5.5V15H2.5V16ZM3.5 9H4.5V5H2.5V6H3.5V9ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z"
        fill={primaryColor}
      />
    </svg>
  );
}
