import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuthStore from "@tudigo-monorepo/web-tudigo-auth-store";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

export function ProtectedRoute({
  notAuthorizedRedirect = "/",
}: {
  notAuthorizedRedirect?: string;
}) {
  const accessToken = useAuthStore((state) => state.accessToken);
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      const pageName = location.pathname;
      const pageUrl = `${window.location.origin}${location.pathname}${location.search}`;

      mixpanelTrackEvent("ui.page.view", {
        page_name: pageName,
        page_url: pageUrl,
      });
    }
  }, [accessToken, location.pathname, location.search]);

  return accessToken ? <Outlet /> : <Navigate to={notAuthorizedRedirect} />;
}
