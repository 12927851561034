import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

import { IS_LOCALHOST } from "@tudigo-monorepo/core-tudigo-utils";

let mixpanelInitialized = false;

export const useInitMixpanel = (token: string) => {
  useEffect(() => {
    mixpanel.init(token, {
      debug: false,
      track_pageview: true,
      persistence: "cookie",
    });

    mixpanelInitialized = true;

    return () => {
      mixpanel.reset();
      mixpanelInitialized = false;
    };
  }, [token]);
};

export const mixpanelIdentify = (userId: string) => {
  mixpanel.identify(userId);
};

export const mixpanelTrackPageView = (
  pageName: string,
  properties?: Record<string, string | undefined>,
) => {
  if (IS_LOCALHOST) {
    console.log("🤖 Mixpanel track page view", pageName, properties);
  }
  mixpanel.track_pageview({
    page: pageName,
    ...properties,
  });
};

export const mixpanelTrackEvent = (
  name: string,
  properties: Record<string, string | number | undefined | null>,
) => {
  if (!mixpanelInitialized) {
    console.warn(
      "Mixpanel n'est pas initialisé, impossible de traquer l'événement:",
      name,
    );

    return;
  }
  if (IS_LOCALHOST) {
    console.log("🤖 Mixpanel track event", name, properties);
  }
  mixpanel.track(name, properties);
};
