import numeral from "numeral";

import "./currency.locales";

export function formatCurrency(
  cents: number | undefined | null,
  locale = "fr-fr",
  displayCents = true,
): string {
  if (cents === undefined || cents === null) return "";
  numeral.locale(locale);

  const formatString = displayCents ? "0,0.00 $" : "0,0 $";

  if (cents % 100 !== 0) {
    return numeral(cents / 100).format(formatString);
  }

  return numeral(cents / 100).format("0,0 $");
}

export function formatLargeCurrency(
  cents: number | undefined | null,
  locale = "fr-fr",
): string {
  if (cents === undefined || cents === null) return "";
  numeral.locale(locale);

  const amount = cents / 100;

  if (amount >= 1_000_000) {
    return numeral(cents / 100).format("0.0a $");
  }

  return numeral(cents / 100).format("0a $");
}

export function getCurrencySymbol(locale = "fr-fr"): string {
  numeral.locale(locale);

  return numeral.localeData().currency.symbol;
}
