import { ClubV2 } from "./models";

const emptyClubShape: ClubV2 = {
  name: "",
  iconBorderColor: "#ffffff",
  organization: null,
  status: "draft",
  mechanicSettings: undefined,
  faqs: [],
  content: {
    name: "",
    baseline: "",
    tagline: "",
    premiumAdvantages: [],
    presentationMedias: [],
    medias: [],
    presentation: [],
    mentoringDescription: [],
    exitDescription: [],
    thesis: [],
    modalities: [],
    logo: null,
    cover: null,
    thankYouImage: null,
  },
  configuration: {
    isPremiumAvailable: false,
    subscriptionPrice: {
      unitAmountInCents: 0,
      currency: "EUR",
      renewalInterval: "year",
      renewalIntervalCount: 1,
      active: true,
    },
    introductorySubscriptionPrice: {
      unitAmountInCents: 0,
      currency: "EUR",
      renewalInterval: "year",
      renewalIntervalCount: 1,
      active: true,
    },
    maxIntroductorySubscriptions: 0,
    maxSubscriptions: 0,
    subscriptionsClosingDate: null,
    subscriptionsOpeningDate: null,
  },
  leaders: [],
  statistics: {
    followersCount: 255,
    clubLeadersCount: 5,
    liveProjectsCount: 5,
  },
  socialLinks: {
    discord: "",
  },
  trackRecords: [],
  industries: [],
  territories: [],
  maturities: [],
  mentorings: [],
};

export { emptyClubShape };
