import { forwardRef, ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type ErrorProps = {
  children: ReactNode;
  className?: string;
};

export const ErrorMessage = forwardRef<HTMLParagraphElement, ErrorProps>(
  (props, ref) => {
    const { className, ...rest } = props;

    return (
      <p
        ref={ref}
        className={cn("text-destructive text-sm font-medium", className)}
        {...rest}
      />
    );
  },
);
