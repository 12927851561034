import { useCurrentRefinements } from "react-instantsearch";

import {
  algoliaRefinementValueToKey,
  type AlgoliaRefinementAttributesKey,
  type AlgoliaRefinementAttributesValue,
} from "../models/algolia-refinement-attributes";

export const useCurrentRefinement = (
  refinementKey: AlgoliaRefinementAttributesKey,
) => {
  const { items: currentRefinements } = useCurrentRefinements();

  const currentRefinement = currentRefinements.find(
    (refinement) =>
      algoliaRefinementValueToKey[
        refinement.attribute as AlgoliaRefinementAttributesValue
      ] === refinementKey,
  );

  const isRefined = !!currentRefinement?.refinements.length;

  return { currentRefinement, isRefined };
};
