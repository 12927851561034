import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Text(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "19",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13.5C13.7652 0 14.0196 0.105357 14.2071 0.292893C14.3946 0.48043 14.5 0.734784 14.5 1V3C14.5 3.26522 14.3946 3.51957 14.2071 3.70711C14.0196 3.89464 13.7652 4 13.5 4C13.2348 4 12.9804 3.89464 12.7929 3.70711C12.6054 3.51957 12.5 3.26522 12.5 3V2H8.25V14H9.25C9.51522 14 9.76957 14.1054 9.95711 14.2929C10.1446 14.4804 10.25 14.7348 10.25 15C10.25 15.2652 10.1446 15.5196 9.95711 15.7071C9.76957 15.8946 9.51522 16 9.25 16H5.25C4.98478 16 4.73043 15.8946 4.54289 15.7071C4.35536 15.5196 4.25 15.2652 4.25 15C4.25 14.7348 4.35536 14.4804 4.54289 14.2929C4.73043 14.1054 4.98478 14 5.25 14H6.25V2H2V3C2 3.26522 1.89464 3.51957 1.70711 3.70711C1.51957 3.89464 1.26522 4 1 4C0.734784 4 0.48043 3.89464 0.292893 3.70711C0.105357 3.51957 0 3.26522 0 3V1Z"
        fill={primaryColor}
      />
      <path
        d="M10 6.625C10 6.45924 10.0654 6.30027 10.1818 6.18306C10.2982 6.06585 10.4561 6 10.6207 6H18.3793C18.5439 6 18.7018 6.06585 18.8182 6.18306C18.9346 6.30027 19 6.45924 19 6.625V7.875C19 8.04076 18.9346 8.19973 18.8182 8.31694C18.7018 8.43415 18.5439 8.5 18.3793 8.5C18.2147 8.5 18.0568 8.43415 17.9404 8.31694C17.824 8.19973 17.7586 8.04076 17.7586 7.875V7.25H15.1207V14.75H15.7414C15.906 14.75 16.0639 14.8158 16.1803 14.9331C16.2967 15.0503 16.3621 15.2092 16.3621 15.375C16.3621 15.5408 16.2967 15.6997 16.1803 15.8169C16.0639 15.9342 15.906 16 15.7414 16H13.2586C13.094 16 12.9361 15.9342 12.8197 15.8169C12.7033 15.6997 12.6379 15.5408 12.6379 15.375C12.6379 15.2092 12.7033 15.0503 12.8197 14.9331C12.9361 14.8158 13.094 14.75 13.2586 14.75H13.8793V7.25H11.2414V7.875C11.2414 8.04076 11.176 8.19973 11.0596 8.31694C10.9432 8.43415 10.7853 8.5 10.6207 8.5C10.4561 8.5 10.2982 8.43415 10.1818 8.31694C10.0654 8.19973 10 8.04076 10 7.875V6.625Z"
        fill={primaryColor}
      />
    </svg>
  );
}
