import { ProjectCategory } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const projectCategoriesEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getProjectCategories
   * @summary get project categories
   * @request GET:/api/v1/project-categories
   * @secure
   */
  getProjectCategories: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ProjectCategory[]>, void>({
      path: `/api/v1/project-categories`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
