import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Search(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#666879",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={primaryColor}
        d="M9.99999 2.75C11.3701 2.74983 12.7122 3.13789 13.8708 3.86922C15.0294 4.60054 15.957 5.64519 16.5462 6.88212C17.1354 8.11905 17.3621 9.49762 17.1999 10.8581C17.0378 12.2186 16.4934 13.5052 15.63 14.569L20.53 19.469C20.6645 19.6027 20.7434 19.7823 20.7508 19.9718C20.7582 20.1613 20.6936 20.3466 20.5699 20.4903C20.4461 20.6341 20.2726 20.7256 20.0841 20.7465C19.8956 20.7674 19.7062 20.7161 19.554 20.603L19.47 20.53L14.569 15.63C13.6679 16.3611 12.6044 16.8653 11.468 17.1C10.3316 17.3348 9.15544 17.2934 8.03836 16.9793C6.92129 16.6651 5.89594 16.0874 5.04848 15.2947C4.20103 14.502 3.55623 13.5175 3.16828 12.4238C2.78033 11.3302 2.66055 10.1594 2.81903 9.00989C2.9775 7.86035 3.40959 6.76565 4.07898 5.81778C4.74837 4.8699 5.63551 4.09655 6.66584 3.56272C7.69616 3.02889 8.83958 2.75018 9.99999 2.75ZM9.99999 4.25C9.24489 4.25 8.49718 4.39873 7.79956 4.68769C7.10194 4.97666 6.46806 5.4002 5.93412 5.93414C5.40019 6.46807 4.97664 7.10195 4.68768 7.79957C4.39872 8.49719 4.24999 9.2449 4.24999 10C4.24999 10.7551 4.39872 11.5028 4.68768 12.2004C4.97664 12.8981 5.40019 13.5319 5.93412 14.0659C6.46806 14.5998 7.10194 15.0233 7.79956 15.3123C8.49718 15.6013 9.24489 15.75 9.99999 15.75C11.525 15.75 12.9875 15.1442 14.0659 14.0659C15.1442 12.9875 15.75 11.525 15.75 10C15.75 8.47501 15.1442 7.01247 14.0659 5.93414C12.9875 4.8558 11.525 4.25 9.99999 4.25Z"
      />
    </svg>
  );
}
