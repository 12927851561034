import {
  isSharesProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Chip } from "../chip";

export function ProjectCardChips(props: {
  project: Project;
  smallCard?: boolean;
}) {
  const { project, smallCard = false } = props;

  const { t } = useTranslation();

  const getProjectMechanicLabel = () => {
    if (isSharesProject(project)) {
      return t("component.project_card.chips.shares");
    }

    if (project.bondsMechanicSettings?.isConvertible) {
      return t("component.project_card.chips.bonds_convertible");
    }

    return t("component.project_card.chips.bonds");
  };

  return (
    <div
      className={cn("flex flex-wrap items-center gap-1.5 ", {
        "h-[28px] overflow-hidden": smallCard,
      })}
    >
      <Chip label={getProjectMechanicLabel()} />

      {project.tags?.map((tag) => (
        <Chip key={tag.name} label={tag.name} type="secondary" />
      ))}
      {project.categories?.map((category) => (
        <Chip key={category.name} label={category.name} type="secondary" />
      ))}
    </div>
  );
}
