import * as React from "react";

type State = boolean;
type Toggler = () => void;

export const useToggler = (
  initial = false,
): [State, Toggler, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [value, setValue] = React.useState<boolean>(initial);
  const toggleValue = () => setValue((prev) => !prev);

  return [value, toggleValue, setValue];
};
