import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ButtonVariant } from "./button";

export const buttonStyles: Record<ButtonVariant, string> = {
  primary: cn(
    "bg-primary border-white text-white",
    "sm:hover:text-primary sm:hover:border-accent-medium sm:hover:text-dark-2 sm:hover:bg-white",
  ),
  tertiary: cn(
    "text-dark-2 border-tertiary bg-white",
    "sm:hover:bg-tertiary sm:hover:border-accent-medium sm:hover:text-dark-2",
  ),
  ghost: cn(
    "text-dark-2 border-transparent bg-white",
    "sm:hover:bg-ghost sm:hover:border-accent-medium sm:hover:text-dark-2",
  ),
  secondary: cn(
    "bg-secondary text-primary",
    "sm:hover:text-primary sm:hover:border-accent-medium sm:hover:text-dark-2 sm:hover:bg-white",
  ),
  destructive: cn(
    "text-error border-tertiary bg-white",
    "sm:hover:bg-tertiary sm:hover:border-error",
  ),
  "destructive-ghost": cn(
    "text-error border-white bg-white",
    "sm:hover:bg-tertiary sm:hover:border-error",
  ),
  green: cn(
    "bg-success border-success text-white",
    "sm:hover:text-dark-2 sm:hover:border-success-medium sm:hover:bg-white",
    "active:bg-success-light active:border-success-medium active:text-success",
  ),
};
