import * as React from "react";
import * as SlickSliderPrimitive from "react-slick";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { getCarouselSlidesCount } from "./utils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";

type CarouselArrowsPlacement = "aside" | "above";

type CarouselSlidesToShow = "auto" | number;

type ArrowProps = {
  direction: "left" | "right";
  classes?: string;
  placement?: CarouselArrowsPlacement;
  onClick: () => void;
};

type CarouselProps = React.PropsWithChildren<
  Omit<SlickSliderPrimitive.Settings, "slidesToShow"> & {
    arrowsPlacement?: CarouselArrowsPlacement;
    arrowLeftClassName?: string;
    arrowRightClassName?: string;
    slidesToShow?: CarouselSlidesToShow;
  }
>;

type SettingsState = {
  slidesCount: number;
  hasArrows: boolean;
  hasDots: boolean;
};

const SlickSlider = SlickSliderPrimitive.default;

const Arrow = ({
  classes,
  direction,
  placement = "aside",
  onClick,
}: ArrowProps) => {
  return (
    <div
      data-carousel-arrow={direction}
      onClick={onClick}
      className={cn(
        "absolute top-[calc(50%-16px)]",
        "h-8 w-8",
        "hidden items-center justify-center sm:flex",
        "z-30 cursor-pointer rounded-full shadow-[0_4px_16px_0_rgba(0,4,32,0.10)] transition-all",
        "bg-white hover:shadow-[0_4px_16px_0_rgba(0,4,32,0.20)]",
        {
          "left-[-15px]": placement === "aside" && direction === "left",
          "right-[-15px]": placement === "aside" && direction === "right",
          "right-12": placement === "above" && direction === "left",
          "right-0": placement === "above" && direction === "right",
          "top-4": placement === "above",
        },
        classes,
      )}
    >
      <Icon
        name={direction === "left" ? "ChevronLeft" : "ChevronRight"}
        primaryColor={themeColors["dark-2"]}
        width={16}
      />
    </div>
  );
};

const Carousel = ({
  children,
  className,
  arrowsPlacement = "aside",
  arrowLeftClassName,
  arrowRightClassName,
  dotsClass,
  arrows = false,
  dots = false,
  slidesToShow = "auto",
  ...props
}: CarouselProps) => {
  const ref = React.useRef<SlickSliderPrimitive.default>(null);

  const [settingsState, setSettingsState] = React.useState<SettingsState>({
    slidesCount: getCarouselSlidesCount(slidesToShow),
    hasArrows: arrows,
    hasDots: dots,
  });

  React.useEffect(() => {
    const setSettings = () => {
      const isMobile = window.innerWidth <= 640;
      const slidesCount = getCarouselSlidesCount(slidesToShow);
      setSettingsState(() => ({
        slidesCount,
        hasArrows: !isMobile,
        hasDots: isMobile,
      }));
    };

    setSettings();

    window.addEventListener("resize", setSettings);

    return () => {
      window.removeEventListener("resize", setSettings);
    };
  }, [slidesToShow]);

  const next = () => {
    if (ref.current) {
      ref.current.slickNext();
    }
  };

  const prev = () => {
    if (ref.current) {
      ref.current.slickPrev();
    }
  };

  return (
    <SlickSlider
      ref={ref}
      accessibility
      adaptiveHeight
      slidesToShow={settingsState.slidesCount}
      arrows={settingsState.hasArrows}
      dots={settingsState.hasDots}
      prevArrow={
        <Arrow
          direction="left"
          placement={arrowsPlacement}
          classes={arrowLeftClassName}
          onClick={prev}
        />
      }
      nextArrow={
        <Arrow
          direction="right"
          placement={arrowsPlacement}
          classes={arrowRightClassName}
          onClick={next}
        />
      }
      className={cn(
        "h-full w-full py-4",
        {
          "sm:pt-16": arrowsPlacement === "above",
        },
        className,
      )}
      dotsClass={cn("slick-dots", { "sm:!hidden": !dots }, dotsClass)}
      cssEase="cubic-bezier(0.645,0.045,0.355,1)"
      {...props}
    >
      {children}
    </SlickSlider>
  );
};

Carousel.displayName = "Carousel";

export { Carousel, CarouselArrowsPlacement, CarouselSlidesToShow };
