import { type Step, type StepObject } from "./stepper";

const findStepIndex = (steps: Step[], targetIndex: number): number => {
  const targetStep: string | StepObject = steps[targetIndex];

  return steps.findIndex((step) => {
    if (typeof step === "object" && typeof targetStep === "object") {
      return step.label === targetStep.label;
    }

    return step === targetStep;
  });
};

const isStepVisible = (params: {
  activeStep: number;
  currentStep: number;
  steps: Step[];
}): boolean => {
  const { activeStep, currentStep, steps } = params;
  const secondStepIndex = findStepIndex(steps, 1);
  const beforeLastStepIndex = findStepIndex(steps, steps.length - 2);
  const lastStepIndex = findStepIndex(steps, steps.length - 1);

  return (
    currentStep === 0 ||
    currentStep === lastStepIndex ||
    currentStep === activeStep ||
    (currentStep === activeStep + 1 && currentStep === secondStepIndex) ||
    (currentStep === activeStep - 1 && currentStep === beforeLastStepIndex) ||
    (activeStep > lastStepIndex && currentStep === beforeLastStepIndex)
  );
};

const isStepObject = (step: string | StepObject): step is StepObject => {
  return typeof step === "object" && "label" in step;
};

export { isStepVisible, isStepObject };
