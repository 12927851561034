import { type PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export interface NavbarLabelProps extends PropsWithChildren {
  className?: string;
}

export const NavbarLabel = (props: NavbarLabelProps) => {
  return (
    <span
      className={cn(
        "flex h-full items-center justify-center",
        "border-b-2 border-transparent",
        "font-roobert text-black-primary text-lg",
        "hover:text-primary-rebrand",
        "transition-colors duration-200",
        props.className,
      )}
    >
      {props.children}
    </span>
  );
};
