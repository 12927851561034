import { User, UserState } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface UpdateUserBody {
  firstName?: User["firstName"];
  lastName?: User["lastName"];
  profileImage?: User["profileImage"];
  phoneNumber?: User["phoneNumber"];
  locale?: User["locale"];
}

export interface MarkIntroHasDone {
  state?: {
    hasDoneIntro?: boolean;
  };
}

export interface ChangeEmailRequestBody {
  newEmail: string;
}

export interface ChangePasswordRequestBody {
  oldPassword: string;
  newPassword: string;
}

export const usersEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getUserById
   * @summary get user by id, id can be replaced with 'me'
   * @request GET:/api/v1/users/:userId
   * @secure
   */
  getUserById: (
    userId: User["id"] | "me",
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) => {
    return request<TudigoResponse<User>, void>({
      ...params,
      path: `/api/v1/users/${userId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      headers: {
        ...params.headers,
      },
    });
  },

  /**
   * No description
   *
   * @name getUsers
   * @summary get users
   * @request GET:/api/v1/users
   * @secure
   */
  getUsers: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<User[]>, void>({
      path: `/api/v1/users`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name updateUser
   * @summary updateUser, id can be replaced with 'me'
   * @request PUT:/api/v1/users/:userId
   * @secure
   */
  updateUser: (
    userId: User["id"] | "me",
    body: UpdateUserBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<User>, void>({
      path: `/api/v1/users/${userId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  markIntroHasDone: (userId: User["id"] | "me") =>
    request<TudigoResponse<UserState>, void>({
      path: `/api/v1/users/${userId}/states`,
      method: "PUT",
      body: {
        hasDoneIntro: true,
      },
      secure: true,
      type: ContentType.Json,
    }),

  /**
   * No description
   *
   * @name deleteUser
   * @summary deleteUser, id can be replaced with 'me'
   * @request DELETE:/api/v1/users/:userId
   * @secure
   */
  deleteUser: (userId: User["id"] | "me") =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/users/${userId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
    }),

  /**
   * No description
   *
   * @name updateUser
   * @summary updateUser, id can be replaced with 'me'
   * @request PUT:/api/v1/users/:userId
   * @secure
   */
  requestChangeUserEmail: (
    userId: User["id"] | "me",
    body: ChangeEmailRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/users/${userId}/change-email-requests`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
  /**
   * No description
   *
   * @name updateUserPassword
   * @summary updateUserPassword, id can be replaced with 'me'
   * @request PUT:/api/v1/users/:userId
   * @secure
   */
  requestChangeUserPassword: (
    userId: User["id"] | "me",
    body: ChangePasswordRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/users/${userId}/change-password-requests`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  grantAdminRole: (body: { id: User["id"] }, params: RequestParams = {}) =>
    request<TudigoResponse<User>, void>({
      path: "/api/v1/admin-role",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  removeAdminRole: (body: { id: User["id"] }, params: RequestParams = {}) =>
    request<TudigoResponse<User>, void>({
      path: `/api/v1/admin-role/${body.id}`,
      method: "DELETE",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
