import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Bank } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../../index";
import { getQueryListKey } from "../../query-key-factory";

type GetBanksParams = CollectionRequestParams<undefined, { term?: string }>;

export const getBanks = async (params: GetBanksParams) => {
  const apiClient = getApiClient();
  const { pagination, fields, filters } = params;

  const response = await apiClient.apiV1.banks.getBanks({
    fields: fields,
    offset: pagination?.offset,
    limit: pagination?.limit,
    filters,
  });

  return response.data;
};

export const useGetBanksQuery = (
  params: GetBanksParams,
  options?: UseQueryOptions<TudigoResponse<Bank[]>, TudigoError>,
) =>
  useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.BANKS,
      fields: params?.fields,
      filters: params?.filters,
    }),
    queryFn: () => getBanks(params),
    ...options,
  });
