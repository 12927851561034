import type { Todo } from "@tudigo-monorepo/core-tudigo-api-models";

import {
  ContentType,
  type Request,
  type RequestParams,
  type TudigoResponse,
} from "../api";

export const todosEndpoints = (request: Request) => ({
  markTodoAsDone: (
    todoId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Todo>, void>({
      path: `/api/v1/todos/${todoId}`,
      method: "PUT",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
