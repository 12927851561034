import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import {
  Button,
  Icon,
  PresentationCard,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { TrustpilotMicroStarWidget } from "../../pages/shared-internal/components/trustpilot-micro-star-widget";

const SITE_URL = import.meta.env["VITE_WEBSITE_URL"];
const scheduleMeetingUrl = new URL(`${SITE_URL}/conseillers-investissement`)
  .href;

const advantagesList: string[] = [
  "Analyse de vos objectifs",
  "Stratégie sur mesure",
  "Simulations",
  "Avantages fiscaux",
];

const AppointmentCard = (props: { className?: string }) => {
  const openScheduleMeetingPage = () => {
    window.open(scheduleMeetingUrl, "_blank");
  };

  return (
    <PresentationCard
      className={cn("md:h-[428px]", props.className)}
      title="Faites vous accompagner"
      subtitle="Nos chargés d’investissement participatif sont là pour vous aider gratuitement"
      coverImageUrl={{
        mobile:
          "https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/contact-mobile.png",
        desktop:
          "https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/contact.png",
      }}
      listComponent={
        <ul className="flex flex-col gap-y-1.5">
          {advantagesList.map((label, index) => (
            <li key={index} className="flex items-center gap-x-4">
              <div className="bg-success-light flex h-6 w-6 items-center justify-center rounded-full">
                <Icon name="Checked" primaryColor={themeColors.success} />
              </div>
              <Typography>{label}</Typography>
            </li>
          ))}
        </ul>
      }
      buttonComponent={
        <Button
          label="Prendre rendez-vous"
          iconLeft="Helpdesk"
          className="w-[212px]"
          onClick={openScheduleMeetingPage}
        />
      }
      footerComponent={
        <div className="border-light-1 flex w-full border-t pt-4">
          <div className="-ml-4">
            <TrustpilotMicroStarWidget />
          </div>
        </div>
      }
    />
  );
};

export { AppointmentCard };
