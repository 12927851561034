import intlTelInput from "intl-tel-input";

import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { PhoneInputError } from "./phone-input";

export const validatePhoneNumber = (
  itiPlugin: intlTelInput.Plugin | undefined,
) => {
  const number = itiPlugin?.getNumber() ?? "";
  const isValidNumber = itiPlugin?.isValidNumber() ?? false;
  const validationErrorCode = itiPlugin?.getValidationError() as number;
  const isError = number.length > 0 && !isValidNumber;

  return {
    isError,
    errorCode: validationErrorCode === -99 ? null : validationErrorCode,
  };
};

export const usePhoneNumberErrorMessage = (
  error: PhoneInputError,
  expectedFormat: string | undefined,
) => {
  const { t } = useTranslation();

  const errorTranslationKeysMap = [
    "phone_input.error.invalid_number",
    "phone_input.error.invalid_country_code",
    "phone_input.error.too_short",
    "phone_input.error.too_long",
    "phone_input.error.invalid_number",
  ];

  const errorKey =
    error.code && errorTranslationKeysMap[error.code]
      ? errorTranslationKeysMap[error.code]
      : "phone_input.error.invalid_number";

  const message = expectedFormat
    ? `${t(errorKey)}. ${t("phone_input.error.expected_format", {
        format: expectedFormat,
      })}`
    : t(errorKey);

  return message;
};
