import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function QuoteLeft(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: width || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55713 17.8315C6.94645 18.1369 6.34668 18.2896 5.75781 18.2896C4.90723 18.2896 4.03483 18.0496 3.14062 17.5698C2.26823 17.09 1.52669 16.3376 0.916016 15.3125C0.305339 14.2874 0 13.0116 0 11.4849C0 9.3693 0.665202 7.33008 1.99561 5.36719C3.34782 3.4043 5.92139 1.92122 9.71631 0.917969L10.4033 3.14258C8.39681 3.66602 6.90283 4.46208 5.92139 5.53076C4.96175 6.57764 4.48193 7.67904 4.48193 8.83496C4.48193 9.77279 4.8418 10.5907 5.56152 11.2886C6.28125 11.9865 7.19727 12.3354 8.30957 12.3354C8.52767 12.3354 8.85482 12.3136 9.29102 12.27C9.59635 13.077 9.74902 13.7531 9.74902 14.2983C9.74902 15.1271 9.54183 15.8468 9.12744 16.4575C8.71305 17.0464 8.18962 17.5044 7.55713 17.8315ZM17.8296 17.8315C17.2189 18.1369 16.6191 18.2896 16.0303 18.2896C15.1797 18.2896 14.3073 18.0496 13.4131 17.5698C12.5407 17.09 11.7992 16.3376 11.1885 15.3125C10.5996 14.2874 10.3052 13.0116 10.3052 11.4849C10.3052 9.3693 10.9704 7.33008 12.3008 5.36719C13.653 3.4043 16.2266 1.92122 20.0215 0.917969L20.7412 3.14258C18.7347 3.66602 17.2407 4.46208 16.2593 5.53076C15.2996 6.57764 14.8198 7.67904 14.8198 8.83496C14.8198 9.7946 15.1688 10.6234 15.8667 11.3213C16.5864 11.9974 17.5024 12.3354 18.6147 12.3354C18.811 12.3354 19.1273 12.3136 19.5635 12.27C19.8688 13.077 20.0215 13.7531 20.0215 14.2983C20.0215 15.1271 19.8143 15.8468 19.3999 16.4575C18.9855 17.0464 18.4621 17.5044 17.8296 17.8315Z"
        fill={primaryColor}
      />
    </svg>
  );
}
