import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function InformationIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clipPath="url(#clip0_243_501)">
        <path
          d="M12 3.17215C19.2 3.17215 21 4.97215 21 12.1721C21 19.3721 19.2 21.1721 12 21.1721C4.8 21.1721 3 19.3721 3 12.1721C3 4.97215 4.8 3.17215 12 3.17215Z"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16.1721V16.1821"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 13.1721C12.4498 13.1735 12.8868 13.0232 13.2407 12.7456C13.5945 12.4679 13.8444 12.0791 13.95 11.642C14.0557 11.2048 14.0109 10.7448 13.8229 10.3362C13.6349 9.92763 13.3147 9.59434 12.914 9.39015C12.5162 9.18636 12.0611 9.12317 11.6228 9.21087C11.1845 9.29857 10.7888 9.53198 10.5 9.87315"
          stroke={primaryColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_243_501">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
            transform="translate(0 0.17215)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
