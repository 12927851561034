import React, {
  FC,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CompositeDecorator,
  Editor as DraftEditor,
  DraftHandleValue,
  EditorState,
  RichUtils,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { InputGroup, InputGroupProps } from "../input-group";
import { linkTool } from "./decorators/link-decorator";
import {
  LinkModal,
  removeLink,
  useSelectionHasLink,
  useSelectionLink,
} from "./tool-items/link-tool";

type InputRichTextProps = {
  validated?: boolean;
  value?: string;
  onChange?: (content: string) => void;
  placeholder?: string;
} & InputGroupProps;

export const InputRichText = forwardRef<HTMLInputElement, InputRichTextProps>(
  (props: InputRichTextProps, ref) => {
    const {
      id,
      label,
      className,
      value = "",
      disabled = false,
      required = false,
      onChange,
      errors,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    const toolRef = useRef<HTMLDivElement>(null);

    const decorator = new CompositeDecorator([linkTool]);

    const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(stateFromHTML(value ?? ""), decorator),
    );

    const selection = useMemo(() => editorState.getSelection(), [editorState]);
    const selectionHasLink = useSelectionHasLink(editorState, selection);
    const selectionLink = useSelectionLink(editorState, selection);
    const [urlToolState, setUrlToolState] = useState({
      showURLInput: false,
      urlValue: "",
    });

    useEffect(() => {
      const html = stateToHTML(editorState.getCurrentContent());
      if (value !== html) {
        onChange && onChange(stateToHTML(editorState.getCurrentContent()));
      }
    }, [editorState, onChange, value]);

    const handleKeyCommand = (
      command: string,
      editorState: EditorState,
    ): DraftHandleValue => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);

        return "handled";
      }

      return "not-handled";
    };

    const ToolItem = ({
      icon,
      onClick,
    }: {
      icon: IconName;
      onClick: (e: any) => void;
    }) => {
      return (
        <button
          onMouseDown={(e) => e.preventDefault()}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
          }}
          className="p-3"
        >
          <Icon name={icon} />
        </button>
      );
    };

    const ToolBox: FC = () => {
      return (
        <div
          ref={toolRef}
          className={cn("flex rounded-t-lg border-b bg-white p-1")}
        >
          <ToolItem
            icon="Bold"
            onClick={() => {
              setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
            }}
          />
          <ToolItem
            icon="Italic"
            onClick={() => {
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, "ITALIC"),
              );
            }}
          />
          <ToolItem
            icon="OrderedList"
            onClick={() => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "ordered-list-item"),
              );
            }}
          />
          <ToolItem
            icon="UnorderedList"
            onClick={() => {
              setEditorState(
                RichUtils.toggleBlockType(editorState, "unordered-list-item"),
              );
            }}
          />
          {!selection.isCollapsed() || selectionHasLink ? (
            <ToolItem
              icon="InsertLink" // change by unlink
              onClick={(e) => {
                e.preventDefault();
                if (!selection.isCollapsed() || selectionHasLink) {
                  setUrlToolState({
                    showURLInput: true,
                    urlValue: selectionLink,
                  });
                }
              }}
            />
          ) : null}

          {selectionHasLink && (
            <ToolItem
              icon="InsertLink"
              onClick={(e) => {
                e.preventDefault();
                removeLink(editorState, setEditorState);
              }}
            />
          )}
        </div>
      );
    };

    return (
      <InputGroup
        ref={ref}
        id={inputId}
        className={className}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated}
        fakeInputClassName="px-0 border-gray"
        renderInput={(inputProps) => (
          <div className={cn(inputProps.className, "py-0")}>
            <ToolBox />
            <LinkModal
              urlToolState={urlToolState}
              setUrlToolState={setUrlToolState}
              editorState={editorState}
              setEditorState={setEditorState}
            />
            <div className="p-4">
              <DraftEditor
                preserveSelectionOnBlur
                handleKeyCommand={handleKeyCommand}
                editorState={editorState}
                onChange={setEditorState}
              />
            </div>
          </div>
        )}
      />
    );
  },
);
