export type AlgoliaRefinementAttributesKey =
  | "instrument"
  | "tax_exemption"
  | "maturity"
  | "category"
  | "risk";

export type AlgoliaRefinementAttributesValue =
  | "instrument"
  | "taxExemptionMechanics"
  | "maturity.code"
  | "categories.name"
  | "riskFacet";

export type AlgoliaProjectsStatusFilter = "started" | "finished";

export const algoliaRefinementAttributes: Record<
  AlgoliaRefinementAttributesKey,
  AlgoliaRefinementAttributesValue
> = {
  instrument: "instrument",
  tax_exemption: "taxExemptionMechanics",
  maturity: "maturity.code",
  category: "categories.name",
  risk: "riskFacet",
} as const;

export const algoliaRefinementValueToKey: Record<
  AlgoliaRefinementAttributesValue,
  AlgoliaRefinementAttributesKey
> = {
  instrument: "instrument",
  taxExemptionMechanics: "tax_exemption",
  "maturity.code": "maturity",
  "categories.name": "category",
  riskFacet: "risk",
} as const;

export const algoliaProjectsStatusFilter: Record<
  AlgoliaProjectsStatusFilter,
  string
> = {
  started: "status:started",
  finished: "(status:finished OR status:prefinished)",
} as const;
