import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { TudigoLogoSmallRounded } from "../../logo/tudigo-logo-rebrand";
import { SocialMediaLinks } from "../../ui/social-media-links/social-media-links";
import { FooterCategoryRebrandDesktop } from "./footer-category-rebrand-desktop";
import { FooterCategoryRebrandMobile } from "./footer-category-rebrand-mobile";
import { categories } from "./footer-data-rebrand";
import { NewsletterSignup } from "./newsletter-signup/newsletter-signup";

const groupedCategories = [
  ["Pour investir", "Témoignages"],
  ["Pour lever des fonds", "À Propos"],
  ["Ressources"],
];

export function FooterRebrand() {
  return (
    <footer
      className={cn(
        "bg-black-800 font-roobert text-black-primary flex w-full flex-col items-center justify-center gap-16 px-4 py-10 text-base md:px-16 md:py-16",
      )}
    >
      <div className="footer-wrapper flex max-w-screen-xl flex-col  ">
        <div className="flex flex-col lg:flex-row lg:gap-10">
          <div className="footer-links relative flex flex-col lg:grid lg:grid-cols-3 lg:gap-x-10">
            <div className="mb-5 flex justify-center lg:hidden">
              <a href="/">
                <TudigoLogoSmallRounded />
              </a>
            </div>

            {groupedCategories.map((group, groupIndex) => (
              <div key={groupIndex} className="flex flex-col lg:gap-y-12">
                {group.map((title) => {
                  const category = categories.find(
                    (cat) => cat.title === title,
                  );

                  return (
                    category && (
                      <React.Fragment key={category.title}>
                        <div className="hidden lg:flex">
                          <FooterCategoryRebrandDesktop
                            category={category}
                            className="hidden lg:flex"
                          />
                        </div>
                        <div className="lg:hidden">
                          <FooterCategoryRebrandMobile category={category} />
                        </div>
                      </React.Fragment>
                    )
                  );
                })}
                {groupIndex === 0 && (
                  <div className="absolute inset-x-0 bottom-0 hidden lg:block">
                    <a href="/">
                      <TudigoLogoSmallRounded />
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div>
            <div className="social-media-links mb-12 flex flex-col gap-y-6 lg:gap-y-4 lg:px-9 ">
              <p className="rounded-md bg-white px-2.5 py-2.5 font-medium lg:bg-transparent lg:px-0 lg:py-0">
                Pour nous suivre
              </p>
              <SocialMediaLinks className="flex justify-center gap-3 lg:justify-normal" />
            </div>
            <NewsletterSignup />
          </div>
        </div>
      </div>
      <div className="text-black-900 flex flex-col gap-6 text-center">
        <p>© Tudigo 2024, Tous droits réservés.</p>
        <p className="text-xs lg:text-sm">
          {`AVERTISSEMENT : Investir dans des sociétés non cotées comporte un
          risque important de perte partielle ou totale du capital ainsi qu’un
          risque d’illiquidité. SAS BULB IN TOWN, société par actions simplifiée
          au capital 278.194,86 euros, dont le siège social est situé 1 rue
          d'Enghien à Bordeaux (33 000), immatriculée au RCS de Bordeaux sous le
          numéro 788 438 406, agréée par l'Autorité des Marchés Financiers en
          qualité de Prestataire de Services de Financement Participatif sous le
          numéro FP-2023-39`}
        </p>
      </div>
    </footer>
  );
}
