import { createContext, useContext, type PropsWithChildren } from "react";

import type { News } from "@tudigo-monorepo/core-tudigo-api-models";

type ProjectNewsContextType = {
  news: News | undefined;
  editMode: boolean;
};

const ProjectNewsContext = createContext<ProjectNewsContextType>({
  news: undefined,
  editMode: false,
});

const useProjectNewsContext = () => {
  const context = useContext(ProjectNewsContext);

  if (context === undefined) {
    throw new Error("useProjectNews must be used within a ProjectNewsProvider");
  }

  return context;
};

const ProjectNewsProvider = ({
  children,
  news,
  editMode,
}: PropsWithChildren<ProjectNewsContextType>) => {
  return (
    <ProjectNewsContext.Provider value={{ news, editMode }}>
      {children}
    </ProjectNewsContext.Provider>
  );
};

export { ProjectNewsProvider, useProjectNewsContext };
