enum SubscribeClubStepPage {
  STEP_1_OFFER = 1,
  STEP_2_SIGN = 2,
  STEP_3_PAYMENT = 3,
  STEP_4_SUCCESS = 4,
}

type SubscribeClubStepPath = "offer" | "sign" | "payment" | "success";

interface SubscribeClubStep {
  page: SubscribeClubStepPage;
  path: SubscribeClubStepPath;
}

const subscribeClubStepsConfig: Record<
  SubscribeClubStepPage,
  SubscribeClubStep
> = {
  1: { page: SubscribeClubStepPage.STEP_1_OFFER, path: "offer" },
  2: { page: SubscribeClubStepPage.STEP_2_SIGN, path: "sign" },
  3: { page: SubscribeClubStepPage.STEP_3_PAYMENT, path: "payment" },
  4: { page: SubscribeClubStepPage.STEP_4_SUCCESS, path: "success" },
} as const;

export {
  SubscribeClubStepPage,
  type SubscribeClubStepPath,
  type SubscribeClubStep,
  subscribeClubStepsConfig,
};
