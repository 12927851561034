import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useEditorContext } from "../store/editor-context";
import { EditorPluginInput } from "./editor-plugin-input";
import { EditorTutorial } from "./editor-tutorial";

export const EditorContextualPanel = () => {
  const {
    content,
    selectedBlockId,
    selectedPlugin,
    settings: { editMode },
    setSettings,
    selectedBlockContent,
  } = useEditorContext();

  if (!editMode) return null;
  if (!selectedBlockContent) return null;

  return (
    <div
      key={selectedPlugin?.name}
      className="editor-contextual-panel h-screen w-[326px] overflow-y-auto bg-white p-2.5 pb-40"
    >
      {(content.length === 0 || selectedBlockId === null) &&
      selectedPlugin === null ? (
        <EditorTutorial />
      ) : selectedPlugin !== null ? (
        <div
          className="flex flex-col gap-y-2.5"
          onMouseEnter={() => {
            setSettings((prev) => ({
              ...prev,
              pluginInputsHovered: true,
            }));
          }}
          onMouseLeave={() => {
            setSettings((prev) => ({
              ...prev,
              pluginInputsHovered: false,
            }));
          }}
        >
          <div className="flex items-center gap-x-2.5">
            <Icon
              name={selectedPlugin.icon}
              primaryColor={themeColors["dark-1"]}
            />
            <Typography variant="body2" className="font-bold">
              {selectedPlugin.displayName}
            </Typography>
          </div>
          {selectedPlugin.inputsSections
            .filter(
              (section) =>
                !section.visibilityCondition ||
                section.visibilityCondition(selectedBlockContent.data),
            )
            .map((section, index) => (
              <div
                key={index}
                className={cn(
                  "editor-contextual-panel-list flex flex-col gap-y-4 rounded-lg",
                  {
                    "border-light-1 border p-4": section.hasBorder,
                  },
                )}
              >
                {section.inputs.map((input, index2) => (
                  <EditorPluginInput key={index2} input={input} />
                ))}
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};
