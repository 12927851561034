import React, { forwardRef, InputHTMLAttributes } from "react";

import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroupProps } from "../input-group";
import { InputGroup } from "../input-group/input-group";

type InputEmailProps = {
  validated?: boolean;
} & InputGroupProps &
  InputHTMLAttributes<HTMLInputElement>;

export const InputEmail = forwardRef<HTMLInputElement, InputEmailProps>(
  (props: InputEmailProps, ref) => {
    const {
      id,
      label,
      className,
      autoComplete,
      name,
      value = "",
      pattern,
      placeholder,
      autoFocus = false,
      disabled = false,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
      groupRef,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    return (
      <InputGroup
        groupRef={groupRef}
        ref={ref}
        id={inputId}
        className={className}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated}
        iconRight={
          <Icon
            name="Mail"
            primaryColor={themeColors["dark-2"]}
            size="S"
            height={18}
            width={18}
          />
        }
        renderInput={(inputProps) => (
          <input
            type="email"
            id={inputId}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            name={name}
            onKeyDown={onKeyDown}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            pattern={pattern}
            onFocus={onFocus}
            onChange={onChange}
            {...inputProps}
          />
        )}
      />
    );
  },
);
