import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";

import { getApiClient } from "../../../";

export const getSignatureRequestSyncById = async (
  signatureRequestId: string,
  wait = 1500,
) => {
  await new Promise((resolve) => setTimeout(resolve, wait));
  const apiClient = getApiClient();

  if (!signatureRequestId) {
    throw new Error("signatureRequestId is required");
  }
  await apiClient.apiV1.signatures.getSignatureRequestSync(signatureRequestId);
};
export const useGetSignatureRequestSyncById = (
  signatureRequestId: string,
  options?: UseQueryOptions<unknown, TudigoError>,
) =>
  useQuery({
    queryKey: ["signatureRequestSync", signatureRequestId],
    queryFn: () => getSignatureRequestSyncById(signatureRequestId),
    ...options,
  });
