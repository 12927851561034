import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Club } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import { getQueryDetailKey } from "../../query-key-factory";

type GetClubByIdQuery = {
  fields?: string;
  clubId: string;
};

export const getClubById = async (query: GetClubByIdQuery) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getClubById(query);

  return response.data;
};

export const useGetClubById = (
  params: GetClubByIdQuery,
  options?: UseQueryOptions<TudigoResponse<Club>, TudigoError>,
) => {
  const { fields, clubId } = params ?? {};

  return useQuery({
    queryKey: getQueryDetailKey({
      key: QUERY_KEYS.CLUBS,
      id: clubId,
    }),
    queryFn: () =>
      getClubById({
        fields,
        clubId,
      }),
    ...options,
  });
};
