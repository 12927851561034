import { ReactNode } from "react";
import BaseDropdown from "@restart/ui/Dropdown";
import { Placement } from "@restart/ui/usePopper";

import DropdownMenu from "./dropdown-menu";
import DropdownMenuItem from "./dropdown-menu-item";
import DropdownToggle from "./dropdown-toggle";

export interface DropdownProps {
  show?: boolean;
  onToggle: (open: boolean) => void;
  placement?: Placement;
  role?: string;
  className?: string;
  children: ReactNode;
  onSelect?: (value: any) => void;
}

function Dropdown(props: DropdownProps) {
  const { onSelect, children, className, placement, show, onToggle } = props;

  return (
    <BaseDropdown
      onSelect={onSelect}
      placement={placement}
      show={show}
      onToggle={onToggle}
    >
      <div className={className}>{children}</div>
    </BaseDropdown>
  );
}

Dropdown.displayName = "Dropdown";

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownMenuItem;

export { Dropdown };
