import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function PictureMulti(props: IconElementsProps) {
  const { primaryColor = "#666879", width, height, size = "S" } = props;

  return (
    <svg
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0424 7.33548C11.6045 7.33548 12.0603 6.87977 12.0603 6.31762C12.0603 5.75548 11.6045 5.29977 11.0424 5.29977C10.4803 5.29977 10.0245 5.75548 10.0245 6.31762C10.0245 6.87977 10.4803 7.33548 11.0424 7.33548ZM2.39062 5.80869C2.39062 4.12226 3.75775 2.75513 5.44419 2.75513H11.5513C13.2378 2.75513 14.6049 4.12226 14.6049 5.80869V11.9158C14.6049 13.6023 13.2378 14.9694 11.5513 14.9694H5.44419C3.75775 14.9694 2.39062 13.6023 2.39062 11.9158V5.80869ZM5.44419 3.77298C4.3199 3.77298 3.40848 4.6844 3.40848 5.80869V11.9158C3.40848 12.2934 3.51125 12.6469 3.69033 12.9499L7.05827 9.58201C7.85326 8.78702 9.1422 8.78702 9.9372 9.58201L13.3052 12.95C13.4843 12.6469 13.587 12.2934 13.587 11.9158V5.80869C13.587 4.6844 12.6756 3.77298 11.5513 3.77298H5.44419ZM9.21747 10.3017C8.81997 9.90425 8.1755 9.90425 7.778 10.3017L4.41006 13.6697C4.71314 13.8488 5.06666 13.9515 5.44419 13.9515H11.5513C11.9288 13.9515 12.2824 13.8488 12.5854 13.6697L9.21747 10.3017ZM7.47993 17.0051C6.57556 17.0051 5.76302 16.612 5.20389 15.9873H12.0603C13.9048 15.9873 15.4219 14.5854 15.6044 12.789C15.6165 12.6693 15.6228 12.5477 15.6228 12.4248V5.56839C16.2475 6.12752 16.6406 6.94006 16.6406 7.84443V12.4248C16.6406 14.9544 14.5899 17.0051 12.0603 17.0051H7.47993Z"
        fill={primaryColor}
      />
    </svg>
  );
}
