import { Form } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const formsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getFormById
   * @summary get a form by its id
   * @request GET:/api/v1/forms/:formId
   * @secure
   */
  getFormById: (
    formId: Form["id"],
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Form>, void>({
      path: `/api/v1/forms/${formId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
