import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubLeaderSpeciality } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { CollectionRequestParams, getApiClient } from "../../..";
import { getQueryListKey } from "../../query-key-factory";

type SpecialitiesQuery = {
  fields?: string;
  limit?: number;
  offset?: number;
  sort_direction?: "ASC" | "DESC";
};

const defaultFields = "code,name";

export const getSpecialities = async ({
  query,
}: {
  query: SpecialitiesQuery;
}) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.getSpecialities(query);

  return response.data;
};

export const useGetSpecialities = (
  params?: CollectionRequestParams<undefined, SpecialitiesQuery>,
  options?: Partial<
    UseQueryOptions<TudigoResponse<ClubLeaderSpeciality[]>, TudigoError>
  >,
) => {
  const { pagination, fields = defaultFields, filters } = params ?? {};

  return useQuery({
    queryKey: getQueryListKey({
      key: QUERY_KEYS.SPECIALITIES,
      filters,
    }),
    queryFn: () =>
      getSpecialities({
        query: {
          ...filters,
          fields,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    ...options,
  });
};
