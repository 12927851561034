import React from "react";
import { NavLink } from "react-router-dom";

type LinkableProps = {
  children: React.ReactNode;
  className?: string;
  navigateTo?: string;
  role?: React.AriaRole;
  onClick?: () => void;
};

export const Linkable: React.FC<LinkableProps> = ({
  children,
  className,
  navigateTo,
  role,
  onClick,
  ...rest
}) => {
  return navigateTo ? (
    <NavLink to={navigateTo} className={className} onClick={onClick} {...rest}>
      {children}
    </NavLink>
  ) : (
    <div
      role={role}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
