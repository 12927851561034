import { ReactNode } from "react";
import { JSX } from "react/jsx-runtime";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export type TitleVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle";
type H3Brand = "brand-1" | "brand-2";

type TypographyProps = {
  children: ReactNode;
  level?: TitleVariant;
  h3Brand?: H3Brand;
  className?: string;
  onClick?: () => void;
};

export function Title(props: TypographyProps) {
  const {
    level = "h1",
    h3Brand = "brand-1",
    children,
    className,
    onClick,
  } = props;

  const Component =
    level === "subtitle" ? "p" : (level as keyof JSX.IntrinsicElements);

  const styles: Record<TitleVariant, string> = {
    h1: "font-obviously text-[27px] leading-[45.9px] font-[570]",
    h2: "font-obviously text-lg leading-[23.4px] font-[570]",
    h3:
      h3Brand === "brand-1"
        ? "font-obviously text-base leading-[21px] font-[570]"
        : "font-montserrat text-lg leading-6 font-bold",
    h4: "font-montserrat text-base leading-[27.2px] font-bold",
    h5: "font-montserrat text-sm leading-[14px] font-semibold",
    h6: "font-montserrat text-xs leading-[20.4px] font-bold",
    subtitle: "font-montserrat text-base leading-[27.2px] font-normal",
  };

  return (
    <Component
      className={cn(styles[level], className, { "cursor-pointer": onClick })}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
