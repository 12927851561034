import { FormEvent, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { Club } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";
import {
  getQueryListKey,
  useRequestSubscriptionSync,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubAdvantages } from "../../../../components/club-advantage";
import { ClubMembership } from "../../../../components/club-membership";

type FunnelClubPaymentProps = {
  club: Club;
  subscriptionId: string;
};

export const FunnelClubPayment = ({
  club,
  subscriptionId,
}: FunnelClubPaymentProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: requestSubscriptionSync } = useRequestSubscriptionSync({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.MY_CLUB_MEMBERS,
        }),
      });
      navigate(`/clubs/join/${club.id}/success`);
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  const configuration = club?.configuration;

  const advantages = club?.description?.map((advantage) => ({
    text: advantage?.data?.phrase,
    subText: advantage?.data?.description,
  }));

  const handleSubmit = async (event: FormEvent) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      setError(true);
    } else {
      requestSubscriptionSync({
        subscriptionId: subscriptionId,
      });

      setError(false);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="border-b pb-5">
        <Title>{t("Votre Paiement !")}</Title>
      </div>
      {error && (
        <Alert
          variant="error"
          title="Paiement refusé"
          description="Le paiement n’est pas passé. Vérifiez les informations de votre carte ou essayez avec une autre carte bancaire."
          size="F"
        />
      )}
      <ClubMembership
        title={t("my_account.wep_app.clubs.membership")}
        description={t(
          "my.account.web.app.clubs.membership_info.subscription",
          {
            subscriptionsCount: configuration?.maxIntroductorySubscriptions,
          },
        )}
        price={t(
          "my.account.web.app.clubs.membership_info.subscription.currency_VAT",
          {
            amount:
              configuration?.introductorySubscriptionPrice?.unitAmountInCents /
              100,
          },
        )}
        oldPrice={t(
          "my.account.web.app.clubs.membership_info.subscription.currency_VAT",
          {
            amount: configuration?.subscriptionPrice?.unitAmountInCents / 100,
          },
        )}
        membershipText={t(
          "my.account.web.app.clubs.membership_info.subscription.amount_subscription",
        )}
        isIntroductoryPriceAvailable={club?.isIntroductoryPriceAvailable}
      />
      <div className="flex flex-col gap-4 sm:flex-row">
        <ClubAdvantages className="basis-1/2" advantages={advantages} />

        <div className="basis-1/2 rounded border p-4 shadow-lg">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <Typography variant="body1" className="pb-6 pl-2 font-bold">
              Paiement par carte bancaire
            </Typography>
            <div className="mx-[-1rem] border-b px-4" />
            <PaymentElement
              options={{ terms: { sepaDebit: "never", card: "never" } }}
            />
            <div className="mx-[-1rem] border-b px-4" />
            <Button
              className="mx-auto"
              type="submit"
              label="Valider"
              isLoading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
};
