import { useInstantSearch } from "react-instantsearch";

import { useProjectsRefinements } from "./use-projects-refinements";

export const useProjectsSearchResults = () => {
  const { results, error } = useInstantSearch();
  const { hasRefinements } = useProjectsRefinements();

  const projectsCount = results.nbHits;
  const hasQuery = results.query.length > 0;
  const hasQueryResults = (hasQuery || hasRefinements) && projectsCount >= 1;
  const hasEmptyResults = (hasQuery || hasRefinements) && projectsCount === 0;

  return {
    isError: !!error,
    hasQueryResults,
    hasEmptyResults,
  };
};
