import { useLayoutEffect, useRef } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { ChipCount } from "../chip-count";
import { Icon } from "../icons/icon";

type ThumbProps = {
  disabled: boolean;
  percentageLeft: number;
  value: number;
  displayChip?: boolean;
  valueChipFormat?: (value: number) => string;
};

export function Thumb(props: ThumbProps) {
  const { disabled, value, percentageLeft, displayChip, valueChipFormat } =
    props;

  const thumbColor = disabled
    ? themeColors["dark-3"]
    : themeColors["accent-medium"];

  const ref = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      thumbRef.current &&
      ref.current &&
      ref.current?.getBoundingClientRect().left +
        ref.current?.clientWidth -
        window.innerWidth >
        0
    ) {
      thumbRef.current.style.left = `${
        window.innerWidth -
        ref.current?.getBoundingClientRect().left -
        ref.current?.clientWidth
      }px`;
    }
  }, [value]);

  return (
    <div
      ref={ref}
      className="range-slider-thumb pointer-events-none absolute bottom-[18px] z-10 flex flex-col gap-y-1"
      style={{
        left: `calc(${percentageLeft}% - 12px)`,
      }}
    >
      {displayChip && (
        <div ref={thumbRef} className="relative">
          <ChipCount
            variant="accent"
            className={cn("whitespace-nowrap", {
              "-translate-x-1": value > 0,
            })}
            label={valueChipFormat ? valueChipFormat(value) : value}
          />
        </div>
      )}
      <Icon name="Thumb" primaryColor={thumbColor} />
    </div>
  );
}
