import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function EventPlugin(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        stroke={primaryColor}
      />
      <path
        d="M16.955 8.81163C16.9865 8.91632 17 9.02556 17 9.1348V14.9428C17 15.571 16.4962 16.0807 15.8754 16.0807C15.7674 16.0807 15.6595 16.0671 15.556 16.0352L12.7985 15.2068C12.3126 16.2127 11.1116 16.627 10.1219 16.1354C9.46964 15.8122 9.04229 15.1567 9.0063 14.4194V14.3192V14.0643L7.80972 13.7047C7.32839 13.5591 7 13.1176 7 12.6123V11.4698C7 10.9646 7.32839 10.5231 7.80522 10.3774L15.556 8.04694C16.1498 7.86942 16.7796 8.2108 16.955 8.81163ZM9.74854 14.2874V14.3147C9.74854 15.0156 10.3063 15.5801 10.9991 15.5801C11.4309 15.5801 11.8358 15.3525 12.0607 14.9838L9.74854 14.2874ZM15.7719 8.77066L8.01664 11.1012C7.8592 11.1512 7.74674 11.2969 7.74674 11.4653V12.6078C7.74674 12.7762 7.8547 12.9264 8.01664 12.9719L15.7674 15.3024C15.9654 15.3616 16.1768 15.2478 16.2353 15.0475C16.2443 15.0111 16.2488 14.9747 16.2488 14.9383V9.1348C16.2488 8.92542 16.0823 8.75701 15.8754 8.75701C15.8394 8.75701 15.8034 8.76156 15.7674 8.77066H15.7719Z"
        fill={primaryColor}
      />
    </svg>
  );
}
