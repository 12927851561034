import React from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { Alert } from "@tudigo-monorepo/web-tudigo-components";

import { ProjectsCarousel } from "./projects-carousel";

type EmptyResultsProps = React.PropsWithChildren<{
  suggestedProjects: Project[];
  visible: boolean;
  message?: string;
}>;

const EmptyResults = ({
  children,
  suggestedProjects,
  visible,
  message,
}: EmptyResultsProps) => {
  if (!visible) return null;

  return (
    <div className="flex flex-col gap-y-6">
      <Alert
        variant="warning"
        title={message ?? "Aucun résultat pour votre recherche"}
        icon="Error"
        className="items-center"
      />
      {children}
      <ProjectsCarousel projects={suggestedProjects} visible />
    </div>
  );
};

export { EmptyResults };
