import { useEffect } from "react";

declare global {
  interface Window {
    axeptioSettings: any;
  }
}

export const useInitAxeptio = () => {
  useEffect(() => {
    const d = document;
    const s = "script";
    const t = d.getElementsByTagName(s)[0];
    const e = d.createElement(s);
    e.async = true;
    e.src = "//static.axept.io/sdk.js";
    t.parentNode?.insertBefore(e, t);

    return () => {
      document.body.removeChild(e);
    };
  }, []);
};
