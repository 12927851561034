import React, { useEffect } from "react";

import "./newsletter-signup.css";

export function NewsletterSignup() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6032261",
          formId: "89e0c0c9-2ce3-4c8e-bf38-4e5ee3abdb95",
          target: "#hubspotForm",
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="md: flex w-full max-w-full  flex-col gap-3 rounded-xl  bg-white  lg:min-w-[250px] lg:max-w-[250px]">
      <div className="flex flex-col gap-2 px-6 pt-6 text-center md:px-9 md:pt-9 lg:text-left    ">
        <p className=" text-lg font-medium md:text-base">{`S'inscrire à la Newsletter`}</p>
        <p className="text-black-900 line text-[15px] leading-[19px]">
          Tous les 15 jours, du contenu exclusif pour passer à l’action et
          investir en non coté
        </p>
      </div>
      <div
        id="hubspotForm"
        className="form-container font-roobert font- px-2.5 pb-2.5 text-[15px] "
      />
    </div>
  );
}
