import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialLinkedin2(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{ minWidth: width, minHeight: height }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 23"
      fill={primaryColor}
    >
      <g>
        <path
          d="M18.8566 0.161133H1.8094C1.41783 0.161133 1.04231 0.316681 0.765432 0.593557C0.488556 0.870434 0.333008 1.24596 0.333008 1.63752V18.6847C0.333008 19.0763 0.488556 19.4518 0.765432 19.7287C1.04231 20.0056 1.41783 20.1611 1.8094 20.1611H18.8566C19.2482 20.1611 19.6237 20.0056 19.9006 19.7287C20.1775 19.4518 20.333 19.0763 20.333 18.6847V1.63752C20.333 1.24596 20.1775 0.870434 19.9006 0.593557C19.6237 0.316681 19.2482 0.161133 18.8566 0.161133ZM6.29412 17.1986H3.28718V7.64724H6.29412V17.1986ZM4.78856 6.32363C4.44748 6.32171 4.1146 6.2188 3.83195 6.02787C3.5493 5.83695 3.32954 5.56658 3.20041 5.25087C3.07127 4.93517 3.03855 4.58829 3.10637 4.25401C3.17419 3.91973 3.33951 3.61303 3.58147 3.37262C3.82343 3.1322 4.13119 2.96886 4.4659 2.90318C4.80061 2.83751 5.14727 2.87246 5.46213 3.00362C5.777 3.13478 6.04596 3.35627 6.23506 3.64014C6.42417 3.92401 6.52494 4.25754 6.52467 4.59863C6.52789 4.82699 6.4851 5.05367 6.39885 5.26514C6.3126 5.47661 6.18466 5.66855 6.02265 5.82952C5.86064 5.99049 5.66788 6.1172 5.45586 6.20209C5.24384 6.28698 5.0169 6.32832 4.78856 6.32363ZM17.3775 17.207H14.3719V11.9889C14.3719 10.45 13.7177 9.97502 12.8733 9.97502C11.9816 9.97502 11.1066 10.6472 11.1066 12.0278V17.207H8.09967V7.65419H10.9913V8.9778H11.0302C11.3205 8.3903 12.3372 7.38613 13.8886 7.38613C15.5663 7.38613 17.3788 8.38197 17.3788 11.2986L17.3775 17.207Z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
}
