import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { ProjectCard } from "@tudigo-monorepo/web-tudigo-components";

import { projectCardsGridClassName } from "../utils/classname-utils";

type BaseHit = { objectID: string; [key: string]: unknown };
type ProjectHit = Project & BaseHit;

export const CustomHits = (props: { hits: ProjectHit[] }) => {
  const { currentPagePaths } = useSpaceBusiness();

  return (
    <div className={projectCardsGridClassName}>
      {props.hits.map((hit) => (
        <ProjectCard
          key={hit.id}
          navigateTo={currentPagePaths[PageEnum.PROJECT].replace(
            ":projectId",
            hit.content?.slug ?? hit.id,
          )}
          project={hit}
        />
      ))}
    </div>
  );
};
