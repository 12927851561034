import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialTiktok(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3138 7.21957C15.5999 8.14221 17.1755 8.68508 18.8771 8.68508V5.39885C18.5551 5.39892 18.2339 5.36522 17.9188 5.29823V7.88496C16.2173 7.88496 14.6419 7.34209 13.3554 6.41952V13.1257C13.3554 16.4805 10.6455 19.1999 7.30283 19.1999C6.0556 19.1999 4.89635 18.8215 3.93336 18.1726C5.03245 19.3004 6.56522 20 8.26096 20C11.6038 20 14.3139 17.2806 14.3139 13.9257V7.21957H14.3138ZM15.496 3.90417C14.8387 3.18353 14.4071 2.25224 14.3138 1.22266V0.79998H13.4056C13.6342 2.10858 14.4139 3.22657 15.496 3.90417ZM6.0476 15.5982C5.68037 15.115 5.48192 14.5238 5.48281 13.916C5.48281 12.3817 6.72231 11.1376 8.25153 11.1376C8.53652 11.1375 8.8198 11.1813 9.0914 11.2678V7.90815C8.774 7.8645 8.45367 7.84597 8.13347 7.85276V10.4678C7.86167 10.3813 7.57825 10.3374 7.29319 10.3376C5.76397 10.3376 4.52454 11.5816 4.52454 13.1161C4.52454 14.2012 5.14408 15.1406 6.0476 15.5982Z"
        fill="#999BA6"
      />
      <path
        d="M13.3554 6.41945C14.6419 7.34202 16.2172 7.88489 17.9188 7.88489V5.29816C16.969 5.09513 16.1281 4.59702 15.4959 3.90417C14.4138 3.2265 13.6342 2.10851 13.4056 0.79998H11.0201V13.9255C11.0147 15.4557 9.77728 16.6947 8.25135 16.6947C7.35214 16.6947 6.55329 16.2645 6.04735 15.5982C5.14391 15.1406 4.52436 14.2011 4.52436 13.1162C4.52436 11.5818 5.7638 10.3377 7.29301 10.3377C7.586 10.3377 7.8684 10.3835 8.13329 10.4678V7.85283C4.84934 7.92092 2.20825 10.6138 2.20825 13.9256C2.20825 15.5788 2.86594 17.0776 3.93339 18.1726C4.89637 18.8215 6.05562 19.2 7.30285 19.2C10.6456 19.2 13.3555 16.4805 13.3555 13.1257V6.41945H13.3554Z"
        fill="#00083C"
      />
      <path
        d="M17.9188 5.29817V4.59874C17.0623 4.60004 16.2226 4.35932 15.496 3.90411C16.1392 4.61089 16.9863 5.09822 17.9188 5.29817ZM13.4056 0.799986C13.3838 0.674926 13.367 0.549042 13.3554 0.422678V0H10.0617V13.1257C10.0564 14.6556 8.8191 15.8946 7.29303 15.8946C6.845 15.8946 6.42199 15.7879 6.04737 15.5983C6.55331 16.2645 7.35216 16.6946 8.25137 16.6946C9.77717 16.6946 11.0148 15.4558 11.0201 13.9256V0.799986H13.4056ZM8.13345 7.85284V7.10824C7.85823 7.07049 7.58076 7.05155 7.30294 7.05169C3.95993 7.05162 1.25 9.77116 1.25 13.1257C1.25 15.2288 2.31505 17.0822 3.93347 18.1725C2.86603 17.0775 2.20834 15.5787 2.20834 13.9255C2.20834 10.6138 4.84936 7.92093 8.13345 7.85284Z"
        fill="#666879"
      />
    </svg>
  );
}
