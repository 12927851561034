import { Link } from "react-router-dom";

import {
  isComingProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { ProjectFlag } from "../project-flag/project-flag";
import { ProjectCardChips } from "./project-card-chips";
import { ProjectCardCover } from "./project-card-cover";
import { ProjectCardFooter } from "./project-card-footer";
import { ProjectCardMechanicSettings } from "./project-card-mechanic-settings";
import { ProjectClubIcon } from "./project-club-icon";

type ProjectCardProps = {
  project: Project;
  className?: string;
  navigateTo?: string;
  smallCard?: boolean;
  target?: string;
};

export function ProjectCard(props: ProjectCardProps) {
  const { project, className, navigateTo, smallCard = false, target } = props;

  const { isQualifiedOrWma } = useUserBusiness();
  const { content } = project;
  const isProjectPageLink = !!navigateTo && !isComingProject(project);

  return (
    <Link
      target={target}
      to={isProjectPageLink ? navigateTo : ""}
      className={cn(
        "w-full min-w-[294px]",
        "flex flex-col gap-4",
        "overflow-hidden rounded-lg bg-white",
        "shadow-card hover:shadow-card-hover",
        "duration-300 ease-in-out",
        { "h-[312px]": smallCard },
        {
          "cursor-pointer": isProjectPageLink,
        },
        className,
      )}
    >
      <ProjectCardCover projectContent={content} smallCard={smallCard}>
        <ProjectFlag project={project} />
        {project.club && <ProjectClubIcon club={project.club} />}
      </ProjectCardCover>

      <div className="flex h-full flex-col justify-between gap-4 bg-transparent">
        <div className="flex flex-col gap-4 px-4">
          <ProjectCardChips project={project} smallCard />

          {content?.name && (
            <h2 className="font-brand text-primary truncate text-lg">
              {content?.name}
            </h2>
          )}

          {!smallCard && (
            <>
              {content?.shortDescription && (
                <p className="font-montserrat text-primary text-xs">
                  {content?.shortDescription}
                </p>
              )}
              <ProjectCardMechanicSettings
                project={project}
                isLimitedAccess={!isQualifiedOrWma}
              />
            </>
          )}
        </div>

        <ProjectCardFooter project={project} />
      </div>
    </Link>
  );
}
