import React from "react";

export interface Category {
  title?: string;
  links: Link[];
}

export interface Link {
  name?: string;
  href: string;
  Icon?: React.FC<{ className: string }>;
}
const ALGOLIA_INDEX_PREFIX = import.meta.env["VITE_ALGOLIA_INDEX_PREFIX"];

export const categories: Category[] = [
  {
    title: "Pour investir",
    links: [
      { name: "Toutes les opportunités", href: "/" },
      {
        name: "Investir en Actions",
        href: `/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Binstrument%5D%5B0%5D=shares`,
      },
      {
        name: "Investir en Obligations",
        href: `/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Binstrument%5D%5B0%5D=bonds`,
      },
      {
        name: "Investissement immobilier",
        href: `/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Bcategories.name%5D%5B0%5D=Immobilier`,
      },
      {
        name: "Pourquoi investir ?",
        href: "https://tudigo.co/pourquoi-investir",
      },
      { name: "Nous contacter", href: "https://tudigo.co/contact" },
      {
        name: "Comprendre les risques",
        href: "https://tudigo.co/comprendre-risques",
      },
      {
        name: "Conseillers en Gestion de Patrimoine",
        href: "https://tudigo.co/cgp",
      },
    ],
  },
  {
    title: "Témoignages",
    links: [
      {
        name: "Interview Investisseurs",
        href: "https://tudigo.co/interviews-investisseurs",
      },
      {
        name: "Interview Entreprises",
        href: "https://tudigo.co/interviews-entreprises",
      },
    ],
  },
  {
    title: "Pour lever des fonds",
    links: [
      {
        name: "Déposer un dossier",
        href: "https://tudigo.typeform.com/to/ucMQlqZf?typeform-source=www.tudigo.co",
      },
      {
        name: "Comment lever des fonds ?",
        href: "https://tudigo.co/lever-des-fonds",
      },
      {
        name: "Nos histoires inspirantes",
        href: "https://tudigo.co/histoires-inspirantes",
      },
      {
        name: "Sélection des projets",
        href: "https://tudigo.co/selection-projets",
      },
    ],
  },
  {
    title: "À Propos",
    links: [
      {
        name: "Qui sommes-nous ?",
        href: "https://tudigo.co/qui-sommes-nous",
      },
      {
        name: "Notre performance",
        href: "https://tudigo.co/performance",
      },
      {
        name: "Procédure extinctive",
        href: "https://tudigo.co/procedure-gestion-extinctive",
      },
      {
        name: "Espace Presse",
        href: "https://tudigo.co/presse",
      },
      {
        name: "Nos partenaires",
        href: "https://tudigo.co/partenaires",
      },
      { name: "Contact", href: "https://tudigo.co/contact" },
      { name: "CGU", href: "https://tudigo.co/cgu" },
      {
        name: "Mentions légales",
        href: "https://tudigo.co/mentions-legales",
      },
      {
        name: "Politique de confidentialité",
        href: "https://tudigo.co/privacy",
      },
      {
        name: "Réclamations",
        href: "https://cdn.prod.website-files.com/6642339dc91a7d65e7ad1eed/667ac7911d8b9ffec0b28376_Formulaire%20de%20r%C3%A9clamation.pdf",
      },
      {
        name: "Gestion des conflits d'intérêts",
        href: "https://tudigo.co/gestion-conflits-interets",
      },
      {
        name: "Due diligence",
        href: "https://tudigo.co/due-diligence",
      },
    ],
  },
  {
    title: "Ressources",
    links: [
      {
        name: "Le blog de Tudigo",
        href: "https://tudigo.co/blog",
      },
      {
        name: "Nos articles",
        href: "https://tudigo.co/blog/articles",
      },
      {
        name: "Nos webinaires",
        href: "https://tudigo.co/blog/webinaires",
      },
      {
        name: "Guide : Comment investir quand on débute ?",
        href: "https://tudigo.co/comment-investir",
      },
      {
        name: "Guide : Investir pour réduire ses impôts",
        href: "https://tudigo.co/defiscalisation",
      },
      {
        name: "Guide : Élaborer sa stratégie d'investissement avec Tudigo",
        href: "https://tudigo.co/strategie-investissement",
      },
      {
        name: "Guide : Le crowdfunding, ou investir dans l'économie réelle",
        href: "https://tudigo.co/crowdfunding",
      },
      {
        name: "Questions fréquentes",
        href: "https://tudigo.co/faq",
      },
    ],
  },
];
