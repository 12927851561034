import { useEffect } from "react";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

const CHATBOT_SCRIPT_URL =
  "https://convia.io/noname/v1/noname.js?key=0192dd63-8367-7930-bfda-0d5c9323e3cd";

export const useInitConvia = () => {
  const { isAdmin } = useUserBusiness();
  const isProd = import.meta.env["VITE_ENV"] === "prod";

  useEffect(() => {
    if (!isAdmin || isProd) return;

    const script = document.createElement("script");
    script.src = CHATBOT_SCRIPT_URL;
    script.defer = true;
    script.type = "text/javascript";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isAdmin, isProd]);
};
