import { SimulatorRepaymentPeriod } from "../repayments";

export enum SimulatorScenario {
  Min = "min",
  Medium = "medium",
  Max = "max",
}

export interface Simulator {
  annualRoiYield: string;
  roiInCents: string;
  roiYield: string;
  targetedOutInCents: string;
  targetedGainInCents: string;
  taxExemptionInCents: string;
  targetedYieldInPercent: number;
  tri?: string;
  repaymentInCents?: string;
  interestsInCents?: string;
  repayments?: SimulatorRepaymentPeriod[];
}

export interface ShareProjectSimulatorQuery {
  amountInCents: number;
  scenario?: SimulatorScenario;
  taxRate: number;
}

export interface BondProjectSimulatorQuery {
  amountInCents: number;
}
