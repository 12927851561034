import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialTwitter(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 21 19"
      fill={primaryColor}
      className={className}
    >
      <path
        d="M15.2721 1.67056H18.0833L11.9416 8.69014L19.1668 18.2422H13.5095L9.07853 12.4489L4.00845 18.2422H1.19552L7.76469 10.734L0.833496 1.67056H6.63442L10.6397 6.96584L15.2721 1.67056ZM14.2854 16.5595H15.8432L5.78799 3.26484H4.11638L14.2854 16.5595Z"
        fill={primaryColor}
      />
    </svg>
  );
}
