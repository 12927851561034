import type { PropsWithChildren } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, InstantSearchProps } from "react-instantsearch";

import { useProjectIndexName } from "../index";

const appId = import.meta.env["VITE_ALGOLIA_APP_ID"];
const apiKey = import.meta.env["VITE_ALGOLIA_API_KEY"];
const algoliaSearchClient = algoliasearch(appId, apiKey);

export const SearchProvider = (
  props: PropsWithChildren<Omit<InstantSearchProps, "searchClient">>,
) => {
  const indexName = useProjectIndexName();

  return (
    <InstantSearch
      {...props}
      searchClient={algoliaSearchClient}
      indexName={indexName}
      routing
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      {props.children}
    </InstantSearch>
  );
};
