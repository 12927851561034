import { useCallback, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import { useNavigate, useParams } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { Club, ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { SIGNATURE_REQUESTS } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetClubById,
  useGetUserClubMembers,
} from "@tudigo-monorepo/web-tudigo-api-client";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { FunnelClubDescription } from "./components/funnel-club-description/funnel-club-description";
import { FunnelClubMembershipForm } from "./components/funnel-club-membership-form/funnel-club-membership-form";
import { FunnelClubPayment } from "./components/funnel-club-payment/funnel-club-payment";
import { FunnelClubSuccess } from "./components/funnel-club-sucess/funnel-club-success";

const STEPS = {
  MEMBER_FORM: "membership-form",
  PAYMENT: "payment",
  DESCRIPTION: "description",
  SUCCESS: "success",
};

const stripeKey = String(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const stripePromise = loadStripe(stripeKey);

export const FunnelClubPage = () => {
  const { step, clubId } = useParams() as Record<"step" | "clubId", string>;
  const navigate = useNavigate();

  const { data: club } = useGetClubById({ clubId });

  const { user: connectedUser } = useUserBusiness();

  const userId = connectedUser?.id;

  const { data: clubMembers, isLoading: isLoadingClubMembers } =
    useGetUserClubMembers(
      {
        pathParams: {
          userId: userId!,
        },
      },
      {
        refetchOnMount: true,
        enabled: !!userId,
      },
    );

  const currentClubMember = clubMembers?.data?.find(
    (clubMember) => clubMember.club?.id === clubId,
  );

  const currentClubSubscriptionPaymentIntent =
    currentClubMember?.subscription?.latestPaymentIntent?.token;

  const currentClubHasContractToSign =
    currentClubMember?.contract?.signatureRequest?.status ===
    SIGNATURE_REQUESTS.STATUS.ONGOING;

  const currentClubHasContractSigned =
    currentClubMember?.contract?.signatureRequest?.status ===
    SIGNATURE_REQUESTS.STATUS.DONE;

  const subscriptionId = currentClubMember?.subscription?.id;

  const currentClubIsActive = currentClubMember?.status === "active";

  const checkSubscriptionStatusStep = useCallback(async () => {
    // 2nd step
    const isMemberShipStep =
      currentClubHasContractToSign &&
      !currentClubSubscriptionPaymentIntent &&
      !currentClubIsActive;

    // 3rd step
    const isPaymentStep =
      currentClubHasContractSigned &&
      currentClubSubscriptionPaymentIntent &&
      !currentClubIsActive;

    // 1st step
    const isDescriptionStep =
      !currentClubHasContractToSign && !currentClubHasContractSigned;

    // 4th step
    const isSuccessStep = currentClubIsActive;

    if (isMemberShipStep) {
      const signLink =
        currentClubMember?.contract?.signatureRequest?.mySignatureLink;
      const signId = currentClubMember?.contract?.signatureRequest?.id;

      return navigate(
        `/clubs/join/${clubId}/membership-form?${queryString.stringify({
          signLink,
          signId,
        })}`,
      );
    }

    if (isPaymentStep) {
      return navigate(`/clubs/join/${clubId}/payment`);
    }

    if (isSuccessStep) {
      return navigate(`/clubs/join/${clubId}/success`);
    }

    if (isDescriptionStep) {
      return navigate(`/clubs/join/${clubId}/description`);
    }
  }, [
    clubId,
    currentClubMember,
    navigate,
    currentClubSubscriptionPaymentIntent,
    currentClubHasContractSigned,
    currentClubHasContractToSign,
    currentClubIsActive,
  ]);

  useEffect(() => {
    if (!connectedUser) {
      localStorage.setItem(
        "target-url",
        `/clubs/join/${clubId}/description?skip_intro=1`,
      );
      window.location.replace(getAuthClient().authorizeUrl());

      return;
    }

    if (clubMembers) {
      checkSubscriptionStatusStep();
    }
  }, [clubMembers, checkSubscriptionStatusStep, connectedUser, clubId]);

  const renderStep = () => {
    const isMemberFormStep = step === STEPS.MEMBER_FORM;
    const isPaymentStep = step === STEPS.PAYMENT;
    const isSuccessStep = step === STEPS.SUCCESS;

    if (isMemberFormStep) {
      return <FunnelClubMembershipForm />;
    }

    if (
      isPaymentStep &&
      currentClubSubscriptionPaymentIntent &&
      subscriptionId
    ) {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: currentClubSubscriptionPaymentIntent,
          }}
        >
          <FunnelClubPayment
            club={club?.data as Club}
            subscriptionId={subscriptionId}
          />
        </Elements>
      );
    }

    if (isSuccessStep) {
      return (
        <FunnelClubSuccess
          club={club?.data as Club}
          clubMember={currentClubMember as ClubMember}
        />
      );
    }

    return <FunnelClubDescription club={club?.data as Club} />;
  };

  if (!club || isLoadingClubMembers)
    return (
      <div className="flex justify-center">
        <Icon name="Loading" />
      </div>
    );

  return (
    <div className="m-auto flex max-w-[920px] flex-col gap-5 pb-16">
      {renderStep()}
    </div>
  );
};
