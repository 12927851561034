import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialYoutube(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 22.96 16.07"
      fill={primaryColor}
      className={className}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill={primaryColor}
        fillRule="evenodd"
      >
        <g
          id="Footer-Copy"
          transform="translate(-1179, -101.001)"
          fill="currentColor"
        >
          <g id="Group-15" transform="translate(1107, 98)">
            <path
              d="M83.630341,3.00101925 C87.0554653,3.01176013 90.3431216,3.10738486 91.2918806,3.28755049 C91.9777973,3.41589914 92.6220769,3.80313908 93.000098,4.30117572 C94.3127629,6.36791808 94.4245256,15.6880047 92.7853382,17.3269182 C92.3722542,17.7306131 91.8485031,18.0059593 91.2568178,18.109077 C89.4729352,18.2434592 87.5246957,18.3257001 85.6054741,18.3670387 L84.8397915,18.3814194 C80.1332789,18.4569763 75.7602019,18.2927834 74.6227904,18.0575182 C74.0300094,17.9379455 73.5139283,17.6713752 73.1370028,17.2841353 C72.8444473,16.9912371 72.612156,16.5447593 72.432459,15.926053 C72.0051307,14.4966659 71.449604,5.67242198 73.2224685,4.02692647 C73.6432225,3.63968653 74.1330064,3.39066821 74.6830546,3.28755049 C75.631298,3.10686863 78.9186813,3.01145646 82.3437753,3.00097102 Z M80.7872748,7.37167034 L80.7872748,13.5444725 C82.9787019,12.4211476 85.0276863,11.3910674 86.6833094,10.4728809 C84.6759622,9.39892081 82.9787019,8.40284753 80.7872748,7.37167034 Z"
              id="Youtube"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
