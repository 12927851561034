import React from "react";

import {
  Toggle,
  ToggleProps,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export type SmartToggleProps<T> = SmartFieldCommonProps<T> &
  ToggleProps & {
    mapCheckedValue?: (value: boolean) => any;
    mapValueChecked?: (value: any) => boolean;
  };

export function SmartToggleField<T>(props: SmartToggleProps<T>) {
  const { form, handleFormChange } = useFormContext();

  const { id, label, required, mapCheckedValue, mapValueChecked } = props;

  const value = extractValueFromPath(form, props.name) || false;

  return (
    <div className="flex flex-col">
      <label htmlFor={id}>
        <Typography variant="body3-regular" className="text-dark-1">
          {label} {required && <span className="text-error">*</span>}
        </Typography>
      </label>
      <Toggle
        {...props}
        checked={mapValueChecked ? mapValueChecked(value) : value}
        onChange={(value: boolean) => {
          handleFormChange({
            ...updateObject(
              form,
              props.name,
              mapCheckedValue ? mapCheckedValue(value) : value,
            ),
          });
        }}
      />
    </div>
  );
}
