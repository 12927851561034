import { useClearRefinements } from "react-instantsearch";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Icon, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { useProjectsRefinements } from "./../hooks/use-projects-refinements";

export const ResetFilters = (props: { className?: string }) => {
  const { className } = props;

  const { t } = useTranslation();
  const { refine: clearFilters } = useClearRefinements();
  const { hasRefinements } = useProjectsRefinements();

  if (!hasRefinements) return null;

  return (
    <button
      onClick={clearFilters}
      className={cn("flex items-center justify-center gap-x-2", className)}
    >
      <Icon name="ReloadIcon" primaryColor={themeColors.accent} />
      <Typography variant="body2" className="text-accent">
        {t(`search_projects_page.refinements.reset.cta.label`)}
      </Typography>
    </button>
  );
};
