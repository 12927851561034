import { Comment } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, TudigoResponse } from "../api";

export const commentsEndPoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getComments
   * @summary get global configuration
   * @request GET:/api/comments
   * @secure
   */
  getCommentsByProjectId: (
    projectId: string,
    query?: { [key: string]: unknown },
  ) =>
    request<TudigoResponse<Comment[]>, void>({
      path: `/api/v1/projects/${projectId}/comments`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
    }),

  getCommentsByClubId: (clubId: string, query?: { [key: string]: unknown }) =>
    request<TudigoResponse<Comment[]>, void>({
      path: `/api/v1/clubs/${clubId}/comments`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
    }),

  createComment: (body?: { [key: string]: unknown }) =>
    request<TudigoResponse<Comment>, void>({
      path: "/api/v1/comments",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
    }),
});
