import {
  CountrySelectInput,
  ErrorMessage,
  Label,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export function SmartCountryField<T>(props: SmartFieldCommonProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  const value = extractValueFromPath(form, props.name as string);

  return (
    <div className="flex flex-col gap-1">
      <Label>{props?.label}</Label>
      <CountrySelectInput
        {...props}
        className="w-full justify-normal"
        value={value}
        onChange={(country) => {
          handleFormChange({
            ...updateObject(form, props.name as string, country),
          });
        }}
      />
      <ErrorMessage>
        {error?.parametersErrors?.[props.name as string] || props.errors}
      </ErrorMessage>
    </div>
  );
}
