import { InvestorProfile, User } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const investorProfilesEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getCurrentInvestorProfileByUserId
   * @summary get current investor profile by UserId, UserId can be replaced with 'me'
   * @request GET:/api/v1/users/:userId/investor-profiles/current
   * @secure
   */
  getCurrentInvestorProfileByUserId: (
    userId: User["id"] | "me",
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<InvestorProfile>, void>({
      path: `/api/v1/users/${userId}/investor-profiles/current`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name updateInvestorProfile
   * @summary updateInvestorProfile, id can be replaced with 'me'
   * @request PUT:/api/v1/users/:userId/investor-profiles/current
   * @secure
   */
  updateInvestorProfile: (
    userId: User["id"] | "me",
    body: InvestorProfile,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<InvestorProfile>, void>({
      path: `/api/v1/users/${userId}/investor-profiles/current`,
      method: "PUT",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
