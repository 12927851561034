const StripeIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="30"
    viewBox="0 0 72 30"
    fill="none"
    className={props.className}
  >
    <path
      d="M49.7145 5.88065C49.0376 5.87673 48.3667 6.00646 47.7401 6.26239C47.1135 6.51832 46.5436 6.89543 46.063 7.37207L45.823 6.18922H41.7259V28.3378L46.3887 27.3264V21.9606C47.3162 22.7373 48.4876 23.1622 49.6973 23.1606C53.023 23.1606 56.0745 20.4349 56.0745 14.4006C56.0745 8.88064 52.9887 5.88065 49.7145 5.88065ZM48.6002 18.9606C48.1912 18.9842 47.7821 18.9165 47.4026 18.7623C47.023 18.6081 46.6826 18.3713 46.4059 18.0692V11.0235C46.6837 10.7103 47.028 10.4632 47.4135 10.3C47.7991 10.1369 48.2162 10.062 48.6345 10.0806C50.3488 10.0806 51.5145 12.0178 51.5145 14.5035C51.5145 16.9892 50.3316 18.9606 48.6002 18.9606Z"
      fill="#635BFF"
    />
    <path
      d="M64.6802 5.88064C60.1888 5.88064 57.4631 9.75493 57.4631 14.6406C57.4631 20.4178 60.6859 23.3492 65.2802 23.3492C67.0733 23.3785 68.8439 22.9476 70.423 22.0978V18.2406C68.9889 18.9642 67.4006 19.329 65.7945 19.3035C63.9431 19.3035 62.3659 18.6349 62.1259 16.3892H71.3487C71.3487 16.1321 71.3487 15.1206 71.3487 14.6749C71.503 9.75493 69.1716 5.88064 64.6802 5.88064ZM62.1602 12.8578C62.1602 10.6978 63.4802 9.78922 64.6459 9.78922C65.8116 9.78922 67.0459 10.6978 67.0459 12.8578H62.1602Z"
      fill="#635BFF"
    />
    <path
      d="M29.143 7.47493L28.8516 6.06921H24.8745V22.7149H29.4688V11.4349C29.8837 10.9312 30.4425 10.5661 31.0705 10.3884C31.6984 10.2107 32.3657 10.2289 32.983 10.4406V6.06921C32.2817 5.79447 31.5041 5.78574 30.7968 6.04467C30.0894 6.30361 29.5012 6.81232 29.143 7.47493Z"
      fill="#635BFF"
    />
    <path
      d="M5.93157 11.0749C5.93157 10.3206 6.54871 10.0292 7.52586 10.0292C9.14529 10.0641 10.7327 10.4874 12.1544 11.2635V6.75493C10.6845 6.16058 9.11132 5.86343 7.52586 5.88065C3.73729 5.88065 1.21729 7.90351 1.21729 11.2978C1.21729 16.6121 8.31443 15.7549 8.31443 18.0349C8.31443 18.9264 7.56014 19.2178 6.60014 19.2178C4.78893 19.1119 3.02766 18.5836 1.45728 17.6749V22.2864C3.0758 23.0082 4.82793 23.382 6.60014 23.3835C10.4744 23.3835 13.1487 21.4121 13.1487 17.9664C13.063 12.2064 5.93157 13.2349 5.93157 11.0749Z"
      fill="#635BFF"
    />
    <path
      d="M19.3373 2.12636L14.7944 3.12064V18.4292C14.771 19.0744 14.8794 19.7175 15.113 20.3194C15.3465 20.9212 15.7003 21.4691 16.1528 21.9296C16.6052 22.3901 17.1468 22.7535 17.7445 22.9975C18.3421 23.2416 18.9833 23.3613 19.6287 23.3492C20.7549 23.3996 21.8771 23.1822 22.903 22.7149V18.8578C22.303 19.1149 19.3716 19.9892 19.3716 17.1435V10.2864H22.903V6.27493H19.3716L19.3373 2.12636Z"
      fill="#635BFF"
    />
    <path
      d="M34.7144 4.62922L39.3944 3.65208V-0.0507812L34.7144 0.926361V4.62922Z"
      fill="#635BFF"
    />
    <path
      d="M39.3944 6.18922H34.7144V22.7149H39.3944V6.18922Z"
      fill="#635BFF"
    />
  </svg>
);

export { StripeIcon };
