import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { formatCurrency } from "@tudigo-monorepo/core-tudigo-utils";

const ClubSubscriptionPrice = (props: {
  price?: number;
  asOldPrice?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  return (
    <p
      className={cn(
        "text-primary font-sans font-bold",
        {
          "text-dark-4 text-xs font-semibold line-through": props.asOldPrice,
        },
        props.className,
      )}
    >
      {t(
        "my.account.web.app.clubs.membership_info.subscription.amount_with_currency_VAT",
        {
          amount: formatCurrency(props.price),
        },
      )}
    </p>
  );
};

export { ClubSubscriptionPrice };
