import { Link } from "react-router-dom";

import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";

import { TudigoLogoRebrand } from "../../logo/tudigo-logo-rebrand";
import { HorizontalMenu } from "./horizontal-menu/horizontal-menu";
import { VerticalMenu } from "./vertical-menu/vertical-menu";
import { VerticalMenuTrigger } from "./vertical-menu/vertical-menu-trigger";

export function NavbarRebrand() {
  const { currentPagePaths } = useSpaceBusiness();

  return (
    <header className="text-black-primary z-topbar sticky top-0 w-full border-b bg-white">
      <div className="container mx-auto flex h-[66px] max-w-screen-2xl items-center p-4 lg:h-[72px] lg:px-8 lg:py-3">
        <div className="flex w-full items-center gap-x-8">
          <Link to={currentPagePaths[PageEnum.HOME]}>
            <TudigoLogoRebrand />
          </Link>

          <VerticalMenuTrigger>
            {(props) => <VerticalMenu {...props} />}
          </VerticalMenuTrigger>

          <HorizontalMenu />
        </div>
      </div>
    </header>
  );
}
