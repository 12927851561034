import {
  Chip,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

type ClubMembershipProps = {
  title: string;
  description: string;
  price: string;
  oldPrice: string;
  membershipText: string;
  isIntroductoryPriceAvailable: boolean;
};

export const ClubMembership = ({
  title,
  description,
  price,
  oldPrice,
  membershipText,
  isIntroductoryPriceAvailable,
}: ClubMembershipProps) => {
  return (
    <div className="bg-success-light flex flex-col gap-3 rounded-md border p-5">
      <Title className="" level="h4">
        {title}
      </Title>
      {isIntroductoryPriceAvailable && (
        <div className="flex justify-between border-b pb-3">
          <Typography className="self-center" variant="body3-regular">
            {description}
          </Typography>
          <div className="flex gap-2">
            <Typography className="self-center" variant="body3-semibold">
              {price}
            </Typography>
            <Chip className="bg-success-light" label="Offre limitée" />
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <Typography className="text-dark-4" variant="caption1-medium">
          {membershipText}
        </Typography>
        <Typography
          className={
            isIntroductoryPriceAvailable
              ? "text-dark-4 line-through"
              : "self-center"
          }
          variant={
            isIntroductoryPriceAvailable ? "caption1-medium" : "body3-semibold"
          }
        >
          {oldPrice}
        </Typography>
      </div>
    </div>
  );
};
