import {
  Organization,
  OrganizationMember,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateOrganizationMemberRequestBody {
  organizationId: Organization["id"];
  email: OrganizationMember["email"];
  firstName?: OrganizationMember["firstName"];
  lastName?: OrganizationMember["lastName"];
  role: OrganizationMember["role"];
}

export const organizationMembersEndpoints = (request: Request) => ({
  createOrganizationMember: (
    body: CreateOrganizationMemberRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationMember>, void>({
      path: `/api/v1/organizations/${body.organizationId}/members`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
