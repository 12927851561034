import React, { useRef } from "react";

import { useOutClickBox } from "../shared/hooks/use-out-click-box/use-out-click-box";

type OutClickBoxProps = {
  onOutClick: () => void;
  children: React.ReactNode;
  className?: string;
};
export function OutClickBox(props: OutClickBoxProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { onOutClick, children, className } = props;

  useOutClickBox({
    ref,
    onOutClick,
  });

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}
