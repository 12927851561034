import { Article, Tag } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  useGetWebflowCollectionById,
  useGetWebflowTags,
} from "@tudigo-monorepo/web-tudigo-api-client";

type UseArticlesWithTagsReturn = {
  isLoading: boolean;
  articlesWithTags: Article[];
};

export const useArticlesWithTags = (): UseArticlesWithTagsReturn => {
  const { data: articlesData, isLoading: articlesLoading } =
    useGetWebflowCollectionById({
      pathParams: {
        collectionId: "6654afe35ff9d4fb83d26dd4",
      },
    });

  const { data: tagsData, isLoading: tagsLoading } = useGetWebflowTags();

  if (articlesLoading || tagsLoading) {
    return { isLoading: true, articlesWithTags: [] };
  }

  const articles = (articlesData?.items || []) as Article[];
  const tags = (tagsData?.items || []) as Tag[];

  const articlesWithTags = articles.map((item: Article) => {
    if ("tagThematique" in item.fieldData) {
      const tag = tags.find(
        (tag: Tag) => tag.id === item.fieldData?.tagThematique,
      );

      const tagName = tag?.fieldData?.name ?? "";

      return {
        ...item,
        fieldData: { ...item.fieldData, tagThematique: tagName },
      };
    }

    if ("thematiquesAssociees" in item.fieldData) {
      const tagsNames = item.fieldData.thematiquesAssociees?.map(
        (tagId: string) => {
          const tag = tags.find((tag: Tag) => tag.id === tagId);

          return tag?.fieldData?.name ?? "";
        },
      );

      return {
        ...item,
        fieldData: { ...item.fieldData, thematiquesAssociees: tagsNames },
      };
    }

    return item;
  });

  return { isLoading: false, articlesWithTags };
};
