import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as React from "react";

import { Image } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { getResizedImage } from "@tudigo-monorepo/core-tudigo-utils";

import { ChipCount } from "../chip-count";
import { Icon } from "../icons/icon";
import { Typography } from "../typography";

type ImageUploaderProps = {
  image: Image;
  className?: string;
  label?: string | ReactNode;
  required?: boolean;
  onImageChange: (image: Image) => void;
  allowedExtensions?: string[];
  maxSizeInMB?: number;
  ratio?: string;
  errors?: string[];
};

const fileSizeToMB = (size: number) => size / Math.pow(1024, 2);

const ALLOWED_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];
const MAX_SIZE_IN_MB = 10;

export function ImageUploader(props: ImageUploaderProps) {
  const {
    image,
    className,
    label = null,
    required = false,
    allowedExtensions = ALLOWED_EXTENSIONS,
    maxSizeInMB = MAX_SIZE_IN_MB,
    ratio,
    onImageChange,
    errors,
  } = props;

  const [file, setFile] = useState<File>();
  const [isBadExtension, setIsBadExtension] = useState(false);
  const [hasExceededSizeLimit, setHasExceededSizeLimit] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const reader = useMemo(() => new FileReader(), []);

  useEffect(() => {
    const handleFileRead = () => {
      onImageChange({
        ...image,
        base64: String(reader.result),
        originalFilename: file?.name ? file.name : null,
      });
    };

    reader.addEventListener("load", handleFileRead);

    return () => {
      reader.removeEventListener("load", handleFileRead);
    };
  }, [file, reader, onImageChange, image]);

  const handleIsValidFile = (file: File) => {
    setHasExceededSizeLimit(fileSizeToMB(file.size) > maxSizeInMB);
    setIsBadExtension(
      allowedExtensions.indexOf(file.name.split(".").pop()!) < 0,
    );
  };

  useEffect(() => {
    if (file) {
      if (hasExceededSizeLimit || isBadExtension) return;
      reader.readAsDataURL(file);
    }
  }, [file, reader, hasExceededSizeLimit, isBadExtension]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      handleIsValidFile(file);
      setFile(file);
    }
  };

  const removeFile = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setFile(undefined);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    onImageChange({
      ...image,
      base64: null,
      url: null,
      id: null,
      originalFilename: null,
    });
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (fileInput.current) {
      const file = event.dataTransfer.files[0];
      handleIsValidFile(file);
      setFile(file);
    }
  };

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getFilename = useCallback((): string => {
    if (file?.name) {
      return file?.name;
    }
    if (image.base64 || image.url) {
      return image.originalFilename ? image.originalFilename : "photo.jpg";
    }

    return "";
  }, [file?.name, image.base64, image.originalFilename, image.url]);

  const getSrc = useCallback((): string => {
    if (image.base64) {
      return image.base64;
    }

    return (
      getResizedImage(image, {
        requestedWidth: 32,
        requestedHeight: 32,
        requestedMode: "cropped",
      }).url || ""
    );
  }, [image]);

  return (
    <div
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <label className="flex flex-col gap-y-2.5">
        <div>
          <Typography variant="body3-regular" className="text-dark-1">
            {label} {required && <span className="text-error">*</span>}
          </Typography>

          {errors && errors.length > 0 && (
            <div className="flex flex-col gap-2.5 text-start">
              {errors?.map((error, index) => (
                <Typography
                  key={index}
                  variant="body3-regular"
                  className="text-error"
                >
                  {error}
                </Typography>
              ))}
            </div>
          )}
        </div>
        {!image.url && !image.base64 && (
          <>
            <div className="border-medium-2 flex flex-col items-center justify-center rounded-lg border-2 border-dashed px-2 py-6">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center gap-x-4">
                  <Icon name="Upload" primaryColor={themeColors["dark-2"]} />
                  <Typography
                    variant="caption1-semibold"
                    className="text-dark-1"
                  >
                    Cliquer pour parcourir ou glisser, déposer un fichier
                  </Typography>
                </div>
                <input
                  ref={fileInput}
                  type="file"
                  style={{ left: 100000, width: 0, height: 0, opacity: 0 }}
                  className="absolute overflow-hidden"
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex items-center justify-center gap-x-2">
              {allowedExtensions.map((ext, index) => (
                <ChipCount key={index} label={ext} className="uppercase" />
              ))}
              <ChipCount
                label={`< ${maxSizeInMB}Mo`}
                variant="light"
                size="S"
                className="border-dark-2 border bg-white"
              />
              {ratio && (
                <Typography variant="body3-regular" className="text-dark-3">
                  {ratio && ratio}
                </Typography>
              )}
            </div>
          </>
        )}

        {hasExceededSizeLimit && (
          <Typography variant="caption1-medium" className="text-error">
            Le fichier est trop volumineux (max {maxSizeInMB}Mo)
          </Typography>
        )}

        {isBadExtension && (
          <Typography variant="caption1-medium" className="text-error">
            Le fichier doit correspondre à l&apos;un des formats suivants :{" "}
            {allowedExtensions.toString().replaceAll(",", ", ")}
          </Typography>
        )}

        {(image.base64 || image.url) && (
          <div
            className={cn(
              "flex flex-row items-center rounded-lg bg-white p-4",
              className,
            )}
          >
            <img
              src={getSrc()}
              alt={getFilename()}
              className="h-8 w-8 rounded-full object-cover"
            />
            <Typography
              variant="body3-semibold"
              className="ml-2 truncate leading-none"
            >
              {getFilename()}
            </Typography>
            <div className="ml-4 cursor-pointer" onClick={removeFile}>
              <Icon
                name="Delete"
                primaryColor={themeColors["error"]}
                secondaryColor={themeColors["error"]}
              />
            </div>
          </div>
        )}
      </label>
    </div>
  );
}
