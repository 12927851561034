import React from "react";

import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselRoot,
} from "@tudigo-monorepo/web-tudigo-components";

import { useArticlesWithTags } from "../hooks/use-articles-with-tags";
import { ArticleCard } from "./article-card";

const BlogCarousel = () => {
  const { isLoading, articlesWithTags } = useArticlesWithTags();

  const firstFiveArticles = articlesWithTags.slice(0, 6);

  return (
    <div className="flex w-full flex-col items-center rounded-lg bg-white px-4 py-8 sm:px-[72px]">
      <h2 className="font-brand mb-8 text-xl">Dernières actualités</h2>
      <CarouselRoot className="w-full" opts={{ slidesToScroll: "auto" }}>
        <CarouselContent className="mb-4">
          {!isLoading &&
            firstFiveArticles?.map((article) => (
              <CarouselItem key={article.id} className="md:basis-1/2">
                <ArticleCard article={article} />
              </CarouselItem>
            ))}
        </CarouselContent>
        <div className="mb-4 flex justify-end gap-1">
          <CarouselPrevious />
          <CarouselNext />
        </div>
      </CarouselRoot>
    </div>
  );
};

export { BlogCarousel };
