export function hoursLeftFromNow(date: Date) {
  const MS_PER_HOUR = 1000 * 60 * 60;

  const utc1 = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
  );

  const dateNow = new Date();
  const utc2 = Date.UTC(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
    dateNow.getHours(),
  );

  return Math.abs(Math.floor((utc1 - utc2) / MS_PER_HOUR));
}
