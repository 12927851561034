import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Notification(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.99601C13.9466 1.99345 15.8178 2.74885 17.217 4.10217C18.6162 5.45548 19.4336 7.30039 19.496 9.24601L19.5 9.49601V13.593L20.88 16.749C20.963 16.9392 20.9973 17.147 20.98 17.3538C20.9626 17.5606 20.894 17.7598 20.7804 17.9334C20.6667 18.107 20.5117 18.2497 20.3292 18.3484C20.1467 18.4471 19.9425 18.4989 19.735 18.499L15 18.502C15.001 19.283 14.6974 20.0336 14.1538 20.5943C13.6101 21.155 12.8692 21.4816 12.0885 21.5046C11.3079 21.5277 10.549 21.2454 9.97321 20.7177C9.3974 20.1901 9.05006 19.4587 9.005 18.679L9 18.499H4.275C4.06781 18.4988 3.86392 18.4471 3.68165 18.3486C3.49938 18.25 3.34443 18.1078 3.23072 17.9346C3.11701 17.7614 3.04809 17.5627 3.03016 17.3563C3.01223 17.1499 3.04585 16.9422 3.128 16.752L4.5 13.594V9.49601C4.5 5.34101 7.852 1.99601 12 1.99601ZM13.5 18.5L10.5 18.502C10.4999 18.8873 10.6481 19.2579 10.9139 19.5369C11.1796 19.8158 11.5426 19.9819 11.9274 20.0005C12.3123 20.0192 12.6895 19.889 12.981 19.637C13.2725 19.385 13.4558 19.0305 13.493 18.647L13.499 18.5H13.5ZM12 3.49601C8.68 3.49601 6 6.17001 6 9.49601V13.906L4.656 17H19.353L18 13.907V9.50901L17.996 9.28401C17.9441 7.72943 17.2892 6.25606 16.1701 5.17578C15.051 4.09549 13.5554 3.49306 12 3.49601Z"
        fill={primaryColor}
      />
    </svg>
  );
}
