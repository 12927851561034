import {
  WebFlowCollection,
  WebFlowCollectionItem,
} from "@tudigo-monorepo/core-tudigo-api-models";

export const formatNavbarCardWebflowItem = (
  webflowItems?: WebFlowCollection,
) => {
  const items = (webflowItems ?? []) as WebFlowCollectionItem[];

  const selectedItem = items.find(
    (item) => item.fieldData?.afficherNavbar === true,
  );

  const coverImage =
    selectedItem?.fieldData?.coverImage ||
    selectedItem?.fieldData?.imageDeCover;

  const name = selectedItem?.fieldData?.name ?? "";

  const description =
    (selectedItem?.fieldData?.aProposDeLEntreprise ||
      selectedItem?.fieldData?.descriptionOpenGraph) ??
    "";

  const slug = selectedItem?.fieldData?.slug ?? "";

  const formattedItem = {
    coverUrl: coverImage?.url,
    name,
    description,
    slug,
  };

  return formattedItem;
};
