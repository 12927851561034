import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Upload(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3H13.2455C16.1805 3 17.6481 3 18.6672 3.71805C18.9592 3.92379 19.2185 4.16777 19.4371 4.4426C20.2 5.4018 20.2 6.78302 20.2 9.54545V11.8364C20.2 14.5032 20.2 15.8366 19.778 16.9016C19.0995 18.6137 17.6646 19.9642 15.8455 20.6028C14.7139 21 13.2972 21 10.4636 21C8.84447 21 8.03489 21 7.3883 20.773C6.34881 20.4081 5.52888 19.6364 5.14117 18.6581C4.9 18.0495 4.9 17.2876 4.9 15.7636V12"
        stroke={primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2 12C20.2 13.6569 18.8569 15 17.2 15C16.6008 15 15.8944 14.895 15.3118 15.0511C14.7941 15.1898 14.3898 15.5941 14.2511 16.1118C14.095 16.6944 14.2 17.4008 14.2 18C14.2 19.6569 12.8569 21 11.2 21"
        stroke={primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2 6.6L4 6.6M7.6 3V10.2"
        stroke={primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
