import { Investment } from "../investments";
import { User } from "../users";

export enum WMACustomerStatusEnum {
  INVITED = "invited",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELED = "canceled",
}

export enum WMAProfileStatusEnum {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export interface WMACustomer {
  id?: string;
  wmaUser?: User;
  customerUser?: User;
  status?: WMACustomerStatusEnum;
}

export interface WMAInvestment {
  id?: string;
  wmaCustomer?: WMACustomer;
  investment?: Investment;
}
