import {
  InputDate,
  InputDateProps,
  SimpleInputDateProps,
} from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

interface SmartDateFieldProps<T>
  extends SmartFieldCommonProps<T>,
    Pick<InputDateProps, "popperPlacement" | "showTimeSelect">,
    Pick<SimpleInputDateProps, "minDate" | "maxDate"> {}

export function SmartDateField<T>(props: SmartDateFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  const value = extractValueFromPath(form, props.name as string);

  return (
    <InputDate
      {...props}
      range={false}
      value={value ? new Date(value) : undefined}
      errors={error?.parametersErrors?.[props.name as string] || props.errors}
      onChange={(date?: Date) => {
        handleFormChange({
          ...updateObject(form, props.name as string, date),
        });
      }}
    />
  );
}
