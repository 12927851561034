import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const alertVariants = cva(
  "relative w-full rounded-md border p-6 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-6 [&>svg]:top-6 [&>svg]:text-foreground font-roobert",
  {
    variants: {
      variant: {
        default:
          "bg-background text-foreground border-black-600 [&>svg]:text-black-primary",
        destructive:
          "bg-error-light text-black-900 border-error-medium [&>svg]:text-error",
        success:
          "bg-success-light text-black-900 border-success-medium [&>svg]:text-success",
        accent:
          "bg-accent-super-light text-black-900 border-accent-light [&>svg]:text-accent",
        warning:
          "bg-warning-super-light text-black-900 border-warning [&>svg]:text-warning",
        waiting:
          "bg-waiting-super-light text-black-900 border-waiting [&>svg]:text-waiting",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const AlertRebrand = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));

const AlertRebrandTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "mb-2 text-balance pl-7 font-medium leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));

const AlertRebrandDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-xs [&_p]:leading-relaxed", className)}
    {...props}
  />
));

export { AlertRebrand, AlertRebrandTitle, AlertRebrandDescription };
