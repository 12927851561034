import type { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type PresentationCardProps = {
  title: string;
  subtitle?: string;
  listComponent?: ReactNode;
  buttonComponent?: ReactNode;
  footerComponent?: ReactNode;
  coverImageUrl?: string | { mobile: string; desktop: string };
  className?: string;
  clipPath?: boolean;
};

const PresentationCard = ({
  title,
  subtitle,
  listComponent,
  buttonComponent,
  footerComponent,
  coverImageUrl,
  clipPath = false,
  className,
}: PresentationCardProps) => {
  return (
    <article
      className={cn(
        "container relative mx-auto",
        "flex w-full flex-col",
        "md:flex-row md:justify-between md:gap-x-6",
        "rounded-lg border bg-white",
        "overflow-hidden",
        className,
      )}
    >
      <div className="flex w-full flex-col gap-y-6 py-4 pl-6 md:w-1/2">
        <h2 className="font-brand text-primary text-lg font-medium">{title}</h2>
        {!!subtitle && <p className="text-primary font-sans">{subtitle}</p>}
        {listComponent}
        {buttonComponent}
        {footerComponent}
      </div>

      <div
        role="img"
        className="block h-[130px] rounded-b-lg bg-cover bg-center bg-no-repeat md:hidden"
        style={{
          backgroundImage: `url(${
            typeof coverImageUrl === "object"
              ? coverImageUrl.mobile
              : coverImageUrl
          })`,
        }}
      />

      <img
        alt="Cover"
        className={cn(
          "ml-auto hidden h-full w-full object-cover md:flex md:w-1/2",
          {
            "absolute inset-0 [clip-path:polygon(07%_0%,100%_0%,100%_100%,0%_100%)]":
              clipPath,
          },
        )}
        src={
          typeof coverImageUrl === "object"
            ? coverImageUrl.desktop
            : coverImageUrl
        }
      />
    </article>
  );
};

export { PresentationCard };
