import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Loading(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={`${className} spinner`}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_466_52546)">
        <path
          d="M12 0C12.7125 0 13.2891 0.576563 13.2891 1.28906V4.85156C13.2891 5.56406 12.7125 6.14062 12 6.14062C11.2875 6.14062 10.7109 5.56406 10.7109 4.85156V1.28906C10.7109 0.576563 11.2875 0 12 0Z"
          fill={primaryColor}
        />
        <path
          d="M3.51567 3.51555C4.01724 3.01398 4.83755 3.01398 5.33911 3.51555L7.8563 6.03273C8.35786 6.5343 8.35786 7.35461 7.8563 7.85617C7.35474 8.35773 6.53442 8.35773 6.03286 7.85617L3.51567 5.33898C3.00942 4.83273 3.00942 4.01711 3.51567 3.51555Z"
          fill={primaryColor}
        />
        <path
          d="M0 12C0 11.2875 0.576563 10.7109 1.28906 10.7109H4.85156C5.56406 10.7109 6.14062 11.2875 6.14062 12C6.14062 12.7125 5.56406 13.2891 4.85156 13.2891H1.28906C0.576563 13.2891 0 12.7125 0 12Z"
          fill={primaryColor}
        />
        <path
          d="M3.51558 20.4843C3.01401 19.9827 3.01401 19.1624 3.51558 18.6608L6.03276 16.1436C6.53433 15.6421 7.35464 15.6421 7.8562 16.1436C8.35776 16.6452 8.35776 17.4655 7.8562 17.9671L5.33901 20.4843C4.83276 20.9905 4.01714 20.9905 3.51558 20.4843Z"
          fill={primaryColor}
        />
        <path
          d="M12 24C11.2875 24 10.7109 23.4234 10.7109 22.7109V19.1484C10.7109 18.4359 11.2875 17.8594 12 17.8594C12.7125 17.8594 13.2891 18.4359 13.2891 19.1484V22.7109C13.2891 23.4234 12.7125 24 12 24Z"
          fill={primaryColor}
        />
        <path
          d="M20.4844 20.4843C19.9828 20.9858 19.1625 20.9858 18.6609 20.4843L16.1437 17.9671C15.6422 17.4655 15.6422 16.6452 16.1437 16.1436C16.6453 15.6421 17.4656 15.6421 17.9672 16.1436L20.4844 18.6608C20.9906 19.1671 20.9906 19.9827 20.4844 20.4843Z"
          fill={primaryColor}
        />
        <path
          d="M24 12C24 12.7125 23.4234 13.2891 22.7109 13.2891H19.1484C18.4359 13.2891 17.8594 12.7125 17.8594 12C17.8594 11.2875 18.4359 10.7109 19.1484 10.7109H22.7109C23.4234 10.7109 24 11.2875 24 12Z"
          fill={primaryColor}
        />
        <path
          d="M20.4844 3.51558C20.9859 4.01714 20.9859 4.83746 20.4844 5.33902L17.9672 7.85621C17.4656 8.35777 16.6453 8.35777 16.1437 7.85621C15.6422 7.35464 15.6422 6.53433 16.1437 6.03277L18.6609 3.51558C19.1672 3.00933 19.9828 3.00933 20.4844 3.51558Z"
          fill={primaryColor}
        />
        <path
          d="M12 0C12.7125 0 13.2891 0.576563 13.2891 1.28906V4.85156C13.2891 5.56406 12.7125 6.14062 12 6.14062C11.2875 6.14062 10.7109 5.56406 10.7109 4.85156V1.28906C10.7109 0.576563 11.2875 0 12 0Z"
          fill={primaryColor}
        />
        <path
          d="M3.51567 3.51555C4.01724 3.01398 4.83755 3.01398 5.33911 3.51555L7.8563 6.03273C8.35786 6.5343 8.35786 7.35461 7.8563 7.85617C7.35474 8.35773 6.53442 8.35773 6.03286 7.85617L3.51567 5.33898C3.00942 4.83273 3.00942 4.01711 3.51567 3.51555Z"
          fill={primaryColor}
        />
        <path
          d="M0 12C0 11.2875 0.576563 10.7109 1.28906 10.7109H4.85156C5.56406 10.7109 6.14062 11.2875 6.14062 12C6.14062 12.7125 5.56406 13.2891 4.85156 13.2891H1.28906C0.576563 13.2891 0 12.7125 0 12Z"
          fill={primaryColor}
        />
        <path
          d="M3.51558 20.4843C3.01401 19.9827 3.01401 19.1624 3.51558 18.6608L6.03276 16.1436C6.53433 15.6421 7.35464 15.6421 7.8562 16.1436C8.35776 16.6452 8.35776 17.4655 7.8562 17.9671L5.33901 20.4843C4.83276 20.9905 4.01714 20.9905 3.51558 20.4843Z"
          fill={primaryColor}
        />
        <path
          d="M12 24C11.2875 24 10.7109 23.4234 10.7109 22.7109V19.1484C10.7109 18.4359 11.2875 17.8594 12 17.8594C12.7125 17.8594 13.2891 18.4359 13.2891 19.1484V22.7109C13.2891 23.4234 12.7125 24 12 24Z"
          fill={primaryColor}
        />
        <path
          d="M20.4844 20.4843C19.9828 20.9858 19.1625 20.9858 18.6609 20.4843L16.1437 17.9671C15.6422 17.4655 15.6422 16.6452 16.1437 16.1436C16.6453 15.6421 17.4656 15.6421 17.9672 16.1436L20.4844 18.6608C20.9906 19.1671 20.9906 19.9827 20.4844 20.4843Z"
          fill={primaryColor}
        />
        <path
          d="M24 12C24 12.7125 23.4234 13.2891 22.7109 13.2891H19.1484C18.4359 13.2891 17.8594 12.7125 17.8594 12C17.8594 11.2875 18.4359 10.7109 19.1484 10.7109H22.7109C23.4234 10.7109 24 11.2875 24 12Z"
          fill={primaryColor}
        />
        <path
          d="M20.4844 3.51558C20.9859 4.01714 20.9859 4.83746 20.4844 5.33902L17.9672 7.85621C17.4656 8.35777 16.6453 8.35777 16.1437 7.85621C15.6422 7.35464 15.6422 6.53433 16.1437 6.03277L18.6609 3.51558C19.1672 3.00933 19.9828 3.00933 20.4844 3.51558Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_466_52546">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
