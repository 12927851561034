import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

export const NavbarMenuTestimonials = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/interviews-investisseurs"
          onClick={props.onLinkClick}
        >
          Interviews Investisseurs
          <NavbarMenuLinkLegend>
            Impact, rendement, diversification : leur expérience
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/interviews-entreprises"
          onClick={props.onLinkClick}
        >
          Interviews Entreprises
          <NavbarMenuLinkLegend>
            Expertise, accompagnement et efficacité : ils témoignent
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
