import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Info(props: IconElementsProps) {
  const { primaryColor = "#666879", width, height, size = "S" } = props;

  return (
    <svg
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.5C12.4142 10.5 12.75 10.8358 12.75 11.25V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V11.25C11.25 10.8358 11.5858 10.5 12 10.5ZM12 9.3736C12.6205 9.3736 13.1236 8.87057 13.1236 8.25005C13.1236 7.62953 12.6205 7.12649 12 7.12649C11.3795 7.12649 10.8765 7.62953 10.8765 8.25005C10.8765 8.87057 11.3795 9.3736 12 9.3736ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
