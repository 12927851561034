import {
  InvestmentDocument,
  User,
  WMACustomer,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export type SendInvestmentDocumentSignatureLinkRequest = {
  investmentDocumentId: InvestmentDocument["id"];
  userId: User["id"] | "me";
};

export const investmentDocumentsEndpoints = (request: Request) => ({
  sendInvestmentDocumentSignatureLink: (
    investmentDocumentId: InvestmentDocument["id"],
    userId: User["id"] | "me",
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMACustomer>, void>({
      path: `/api/v1/investment-documents/${investmentDocumentId}/users/${userId}/signature-link/send`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
