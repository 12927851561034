import { Consent, Contract } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateConsentRequestBody {
  contract: {
    code: Contract["code"];
    version: Contract["version"];
  };
}
export const consentsEndpoints = (request: Request) => ({
  createConsent: (body: CreateConsentRequestBody, params: RequestParams = {}) =>
    request<TudigoResponse<Consent>, void>({
      path: `/api/v1/consents`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
