import {
  CMSBlock,
  CMSBlockData,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  extractValueFromPath,
  InputSelectDescription,
  updateObject,
} from "@tudigo-monorepo/web-feature-form";
import { InputSelect } from "@tudigo-monorepo/web-tudigo-components";

type EditorPluginInputProps = {
  input: InputSelectDescription<CMSBlockData>;
  path?: string;
  blockContent: CMSBlock;
  setBlockContent: (blockContent: CMSBlock) => void;
};

export const EditorSelectInput = (props: EditorPluginInputProps) => {
  const { input, path, blockContent, setBlockContent } = props;

  return (
    <InputSelect
      items={input.options}
      getValueFromItem={(item: any) => item.value}
      labelFunction={(item) => item.label}
      comparisonFunction={(item, value) => item.value === value}
      label={input.label}
      required={input.required}
      selectedValue={extractValueFromPath(blockContent, path + input.name)}
      multiple={false}
      onChange={(value) => {
        setBlockContent({
          ...updateObject(blockContent, path + input.name, value),
        });
      }}
    />
  );
};
