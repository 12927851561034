import { ReactNode, useEffect, useId, useState } from "react";
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  Table as TanstackTable,
  useReactTable,
} from "@tanstack/react-table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { mockArray } from "@tudigo-monorepo/core-tudigo-utils";
import { CollectionRequestParams } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Action,
  Button,
  ButtonProps,
  Pagination,
} from "@tudigo-monorepo/web-tudigo-components";

export interface TudigoCollectionProps<T, P, F> {
  columns: ColumnDef<T, any>[];
  useQueryFn: (
    params: CollectionRequestParams<P, F> & { pathParams?: P },
    options?: any,
  ) => UseQueryResult<TudigoResponse<T[]>, TudigoError>;
  actions?: Action<T>[];
  queryOption?: Omit<
    UseQueryOptions<TudigoResponse<T[]>, TudigoError>,
    "queryKey"
  >;
  useLocationAsState?: boolean;
  pagination?: {
    pageIndex: number;
    pageSize: number;
  };
  hidePagination?: boolean;
  showMore?: ButtonProps;
  pathParams?: P;
  fields?: string;
  filters?: F;
  renderEmpty?: () => ReactNode;
  render: (
    table: TanstackTable<T>,
    collectionProps: TudigoCollectionProps<T, P, F>,
    queryResponse?: TudigoResponse<T[]> | undefined,
  ) => ReactNode;
  onQuerySuccess?: (response: TudigoResponse<T[]> | undefined) => void;
}

export function TudigoCollection<T, P, F>(
  props: TudigoCollectionProps<T, P, F>,
) {
  const {
    queryOption,
    hidePagination = false,
    pathParams,
    columns,
    fields,
    useQueryFn,
    useLocationAsState = false,
    pagination: defaultPagination,
    filters: filtersFromProps,
    showMore,
    onQuerySuccess,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const enable = queryOption?.enabled !== false;

  const searchParamsPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : null;
  const searchParamsPageSize = searchParams.get("pageSize")
    ? parseInt(searchParams.get("pageSize") as string)
    : null;

  const [filters, setFilters] = useState<F | undefined>(filtersFromProps);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex:
      (useLocationAsState && searchParamsPage
        ? searchParamsPage
        : defaultPagination?.pageIndex) || 0,
    pageSize:
      (useLocationAsState && searchParamsPageSize
        ? searchParamsPageSize
        : defaultPagination?.pageSize) || 20,
  });

  const {
    data: response,
    isFetching, // works also with refetch unlike isLoading (https://github.com/TanStack/query/issues/2559)
    refetch,
  } = useQueryFn(
    {
      pagination: {
        offset: pagination.pageIndex * pagination.pageSize,
        limit: pagination.pageSize,
      },
      filters: filters as F,
      fields,
      pathParams: pathParams as P,
    },
    queryOption,
  );

  useEffect(() => {
    onQuerySuccess?.(response);
  }, [response, onQuerySuccess]);

  useEffect(() => {
    if (
      !defaultPagination ||
      defaultPagination?.pageSize === pagination.pageSize
    ) {
      return;
    }

    setPagination({
      pageIndex: defaultPagination.pageIndex,
      pageSize: defaultPagination.pageSize,
    });
  }, [
    refetch,
    defaultPagination,
    defaultPagination?.pageSize,
    pagination.pageSize,
  ]);

  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(filtersFromProps)) return;
    setFilters(filtersFromProps);
  }, [filters, filtersFromProps]);

  useEffect(() => {
    if (useLocationAsState) {
      searchParams.set("page", pagination.pageIndex.toString());
      searchParams.set("pageSize", pagination.pageSize.toString());
      navigate({ search: searchParams.toString() });
    }
  }, [
    navigate,
    pagination.pageIndex,
    pagination.pageSize,
    searchParams,
    useLocationAsState,
  ]);

  useEffect(() => {
    if (
      useLocationAsState &&
      searchParamsPage !== null &&
      searchParamsPageSize != null
    ) {
      if (!enable) return;

      refetch();
    }
  }, [
    location.search,
    enable,
    refetch,
    searchParamsPage,
    searchParamsPageSize,
    useLocationAsState,
  ]);

  useEffect(() => {
    if (!useLocationAsState) {
      if (!enable) {
        return;
      }
      refetch();
    }
  }, [pagination, useLocationAsState, refetch, enable]);

  // useEffect(() => {
  //   if (pathParams) {
  //     if (!enable) {
  //       return;
  //     }
  //     refetch();
  //   }
  // }, [pathParams, enable, refetch]);

  const tableCount = response?.pagination.count || 0;
  const hasMoreDataAvailable = pagination.pageSize < tableCount;

  const table = useReactTable<T>({
    data: response?.data || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: Math.ceil(tableCount / pagination.pageSize),
    state: { pagination },
    onPaginationChange: setPagination,
  });

  return (
    <div className="flex flex-col gap-y-6">
      {response?.pagination?.count === 0 && response?.data.length === 0
        ? props.renderEmpty?.()
        : props.render(table, props, response)}

      {!hidePagination && hasMoreDataAvailable && (
        <Pagination table={table} className="mx-auto" />
      )}

      {showMore && hasMoreDataAvailable && (
        <Button
          {...showMore}
          onClick={showMore.onClick}
          label={isFetching ? "Chargement" : showMore.label ?? "Afficher plus"}
          variant={showMore.variant ?? "tertiary"}
          className={showMore.className ?? "mx-auto w-fit"}
          isLoading={isFetching}
          disabled={isFetching}
        />
      )}
    </div>
  );
}

export const useMockCollectionQueryFn = (): UseQueryResult<
  TudigoResponse<[]>,
  TudigoError
> => {
  const randId = useId();

  return useQuery({
    refetchOnMount: false,
    queryKey: [randId],
    queryFn: () => ({
      data: mockArray(5),
      status: 200,
      resourceName: randId,
      pagination: {
        count: 5,
      },
    }),
  });
};

export default TudigoCollection;
