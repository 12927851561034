import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function WalletIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill={primaryColor}
        d="M16.25 13.9375C16.0511 13.9375 15.8603 14.0165 15.7197 14.1572C15.579 14.2978 15.5 14.4886 15.5 14.6875C15.5 14.8864 15.579 15.0772 15.7197 15.2178C15.8603 15.3585 16.0511 15.4375 16.25 15.4375H18.25C18.4489 15.4375 18.6397 15.3585 18.7803 15.2178C18.921 15.0772 19 14.8864 19 14.6875C19 14.4886 18.921 14.2978 18.7803 14.1572C18.6397 14.0165 18.4489 13.9375 18.25 13.9375H16.25ZM19.25 6.6875C19.25 5.95815 18.9603 5.25868 18.4445 4.74296C17.9288 4.22723 17.2293 3.9375 16.5 3.9375H5.25C4.94116 3.93755 4.63564 4.00118 4.35246 4.12443C4.06928 4.24768 3.81451 4.42791 3.604 4.65389C3.3935 4.87988 3.23177 5.14678 3.12889 5.43797C3.02601 5.72917 2.98418 6.03843 3.006 6.3465C3.00217 6.37669 3.00017 6.40707 3 6.4375V18.6875C3 19.5495 3.34241 20.3761 3.9519 20.9856C4.5614 21.5951 5.38805 21.9375 6.25 21.9375H18.75C19.4793 21.9375 20.1788 21.6478 20.6945 21.132C21.2103 20.6163 21.5 19.9168 21.5 19.1875V9.6875C21.5002 9.04475 21.2752 8.42223 20.8642 7.92808C20.4532 7.43393 19.882 7.09936 19.25 6.9825V6.6875ZM4.5 6.1875C4.5 5.98859 4.57902 5.79782 4.71967 5.65717C4.86032 5.51652 5.05109 5.4375 5.25 5.4375H16.5C17.19 5.4375 17.75 5.9975 17.75 6.6875V6.9375H5.25C5.05109 6.9375 4.86032 6.85848 4.71967 6.71783C4.57902 6.57718 4.5 6.38641 4.5 6.1875ZM5.25 8.4375H18.75C19.44 8.4375 20 8.9975 20 9.6875V19.1875C20 19.8775 19.44 20.4375 18.75 20.4375H6.25C5.78587 20.4375 5.34075 20.2531 5.01256 19.9249C4.68437 19.5967 4.5 19.1516 4.5 18.6875V8.3095C4.735 8.3925 4.987 8.4375 5.25 8.4375Z"
      />
    </svg>
  );
}
