import { Shareholder } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const organizationShareholdersEndpoints = (request: Request) => ({
  getShareholders: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Shareholder[]>, void>({
      path: `/api/v1/shareholders`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getShareholderById: (
    shareholderId: Shareholder["id"],
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Shareholder>, void>({
      path: `/api/v1/shareholders/${shareholderId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
