import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Logout(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    secondaryColor = "#666879",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_439_18809)">
        <path
          d="M16.5 17.46C16.19 21.06 14.34 22.53 10.29 22.53L10.16 22.53C5.69 22.53 3.9 20.74 3.9 16.27L3.9 9.75003C3.9 5.28003 5.69 3.49003 10.16 3.49003L10.29 3.49003C14.31 3.49003 16.16 4.94003 16.49 8.48003"
          stroke={primaryColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity="0.4">
          <path
            d="M9 13.0001H21.88"
            stroke={secondaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.6504 9.65045L23.0004 13.0005L19.6504 16.3505"
            stroke={secondaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_439_18809">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
            transform="translate(0 0.000305176)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
