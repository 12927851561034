import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";
import { IconSizes } from "../icons/icon-sizes.type";
import { Typography, TypographyVariant } from "../typography";

export type ChipSize = "M" | "S";

export type ChipType =
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "warning"
  | "waiting"
  | "desactivate"
  | "info"
  | "outlined"
  | "outlined-dark";

export type ChipProps = {
  label: string | number;
  className?: string;
  iconLeft?: IconName;
  iconRight?: IconName;
  iconSize?: IconSizes;
  size?: ChipSize;
  type?: ChipType;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const classNames: Record<ChipType, string> = {
  primary: "bg-white",
  secondary: "bg-white",
  success: "bg-success-light",
  error: "bg-error-light",
  warning: "bg-warning-super-light",
  waiting: "bg-waiting-super-light",
  desactivate: "bg-light-2",
  info: "bg-light-2",
  outlined: "bg-transparent",
  "outlined-dark": "bg-transparent",
};

const iconColors: Record<ChipType, string> = {
  primary: themeColors["dark-2"],
  secondary: themeColors["dark-4"],
  success: themeColors["success"],
  error: themeColors["error"],
  warning: themeColors["warning"],
  waiting: themeColors["waiting"],
  desactivate: themeColors["dark-3"],
  info: themeColors["primary"],
  outlined: themeColors.white,
  "outlined-dark": themeColors["black-primary"],
};

const typographyVariants: Record<ChipType, TypographyVariant> = {
  primary: "body3-medium",
  secondary: "caption1-medium",
  success: "body3-semibold",
  error: "body3-semibold",
  warning: "body3-semibold",
  waiting: "body3-semibold",
  desactivate: "body3-semibold",
  info: "body3-semibold",
  outlined: "body3-medium",
  "outlined-dark": "body3-medium",
};

const typographyColors: Record<ChipType, string> = {
  primary: "text-dark-2",
  secondary: "text-dark-4",
  success: "text-success",
  error: "text-error",
  warning: "text-warning",
  waiting: "text-waiting",
  desactivate: "text-dark-3",
  info: "text-primary",
  outlined: "text-white",
  "outlined-dark": "text-dark",
};

export function Chip(props: ChipProps) {
  const {
    label,
    className,
    iconLeft,
    iconRight,
    iconSize,
    size = "M",
    type = "primary",
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const border =
    type === "primary"
      ? "border border-dark-2"
      : type === "secondary"
        ? "border border-dark-4"
        : type === "outlined" || type === "outlined-dark"
          ? "border border-white"
          : "border-none";

  const radius =
    type === "primary" || type === "secondary" || type === "outlined"
      ? "rounded-lg"
      : "rounded";

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        "chip w-fit px-2",
        "flex items-center justify-center gap-1.5 whitespace-nowrap",
        {
          "cursor-pointer": onClick,
          "py-0.5": type === "primary" && size === "M",
          "py-[5.5px]": type === "secondary" && size === "M",
          "py-[3px]":
            [
              "success",
              "error",
              "warning",
              "waiting",
              "desactivate",
              "info",
            ].includes(type) && size === "M",
          "py-[2.5px]": type === "secondary" && size === "S",
        },
        radius,
        border,
        classNames[type],
        className,
      )}
    >
      {iconLeft ? (
        <Icon name={iconLeft} primaryColor={iconColors[type]} size={iconSize} />
      ) : null}

      {!!label && (
        <Typography
          variant={typographyVariants[type]}
          className={cn(`${typographyColors[type]}`, {
            "leading-[20px]": type === "primary" && size === "S",
          })}
        >
          {label}
        </Typography>
      )}

      {iconRight ? (
        <Icon
          name={iconRight}
          primaryColor={iconColors[type]}
          size={iconSize}
        />
      ) : null}
    </div>
  );
}
