import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useEditorContext } from "../store/editor-context";

export const EditorTools = () => {
  const {
    plugins,
    settings: { editMode },
  } = useEditorContext();

  if (!editMode) return null;

  return (
    <Droppable isDropDisabled droppableId="editor-tools" direction="vertical">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="editor-tools h-full w-[120px] overflow-y-auto bg-white py-2.5 pl-6"
        >
          {plugins.map((plugin, index) => {
            return (
              <Draggable
                draggableId={`plugin-${plugin.name}`}
                index={index}
                key={index}
              >
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={plugin.name}
                      className={cn(
                        {
                          "!transform-none": !snapshot.isDragging,
                        },
                        "h-[45px] w-[80px]",
                        "flex items-center justify-center rounded-lg",
                        "border-light-1  mb-[10px] flex cursor-grab select-none gap-x-2.5 border transition-colors",
                        "hover:border-accent-medium active:bg-light-1",
                      )}
                    >
                      <Icon name="Drag" primaryColor={themeColors["dark-2"]} />
                      <Icon
                        name={plugin.icon}
                        primaryColor={themeColors["dark-1"]}
                      />
                    </div>
                    {/*Clone dragging element */}
                    {snapshot.isDragging && (
                      <div
                        key={`clone-${plugin.name}`}
                        className="clone border-light-1 hover:border-accent-medium active:bg-light-1 mb-[10px] flex h-[45px] w-[80px] cursor-grab select-none items-center gap-x-2.5 rounded-lg border px-4 py-3 transition-colors"
                      >
                        <Icon
                          name="Drag"
                          primaryColor={themeColors["dark-2"]}
                        />
                        <Icon
                          name={plugin.icon}
                          primaryColor={themeColors["dark-1"]}
                        />
                      </div>
                    )}
                  </>
                )}
              </Draggable>
            );
          })}
          <div className="hidden">{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};
