import { forwardRef, ReactNode } from "react";
import Anchor from "@restart/ui/Anchor";
import {
  DropdownItemProps as BaseDropdownItemProps,
  useDropdownItem,
} from "@restart/ui/DropdownItem";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const DropdownItem: (props: BaseDropdownItemProps) => ReactNode = forwardRef(
  (
    {
      className,
      eventKey,
      disabled = false,
      onClick,
      active,
      as: Component = Anchor,
      ...props
    },
    ref,
  ) => {
    const [dropdownItemProps, meta] = useDropdownItem({
      key: eventKey,
      href: props.href,
      disabled,
      onClick,
      active,
    });

    return (
      <Component
        {...props}
        {...dropdownItemProps}
        ref={ref}
        className={cn(
          "hover:border-accent-medium flex min-h-[38px] w-full cursor-pointer items-center rounded-lg border border-transparent bg-white px-4 transition-all",
          className,
          {
            "bg-accent-super-light border-accent-medium": meta.isActive,
          },
          meta.isActive && "active",
          disabled && "disabled",
        )}
      />
    );
  },
);

export default DropdownItem;
