import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Heading(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.97531 6.01754C8.97531 5.74767 8.87125 5.48886 8.68603 5.29803C8.50081 5.10721 8.2496 5 7.98765 5C7.72571 5 7.4745 5.10721 7.28928 5.29803C7.10406 5.48886 7 5.74767 7 6.01754V18.2281C7 18.4979 7.10406 18.7568 7.28928 18.9476C7.4745 19.1384 7.72571 19.2456 7.98765 19.2456C8.2496 19.2456 8.50081 19.1384 8.68603 18.9476C8.87125 18.7568 8.97531 18.4979 8.97531 18.2281V13.1404H14.9012V18.2281C14.9012 18.4979 15.0053 18.7568 15.1905 18.9476C15.3757 19.1384 15.6269 19.2456 15.8889 19.2456C16.1508 19.2456 16.402 19.1384 16.5873 18.9476C16.7725 18.7568 16.8765 18.4979 16.8765 18.2281V6.01754C16.8765 5.74767 16.7725 5.48886 16.5873 5.29803C16.402 5.10721 16.1508 5 15.8889 5C15.6269 5 15.3757 5.10721 15.1905 5.29803C15.0053 5.48886 14.9012 5.74767 14.9012 6.01754V11.1053H8.97531V6.01754Z"
        fill={primaryColor}
      />
    </svg>
  );
}
