import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";

import { LoginOrSignupRebrand } from "../login-or-signup-rebrand";
import { UserSpaceLink } from "../user-space-link";

export const NavbarUserBlock = () => {
  const { user } = useUserBusiness();

  return user ? (
    <UserSpaceLink className="w-full lg:ml-auto lg:w-fit" />
  ) : (
    <LoginOrSignupRebrand className="md:justify-end" />
  );
};
