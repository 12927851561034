import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export const buttonCommonStyle = cn(
  "py-[10px]",
  "flex-shrink-0 rounded-full border",
  "font-montserrat font-sm text-[14px] font-medium",
  "transition-colors",
  "hover:border",
  "disabled:bg-light-2 disabled:text-dark-4 disabled:cursor-not-allowed disabled:border-none",
);
