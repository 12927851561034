import { ReactNode, useState } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Button } from "../button";
import { Chip, ChipSize, ChipType } from "../chip";
import { Icon } from "../icons/icon";
import { Typography } from "../typography";

type ExpansionPanelProps = {
  title: string;
  subTitle?: string;
  chip?: {
    label: string;
    type?: ChipType;
    size?: ChipSize;
  };
  canBeDeleted?: boolean;
  deleteAction?: () => void;
  expendedValue: boolean;
  setExpendedValue?: (value: boolean) => void;
  children: ReactNode;
  dragHandle?: ReactNode | null;
  className?: string;
  textsContainerClassName?: string;
  headerClassName?: string;
  expandedPanelClassName?: string;
};

export const ExpansionPanel = (props: ExpansionPanelProps) => {
  const {
    title,
    subTitle,
    chip,
    children,
    canBeDeleted,
    deleteAction,
    dragHandle,
    className,
    textsContainerClassName,
    headerClassName,
    expandedPanelClassName,
  } = props;
  let { setExpendedValue } = props;
  const [localExpendedValue, setLocaleExpendedValue] = useState<boolean>(
    props.expendedValue ?? false,
  );

  setExpendedValue = props.setExpendedValue ?? setLocaleExpendedValue;
  const expendedValue = props.expendedValue ?? localExpendedValue;

  return (
    <div className={cn("border-light-1 flex flex-col", className)}>
      <div
        className={cn(
          "border-light-1 flex w-full cursor-pointer flex-row items-center justify-between gap-3 rounded-b-lg rounded-t-lg border-x border-y px-4 py-2.5",
          { "rounded-b-none": expendedValue },
          headerClassName,
        )}
        onClick={() => {
          setExpendedValue && setExpendedValue(!expendedValue);
        }}
      >
        <div className="flex items-center gap-3">
          {dragHandle}
          <div
            className={cn(
              "flex flex-col justify-center",
              textsContainerClassName,
            )}
          >
            <Typography
              className="text-dark-2 truncate"
              variant="caption1-semibold"
              dangerouslySetInnerHTML
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography
                className="text-dark-2 truncate"
                variant="body3-regular"
              >
                {subTitle}
              </Typography>
            )}
          </div>
        </div>

        {chip && (
          <div className="flex flex-col justify-center">
            <Chip label={chip?.label} type={chip?.type} size={chip?.size} />
          </div>
        )}

        <div className="flex items-center">
          {canBeDeleted && (
            <Button
              className="mr-3 self-end"
              singleIcon="Delete"
              variant="destructive"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                deleteAction?.();
              }}
            />
          )}
          <Icon
            name="ChevronDown"
            size="S"
            primaryColor={themeColors["dark-2"]}
            className={cn("ml-auto rotate-0 self-center transition-transform", {
              "rotate-180": expendedValue,
            })}
          />
        </div>
      </div>

      {expendedValue && (
        <div
          className={cn(
            "border-light-1 flex h-full w-full flex-col gap-y-4 rounded-b-lg border-x border-b px-[34px] py-4",
            expandedPanelClassName,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
