import { useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";

import { Club } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useSubscribeClub } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Button,
  Title,
  Typography,
} from "@tudigo-monorepo/web-tudigo-components";

import { ClubAdvantages } from "../../../../components/club-advantage";
import { ClubMembership } from "../../../../components/club-membership";

type FunnelClubDescriptionProps = {
  club: Club;
};

export const FunnelClubDescription = (props: FunnelClubDescriptionProps) => {
  const { t } = useTranslation();
  const { club } = props;

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { mutate: subscribeClub } = useSubscribeClub({
    onSuccess({ data }) {
      const signLink = data?.contract?.signatureRequest?.mySignatureLink;
      const signId = data?.contract?.signatureRequest?.id;

      navigate(
        `/clubs/join/${club?.id}/membership-form?${queryString.stringify({
          signLink,
          signId,
        })}`,
      );
    },
    onError() {
      setIsLoading(false);
    },
  });

  const advantages = club?.description?.map((advantage) => ({
    text: advantage?.data?.phrase,
    subText: advantage?.data?.description,
  }));

  const configuration = club?.configuration;

  const handleSubscribeClub = () => {
    setIsLoading(true);
    subscribeClub({ clubId: club?.id });
  };

  return (
    <>
      <div className="border-b pb-3">
        <Title>Le club {club?.name}</Title>
        <Typography className="text-dark-2" variant="body2">
          {t("my_account.wep_app.clubs.advantages.description")}
        </Typography>
      </div>
      <div className="flex flex-col gap-5 sm:flex-row">
        <ClubAdvantages className="sm:w-1/2" advantages={advantages} />
        <img
          className="sm:w-1/2"
          src={club?.image?.url ?? ""}
          alt={club?.name}
        />
      </div>

      <ClubMembership
        title={t("my_account.wep_app.clubs.membership")}
        description={t(
          "my.account.web.app.clubs.membership_info.subscription",
          {
            subscriptionsCount: configuration?.maxIntroductorySubscriptions,
          },
        )}
        price={t(
          "my.account.web.app.clubs.membership_info.subscription.currency_VAT",
          {
            amount:
              configuration?.introductorySubscriptionPrice?.unitAmountInCents /
              100,
          },
        )}
        oldPrice={t(
          "my.account.web.app.clubs.membership_info.subscription.currency_VAT",
          {
            amount: configuration?.subscriptionPrice?.unitAmountInCents / 100,
          },
        )}
        membershipText={t(
          "my.account.web.app.clubs.membership_info.subscription.amount_subscription",
        )}
        isIntroductoryPriceAvailable={club?.isIntroductoryPriceAvailable}
      />

      <Button
        className="m-auto mb-6 w-fit px-9"
        variant="green"
        label={t("my_account.wep_app.clubs.button")}
        onClick={handleSubscribeClub}
        isLoading={isLoading}
      />
    </>
  );
};
