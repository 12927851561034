import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { CreateLeadRequestBody } from "libs/core/tudigo-api/src/lib/endpoints/leads";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Lead } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient, useInvalidateConnectedUser } from "../../..";

type Params = {
  data: CreateLeadRequestBody;
  fields?: string;
};

export const subscribeProjectNotifications = async (params: Params) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.leadsEndpoints.subscribeProjectNotifications(
      params.data,
    );

  return response.data;
};

export const useSubscribeProjectNotificationsMutation = (
  options?: UseMutationOptions<TudigoResponse<Lead>, TudigoError, Params>,
) => {
  const invalidateUser = useInvalidateConnectedUser();

  return useMutation({
    ...options,
    mutationFn: (params: Params) => subscribeProjectNotifications(params),
    onSuccess: (...props) => {
      invalidateUser();
      options?.onSuccess?.(...props);
    },
  });
};
