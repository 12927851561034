import {
  EquitySavingPlan,
  Investment,
  Organization,
  Project,
  User,
  WMAInvestment,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export type CreateWmaCustomerInvestmentRequestBody = {
  wmaCustomer: {
    id: string;
  };
  investment: {
    project: {
      id: Project["id"];
    };
    totalAmountInCents?: number;
    user?: {
      id: User["id"];
    };
    paymentMethodType?: Investment["paymentMethodType"];
    investorOrganization?: {
      id: Organization["id"];
    };
    autoConsentProjectAgreementsAndSecurity?: boolean;
    equitySavingPlan?: {
      id: EquitySavingPlan["id"];
    };
  };
};

export const wmaInvestmentsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getInvestmentById
   * @summary get investment by id
   * @request GET:/api/v1/wma-investments/:wmaInvestmentId
   * @secure
   */
  getWmaInvestmentById: (
    wmaInvestmentId: WMAInvestment["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMAInvestment>, void>({
      path: `/api/v1/wma-investments/${wmaInvestmentId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getWMACustomersInvestments: (
    wmaUserId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMAInvestment[]>, void>({
      path: `/api/v1/wma-user/${wmaUserId}/wma-investments`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createWMACustomerInvestment: (
    body: CreateWmaCustomerInvestmentRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WMAInvestment>, void>({
      path: `/api/v1/wma-customers/${body.wmaCustomer?.id}/wma-investments`,
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  sendWmaInvestmentFolderEmail: (
    wmaInvestmentId: string,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/wma-investments/${wmaInvestmentId}/notify-esp-to-send`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
