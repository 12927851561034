import React, { ReactNode } from "react";
import { useInstantSearch } from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  buttonCommonStyle,
  buttonStyles,
  ChipCount,
  Icon,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

import { useProjectsRefinements } from "../hooks/use-projects-refinements";

const ProjectsPageHeader = ({ children }: React.PropsWithChildren) => {
  return (
    <header className="border-light-1 flex w-full flex-col gap-y-4 border-b pb-4">
      {children}
    </header>
  );
};

const ProjectsPageHeaderTop = () => {
  const { results } = useInstantSearch();

  return (
    <div className="flex items-center gap-x-4">
      <Title>Les opportunités d&apos;investissement</Title>
      <ChipCount
        size="S"
        label={`${results.nbHits} levées`}
        className="mt-2 hidden h-6 font-semibold lg:flex"
      />
    </div>
  );
};

const ProjectsPageHeaderActions = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex flex-col justify-between gap-y-4 sm:flex-row">
      {children}
    </div>
  );
};

const ProjectsPageHeaderActionsMobile = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { results } = useInstantSearch();

  return (
    <div className="flex w-full items-center justify-between gap-4 sm:justify-end lg:hidden">
      {children}
      <ChipCount
        size="S"
        label={`${results.nbHits} levées`}
        className="h-6 font-semibold"
      />
    </div>
  );
};

const ProjectsPageHeaderMobileMenuTrigger = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { totalRefinementsCount } = useProjectsRefinements();

  return (
    <button
      onClick={onClick}
      className={cn(
        buttonCommonStyle,
        buttonStyles.primary,
        "flex w-fit items-center justify-center gap-x-2 px-4 py-[7px] lg:hidden",
      )}
    >
      <Icon name="Filter" primaryColor="white" />
      <div className="flex items-center gap-x-2">
        <span className=" hover:text-primary font-sans text-sm font-medium text-white">
          Filtrer
        </span>
        {totalRefinementsCount > 0 && (
          <span className="bg-accent-super-light-rebrand text-primary-rebrand flex h-6 w-6 items-center justify-center rounded-full font-sans text-xs font-semibold">
            {totalRefinementsCount}
          </span>
        )}
      </div>
    </button>
  );
};

ProjectsPageHeader.Top = ProjectsPageHeaderTop;
ProjectsPageHeader.Actions = ProjectsPageHeaderActions;
ProjectsPageHeader.ActionsMobile = ProjectsPageHeaderActionsMobile;
ProjectsPageHeader.MenuTrigger = ProjectsPageHeaderMobileMenuTrigger;

export { ProjectsPageHeader };
