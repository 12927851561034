import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

const ALGOLIA_INDEX_PREFIX = import.meta.env["VITE_ALGOLIA_INDEX_PREFIX"];

export const NavbarMenuInvest = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink to="/" hardLink onClick={props.onLinkClick}>
          Toutes les opportunités
          <NavbarMenuLinkLegend>
            Découvrez les projets en cours de levée sur Tudigo
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to={`/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Binstrument%5D%5B0%5D=shares`}
          onClick={props.onLinkClick}
          hardLink
        >
          En Actions
          <NavbarMenuLinkLegend>
            Investissez directement au capital de l’entreprise
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to={`/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Binstrument%5D%5B0%5D=bonds`}
          onClick={props.onLinkClick}
          hardLink
        >
          En Obligations
          <NavbarMenuLinkLegend>
            Investissez directement au capital de l’entreprise
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to={`/?${ALGOLIA_INDEX_PREFIX}tudigo_projects_fr%5BrefinementList%5D%5Bcategories.name%5D%5B0%5D=Immobilier`}
          onClick={props.onLinkClick}
          hardLink
        >
          Investissement Immobilier
          <NavbarMenuLinkLegend>
            Prenez part au financement de projets immobiliers
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/pourquoi-investir"
          onClick={props.onLinkClick}
        >
          Pourquoi investir ?
          <NavbarMenuLinkLegend>
            Faites fructifier votre épargne pour un monde meilleur
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink to="https://tudigo.co/faq" onClick={props.onLinkClick}>
          FAQ
          <NavbarMenuLinkLegend>
            Les réponses à toutes vos questions sur Tudigo et le non côté
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
