import React, { forwardRef, InputHTMLAttributes, useId } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroup, InputGroupProps } from "../input-group";

export type CheckProps = {
  iconContainerClassName?: string;
  type?: "checkbox" | "radio";
  onChange?: (checked: boolean) => void;
  validated?: boolean;
} & InputGroupProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

export const Check = forwardRef<HTMLInputElement, CheckProps>(
  (props: CheckProps, ref) => {
    const {
      checked = false,
      type = "checkbox",
      validated,
      id,
      label,
      className,
      autoComplete,
      name,
      value = "",
      pattern,
      placeholder,
      autoFocus = false,
      disabled = false,
      groupRef,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
    } = props;

    let inputId = useId();
    inputId = id || inputId;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.checked);
      }
    };

    return (
      <InputGroup
        ref={ref}
        id={inputId}
        className={className}
        fakeInputClassName="border-0 m-0 p-0 basis-0"
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="right"
        valid={validated}
        groupRef={groupRef}
        renderInput={(inputProps) => (
          <div className="relative flex">
            <input
              type={type}
              id={inputId}
              value={value}
              checked={checked}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              name={name}
              onKeyDown={onKeyDown}
              disabled={disabled}
              required={required}
              placeholder={placeholder}
              pattern={pattern}
              onFocus={onFocus}
              onChange={handleChange}
              className={cn(
                "border-dark-2 h-4 w-4 cursor-pointer appearance-none rounded border",
                "hover:border-dark-4 hover:bg-accent-super-light",
                "checked:!bg-accent checked:!border-accent",
                "disabled:!border-medium-2 disabled:cursor-default disabled:!bg-white",
                {
                  "rounded-full": type === "radio",
                },
              )}
              ref={inputProps.ref}
            />
            {checked && (
              <Icon
                className="absolute"
                name="Checked"
                primaryColor={themeColors["white"]}
              />
            )}
          </div>
        )}
      />
    );
  },
);
