import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Helpdesk(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 18.5C11.8011 18.5 11.6103 18.579 11.4697 18.7197C11.329 18.8603 11.25 19.0511 11.25 19.25C11.25 19.4489 11.329 19.6397 11.4697 19.7803C11.6103 19.921 11.8011 20 12 20C12.1989 20 12.3897 19.921 12.5303 19.7803C12.671 19.6397 12.75 19.4489 12.75 19.25C12.75 19.0511 12.671 18.8603 12.5303 18.7197C12.3897 18.579 12.1989 18.5 12 18.5ZM17.5 10V9C17.5 7.54131 16.9205 6.14236 15.8891 5.11091C14.8576 4.07946 13.4587 3.5 12 3.5C10.5413 3.5 9.14236 4.07946 8.11091 5.11091C7.07946 6.14236 6.5 7.54131 6.5 9V10H9C9.26522 10 9.51957 10.1054 9.70711 10.2929C9.89464 10.4804 10 10.7348 10 11V15C10 15.2652 9.89464 15.5196 9.70711 15.7071C9.51957 15.8946 9.26522 16 9 16H6.5V16.25C6.49993 16.8201 6.7163 17.369 7.10537 17.7858C7.49444 18.2025 8.02721 18.456 8.596 18.495L8.75 18.5H9.878C10.0549 17.9993 10.4032 17.5773 10.8612 17.3085C11.3192 17.0398 11.8575 16.9416 12.3809 17.0314C12.9042 17.1211 13.3791 17.3931 13.7213 17.799C14.0636 18.205 14.2514 18.719 14.2514 19.25C14.2514 19.781 14.0636 20.295 13.7213 20.701C13.3791 21.1069 12.9042 21.3789 12.3809 21.4686C11.8575 21.5584 11.3192 21.4602 10.8612 21.1915C10.4032 20.9227 10.0549 20.5007 9.878 20H8.75C7.79 20.0001 6.86653 19.632 6.16981 18.9716C5.4731 18.3111 5.0562 17.4086 5.005 16.45L5 16.25V9C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9V14C19.0002 14.5046 18.8096 14.9906 18.4665 15.3605C18.1234 15.7305 17.6532 15.9572 17.15 15.995L17 16H15C14.7551 16 14.5187 15.91 14.3356 15.7473C14.1526 15.5845 14.0357 15.3603 14.007 15.117L14 15V11C14 10.7551 14.09 10.5187 14.2527 10.3356C14.4155 10.1526 14.6397 10.0357 14.883 10.007L15 10H17.5ZM8.5 11.5H6.5V14.5H8.5V11.5ZM17.5 11.5H15.5V14.5H17C17.117 14.5 17.2304 14.459 17.3203 14.3841C17.4102 14.3092 17.4709 14.2051 17.492 14.09L17.5 14V11.5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
