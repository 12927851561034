import { useMemo } from "react";

import {
  InternalKycStatus,
  UserRole,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { useSessionStore } from "../../session-store";

export function useUserBusiness() {
  const user = useSessionStore((state) => state.user);
  const role = useSessionStore((state) => state.sessionRole);

  const isIndividualOrganizationKycApproved =
    user?.individualOrganization?.internalKycStatus ===
    InternalKycStatus.APPROVED;

  const isQualified = useMemo(() => {
    if (!user) {
      return false;
    }

    return !user?.investorProfile?.isQualificationExpired;
  }, [user]);

  const isWma = useMemo(() => {
    return user?.appRoles?.includes(UserRole.WMA);
  }, [user]);

  const isAdmin = useMemo(() => {
    return user?.appRoles?.includes(UserRole.ADMIN);
  }, [user]);

  const projectOwnerProjects = user?.projectHolderProfile?.projects;

  const clubLeaderClubs = user?.circlesOwnedAsCircleLeader;

  return {
    isConnected: !!user,
    isAdmin,
    isQualified,
    isWma,
    isQualifiedOrWma: isQualified || isWma,
    user,
    sessionRole: role,
    isIndividualOrganizationKycApproved,
    projectOwnerProjects,
    clubLeaderClubs,
    userIdOrMe: user?.id || "me",
  };
}
