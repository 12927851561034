import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";

import { TrackRecord } from "@tudigo-monorepo/core-tudigo-api-models";

import {
  TrackRecordElement,
  TrackRecordElementProps,
} from "./track-record-element";

type TrackRecordItemsProps = {
  trackRecords: TrackRecord[];
  setTrackRecords: (trackRecords: TrackRecord[]) => void;
  deleteTrackRecord: (index: number) => void;
  setTrackRecord: (trackRecord: TrackRecord, index: number) => void;
};

const SortableItem = SortableElement<
  TrackRecordElementProps & { position: number }
>((props: TrackRecordElementProps & { position: number }) => (
  <TrackRecordElement {...props} index={props.position} />
));

const SortableList = SortableContainer<TrackRecordItemsProps>(
  (props: TrackRecordItemsProps) => {
    return (
      <div className="flex flex-row flex-wrap gap-6 overflow-y-auto">
        {props.trackRecords?.map((trackRecord, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            trackRecord={{
              ...trackRecord,
            }}
            {...props}
          />
        ))}
      </div>
    );
  },
);

export function TrackRecordItems(props: TrackRecordItemsProps) {
  const { setTrackRecords, trackRecords } = props;
  const handleSortEnd = (sort: SortEnd) => {
    const newOrder = arrayMove(trackRecords, sort.oldIndex, sort.newIndex);

    const updatedTrackRecords = newOrder.map((trackRecord, index) => ({
      ...trackRecord,
      position: index,
    }));

    setTrackRecords(updatedTrackRecords);
  };

  return (
    <div>
      <SortableList
        {...props}
        useDragHandle
        axis="y"
        onSortEnd={handleSortEnd}
      />
    </div>
  );
}
