import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Document } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { ChipCount } from "../chip-count";
import { Icon } from "../icons/icon";
import { Typography } from "../typography";

export type PDFUploaderProps = {
  pdf: Document;
  className?: string;
  documentClassName?: string;
  label?: string | ReactNode;
  required?: boolean;
  disabled?: boolean;
  errors?: string[];
  onPdfChange: (pdf: Document) => void;
  onPdfDelete?: (pdf: Document) => void;
};

const fileSizeToMB = (size: number) => size / Math.pow(1024, 2);

const ALLOWED_EXTENSIONS = ["pdf"];
const MAX_SIZE_IN_MB = 10;

export function PdfUploader(props: PDFUploaderProps) {
  const {
    pdf,
    className,
    documentClassName,
    label = null,
    required = false,
    disabled = false,
    errors,
    onPdfChange,
    onPdfDelete,
  } = props;

  const [file, setFile] = useState<File>();
  const [isBadExtention, setIsBadExtention] = useState(false);
  const [hasExceededSizeLimit, setHasExceededSizeLimit] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const reader = useMemo(() => new FileReader(), []);

  useEffect(() => {
    const handleFileRead = () => {
      onPdfChange({
        ...pdf,
        base64: String(reader.result),
        originalFilename: file?.name ? file.name : undefined,
      });
    };

    reader.addEventListener("load", handleFileRead);

    return () => {
      reader.removeEventListener("load", handleFileRead);
    };
  }, [file, reader, onPdfChange, pdf]);

  const handleIsValidFile = (file: File) => {
    setHasExceededSizeLimit(fileSizeToMB(file.size) > MAX_SIZE_IN_MB);
    setIsBadExtention(!ALLOWED_EXTENSIONS.includes(file.type.split("/")[1]));
  };

  useEffect(() => {
    if (file) {
      if (hasExceededSizeLimit || isBadExtention) return;
      reader.readAsDataURL(file);
    }
  }, [file, reader, hasExceededSizeLimit, isBadExtention]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      handleIsValidFile(file);
      setFile(file);
    }
  };

  const removeFile = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setFile(undefined);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    onPdfChange({
      ...pdf,
      base64: undefined,
      url: undefined,
      id: undefined,
      originalFilename: undefined,
    });

    onPdfDelete && onPdfDelete(pdf);
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (fileInput.current) {
      const file = event.dataTransfer.files[0];
      handleIsValidFile(file);
      setFile(file);
    }
  };

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const getFilename = useCallback((): string => {
    if (file?.name) {
      return file?.name;
    }
    if (pdf.base64 || pdf.url) {
      return pdf.originalFilename ? pdf.originalFilename : "document.pdf";
    }

    return "";
  }, [file?.name, pdf.base64, pdf.originalFilename, pdf.url]);

  const renderFileMessage = () => {
    if (hasExceededSizeLimit) {
      return (
        <Typography variant="caption1-medium" className="text-error">
          Le fichier est trop volumineux (max {MAX_SIZE_IN_MB}Mo)
        </Typography>
      );
    }

    if (isBadExtention) {
      return (
        <Typography variant="caption1-medium" className="text-error">
          Le fichier doit correspondre à l&apos;un des formats suivants :{" "}
          {ALLOWED_EXTENSIONS.toString().replaceAll(",", ", ")}
        </Typography>
      );
    }

    if (pdf.base64 || pdf.url) {
      return (
        <div className="flex flex-col">
          <div
            className={cn(
              "border-light-1 flex flex-row items-center justify-between rounded-lg border bg-white px-4 py-6",
              documentClassName,
              { "border-error": !!errors?.length },
            )}
          >
            <Typography variant="body3-semibold" className="ml-2 leading-none">
              {pdf.url ? (
                <a
                  href={pdf.signedUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  {getFilename()}
                </a>
              ) : (
                getFilename()
              )}
            </Typography>

            {!disabled && (
              <button className="ml-4" onClick={removeFile}>
                <Icon
                  name="Delete"
                  primaryColor={themeColors["error"]}
                  secondaryColor={themeColors["error"]}
                />
              </button>
            )}
          </div>

          {errors && errors.length > 0 && (
            <div className="mt-1 flex flex-col gap-2.5 text-start">
              {errors?.map((error, index) => (
                <Typography
                  key={index}
                  variant="body3-regular"
                  className="text-error"
                >
                  {error}
                </Typography>
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      className={className}
    >
      <label className="flex flex-col gap-y-2.5">
        <Typography variant="body3-regular" className="text-dark-1">
          {label} {required && <span className="text-error">*</span>}
        </Typography>

        {!pdf.url && !pdf.base64 && (
          <>
            <div className="border-medium-2 flex flex-col items-center justify-center rounded-lg border-2 border-dashed px-2 py-6">
              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center gap-x-4">
                  <Icon name="Upload" primaryColor={themeColors["dark-2"]} />
                  <Typography
                    variant="caption1-semibold"
                    className="text-dark-1"
                  >
                    Cliquer pour parcourir ou glisser, déposer un fichier
                  </Typography>
                </div>
                <input
                  ref={fileInput}
                  disabled={disabled}
                  type="file"
                  style={{ left: 100000, width: 0, height: 0, opacity: 0 }}
                  className="absolute overflow-hidden"
                  onChange={onChange}
                />
              </div>
            </div>

            <div className="flex items-center justify-center gap-x-2">
              {ALLOWED_EXTENSIONS.map((ext, index) => (
                <ChipCount key={index} label={ext} className="uppercase" />
              ))}
              <ChipCount
                label={`< ${MAX_SIZE_IN_MB}Mo`}
                variant="light"
                size="S"
                className="border-dark-2 border bg-white"
              />
            </div>
          </>
        )}

        {renderFileMessage()}
      </label>
    </div>
  );
}
