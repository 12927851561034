import { Outlet } from "react-router-dom";

import {
  FooterRebrand,
  NavbarRebrand,
} from "@tudigo-monorepo/web-tudigo-components";

export function AppLayout() {
  return (
    <div>
      <NavbarRebrand />

      <main>
        <Outlet />
      </main>

      <FooterRebrand />
    </div>
  );
}
