import React, { forwardRef, MouseEvent } from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { IconName } from "../icons/icon-name.type";

export type ButtonVariant =
  | "primary"
  | "tertiary"
  | "ghost"
  | "secondary"
  | "destructive"
  | "destructive-ghost"
  | "green";

export type ButtonProps = {
  label?: string | React.ReactNode;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  iconColor?: string;
  iconLeft?: IconName;
  iconRight?: IconName;
  singleIcon?: IconName;
  isLoading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "submit" | "reset";
} & VariantProps<typeof buttonVariants>;

export const buttonVariants = cva(
  "py-[10px] flex-shrink-0 rounded-full border font-montserrat font-sm text-[14px] font-medium transition-colors hover:border disabled:bg-light-2 disabled:text-dark-4 disabled:cursor-not-allowed disabled:border-light-2 disabled:hover:border-light-2",
  {
    variants: {
      variant: {
        primary:
          "bg-primary border-white text-white sm:hover:text-primary sm:hover:border-accent-medium sm:hover:text-dark-2 sm:hover:bg-white",
        tertiary:
          "text-dark-2 border-tertiary bg-white sm:hover:bg-tertiary sm:hover:border-accent-medium sm:hover:text-dark-2",
        ghost:
          "text-dark-2 border-transparent bg-white sm:hover:bg-ghost sm:hover:border-accent-medium sm:hover:text-dark-2",
        secondary:
          "bg-secondary text-primary sm:hover:text-primary sm:hover:border-accent-medium sm:hover:text-dark-2 sm:hover:bg-white",
        destructive:
          "text-error border-tertiary bg-white sm:hover:bg-tertiary sm:hover:border-error",
        "destructive-ghost":
          "text-error border-white bg-white sm:hover:bg-tertiary sm:hover:border-error",
        green:
          "bg-success border-success text-white sm:hover:text-dark-2 sm:hover:border-success-medium sm:hover:bg-white active:bg-success-light active:border-success-medium active:text-success",
        "ghost-green":
          "text-success border-transparent bg-white sm:hover:bg-ghost sm:hover:border-accent-medium sm:hover:text-dark-2",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      variant = "primary",
      label,
      className,
      labelClassName,
      disabled,
      onClick,
      iconColor: iconColorProp,
      iconLeft,
      iconRight,
      singleIcon,
      isLoading,
      type = "button",
    } = props;

    const iconColor = iconColorProp
      ? themeColors[iconColorProp as keyof typeof themeColors]
      : "currentColor";

    return (
      <button
        ref={ref}
        onClick={(e) => {
          if (isLoading) return;
          if (onClick) {
            e.stopPropagation();
            onClick(e);
          }
        }}
        disabled={disabled}
        className={cn(buttonVariants({ variant, className }), {
          "flex items-center justify-center gap-x-1 px-4": !singleIcon,
          "px-[10px]": singleIcon,
        })}
        type={type}
      >
        {singleIcon ? (
          <Icon
            name={singleIcon}
            primaryColor={iconColor}
            width={20}
            height={20}
          />
        ) : (
          <>
            {iconLeft && (
              <Icon
                name={iconLeft}
                width={20}
                height={20}
                primaryColor={iconColor}
                className="-translate-x-1"
              />
            )}

            <span
              className={cn(
                "font-montserrat text-sm font-medium",
                labelClassName,
              )}
            >
              {label}
            </span>

            {(iconRight || isLoading) && (
              <Icon
                name={isLoading ? "Loading" : (iconRight as IconName)}
                primaryColor={iconColor}
                width={20}
                height={20}
                className={cn({ "translate-x-1": !!iconRight })}
              />
            )}
          </>
        )}
      </button>
    );
  },
);
