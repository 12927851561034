import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Flash(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: width || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29384 14L6.52685 21.068C6.33985 21.814 7.26285 22.324 7.79585 21.769L19.7898 9.27C19.8918 9.16402 19.9604 9.03041 19.987 8.88577C20.0136 8.74114 19.9971 8.59187 19.9396 8.45653C19.882 8.32119 19.786 8.20576 19.6633 8.12459C19.5407 8.04342 19.3969 8.00009 19.2498 8H14.7898L16.4618 2.987C16.4994 2.87423 16.5096 2.75415 16.4917 2.63665C16.4737 2.51916 16.4281 2.40762 16.3585 2.31122C16.289 2.21482 16.1975 2.13634 16.0917 2.08222C15.9859 2.02811 15.8687 1.99993 15.7498 2H8.74985C8.58688 2.00003 8.42836 2.05314 8.29827 2.1513C8.16819 2.24945 8.07361 2.3873 8.02885 2.544L5.02885 13.044C4.99696 13.1556 4.99142 13.2731 5.01266 13.3872C5.03389 13.5013 5.08132 13.6089 5.1512 13.7016C5.22109 13.7943 5.31154 13.8695 5.41542 13.9212C5.5193 13.973 5.63378 14 5.74985 14H8.29384ZM13.0388 8.513C13.0013 8.62569 12.9911 8.74568 13.009 8.8631C13.0269 8.98052 13.0724 9.092 13.1419 9.18837C13.2113 9.28475 13.3026 9.36326 13.4083 9.41743C13.514 9.47161 13.6311 9.49991 13.7498 9.5H17.4898L8.66585 18.696L9.98185 13.432C10.0095 13.3215 10.0116 13.2061 9.98799 13.0946C9.96439 12.9832 9.9157 12.8785 9.84563 12.7887C9.77555 12.6989 9.68593 12.6262 9.58356 12.5762C9.4812 12.5261 9.36878 12.5001 9.25485 12.5H6.74485L9.31485 3.5H14.7088L13.0388 8.513Z"
        fill={primaryColor}
      />
    </svg>
  );
}
