import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";

import { ObjectDotNotation } from "@tudigo-monorepo/core-tudigo-utils";
import { InputGroupProps } from "@tudigo-monorepo/web-tudigo-components";

import { useFormContext } from "./lib/form-context/form-context";
import { SmartCountryField } from "./lib/smart-country-field/smart-country-field";
import { SmartCurrencyField } from "./lib/smart-currency-field/smart-currency-field";
import { SmartDateField } from "./lib/smart-date-field/smart-date-field";
import { SmartImageField } from "./lib/smart-image-field/smart-image-field";
import { SmartMultiTypeUploaderField } from "./lib/smart-multi-type-uploader-field/smart-multi-type-uploader-field";
import { SmartNumberField } from "./lib/smart-number-field/smart-number-field";
import { SmartPdfField } from "./lib/smart-pdf-field/smart-pdf-field";
import { SmartPhoneField } from "./lib/smart-phone-field/smart-phone-field";
import SmartRadioGroupField from "./lib/smart-radio-group-field/smart-radio-group-field";
import SmartRichTextField from "./lib/smart-rich-text-field/smart-rich-text-field";
import SmartSelectField from "./lib/smart-select-field/smart-select-field";
import { SmartTextAreaField } from "./lib/smart-text-area-field/smart-text-area-field";
import SmartTextField from "./lib/smart-text-field/smart-text-field";
import { SmartToggleField } from "./lib/smart-toggle-field/smart-toggle-field";

export * from "./lib/form-context/form-context";
export * from "./lib/smart-text-field/smart-text-field";
export * from "./lib/smart-date-field/smart-date-field";
export * from "./lib/smart-phone-field/smart-phone-field";
export * from "./lib/smart-country-field/smart-country-field";
export * from "./lib/smart-text-field/smart-text-field";
export * from "./lib/smart-image-field/smart-image-field";
export * from "./lib/smart-select-field/smart-select-field";
export * from "./lib/smart-text-area-field/smart-text-area-field";
export * from "./lib/smart-toggle-field/smart-toggle-field";
export * from "./lib/smart-radio-group-field/smart-radio-group-field";
export * from "./lib/smart-rich-text-field/smart-rich-text-field";
export * from "./lib/smart-pdf-field/smart-pdf-field";
export * from "./lib/smart-multi-type-uploader-field/smart-multi-type-uploader-field";
export * from "./lib/smart-currency-field/smart-currency-field";
export * from "./lib/types";

export type SmartFieldCommonProps<T> = {
  name: ObjectDotNotation<T>;
} & InputGroupProps;

export type SmartField = {
  data: any;
  errors: string[] | undefined;
  onSave: (content: any) => void;
};

export function extractValueFromPath<T>(jsonObject: T, path: string): any {
  return get(jsonObject, path);
}

export function updateObject<T extends object>(
  obj: T,
  path: string,
  newValue: any,
): T {
  const newObj = cloneDeep(obj);
  set(newObj, path, newValue);

  return newObj;
}

export function useSmartField<T>(name: ObjectDotNotation<T>): SmartField {
  const { form, handleFormChange, error } = useFormContext();
  const data = extractValueFromPath(form, name);
  const errors = error?.parametersErrors?.[name];

  const onSave = (content: any) => {
    handleFormChange({
      ...updateObject(form, name, content),
    });
  };

  return {
    data: data ?? [],
    errors,
    onSave,
  };
}

export function getSmartFormComponents<T>() {
  return {
    SmartTextField: SmartTextField<T>,
    SmartDateField: SmartDateField<T>,
    SmartPhoneField: SmartPhoneField<T>,
    SmartCountryField: SmartCountryField<T>,
    SmartImageField: SmartImageField<T>,
    SmartPdfField: SmartPdfField<T>,
    SmartMultiTypeUploaderField: SmartMultiTypeUploaderField<T>,
    SmartSelectField: SmartSelectField<any, any, T>,
    SmartTextAreaField: SmartTextAreaField<T>,
    SmartRadioGroupField: SmartRadioGroupField<any, T>,
    SmartRichTextField: SmartRichTextField<T>,
    SmartToggleField: SmartToggleField<T>,
    SmartCurrencyField: SmartCurrencyField<T>,
    SmartNumberField: SmartNumberField<T>,
  };
}
