import { Analyst, Reporting } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const reportingQueryFields =
  "id," +
  "name," +
  "status," +
  "reporting_campaign.fields(id,organization.fields(name,business_categories))," +
  "analysis_writer.fields(id,firstname,lastname,profile_image,position)," +
  "analysis," +
  "trust_level," +
  "period_start_date," +
  "period_end_date," +
  "fill_date," +
  "reviewed_date," +
  "validated_date," +
  "reporter_comment," +
  "data," +
  "timeseries," +
  "my_investments.fields(id,total_amount_in_cents,payment_date)," +
  "form_properties_to_display," +
  "related_projects.fields(id,content.fields(name,logo),total_amount_collected_in_cents,fundraising_progress_min_goal,fundraising_progress_max_goal,finished_at)";

export const reportingsEndpoints = (request: Request) => ({
  getReportings: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Reporting[]>, void>({
      path: `/api/v1/reportings`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getReportingById: ({
    reportingId,
    query,
    params,
  }: {
    reportingId: Reporting["id"];
    query?: Record<string, string>;
    params?: RequestParams;
  }) => {
    return request<TudigoResponse<Reporting>, void>({
      path: `/api/v1/reportings/${reportingId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  },

  updateReporting: (
    reportingId: Reporting["id"],
    body: Reporting,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Reporting>, void>({
      path: `/api/v2/reportings/${reportingId}`,
      method: "PUT",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getAnalysts: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Analyst[]>, void>({
      path: `/api/v1/analysts`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  publishReporting: (reportingId: string, params: RequestParams = {}) =>
    request<TudigoResponse<Reporting>, void>({
      path: `/api/v1/reportings/${reportingId}/publish`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  unpublishReporting: (reportingId: string, params: RequestParams = {}) =>
    request<TudigoResponse<Reporting>, void>({
      path: `/api/v1/reportings/${reportingId}/unpublish`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  sendReminder: (reportingId: string, params: RequestParams = {}) =>
    request<TudigoResponse<Reporting>, void>({
      path: `/api/v1/reportings/${reportingId}/send-reminder`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
