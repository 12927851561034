import { ProjectTag } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const projectTagsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getProjectTags
   * @summary get project tags
   * @request GET:/api/v1/project-tags
   * @secure
   */
  getProjectTags: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ProjectTag[]>, void>({
      path: `/api/v1/project-tags`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
