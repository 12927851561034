import { MouseEvent, useCallback, useState } from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { mixpanelTrackEvent } from "@tudigo-monorepo/web-tudigo-tracking";

import { NotifyProjectModal } from "../../ui/notify-project-modal";
import { Icon } from "../icons/icon";
import { useProjectNotificationsSubscription } from "./use-project-notifications-subscription";

export function ProjectCardNotify(props: { project: Project }) {
  const { project } = props;

  const { user } = useUserBusiness();
  const { t } = useTranslation();

  const { isSubscribed, isLoading, subscribeProject, unsubscribeProject } =
    useProjectNotificationsSubscription(project);

  const [showNotifyModal, setShowNotifyModal] = useState(false);

  const eventData = {
    project_id: project.id,
    project_name: project.content?.name,
  };

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      if (isSubscribed) {
        return unsubscribeProject();
      }

      if (user) {
        subscribeProject();
        mixpanelTrackEvent("ui.project_listing_comingsoon_interest", eventData);
      } else {
        setShowNotifyModal(true);
      }
    },
    [user, isSubscribed, subscribeProject, unsubscribeProject],
  );

  const handleVisitorConfirmSubscription = (email: string) => {
    subscribeProject(email);
    setShowNotifyModal(false);

    mixpanelTrackEvent("ui.project_listing_comingsoon_interest", eventData);
  };

  return (
    <>
      <NotifyProjectModal
        project={project}
        isOpen={showNotifyModal}
        onConfirm={handleVisitorConfirmSubscription}
        onClose={() => setShowNotifyModal(false)}
      />

      <button
        onClick={handleClick}
        className="group mx-auto flex w-full cursor-pointer items-center justify-center gap-x-2.5 bg-transparent p-4"
      >
        <div
          className={cn(
            "border-light-1 group-hover:border-dark-2 group-hover:[&:is(.border-accent-medium)]:border-accent-medium rounded-full border bg-transparent p-[7px] duration-300 ease-in-out",
            {
              "border-accent-medium bg-accent-super-light": isSubscribed,
            },
          )}
        >
          <Icon
            name={isLoading ? "Loading" : "Notification"}
            primaryColor={themeColors[isSubscribed ? "accent" : "dark-2"]}
          />
        </div>

        <p className="font-montserrat text-dark-4 group-hover:text-dark-2 text-[13px] duration-300 ease-in-out">
          {isSubscribed
            ? t("component.project_card.footer.no_longer_notified")
            : t("component.project_card.footer.be_notified_of_launch")}
        </p>
      </button>
    </>
  );
}
