import {
  CMSBlock,
  CMSBlockData,
  Image,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  extractValueFromPath,
  InputImageDescription,
  updateObject,
} from "@tudigo-monorepo/web-feature-form";
import { ImageUploader } from "@tudigo-monorepo/web-tudigo-components";

type EditorPluginInputProps = {
  input: InputImageDescription<CMSBlockData>;
  path?: string;
  blockContent: CMSBlock;
  setBlockContent: (blockContent: CMSBlock) => void;
};

export const EditorImageInput = (props: EditorPluginInputProps) => {
  const { input, path, blockContent, setBlockContent } = props;

  return (
    <ImageUploader
      label={input.label}
      required={input.required}
      image={{
        base64: null,
        ...extractValueFromPath(blockContent, path + input.name),
      }}
      onImageChange={(image: Image) => {
        setBlockContent({
          ...updateObject(blockContent, path + input.name, image),
        });
      }}
    />
  );
};
