import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

export const NavbarMenuFunds = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.typeform.com/to/ucMQlqZf?typeform-source=www.tudigo.co"
          onClick={props.onLinkClick}
        >
          Déposer son dossier
          <NavbarMenuLinkLegend>
            Besoin de financement ? Transmettez-nous votre dossier !
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/lever-des-fonds"
          onClick={props.onLinkClick}
        >
          Comment lever des fonds ?
          <NavbarMenuLinkLegend>
            Tout ce qu’il faut savoir avant de lever des fonds avec Tudigo
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/histoires-inspirantes"
          onClick={props.onLinkClick}
        >
          Nos histoires inspirantes
          <NavbarMenuLinkLegend>
            Découvrez les belles histoires financées sur Tudigo
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
