import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CircleTrack(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        fill="#32354C"
        stroke={primaryColor}
      />
      <circle cx="20.5" cy="17.5" r="3" fill="white" stroke={primaryColor} />
      <path
        d="M16 9.33165C16.0025 8.20256 15.3159 7.14896 14.1755 6.53483C13.0358 5.92062 11.5984 5.82954 10.3598 6.29215C9.12102 6.75528 8.24893 7.70944 8.04544 8.82615C7.84127 9.94282 8.33365 11.0688 9.3514 11.8165C9.34269 11.8398 9.33709 11.8636 9.33397 11.8874V16.5861C9.33397 16.7532 9.45598 16.9043 9.6421 16.9685C9.82823 17.0327 10.043 16.997 10.1849 16.878L11.9995 15.3624L13.8147 16.878C13.9566 16.997 14.172 17.0327 14.3581 16.9685C14.5442 16.9043 14.6663 16.7532 14.6663 16.5861V11.8874C14.6631 11.8636 14.6575 11.8398 14.6488 11.8165C15.5072 11.1878 15.9994 10.2828 16 9.33165ZM8.99583 9.33165C8.99583 8.6693 9.31268 8.03438 9.87602 7.5661C10.4394 7.09783 11.2031 6.83494 11.9999 6.83494C12.7967 6.83494 13.5611 7.09781 14.1238 7.5661C14.6872 8.03441 15.004 8.66983 15.004 9.33218C15.004 9.99401 14.6871 10.6294 14.1238 11.0977C13.5604 11.566 12.7967 11.8289 11.9999 11.8289C11.2038 11.8284 10.44 11.565 9.87666 11.0967C9.3133 10.6284 8.99708 9.99345 8.99583 9.33165ZM13.6701 15.5833L12.3535 14.4837C12.2601 14.4061 12.1331 14.3621 12.0006 14.3621C11.868 14.3621 11.741 14.4061 11.6476 14.4837L10.3298 15.5838V12.3494C11.3887 12.7592 12.6112 12.7592 13.6701 12.3494L13.6701 15.5833Z"
        fill="white"
      />
    </svg>
  );
}
