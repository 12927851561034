import { useState } from "react";
import { isArray } from "lodash";
import { SortableHandle } from "react-sortable-hoc";

import {
  ClubLeaderSpeciality,
  Image,
  Industry,
  ProjectTag,
  TrackRecord,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetProjectTagsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  ExpansionPanel,
  Icon,
  ImageUploader,
  InputSelect,
  TextArea,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

export type TrackRecordElementProps = {
  trackRecord: TrackRecord;
  index: number;
  deleteTrackRecord: (index: number) => void;
  setTrackRecord: (trackRecord: TrackRecord, index: number) => void;
};

const DragHandle = SortableHandle(() => (
  <div className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border">
    <Icon name="Drag" size="S" primaryColor={themeColors["dark-2"]} />
  </div>
));

export function TrackRecordElement(props: TrackRecordElementProps) {
  const { trackRecord, index, deleteTrackRecord, setTrackRecord } = props;

  const { data: availableProjectTagsResponse } = useGetProjectTagsQuery();

  const { t } = useTranslation();

  const [localExpendedValue, setLocaleExpendedValue] = useState<boolean>(true);

  return (
    <ExpansionPanel
      title={`${trackRecord.title} - ${trackRecord.description}`}
      dragHandle={<DragHandle />}
      canBeDeleted
      expendedValue={localExpendedValue}
      setExpendedValue={setLocaleExpendedValue}
      deleteAction={() => {
        deleteTrackRecord(index);
      }}
      className={cn("w-full bg-white")}
      headerClassName="justify-between"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex w-full flex-col gap-4 md:w-[423px]">
          <TextField
            required
            label={t("back_office.circles.edit_circle.tab_5.form.title.label")}
            value={trackRecord.title}
            onChange={(event) => {
              setTrackRecord(
                {
                  ...trackRecord,
                  title: event.target.value,
                },
                index,
              );
            }}
          />
          <InputSelect<ProjectTag, ProjectTag>
            required
            label={t(
              "back_office.circles.edit_circle.tab_5.form.industry.label",
            )}
            selectedValue={trackRecord.projectTags}
            errors={[]}
            onChange={(item) => {
              setTrackRecord(
                {
                  ...trackRecord,
                  projectTags: isArray(item) ? item : [item],
                },
                index,
              );
            }}
            comparisonFunction={(
              item: ClubLeaderSpeciality,
              value: ClubLeaderSpeciality,
            ) => item.code === value.code}
            getValueFromItem={(item: ProjectTag) => item}
            items={availableProjectTagsResponse?.data ?? []}
            labelFunction={(item: Industry) => item.name}
          />
          <TextArea
            required
            label={t(
              "back_office.circles.edit_circle.tab_5.form.short_description.label",
            )}
            value={trackRecord.description}
            onChange={(event) => {
              setTrackRecord(
                {
                  ...trackRecord,
                  description: event.target.value,
                },
                index,
              );
            }}
          />
          <TextField
            label={t("back_office.circles.edit_circle.tab_5.form.link.label")}
            value={trackRecord.link}
            onChange={(event) => {
              setTrackRecord(
                {
                  ...trackRecord,
                  link: event.target.value,
                },
                index,
              );
            }}
          />
          <ImageUploader
            required
            label={t("back_office.circles.edit_circle.tab_5.form.logo.label")}
            ratio={t("back_office.circles.edit_circle.tab_5.form.logo.cover")}
            allowedExtensions={["jpg", "jpeg", "png"]}
            image={
              trackRecord.logo ?? {
                id: null,
                url: null,
                base64: null,
                resizedImages: null,
                originalFilename: null,
              }
            }
            onImageChange={(image: Image) => {
              setTrackRecord(
                {
                  ...trackRecord,
                  logo: image,
                },
                index,
              );
            }}
          />
        </div>
      </div>
    </ExpansionPanel>
  );
}
