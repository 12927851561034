import {
  Address,
  Document,
  EquitySavingPlan,
  Image,
  PmsFile,
  User,
} from "../../";
import { PmsAccount } from "../pms";

export enum OrganizationBusinessAreaEnum {
  INDUSTRIAL = "industrielle",
  COMMERCIAL = "commerciale",
  ARTISANAL = "artisanale",
  AGRICOLE = "agricole",
  LIBERAL = "libérale",
}

export type OrganizationPerson = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  address: Address | null;
  birthDate: string | null;
  birthLocation: Address | null;
  nationality: string | null;
  gender: "male" | "female" | null;
  isUltimateBeneficialOwner: boolean;
  isOrganizationRepresentative: boolean;
  sharesCount: number | null;
  user: Omit<
    User,
    "individualOrganization" | "organizationsOwned" | "wmaProfile"
  > | null;
  documents: OrganizationPersonDocument[];
};

export type OrganizationMember = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  status: "invited" | "accepted";
  inviter: OrganizationPerson;
  organization: Organization;
  user: User;
};

export type CompanyInformation = {
  legalName?: string;
  legalStatus: string;
  capital: number;
  capitalInCents: number;
  capitalCurrency: string;
  registrationLocation: Address | null;
  headOfficeAddress: Address | null;
  registrationNumbers: {
    siret?: string;
    siren?: string;
    rcs?: string;
  };
  companyObject?: string;
  businessArea?: OrganizationBusinessAreaEnum;
  foundationDate?: string;
  financialYearEnd?: string;
};

/* eslint-disable-next-line */
export type IndividualInformation = OrganizationPerson & {};

export enum InternalKycStatus {
  SHOULD_BE_COMPLETED = "should_be_completed",
  SUBMITTED_AND_WAITING_APPROVAL = "submitted_and_waiting_approval",
  REJECTED = "rejected",
  APPROVED = "approved",
  TO_SYNCHRONIZE = "to_synchronize",
}

export type SocialLinks = {
  discord?: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  tiktok?: string;
  x?: string;
};

export type Organization = {
  id: string;
  name: string;
  website: string;
  logo: Image | null;
  companyInformation: CompanyInformation | null;
  individualInformation: IndividualInformation | null;
  type: "company" | "individual" | "operator";
  persons: OrganizationPerson[];
  ultimateBeneficialOwners: OrganizationPerson[];
  organizationBankAccounts: OrganizationBankAccount[];
  organizationRepresentative: OrganizationPerson;
  pmsAccount: PmsAccount | null;
  documents: OrganizationDocument[];
  internalKycStatus: InternalKycStatus | null;
  equitySavingPlan: EquitySavingPlan | null;
  smallBusinessEquitySavingPlan: EquitySavingPlan | null;
  socialLinks: SocialLinks | null;
  readyToSubscribe: boolean;
  members: Omit<OrganizationMember, "organization">[];
  businessCategories: string[] | null;
};

export type OrganizationBankAccount = {
  id?: string;
  bankAccountNumber: string;
  holderName: string;
  document: Document;
  pmsFile?: PmsFile;
};

export type OrganizationPersonDocument = {
  id?: string;
  organizationPerson?: {
    id: OrganizationPerson["id"];
  };
  document: Document;
  type: string;
  pmsFile?: PmsFile;
};

export type OrganizationDocument = {
  id?: string;
  organization?: {
    id: Organization["id"];
  };
  document: Document;
  type: string;
  pmsFile?: PmsFile;
};
