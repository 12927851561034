import React from "react";
import {
  useHitsPerPage,
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  Alert,
  Button,
  DotsLoader,
} from "@tudigo-monorepo/web-tudigo-components";
import {
  mixpanelTrackPageView,
  useTrack,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useProjectsSearchResults } from "./../hooks/use-projects-search-results";
import { CustomHits } from "./custom-hits";
import { EmptyResults } from "./empty-results";
import { ProjectsSection } from "./projects-section";

const ProjectsPageMainContent = ({ children }: React.PropsWithChildren) => {
  useTrack(() => {
    mixpanelTrackPageView("ui.projects_listing_page.view", {});

    return true;
  }, []);

  return (
    <main className="flex h-full w-full flex-col gap-y-6 py-6">{children}</main>
  );
};

const ProjectsPageMainContentLoader = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;

  return <DotsLoader className="mx-auto" />;
};

const ProjectsPageMainContentResults = () => {
  const { isError, hasQueryResults } = useProjectsSearchResults();
  const { hits, isLastPage, showMore } = useInfiniteHits({
    showPrevious: false,
  });

  useHitsPerPage({
    items: [{ label: "", value: 12, default: true }],
  });

  const isVisible = hasQueryResults && !isError;

  return (
    <div className={cn("flex flex-col gap-y-6", { hidden: !isVisible })}>
      <CustomHits hits={hits as any} />

      {!isLastPage && (
        <Button
          onClick={showMore}
          label="Afficher plus"
          variant="tertiary"
          className="mx-auto w-fit"
        />
      )}
    </div>
  );
};

const ProjectsPageMainContentEmptyResults = () => {
  const { hasEmptyResults } = useProjectsSearchResults();
  const { data } = useGetProjectsQuery({
    filters: { status: "started" },
  });

  if (!data) return null;

  return (
    <EmptyResults visible={hasEmptyResults} suggestedProjects={data?.data} />
  );
};

const ProjectsPageMainContentFallback = () => {
  const { error } = useInstantSearch();

  if (!error) return null;

  return (
    <Alert
      variant="error"
      title="Une erreur est survenue. Veuillez réessayer ultérieurement"
      icon="Error"
      className="[&_*]:text-error items-center"
    />
  );
};

const ProjectsPageMainContentProjectsGallery = () => {
  const { hasQueryResults, hasEmptyResults } = useProjectsSearchResults();
  const isVisible = !hasQueryResults && !hasEmptyResults;

  if (!isVisible) return null;

  return (
    <>
      <ProjectsSection name="ongoing" limit={12} />
      <ProjectsSection name="coming" limit={3} />
      <ProjectsSection name="finished" limit={3} />
    </>
  );
};

ProjectsPageMainContent.Loader = ProjectsPageMainContentLoader;
ProjectsPageMainContent.Results = ProjectsPageMainContentResults;
ProjectsPageMainContent.EmptyResults = ProjectsPageMainContentEmptyResults;
ProjectsPageMainContent.Fallback = ProjectsPageMainContentFallback;
ProjectsPageMainContent.ProjectsGallery =
  ProjectsPageMainContentProjectsGallery;

export { ProjectsPageMainContent };
