import React from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { reorder } from "@tudigo-monorepo/core-tudigo-utils";
import { SmartField } from "@tudigo-monorepo/web-feature-form";

import { EditorActionsBar } from "./editor/editor-actions-bar";
import { EditorContainer } from "./editor/editor-container";
import { EditorContextualPanel } from "./editor/editor-contextual-panel";
import { EditorPreview } from "./editor/editor-preview";
import { EditorTools } from "./editor/editor-tools";
import { useEditorContext } from "./store/editor-context";
import { EditorProvider } from "./store/editor-provider";
import { EditorPlugin } from "./types";
import { createBlockFromPlugin } from "./utils/create-block";

export interface EditorProps<T> {
  field: SmartField;
  plugins?: EditorPlugin<any, any, T>[];
  children?: React.ReactNode;
  context?: T;
  onSave: () => void;
}

function EditorMain<T>({ plugins }: { plugins: EditorPlugin<any, any, T>[] }) {
  const {
    content,
    setContent,
    settings: { previewMode },
  } = useEditorContext();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || previewMode) {
      return;
    }

    if (source.droppableId === "editor-tools") {
      const selectedPlugin = plugins[source.index];
      const newBlock = createBlockFromPlugin(selectedPlugin);
      if (newBlock) {
        const newContent = [...content, newBlock];
        setContent(
          reorder(newContent, newContent.length - 1, destination.index),
        );
      }
    }

    if (source.droppableId === "preview") {
      setContent(reorder(content, source.index, destination.index));
    }
  };

  return (
    <main className="editor-blocks bg-light-1 flex w-full flex-1 items-start overflow-hidden">
      <DragDropContext onDragEnd={onDragEnd}>
        <EditorTools />
        <EditorPreview />
      </DragDropContext>
      <EditorContextualPanel />
    </main>
  );
}

function Editor<T>(props: EditorProps<T>) {
  const { field, plugins = [], onSave, children, context } = props;

  return (
    <EditorProvider field={field} plugins={plugins} context={context}>
      {children}
      <EditorContainer>
        <EditorActionsBar onSave={onSave} />
        <EditorMain plugins={plugins} />
        {/* <EditorDebugger /> */}
      </EditorContainer>
    </EditorProvider>
  );
}

Editor.displayName = "Editor";

export { Editor };
