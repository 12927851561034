import {
  Assembly,
  AssemblyConfiguration,
  AssemblyDocument,
  AssemblyParticipant,
  AssemblyTopic,
  Organization,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface UpdateAssemblyParticipantBody {
  participationChannel?: AssemblyParticipant["participationChannel"];
  proxyType?: AssemblyParticipant["proxyType"];
}

export interface AssemblyVoteRequestBody {
  assemblyParticipant: {
    id: AssemblyParticipant["id"];
  };
  answers: AssemblyVoteRequestAnswer[];
}

export type AssemblyVoteRequestAnswer = {
  assembly_topic: {
    id: string;
  };
  choices: string[];
};

export type CreateAssemblyRequestBody = {
  name?: string;
  organization?: Organization | null;
  documents?: AssemblyDocument[] | [];
  myParticipation?: AssemblyParticipant | null;
  configuration?: AssemblyConfiguration | null;
  topics?: AssemblyTopic[] | [];
};

export type SendVoteReminderRequestBody = {
  assemblyId: Assembly["id"];
  participantId?: AssemblyParticipant["id"];
};

export type PatchAssemblyRequestBody = {
  id: string;
  name?: string;
  organization?: Organization | null;
  documents?: AssemblyDocument[] | [];
  myParticipation?: AssemblyParticipant | null;
  configuration?: AssemblyConfiguration | null;
  topics?: AssemblyTopic[] | [];
};

export type PublishAssemblyRequestBody = {
  id: string;
};

export type CloseAssemblyRequestBody = {
  id: string;
};

export const assembliesEndpoints = (request: Request) => ({
  getAssemblies: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Assembly[]>, void>({
      path: `/api/v1/assemblies`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getAssemblyParticipants: (
    assemblyId: Assembly["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<AssemblyParticipant[]>, void>({
      path: `/api/v1/assemblies/${assemblyId}/participants`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getAssembliesForOrganization: (
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Assembly[]>, void>({
      path: `/api/v1/organizations/${organizationId}/assemblies`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getUserAssemblyParticipationForOrganization: (
    userId: User["id"] | "me",
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<AssemblyParticipant[]>, void>({
      path: `/api/v1/users/${userId}/organizations/${organizationId}/assembly_participants`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getOrganizationAssemblyParticipation: (
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<AssemblyParticipant[]>, void>({
      path: `/api/v1/organizations/${organizationId}/assembly-participants`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getAssemblyById: (
    assemblyId: Assembly["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Assembly>, void>({
      path: `/api/v1/assemblies/${assemblyId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getAssemblyParticipantById: (
    assemblyId: Assembly["id"],
    assemblyParticipantId: AssemblyParticipant["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<AssemblyParticipant>, void>({
      path: `/api/v1/assemblies/${assemblyId}/participants/${assemblyParticipantId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateAssemblyParticipant: (
    assemblyId: Assembly["id"],
    participantId: AssemblyParticipant["id"],
    body: UpdateAssemblyParticipantBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<User>, void>({
      path: `/api/v1/assemblies/${assemblyId}/participants/${participantId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createAssembly: (
    body: CreateAssemblyRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Assembly>, void>({
      path: `/api/v1/assemblies`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  patchAssembly: (body: PatchAssemblyRequestBody, params: RequestParams = {}) =>
    request<TudigoResponse<Assembly>, void>({
      path: `/api/v1/assemblies/${body.id}`,
      method: "PATCH",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  publishAssembly: (
    body: PublishAssemblyRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Assembly>, void>({
      path: `/api/v1/assemblies/${body.id}/publish-assembly-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  closeAssembly: (body: CloseAssemblyRequestBody, params: RequestParams = {}) =>
    request<TudigoResponse<Assembly>, void>({
      path: `/api/v1/assemblies/${body.id}/close-assembly-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  requestAssemblyVote: (
    assemblyId: User["id"] | "me",
    body: AssemblyVoteRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<User>, void>({
      path: `/api/v1/assemblies/${assemblyId}/vote-request`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  requestAssemblyVoteReminderNotification: (
    body: SendVoteReminderRequestBody,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<AssemblyParticipant>, void>({
      path: `/api/v1/assemblies/${body.assemblyId}/send-vote-reminder-request`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
