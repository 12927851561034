import { CMSBlock } from "@tudigo-monorepo/core-tudigo-api-models";

import { EditorPlugin } from "../types";

export const generateBlockId = () => {
  const timestamp = Date.now().toString(36); // Convert to base36 for shorter string
  const randomPart = Math.random().toString(36).substring(2, 10); // Remove '0.' at the start

  return `${timestamp}-${randomPart}`;
};

export const createBlockFromPlugin = (
  plugin: EditorPlugin<any, any, any>,
): CMSBlock => {
  const copy = JSON.parse(JSON.stringify(plugin.shape));

  return {
    ...copy,
    id: generateBlockId(),
  };
};
