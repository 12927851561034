import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Pen(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_432_36632)">
        <g clipPath="url(#clip1_432_36632)">
          <path
            d="M13.5 6.50024L17.5 10.5002M4 20.0002H8L18.5 9.50024C19.0304 8.96981 19.3284 8.25039 19.3284 7.50024C19.3284 6.7501 19.0304 6.03067 18.5 5.50024C17.9696 4.96981 17.2501 4.67181 16.5 4.67181C15.7499 4.67181 15.0304 4.96981 14.5 5.50024L4 16.0002V20.0002Z"
            stroke={primaryColor}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_432_36632">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_432_36632">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
