import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";

import { ClubLeader } from "@tudigo-monorepo/core-tudigo-api-models";

import { LeaderElement, LeaderElementProps } from "./leader-element";

type LeaderItemsProps = {
  leaders: ClubLeader[];
  setLeaders: (leaders: ClubLeader[]) => void;
  deleteLeader: (index: number) => void;
  setLeader: (leader: ClubLeader, index: number) => void;
};

const SortableItem = SortableElement<LeaderElementProps & { position: number }>(
  (props: LeaderElementProps & { position: number }) => (
    <LeaderElement {...props} index={props.position} />
  ),
);

const SortableList = SortableContainer<LeaderItemsProps>(
  (props: LeaderItemsProps) => {
    return (
      <div className="flex flex-row flex-wrap gap-6 overflow-y-auto">
        {props.leaders?.map((leader, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            leader={{
              ...leader,
            }}
            {...props}
          />
        ))}
      </div>
    );
  },
);

export function LeaderItems(props: LeaderItemsProps) {
  const { setLeaders, leaders } = props;
  const handleSortEnd = (sort: SortEnd) => {
    const newOrder = arrayMove(leaders, sort.oldIndex, sort.newIndex);

    const updatedLeaders = newOrder.map((leader, index) => ({
      ...leader,
      position: index,
    }));

    setLeaders(updatedLeaders);
  };

  return (
    <div>
      <SortableList
        {...props}
        useDragHandle
        axis="y"
        onSortEnd={handleSortEnd}
      />
    </div>
  );
}
