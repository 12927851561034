import type { ReactNode } from "react";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { ButtonRebrand } from "@tudigo-monorepo/web-tudigo-components";

import type { AlgoliaRefinementAttributesValue } from "../../models/algolia-refinement-attributes";

export function CustomClearRefinements(props: {
  values?: AlgoliaRefinementAttributesValue[];
  children?: ReactNode;
  className?: string;
}) {
  const { canRefine } = useCurrentRefinements();
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: props.values,
  });

  if (!canRefine) return null;

  return (
    <ButtonRebrand
      onClick={clearRefinements}
      variant="ghost"
      className={cn(
        "flex h-full items-center gap-x-2 py-3 pl-1 pr-3 transition-none hover:bg-inherit focus-visible:ring-0",
        props.className,
      )}
    >
      {props.children}
    </ButtonRebrand>
  );
}
