import React, { createContext, ReactNode, useContext } from "react";

import { Investment } from "@tudigo-monorepo/core-tudigo-api-models";

interface InvestmentQuickActionsContextType {
  investment: Investment;
  closeModal: () => void;
}

const InvestmentQuickActionsContext = createContext<
  InvestmentQuickActionsContextType | undefined
>(undefined);

export const useInvestmentQuickActionsContext = () => {
  const context = useContext(InvestmentQuickActionsContext);
  if (context === undefined) {
    throw new Error(
      "useInvestmentQuickActionsContext must be used within an InvestmentQuickActionsProvider",
    );
  }

  return context;
};

export const InvestmentQuickActionsProvider = ({
  children,
  investment,
  closeModal,
}: {
  children: ReactNode;
  investment: Investment;
  closeModal: () => void;
}) => {
  return (
    <InvestmentQuickActionsContext.Provider value={{ investment, closeModal }}>
      {children}
    </InvestmentQuickActionsContext.Provider>
  );
};
