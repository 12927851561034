import { Fragment, useState } from "react";

import { ProjectDisplayedStatusFilter } from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetProjectsQuery } from "@tudigo-monorepo/web-tudigo-api-client";
import { TudigoCollection } from "@tudigo-monorepo/web-tudigo-collection";
import {
  ProjectCard,
  ProjectCardSkeleton,
} from "@tudigo-monorepo/web-tudigo-components";

import { projectCardsGridClassName } from "../utils/classname-utils";
import { ProjectsSectionName } from "../utils/models";

type ProjectsSectionProps = {
  name: ProjectsSectionName;
  limit: number;
};

const sectionNameToStatusFilter: Record<
  ProjectsSectionName,
  ProjectDisplayedStatusFilter | ProjectDisplayedStatusFilter[]
> = {
  ongoing: "started",
  coming: "scheduled",
  finished: ["prefinished", "finished"],
};

export function ProjectsSection(props: ProjectsSectionProps) {
  const { name, limit: defaultLimit } = props;

  const { currentPagePaths } = useSpaceBusiness();
  const [limit, setLimit] = useState(defaultLimit);

  const { t } = useTranslation();

  const sectionNames = {
    ongoing: t("projects.section.label.ongoing"),
    coming: t("projects.section.label.coming"),
    finished: t("projects.section.label.finished"),
  };

  const pagination = { pageSize: limit, pageIndex: 0 };
  const statusFilter = sectionNameToStatusFilter[name];

  const incrementLimit = () => {
    setLimit((prevLimit) => prevLimit + defaultLimit);
  };

  return (
    <TudigoCollection
      columns={[]}
      actions={[]}
      useQueryFn={useGetProjectsQuery}
      hidePagination
      pagination={pagination}
      showMore={{ onClick: incrementLimit }}
      filters={{ status: statusFilter }}
      renderEmpty={() => (
        <section className="flex flex-col gap-y-6">
          <h3 className="font-montserrat text-primary text-lg font-bold">
            {sectionNames[name]}
          </h3>
          <p className="font-montserrat text-primary">
            {t("projects.section.empty.label")}
          </p>
        </section>
      )}
      render={(table) => (
        <section className="flex flex-col gap-y-6">
          <h3 className="font-montserrat text-primary text-lg font-bold">
            {sectionNames[name]}
          </h3>
          <div className={projectCardsGridClassName}>
            {table.getRowModel().rows.length === 0 && (
              <Fragment>
                {new Array(8).fill(null).map((_, index) => (
                  <ProjectCardSkeleton
                    key={index}
                    className="md:min-w-[294px]"
                  />
                ))}
              </Fragment>
            )}
            {table.getRowModel().rows?.map(({ original: project }) => {
              return (
                <ProjectCard
                  key={project.id}
                  project={project}
                  navigateTo={`${currentPagePaths[PageEnum.PROJECT].replace(
                    ":projectId",
                    project.content?.slug ?? project.id,
                  )}`}
                />
              );
            })}
          </div>
        </section>
      )}
    />
  );
}
