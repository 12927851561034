import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  Carousel,
  ProjectCard,
  Title,
} from "@tudigo-monorepo/web-tudigo-components";

type ProjectsCarouselProps = {
  projects: Project[];
  visible: boolean;
};

const ProjectsCarousel = ({ projects, visible }: ProjectsCarouselProps) => {
  const { currentPagePaths } = useSpaceBusiness();

  if (!visible) return null;

  return (
    <div className="flex w-full flex-col items-center rounded-lg bg-white px-4 py-8 sm:px-[72px]">
      <Title level="h2">Vous pourriez être intéressé par </Title>
      <Carousel slidesToShow="auto" arrowsPlacement="above" autoplay infinite>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            smallCard
            navigateTo={currentPagePaths[PageEnum.PROJECT].replace(
              ":projectId",
              project.content?.slug ?? project.id,
            )}
            project={project}
            className="my-4 sm:min-w-[264px]"
          />
        ))}
      </Carousel>
    </div>
  );
};

export { ProjectsCarousel };
