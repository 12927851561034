import { WMAProfile } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const wmaProfilesEndpoints = (request: Request) => ({
  createWMAProfile: (body: WMAProfile, params: RequestParams = {}) =>
    request<TudigoResponse<WMAProfile>, void>({
      path: "/api/v1/wma-profiles",
      method: "POST",
      body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  deleteWMAProfile: (body: WMAProfile, params: RequestParams = {}) =>
    request<TudigoResponse<WMAProfile>, void>({
      path: `/api/v1/wma-profiles/${body.id}`,
      method: "DELETE",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
