import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Desktop(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5208 16H12.7075H12.2075H13.5208ZM13.5208 16C13.6643 15.9985 13.8029 16.052 13.9083 16.1495L13.5208 16ZM6.9575 16.5H7.4575V16V14.1265V13.6265H6.9575H3.77075H3.77069C3.46979 13.6265 3.1801 13.5123 2.96015 13.307C2.74222 13.1035 2.60887 12.8256 2.58642 12.5285L2.58325 12.4309V4.5625V4.56244C2.58321 4.26154 2.69741 3.97184 2.90275 3.7519C3.10623 3.53396 3.38416 3.40062 3.68127 3.37817L3.77887 3.375H15.395H15.3951C15.696 3.37496 15.9857 3.48916 16.2056 3.6945C16.4235 3.89798 16.5569 4.17591 16.5793 4.47303L16.5825 4.57062V12.439V12.4391C16.5825 12.74 16.4683 13.0297 16.263 13.2496C16.0595 13.4675 15.7816 13.6009 15.4845 13.6233L15.3869 13.6265H12.2075H11.7075V14.1265V16V16.5H12.2075H13.5208V16.5L13.5258 16.5C13.5418 16.4998 13.5572 16.5058 13.5689 16.5166C13.5806 16.5274 13.5877 16.5423 13.5888 16.5582C13.5899 16.5741 13.5849 16.5899 13.5747 16.6022C13.5664 16.6123 13.5552 16.6195 13.5426 16.6228L13.5061 16.625H5.64591C5.63007 16.625 5.61483 16.619 5.60325 16.6082C5.59168 16.5974 5.58465 16.5826 5.58357 16.5668C5.58249 16.551 5.58745 16.5353 5.59744 16.5231C5.60563 16.513 5.61669 16.5058 5.62904 16.5023L5.66289 16.5H6.9575ZM11.5816 13.6265H11.0818H8.08175H7.58155L7.58175 14.1267L7.5825 16.0002L7.5827 16.5H8.0825H11.0825H11.5827L11.5825 15.9998L11.5818 14.1263L11.5816 13.6265Z"
        stroke={primaryColor}
      />
    </svg>
  );
}
