import {
  BondProjectSimulatorQuery,
  ShareProjectSimulatorQuery,
  Simulator,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const simulatorEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getSimulatorById
   * @summary get simulator
   * @request GET:/api/v1/simulator/:projectId
   * @secure
   */
  getSimulatorById: (
    projectId: string,
    query: ShareProjectSimulatorQuery | BondProjectSimulatorQuery,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Simulator>, void>({
      path: `/api/v1/simulator/${projectId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
