import {
  Club,
  ClubLeaderSpeciality,
  ClubMember,
  ClubV2,
  Industry,
  Investment,
  Mentoring,
  SubscriptionPeriod,
  Territory,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export type PublishCircleRequestBody = {
  id: string;
};

export const clubsEndPoints = (request: Request) => ({
  getClubs: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Club[]>, void>({
      path: `/api/v1/clubs`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getClubsV2: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubV2[]>, void>({
      path: `/api/v1/clubs`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getSubscriptionPeriods: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<SubscriptionPeriod[]>, void>({
      path: `/api/v1/subscription-periods`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getUserClubMembers: (
    userId: User["id"],
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubMember[]>, void>({
      path: `/api/v1/users/${userId}/club-members`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getClubMembers: (
    clubId: Club["id"],
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubMember[]>, void>({
      path: `/api/v1/clubs/${clubId}/members`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getCircleSubscriptionPeriods: (
    circleId: Club["id"],
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<SubscriptionPeriod[]>, void>({
      path: `/api/v1/clubs/${circleId}/subscription-periods`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getCircleInvestments: (
    circleId: Club["id"],
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Investment[]>, void>({
      path: `/api/v1/clubs/${circleId}/investments`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getCirclesMembers: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubMember[]>, void>({
      path: `/api/v1/circles/members`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getTerritories: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Territory[]>, void>({
      path: `/api/v1/territories`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getMentorings: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Mentoring[]>, void>({
      path: `/api/v1/mentorings`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getIndustries: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Industry[]>, void>({
      path: `/api/v1/industries`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getSpecialities: (
    query?: {
      fields?: string;
      offset?: number;
      limit?: number;
      filters?: any;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubLeaderSpeciality[]>, void>({
      path: `/api/v1/specialties`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getClubById: ({
    clubId,
    query,
    params,
  }: {
    clubId: string;
    query?: Record<string, string>;
    params?: RequestParams;
  }) =>
    request<TudigoResponse<Club>, void>({
      path: `/api/v1/clubs/${clubId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getClubV2ById: ({
    clubId,
    query,
    params,
  }: {
    clubId: string;
    query?: Record<string, string>;
    params?: RequestParams;
  }) => {
    return request<TudigoResponse<ClubV2>, void>({
      path: `/api/v1/clubs/${clubId}`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  },

  /**
   * No description
   *
   * @name updateClub
   * @summary updateClub
   * @request PUT:/api/v2/clubs/:clubId
   * @secure
   */
  updateClub: (
    clubId: ClubV2["id"],
    body: ClubV2,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubV2>, void>({
      path: `/api/v2/clubs/${clubId}`,
      method: "PUT",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name createClub
   * @summary createClub
   * @request PUT:/api/v2/clubs
   * @secure
   */
  createClub: (
    body: ClubV2,
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubV2>, void>({
      path: `/api/v2/clubs`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  createSubscription: ({
    clubId,
    query,
  }: {
    clubId: string;
    query?: {
      fields?: string;
    };
  }) =>
    request<TudigoResponse<ClubMember>, void>({
      path: `/api/v1/clubs/${clubId}/members`,
      method: "POST",
      query,
      secure: true,
      body: { role: "" },
      type: ContentType.Json,
    }),

  unsubscribeClubMembers: ({ clubMemberId }: { clubMemberId: string }) =>
    request<TudigoResponse<boolean>, void>({
      path: `/api/v1/club-members/${clubMemberId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
    }),

  requestSubscriptionSync: ({ subscriptionId }: { subscriptionId: string }) =>
    request<TudigoResponse<ClubMember>, void>({
      path: `/api/v1/pms-subscriptions/${subscriptionId}/sync-request`,
      method: "POST",
      secure: true,
      type: ContentType.Json,
    }),

  getCircleMemberById: ({
    clubId,
    clubMemberId,
    query,
  }: {
    clubId: string;
    clubMemberId: string;
    query?: {
      fields?: string;
    };
  }) =>
    request<TudigoResponse<ClubMember>, void>({
      path: `/api/v1/clubs/${clubId}/members/${clubMemberId}`,
      method: "GET",
      query,
      secure: true,
      body: { role: "" },
      type: ContentType.Json,
    }),

  createCircleMember: (
    circleId: string,
    body: {
      status: ClubMember["status"];
    },
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubMember>, void>({
      path: `/api/v2/circles/${circleId}/members`,
      method: "POST",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateCircleMember: (
    circleMemberId: string,
    body: {
      status: ClubMember["status"];
    },
    query?: {
      fields?: string;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ClubMember>, void>({
      path: `/api/v2/circle-members/${circleMemberId}`,
      method: "PATCH",
      body: body,
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  publishCircle: (body: PublishCircleRequestBody, params: RequestParams = {}) =>
    request<TudigoResponse<ClubV2>, void>({
      path: `/api/v1/clubs/${body.id}/publish-club-request`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
