import { Lead } from "@tudigo-monorepo/core-tudigo-api-models";
import { localStorageHandler } from "@tudigo-monorepo/core-tudigo-utils";

const LOCAL_STORAGE_KEY = "subscribed_upcoming_projects";

interface ProjectNotificationsSubscription {
  email: string;
  leadId: string;
  projectId: string;
}

const getLocalStorageSubscribedProjects = (): Lead[] => {
  return localStorageHandler.get(LOCAL_STORAGE_KEY) ?? [];
};

const checkIfProjectIsSubscribed = (projectId: string) => {
  return (
    getLocalStorageSubscribedProjects().find(
      (sub) => sub.refId === projectId,
    ) !== undefined
  );
};

export const useVisitorProjectNotificationsSubscription = () => {
  const saveVisitorSubscribedProject = ({
    email,
    leadId,
    projectId,
  }: ProjectNotificationsSubscription) => {
    const isSubscribedProject = checkIfProjectIsSubscribed(projectId);
    const subscribedProjectsIds = getLocalStorageSubscribedProjects();

    if (!isSubscribedProject) {
      const updatedSubs = [
        ...subscribedProjectsIds,
        {
          email,
          id: leadId,
          refId: projectId,
        },
      ];

      localStorageHandler.set(LOCAL_STORAGE_KEY, updatedSubs);
    }
  };

  const removeVisitorSubscribedProject = ({
    leadId,
    projectId,
  }: Omit<ProjectNotificationsSubscription, "email">) => {
    const isSubscribedProject = checkIfProjectIsSubscribed(projectId);
    const subscribedProjectsIds = getLocalStorageSubscribedProjects();

    if (isSubscribedProject) {
      const updatedSubs = subscribedProjectsIds.filter(
        (sub) => sub.refId !== projectId && sub.id !== leadId,
      );

      localStorageHandler.set(LOCAL_STORAGE_KEY, updatedSubs);
    }
  };

  return {
    saveVisitorSubscribedProject,
    removeVisitorSubscribedProject,
    getVisitorSubscribedProjects: getLocalStorageSubscribedProjects,
  };
};
