import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Filter(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = 20,
    height = 20,
    size = "XS",
  } = props;
  const widthToApply = size ? iconSizes[size] : width;
  const heightToApply = size ? iconSizes[size] : height;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: widthToApply, minHeight: heightToApply }}
      width={widthToApply}
      height={heightToApply}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 10C11.1471 10 12.0769 9.07013 12.0769 7.92308C12.0769 6.77602 11.1471 5.84615 10 5.84615C8.85295 5.84615 7.92308 6.77602 7.92308 7.92308C7.92308 9.07013 8.85295 10 10 10Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.07692 16.2308C4.22398 16.2308 5.15385 15.3009 5.15385 14.1538C5.15385 13.0068 4.22398 12.0769 3.07692 12.0769C1.92987 12.0769 1 13.0068 1 14.1538C1 15.3009 1.92987 16.2308 3.07692 16.2308Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9231 12.0769C18.0701 12.0769 19 11.1471 19 10C19 8.85295 18.0701 7.92308 16.9231 7.92308C15.776 7.92308 14.8462 8.85295 14.8462 10C14.8462 11.1471 15.776 12.0769 16.9231 12.0769Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.07692 19V16.2308"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.07692 10V1"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3.76923V1"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9231 5.84615V1"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 19V10"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9231 19V12.0769"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
