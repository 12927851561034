import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Phone(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9688 2.5835C13.3086 2.5835 13.6344 2.71848 13.8747 2.95877C14.115 3.19905 14.25 3.52494 14.25 3.86475V16.1356C14.25 16.4754 14.115 16.8013 13.8747 17.0416C13.6344 17.2818 13.3086 17.4168 12.9688 17.4168H7.03125C6.69144 17.4168 6.36555 17.2818 6.12527 17.0416C5.88499 16.8013 5.75 16.4754 5.75 16.1356V3.86475C5.75 3.52494 5.88499 3.19905 6.12527 2.95877C6.36555 2.71848 6.69144 2.5835 7.03125 2.5835H12.9688ZM8.23702 14.5705L8.59057 14.9241L8.23702 14.5705C8.0319 14.7756 7.91667 15.0538 7.91667 15.3439C7.91667 15.634 8.0319 15.9122 8.23702 16.1173C8.44214 16.3224 8.72034 16.4377 9.01042 16.4377H10.9896C11.2797 16.4377 11.5579 16.3224 11.763 16.1173C11.9681 15.9122 12.0833 15.634 12.0833 15.3439C12.0833 15.0538 11.9681 14.7756 11.763 14.5705C11.5579 14.3654 11.2797 14.2502 10.9896 14.2502H9.01042C8.72034 14.2502 8.44214 14.3654 8.23702 14.5705Z"
        stroke={primaryColor}
      />
    </svg>
  );
}
