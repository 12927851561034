import { createContext, useContext } from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";

export const ProjectContext = createContext<null | Project>(null);

export function useProjectContext() {
  const context = useContext(ProjectContext);

  if (!context) return null;

  return context;
}
