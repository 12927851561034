import React from "react";
import { Placement } from "@popperjs/core";
import { usePopper } from "react-popper";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useOutClickBox } from "../shared/hooks/use-out-click-box/use-out-click-box";
import { usePopperContext } from "./context";

type PopperContentProps = {
  as?: React.ElementType;
  className?: string;
  offset?: { x?: number; y?: number };
  placement?: Placement;
};

export function PopperContent(
  props: React.PropsWithChildren<PopperContentProps>,
) {
  const {
    children,
    className,
    as = "div",
    offset = { x: 0, y: 0 },
    placement = "auto",
  } = props;

  const { isOpen, refElement, setIsOpen, clickable } = usePopperContext();

  const popperElement = React.useRef<HTMLElement | null>(null);
  useOutClickBox({
    ref: popperElement,
    onOutClick: () => setIsOpen(false),
    enabled: clickable && isOpen,
  });

  const { styles, attributes, update } = usePopper(
    refElement,
    popperElement?.current,
    {
      placement,
      strategy: "fixed",
      modifiers: [
        { name: "offset", options: { offset: [offset.x, offset.y] } },
      ],
    },
  );

  React.useEffect(() => {
    if (update) {
      update();
    }
  }, [isOpen, update]);

  const Container = as;

  return (
    <Container
      ref={popperElement}
      style={styles.popper}
      onMouseEnter={() => !clickable && setIsOpen(true)}
      onMouseLeave={() => !clickable && setIsOpen(false)}
      className={cn(
        "z-50 rounded-lg bg-white px-6 py-4 shadow-2xl",
        { hidden: !isOpen },
        isOpen && className,
      )}
      {...attributes.popper}
    >
      {children}
    </Container>
  );
}
