import { useMemo } from "react";
import { useCurrentRefinements } from "react-instantsearch";

import {
  RefinementAttributesValue,
  refinementValueToKey,
  type RefinementAttributesKey,
} from "./../models/algolia-refinement-attributes";

type RefinementItem = {
  attribute: RefinementAttributesValue;
  count: number;
  exhaustive: boolean;
  label: string;
  type: string;
  value: string;
};

type CurrentRefinements = Record<
  RefinementAttributesKey,
  { refinements: RefinementItem[]; hasRefinements: boolean }
>;

export const useProjectsRefinements = () => {
  const { items: refinements, canRefine } = useCurrentRefinements();

  const currentRefinements = useMemo(() => {
    return refinements.reduce(
      (prevRefinements, currentRefinement) => {
        const key =
          refinementValueToKey[
            currentRefinement.attribute as RefinementAttributesValue
          ];

        prevRefinements[key] = {
          refinements: currentRefinement.refinements as RefinementItem[],
          hasRefinements: currentRefinement.refinements.length > 0,
        };

        return prevRefinements;
      },
      {
        INSTRUMENT: { refinements: [], hasRefinements: false },
        TAX_EXEMPTION: { refinements: [], hasRefinements: false },
        STATE: { refinements: [], hasRefinements: false },
        MATURITY: { refinements: [], hasRefinements: false },
        CATEGORY: { refinements: [], hasRefinements: false },
      } as CurrentRefinements,
    );
  }, [refinements]);

  const totalRefinementsCount = useMemo(
    () => refinements.flatMap(({ refinements }) => refinements).length,
    [refinements],
  );

  return {
    currentRefinements,
    totalRefinementsCount,
    hasRefinements: canRefine,
  };
};
