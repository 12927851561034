import {
  createContext,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";
import { useConfigure } from "react-instantsearch";

import {
  algoliaProjectsStatusFilter,
  type AlgoliaProjectsStatusFilter,
  type AlgoliaRefinementAttributesKey,
} from "../models/algolia-refinement-attributes";

type SearchProjectsContextType = {
  refinements: AlgoliaRefinementAttributesKey[];
  status: AlgoliaProjectsStatusFilter;
  isRefinementsMenuOpen: boolean;
  setIsRefinementsMenuOpen: (open: boolean) => void;
};

const SearchProjectsContext = createContext<SearchProjectsContextType | null>(
  null,
);

const useSearchProjectsContext = () => {
  const context = useContext(SearchProjectsContext);

  if (!context) {
    throw new Error(
      "useSearchProjectsContext must be used within a SearchProjectsProvider",
    );
  }

  return context;
};

const SearchProjectsProvider = ({
  children,
  refinements,
  status,
}: PropsWithChildren<
  Pick<SearchProjectsContextType, "refinements" | "status">
>) => {
  useConfigure({ filters: algoliaProjectsStatusFilter[status] });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const contextValue = useMemo(
    () => ({
      refinements,
      status,
      isRefinementsMenuOpen: isMenuOpen,
      setIsRefinementsMenuOpen: setIsMenuOpen,
    }),
    [refinements, status, isMenuOpen, setIsMenuOpen],
  );

  return (
    <SearchProjectsContext.Provider value={contextValue}>
      {children}
    </SearchProjectsContext.Provider>
  );
};

export { SearchProjectsProvider, useSearchProjectsContext };
