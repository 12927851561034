import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function PaperIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill={primaryColor}
        d="M17.75 2.17291C18.3201 2.17284 18.869 2.38921 19.2858 2.77828C19.7025 3.16736 19.956 3.70012 19.995 4.26891L20 4.42191V19.9199C20.0001 20.49 19.7837 21.0389 19.3946 21.4557C19.0056 21.8724 18.4728 22.1259 17.904 22.1649L17.75 22.1699H6.25C5.67987 22.17 5.13098 21.9536 4.71425 21.5645C4.29751 21.1755 4.04402 20.6427 4.005 20.0739L4 19.9219V4.42291C3.99993 3.85279 4.2163 3.30389 4.60537 2.88716C4.99444 2.47043 5.52721 2.21693 6.096 2.17791L6.25 2.17291H17.75ZM17.75 3.67291H6.25C6.06876 3.67292 5.89366 3.73856 5.75707 3.85768C5.62048 3.9768 5.53165 4.14136 5.507 4.32091L5.5 4.42291V19.9209C5.5 20.3009 5.782 20.6149 6.148 20.6639L6.25 20.6709H17.75C17.9312 20.6709 18.1063 20.6053 18.2429 20.4861C18.3795 20.367 18.4684 20.2025 18.493 20.0229L18.5 19.9209V4.42291C18.5 4.24167 18.4344 4.06657 18.3152 3.92998C18.1961 3.79339 18.0316 3.70456 17.852 3.67991L17.75 3.67191V3.67291Z"
      />
      <path
        fill={primaryColor}
        d="M7 15.9219C7 15.723 7.07902 15.5322 7.21967 15.3916C7.36032 15.2509 7.55109 15.1719 7.75 15.1719H16.25C16.4489 15.1719 16.6397 15.2509 16.7803 15.3916C16.921 15.5322 17 15.723 17 15.9219C17 16.1208 16.921 16.3116 16.7803 16.4522C16.6397 16.5929 16.4489 16.6719 16.25 16.6719H7.75C7.55109 16.6719 7.36032 16.5929 7.21967 16.4522C7.07902 16.3116 7 16.1208 7 15.9219ZM7 7.92191C7 7.72299 7.07902 7.53223 7.21967 7.39158C7.36032 7.25092 7.55109 7.17191 7.75 7.17191H16.25C16.4489 7.17191 16.6397 7.25092 16.7803 7.39158C16.921 7.53223 17 7.72299 17 7.92191C17 8.12082 16.921 8.31158 16.7803 8.45224C16.6397 8.59289 16.4489 8.67191 16.25 8.67191H7.75C7.55109 8.67191 7.36032 8.59289 7.21967 8.45224C7.07902 8.31158 7 8.12082 7 7.92191ZM7 11.9219C7 11.723 7.07902 11.5322 7.21967 11.3916C7.36032 11.2509 7.55109 11.1719 7.75 11.1719H16.25C16.3485 11.1719 16.446 11.1913 16.537 11.229C16.628 11.2667 16.7107 11.3219 16.7803 11.3916C16.85 11.4612 16.9052 11.5439 16.9429 11.6349C16.9806 11.7259 17 11.8234 17 11.9219C17 12.0204 16.9806 12.1179 16.9429 12.2089C16.9052 12.2999 16.85 12.3826 16.7803 12.4522C16.7107 12.5219 16.628 12.5771 16.537 12.6148C16.446 12.6525 16.3485 12.6719 16.25 12.6719H7.75C7.55109 12.6719 7.36032 12.5929 7.21967 12.4522C7.07902 12.3116 7 12.1208 7 11.9219Z"
      />
    </svg>
  );
}
