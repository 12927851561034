import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { IconName } from "../icons/icon-name.type";
import { Step } from "./step";
import { isStepObject, isStepVisible } from "./utils";

export type StepObject = {
  label: string;
  navigateTo?: string;
  id?: string;
  isAvailable?: boolean;
  customStyle?: {
    icon: IconName;
    backgroundClassName: string;
    iconColor: string;
  };
};

export type Step = string | StepObject;

type StepperProps = {
  steps: Step[];
  className?: string;
  activeStepIndex?: number;
  selfManaged?: boolean;
  onChange?: (newStep: number) => void;
};

export function Stepper(props: StepperProps) {
  const {
    steps,
    className,
    activeStepIndex: activeStepProp = 0,
    selfManaged = false,
    onChange,
  } = props;

  const [activeStep, setActiveStep] = React.useState<number>(activeStepProp);

  React.useEffect(() => {
    if (activeStepProp === -1) {
      setActiveStep(steps.length);
    } else {
      setActiveStep(activeStepProp);
    }
  }, [activeStepProp, steps.length]);

  const lastStepIndex = React.useMemo(() => {
    return steps.findIndex((step: string | StepObject) => {
      const lastStep = steps[steps.length - 1];
      if (isStepObject(step) && isStepObject(lastStep)) {
        return step.label === lastStep.label;
      }

      return step === lastStep;
    });
  }, [steps]);

  const handleStepClick = (step: string | StepObject, index: number) => {
    if (!selfManaged || (isStepObject(step) && step.isAvailable === false)) {
      return;
    }

    setActiveStep(index);
    onChange?.(index);
  };

  if (steps.length === 0) return null;

  return (
    <div
      className={cn(
        "stepper flex h-[30px] w-full items-center justify-center gap-x-1",
        className,
      )}
    >
      {steps.map((step, index) => {
        const isVisible = isStepVisible({
          activeStep,
          currentStep: index,
          steps,
        });

        return (
          <React.Fragment key={index}>
            <Step
              index={index}
              step={step}
              active={index === activeStep}
              completed={activeStep > index}
              selfManaged={selfManaged}
              visible={isVisible}
              onClick={() => handleStepClick(step, index)}
            />

            {index !== lastStepIndex && (
              <span
                className={cn(
                  "stepper-line bg-light-1 relative h-px min-w-10 flex-1 sm:block sm:border-0",
                  {
                    hidden: !isVisible,
                    "border-t border-dashed border-white": index >= 1,
                  },
                )}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
