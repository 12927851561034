import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryListKey } from "../../..";

type SubscribeClubParam = {
  clubId: string;
  query?: {
    fields?: string;
  };
};

export const subscribeClub = async (params: SubscribeClubParam) => {
  const apiClient = getApiClient();

  const response = await apiClient.apiV1.clubs.createSubscription(params);

  return response.data;
};

export const useSubscribeClub = (
  options?: UseMutationOptions<
    TudigoResponse<ClubMember>,
    TudigoError,
    SubscribeClubParam
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params) => subscribeClub(params),
    onSuccess: (...props) => {
      const queryKey = getQueryListKey({
        key: QUERY_KEYS.MY_CLUB_MEMBERS,
      });
      queryClient.invalidateQueries({ queryKey });
      options?.onSuccess?.(...props);
    },
  });
};
