export const SIGNATURE_REQUESTS = {
  STATUS: {
    DRAFT: "draft",
    ONGOING: "ongoing",
    DONE: "done",
    DELETED: "deleted",
    EXPIRED: "expired",
    ERROR: "error",
  },
};
