import React, { ForwardedRef, SelectHTMLAttributes } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Dropdown } from "../dropdown";
import DropdownMenu from "../dropdown/dropdown-menu";
import DropdownToggle from "../dropdown/dropdown-toggle";
import { Icon } from "../icons/icon";
import { InputGroup, InputGroupProps } from "../input-group/input-group";
import { OutClickBox } from "../out-click-box/out-click-box";
import { InputSelectMenu } from "./input-select-menu";

export type InputSelectProps<T, U> = {
  onChange?: (value: U | U[]) => void;
  selectedValue?: U | U[];
  getValueFromItem: (item: T) => U;
  items: T[];
  placeholder?: string;
  open?: boolean;
  showAllSelectedButton?: boolean;
  className?: string;
  labelFunction: (item: T) => string;
  onClose?: () => void;
  onOpen?: () => void;
  renderItem?: (item: T) => React.ReactNode;
  comparisonFunction: (item: T, value: U) => boolean;
} & Omit<InputGroupProps, "className" | "icon"> &
  Omit<SelectHTMLAttributes<HTMLSelectElement>, "onChange">;

function InputSelectInner<T, U>(
  props: InputSelectProps<T, U>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    id,
    items,
    required,
    label,
    errors,
    className,
    labelPosition,
    placeholder,
    selectedValue,
    valid,
    disabled = false,
    multiple = false,
    open = false,
    getValueFromItem,
    onChange,
    onClose,
    labelFunction,
    renderItem,
    comparisonFunction,
    groupRef,
    canBeReset = true,
  } = props;

  function isArray<T>(value: T | T[]): value is T[] {
    return Array.isArray(value);
  }

  let inputId = React.useId();
  inputId = id || inputId;

  const [isOpen, setIsOpen] = React.useState<boolean>(open);
  const toggleOpen = (): void => setIsOpen((prev) => !prev);

  const handleInputSelectChange = (value: U | U[]) => {
    if (onChange) {
      onChange(value);
      if (!multiple) {
        closeMenu();
      }
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const isSelected = (item: T) => {
    if (!selectedValue) return false;
    if (Array.isArray(selectedValue))
      return selectedValue.some((selectedValue) =>
        comparisonFunction(item, selectedValue),
      );

    return comparisonFunction(item, selectedValue);
  };

  const getItemFromValue = (value: U) => {
    if (!selectedValue) return undefined;

    return items.find((selectedItem) =>
      comparisonFunction(selectedItem, value),
    );
  };

  const getLabelFromValue = (value: U) => {
    const item = getItemFromValue(value);
    if (!item) return undefined;

    return labelFunction(item);
  };

  const getPlaceholder = () => {
    if (!selectedValue) return placeholder;
    if (Array.isArray(selectedValue) && selectedValue.length === 0)
      return placeholder;
    if (Array.isArray(selectedValue))
      return selectedValue.map(getLabelFromValue).join(", ");

    return getLabelFromValue(selectedValue);
  };

  return (
    <Dropdown
      show={isOpen}
      onToggle={(open) => {
        setIsOpen(open);
      }}
      className={className}
    >
      <OutClickBox onOutClick={() => setIsOpen(false)}>
        <DropdownToggle className="flex flex-row items-center justify-between gap-2">
          <InputGroup
            groupRef={groupRef}
            ref={ref}
            id={inputId}
            disabled={disabled}
            label={label}
            required={required}
            errors={errors}
            active={isOpen}
            valid={valid}
            canBeReset={!isArray(selectedValue) && canBeReset}
            reset={() => {
              onChange && onChange((multiple ? [] : null) as any);
            }}
            fakeInputClassName={cn({
              "bg-accent-super-light": isOpen,
            })}
            hasValue={!!selectedValue}
            labelPosition={labelPosition}
            iconRight={
              <Icon
                name="ChevronDown"
                size="S"
                primaryColor={
                  isOpen ? themeColors["accent"] : themeColors["dark-2"]
                }
                className={cn("rotate-0 transition-all", {
                  "rotate-180": isOpen,
                })}
              />
            }
            renderInput={(inputProps) => (
              <div
                className={cn(
                  inputProps.className,
                  "min-h-[36px] cursor-pointer",
                )}
              >
                {getPlaceholder()}
              </div>
            )}
          />
        </DropdownToggle>
        <DropdownMenu>
          <InputSelectMenu<T, U>
            isSelected={isSelected}
            items={items}
            multiple={multiple}
            open={isOpen}
            onClose={toggleOpen}
            getValueFromItem={getValueFromItem}
            handleInputSelectChange={handleInputSelectChange}
            comparisonFunction={comparisonFunction}
            selectedValue={selectedValue}
            labelFunction={labelFunction}
            renderItem={renderItem}
          />
        </DropdownMenu>
      </OutClickBox>
    </Dropdown>
  );
}

declare module "react" {
  function forwardRef<T, P>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

export const InputSelect = React.forwardRef(InputSelectInner);
