import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";

import { Faq } from "@tudigo-monorepo/core-tudigo-api-models";

import { FaqElement, FaqElementProps } from "./faq-element";

type FaqItemsProps = {
  faqs: Faq[];
  setFaqs: (faqs: Faq[]) => void;
  deleteFaq: (index: number) => void;
  setFaq: (faq: Faq, index: number) => void;
};

const SortableItem = SortableElement<FaqElementProps & { position: number }>(
  (props: FaqElementProps & { position: number }) => (
    <FaqElement {...props} index={props.position} />
  ),
);

const SortableList = SortableContainer<FaqItemsProps>(
  (props: FaqItemsProps) => {
    return (
      <div className="flex flex-row flex-wrap gap-6 overflow-y-auto">
        {props.faqs?.map((faq, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            faq={faq}
            {...props}
          />
        ))}
      </div>
    );
  },
);

export function FaqItems(props: FaqItemsProps) {
  const { setFaqs, faqs } = props;
  const handleSortEnd = (sort: SortEnd) => {
    const newOrder = arrayMove(faqs, sort.oldIndex, sort.newIndex);

    const updatedFaqs = newOrder.map((faq, index) => ({
      ...faq,
      position: index,
    }));

    setFaqs(updatedFaqs);
  };

  return (
    <div>
      <SortableList
        {...props}
        useDragHandle
        axis="y"
        onSortEnd={handleSortEnd}
      />
    </div>
  );
}
