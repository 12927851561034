import { useState } from "react";
import { SortableHandle } from "react-sortable-hoc";

import { PremiumAdvantage } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ExpansionPanel,
  Icon,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

export type PremiumAdvantageElementProps = {
  premiumAdvantage: PremiumAdvantage;
  index: number;
  deletePremiumAdvantage: (index: number) => void;
  setPremiumAdvantage: (
    premiumAdvantage: PremiumAdvantage,
    index: number,
  ) => void;
};

const DragHandle = SortableHandle(() => (
  <div className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border">
    <Icon name="Drag" size="S" primaryColor={themeColors["dark-2"]} />
  </div>
));

export function PremiumAdvantageElement(props: PremiumAdvantageElementProps) {
  const {
    premiumAdvantage,
    index,
    deletePremiumAdvantage,
    setPremiumAdvantage,
  } = props;

  const { t } = useTranslation();

  const [localExpendedValue, setLocaleExpendedValue] = useState<boolean>(true);

  return (
    <ExpansionPanel
      title={`${premiumAdvantage.title} - ${premiumAdvantage.description}`}
      dragHandle={<DragHandle />}
      canBeDeleted
      expendedValue={localExpendedValue}
      setExpendedValue={setLocaleExpendedValue}
      deleteAction={() => {
        deletePremiumAdvantage(index);
      }}
      className={cn("w-full bg-white")}
      headerClassName="justify-between"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex w-full flex-col gap-4 md:w-[423px]">
          <TextField
            required
            label={t(
              "back_office.circles.edit_circle.tab_3.form.premium_advantage_title.label",
            )}
            value={premiumAdvantage.title}
            onChange={(event) => {
              setPremiumAdvantage(
                {
                  ...premiumAdvantage,
                  title: event.target.value,
                },
                index,
              );
            }}
          />
          <div className="flex flex-col gap-1">
            <TextField
              label={t(
                "back_office.circles.edit_circle.tab_3.form.premium_advantage_description.label",
              )}
              value={premiumAdvantage.description}
              onChange={(event) => {
                setPremiumAdvantage(
                  {
                    ...premiumAdvantage,
                    description: event.target.value,
                  },
                  index,
                );
              }}
            />
            <span className="flex items-center gap-1 text-xs text-[#80818F]">
              <Icon name="Info" />
              {t(
                "back_office.circles.edit_circle.tab_3.form.premium_advantage_description.helper",
              )}
            </span>
          </div>
        </div>
      </div>
    </ExpansionPanel>
  );
}
