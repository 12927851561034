import React from "react";

import { InputSelectMenuItem } from "./input-select-menu-item";

type InputSelectMenuProps<T, U> = {
  handleInputSelectChange: (value: U | U[]) => void;
  selectedValue?: U | U[];
  items: T[];
  label?: string;
  multiple: boolean;
  open: boolean;
  labelFunction: (item: T) => string;
  onClose?: () => void;
  renderItem?: (item: T) => React.ReactNode;
  isSelected: (item: T) => boolean;
  getValueFromItem: (item: T) => U;
  comparisonFunction: (item: T, value: U) => boolean;
};

export function InputSelectMenu<T, U>(props: InputSelectMenuProps<T, U>) {
  const {
    items,
    multiple,
    open,
    labelFunction,
    selectedValue,
    handleInputSelectChange,
    renderItem,
    isSelected,
    comparisonFunction,
    getValueFromItem,
  } = props;

  const handleChange = (value: U | U[]) => {
    handleInputSelectChange && handleInputSelectChange(value);
  };

  const isAllSelected = () => {
    return items.every((item) => {
      if (!selectedValue) return false;

      return (selectedValue as U[]).find((selectedItem) =>
        comparisonFunction(item, selectedItem),
      );
    });
  };

  if (!open) return null;

  return (
    <div className="flex max-h-[300px] flex-col gap-2">
      {multiple && (
        <InputSelectMenuItem
          multiple
          label="All"
          active={isAllSelected()}
          onClick={() => {
            if (isAllSelected()) {
              handleChange([]);
            } else {
              handleChange(items.map((item) => getValueFromItem(item)));
            }
          }}
          renderItem={renderItem}
        />
      )}
      {Array.from(items).map((itemValue, index) => (
        <InputSelectMenuItem
          key={index}
          multiple={multiple}
          value={itemValue}
          label={labelFunction(itemValue)}
          active={isSelected(itemValue)}
          onClick={(e) => {
            if (Array.isArray(selectedValue)) {
              if (isSelected(itemValue)) {
                handleChange(
                  selectedValue.filter(
                    (selectedItem) =>
                      !comparisonFunction(itemValue, selectedItem),
                  ),
                );
              } else {
                handleChange([...selectedValue, getValueFromItem(itemValue)]);
              }
            } else {
              handleChange(getValueFromItem(itemValue));
            }
            e.preventDefault();
            e.stopPropagation();
          }}
          renderItem={renderItem}
        />
      ))}
    </div>
  );
}
