import InvestImg from "../assets/invest-img.jpg";
import {
  NavbarCard,
  NavbarCardDescription,
  NavbarCardDescriptionContent,
  NavbarCardDescriptionHeading,
  NavbarCardHeading,
  NavbarCardImage,
  NavbarCardLink,
} from "./navbar-card";

export const NavbarCardInvest = () => {
  return (
    <NavbarCard>
      <NavbarCardHeading>Apprendre à investir</NavbarCardHeading>
      <NavbarCardImage src={InvestImg} />
      <NavbarCardDescription>
        <NavbarCardDescriptionHeading>
          Le guide pour commencer à investir
        </NavbarCardDescriptionHeading>
        <NavbarCardDescriptionContent>
          Plutôt qu’investir, on dit souvent “acheter des actions”. Sur le
          papier, c’est simple : vous achetez des parts d’une entreprise, des
          portions de la société.
        </NavbarCardDescriptionContent>
      </NavbarCardDescription>
      <NavbarCardLink to="https://tudigo.co/comment-investir">
        En savoir plus
      </NavbarCardLink>
    </NavbarCard>
  );
};
