import {
  Organization,
  OrganizationPerson,
  User,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateOrganizationPersonRequestBody {
  organization: {
    id: Organization["id"];
  };
  user: {
    id: User["id"];
  };
  address: OrganizationPerson["address"];
  birthDate: OrganizationPerson["birthDate"];
  birthLocation: OrganizationPerson["birthLocation"];
  firstName: OrganizationPerson["firstName"];
  lastName: OrganizationPerson["lastName"];
  gender: OrganizationPerson["gender"];
  nationality: OrganizationPerson["nationality"];
  phone: OrganizationPerson["phone"];
  isUltimateBeneficialOwner: OrganizationPerson["isUltimateBeneficialOwner"];
  isOrganizationRepresentative: OrganizationPerson["isOrganizationRepresentative"];
}

export interface UpdateOrganizationPersonRequestBody {
  address?: OrganizationPerson["address"];
  birthDate?: OrganizationPerson["birthDate"];
  birthLocation?: OrganizationPerson["birthLocation"];
  firstName?: OrganizationPerson["firstName"];
  lastName?: OrganizationPerson["lastName"];
  gender?: OrganizationPerson["gender"];
  nationality?: OrganizationPerson["nationality"];
  phone?: OrganizationPerson["phone"];
  isUltimateBeneficialOwner?: OrganizationPerson["isUltimateBeneficialOwner"];
  isOrganizationRepresentative?: OrganizationPerson["isOrganizationRepresentative"];
}

export const organizationPersonsEndpoints = (request: Request) => ({
  createOrganizationPerson: (
    body: CreateOrganizationPersonRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationPerson>, void>({
      path: `/api/v1/organization-persons`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  updateOrganizationPerson: (
    organizationPersonId: OrganizationPerson["id"],
    body: UpdateOrganizationPersonRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationPerson>, void>({
      path: `/api/v1/organization-persons/${organizationPersonId}`,
      method: "PATCH",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
