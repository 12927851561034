const prefix = "tudigo_" as const;

const formatKey = (key: string) => key.replace(/\s/g, "_").toLowerCase();

const getItem = <T>(key: string): T | null => {
  const item = localStorage.getItem(`${prefix}${formatKey(key)}`);

  return item ? JSON.parse(item) : null;
};

const setItem = <T>(key: string, data: T): void => {
  localStorage.setItem(`${prefix}${formatKey(key)}`, JSON.stringify(data));
};

const removeItem = (key: string): void => {
  localStorage.removeItem(`${prefix}${key}`);
};

const removeAllItems = (): void => {
  for (const key in localStorage) {
    if (key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
};

export const localStorageHandler = {
  set: setItem,
  get: getItem,
  remove: removeItem,
  removeAll: removeAllItems,
};
