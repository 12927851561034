import { PropsWithChildren } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Chip } from "../../old/chip";
import { ProgressBar } from "../../old/progress-bar";
import { Title } from "../../old/title";
import { Typography } from "../../old/typography";

type ProjectProgressBarProps = PropsWithChildren<{
  progressMaxGoal: number;
  progressMinGoal: number;
  className?: string;
}>;

export function ProjectProgressBar({
  progressMaxGoal,
  progressMinGoal,
  className,
  children,
}: ProjectProgressBarProps) {
  const { t } = useTranslation();

  const fundraisingProgress =
    progressMinGoal > 100 ? progressMaxGoal : progressMinGoal;

  return (
    <div
      className={cn(
        "amounts flex flex-col gap-2 overflow-hidden px-4 pb-4",
        className,
      )}
    >
      <ProgressBar
        progress={fundraisingProgress}
        variant={progressMinGoal >= 100 ? "success" : "secondary"}
      />

      <div className="text-primary flex h-[42px] items-center justify-between">
        <div className="percentage-collected-area flex flex-col gap-1">
          <Title level="h3" h3Brand="brand-1" className="leading-none">
            {progressMinGoal}%
          </Title>

          {progressMinGoal >= 100 ? (
            <Chip
              label={t("component.project_card.footer.goal_exceeded")}
              type="success"
              size="S"
            />
          ) : (
            <Typography variant="body3-regular" className="text-dark-2">
              {t("component.project_card.footer.of_goal")}
            </Typography>
          )}
        </div>

        {children}
      </div>
    </div>
  );
}
