import React, { useState } from "react";
import { isArray } from "lodash";
import { SortableHandle } from "react-sortable-hoc";

import {
  ClubLeader,
  ClubLeaderSpeciality,
  Image,
  Industry,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { useGetSpecialities } from "@tudigo-monorepo/web-tudigo-api-client";
import {
  ExpansionPanel,
  Icon,
  ImageUploader,
  InputSelect,
  TextArea,
  TextField,
} from "@tudigo-monorepo/web-tudigo-components";

export type LeaderElementProps = {
  leader: ClubLeader;
  index: number;
  deleteLeader: (index: number) => void;
  setLeader: (leader: ClubLeader, index: number) => void;
};

const DragHandle = SortableHandle(() => (
  <div className="border-dark-2 flex h-[38px] w-[38px] flex-shrink-0 items-center justify-center rounded-full border">
    <Icon name="Drag" size="S" primaryColor={themeColors["dark-2"]} />
  </div>
));

export function LeaderElement(props: LeaderElementProps) {
  const { leader, index, deleteLeader, setLeader } = props;

  const { data: availableSpecialitiesResponse } = useGetSpecialities();

  const { t } = useTranslation();

  const [localExpendedValue, setLocaleExpendedValue] = useState<boolean>(true);

  return (
    <ExpansionPanel
      title={`${leader.firstName} ${leader.lastName}`}
      dragHandle={<DragHandle />}
      canBeDeleted
      expendedValue={localExpendedValue}
      setExpendedValue={setLocaleExpendedValue}
      deleteAction={() => {
        deleteLeader(index);
      }}
      className={cn("w-full bg-white")}
      headerClassName="justify-between"
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex w-full flex-col gap-4 md:w-[423px]">
          <TextField
            required
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.last_name.label",
            )}
            value={leader.lastName}
            onChange={(event) => {
              setLeader(
                {
                  ...leader,
                  lastName: event.target.value,
                },
                index,
              );
            }}
          />
          <TextField
            required
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.first_name.label",
            )}
            value={leader.firstName}
            onChange={(event) => {
              setLeader(
                {
                  ...leader,
                  firstName: event.target.value,
                },
                index,
              );
            }}
          />
          <InputSelect<ClubLeaderSpeciality, ClubLeaderSpeciality>
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.specialities.label",
            )}
            selectedValue={leader.specialties}
            errors={[]}
            onChange={(item) => {
              setLeader(
                {
                  ...leader,
                  specialties: isArray(item) ? item : [item],
                },
                index,
              );
            }}
            comparisonFunction={(
              item: ClubLeaderSpeciality,
              value: ClubLeaderSpeciality,
            ) => item.code === value.code}
            getValueFromItem={(item: ClubLeaderSpeciality) => item}
            items={availableSpecialitiesResponse?.data ?? []}
            labelFunction={(item: Industry) => item.name}
          />

          <TextField
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.title.label",
            )}
            value={leader.title}
            onChange={(event) => {
              setLeader(
                {
                  ...leader,
                  title: event.target.value,
                },
                index,
              );
            }}
          />
          <TextArea
            required
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.short_description.label",
            )}
            value={leader.shortDescription}
            onChange={(event) => {
              setLeader(
                {
                  ...leader,
                  shortDescription: event.target.value,
                },
                index,
              );
            }}
          />
          <TextField
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.linkedin_url.label",
            )}
            value={leader.socialLinks?.linkedin}
            onChange={(event) => {
              setLeader(
                {
                  ...leader,
                  socialLinks: {
                    linkedin: event.target.value,
                  },
                },
                index,
              );
            }}
          />
          <ImageUploader
            required
            label={t(
              "back_office.circles.edit_circle.tab_4.form.leader.profile_picture.label",
            )}
            ratio="802x450"
            image={
              leader.profileImage ?? {
                id: null,
                url: null,
                base64: null,
                resizedImages: null,
                originalFilename: null,
              }
            }
            onImageChange={(image: Image) => {
              setLeader(
                {
                  ...leader,
                  profileImage: image,
                },
                index,
              );
            }}
          />
        </div>
      </div>
    </ExpansionPanel>
  );
}
