import { ChangeEvent, useState } from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";

import { InputEmail } from "../old/input-email";
import { Modal } from "../old/modal";

export type NotifyProjectModalProps = {
  project: Project;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm?: (email: string) => void;
  onClose?: () => void;
};

export function NotifyProjectModal({
  project,
  isOpen,
  isLoading = false,
  onConfirm,
  onClose,
}: NotifyProjectModalProps) {
  const [email, setEmail] = useState("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm?.(email);
  };

  return (
    <Modal
      title={project.content?.name || "M’avertir du lancement"}
      isOpen={isOpen}
      setIsOpen={() => {}}
      confirmLabel="M’avertir du lancement"
      confirmAction={handleConfirm}
      negativeAction={onClose}
      confirmButtonProps={{
        disabled: email.length === 0 || isLoading,
        isLoading,
      }}
      className="h-[605px] min-h-[605px] w-[calc(100%-32px)] lg:w-[423px] lg:max-w-[654px]"
    >
      <div className="flex w-full flex-col gap-y-4 p-4">
        <p className="font-montserrat text-dark-2 text-[13px]">
          Pour faire partie des privilégiés qui découvriront ce projet en
          avant-première laissez nous votre adresse e-mail.
        </p>
        <div className="flex flex-col gap-y-1">
          <InputEmail
            placeholder="Adresse email"
            value={email}
            onChange={handleChange}
          />
          <p className="font-montserrat text-dark-3 text-[13px]">
            En vous inscrivant vous acceptez de recevoir des mails de la part de
            Tudigo.
          </p>
        </div>
      </div>
    </Modal>
  );
}
