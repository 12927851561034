import { VariantProps } from "class-variance-authority";

import {
  getProjectEndDate,
  isComingProject,
  isFinishedProject,
  isLastDaysProject,
  isNewProject,
  isPrefinishedProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  daysLeftFromNow,
  getDate,
  hoursLeftFromNow,
} from "@tudigo-monorepo/core-tudigo-utils";

import { badgeVariants } from "../badge/badge";

const mockTwoDaysDateFromNow = new Date(
  new Date().setDate(new Date().getDate() + 2),
).toISOString();

const TRANSLATION_BASE_KEY = "component.project_card.header.flag";

export const useProjectFlagProps = (
  project?: Project,
): {
  label: string | null;
  variant: VariantProps<typeof badgeVariants>["variant"] | null;
} => ({
  label: useProjectFlagLabel(project),
  variant: getProjectFlagVariant(project),
});

function useProjectFlagLabel(project?: Project): string | null {
  const { t } = useTranslation();
  const endDate = getProjectEndDate(project);

  if (isNewProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.new`);
  }

  if (isComingProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.coming_soon`);
  }

  if (isPrefinishedProject(project)) {
    return t(`${TRANSLATION_BASE_KEY}.financed`);
  }

  if (isFinishedProject(project) && endDate) {
    return t(`${TRANSLATION_BASE_KEY}.financed_in`, {
      year: getDate(endDate).getFullYear(),
    });
  }

  if (isLastDaysProject(project)) {
    const endDate = project?.scheduledEndDate ?? mockTwoDaysDateFromNow;

    if (endDate) {
      const daysLeft = daysLeftFromNow(new Date(endDate));

      if (daysLeft >= 2) {
        return t(`${TRANSLATION_BASE_KEY}.days_left`, { daysLeft });
      }

      if (new Date() > new Date(endDate)) {
        return null;
      }

      const hoursLeft = hoursLeftFromNow(new Date(endDate));

      return t(`${TRANSLATION_BASE_KEY}.hours_left`, { hoursLeft });
    }
  }

  return null;
}

function getProjectFlagVariant(
  project?: Project,
): VariantProps<typeof badgeVariants>["variant"] | null {
  if (isNewProject(project)) {
    return "accent-super-light";
  }

  if (isComingProject(project)) {
    return "accent";
  }

  if (isFinishedProject(project) || isPrefinishedProject(project)) {
    return "success";
  }

  if (isLastDaysProject(project)) {
    const endDate = project?.scheduledEndDate ?? mockTwoDaysDateFromNow;

    if (endDate) {
      const daysLeft = daysLeftFromNow(new Date(endDate));

      if (daysLeft >= 15) {
        return "waiting";
      }

      if (daysLeft >= 7) {
        return "warning";
      }

      return "destructive";
    }
  }

  return null;
}
