import { forwardRef } from "react";
import { XCircleIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  ButtonRebrand,
  DropdownMenuTrigger,
  RemainingItemsList,
} from "@tudigo-monorepo/web-tudigo-components";

import { useCanRefineList } from "../../hooks/use-can-refine-list";
import { useCurrentRefinement } from "../../hooks/use-current-refinement";
import { useTranslateRefinementItemLabel } from "../../hooks/use-translate-refinement-item-label";
import { algoliaRefinementAttributes } from "../../models/algolia-refinement-attributes";
import { CustomClearRefinements } from "./custom-clear-refinements";
import type { RefinementListItem } from "./custom-refinement-list";
import type { RenderRefinementItem } from "./search-projects-refinements";

type RefinementListTriggerProps = {
  refinement: RenderRefinementItem;
  onClick?: (refinement: RenderRefinementItem) => void;
  onRefineChange?: (ref: HTMLDivElement | null) => void;
};

export const RefinementListTrigger = forwardRef<
  HTMLDivElement,
  RefinementListTriggerProps
>((props, ref) => {
  const { currentRefinement, isRefined } = useCurrentRefinement(
    props.refinement.key,
  );
  const translateItemLabel = useTranslateRefinementItemLabel();
  const canRefineList = useCanRefineList();
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      className={cn(
        "h-10 w-full md:w-fit",
        "flex items-center",
        "bg-black-400 text-black-primary",
        "hover:bg-white data-[state=open]:bg-white",
        "font-roobert text-xs",
        "rounded-lg",
        "transition-colors",
        { "border-accent-medium bg-accent-super-light border-2": isRefined },
      )}
    >
      <DropdownMenuTrigger
        asChild
        disabled={!canRefineList(props.refinement.key)}
      >
        <ButtonRebrand
          variant={isRefined ? "ghost" : "secondary"}
          className="h-full w-full p-0 transition-none hover:bg-inherit focus-visible:ring-0"
        >
          <div className={cn("flex items-center p-3", { "pr-1": isRefined })}>
            <span className="block font-normal text-neutral-700">
              {t(
                `search_projects_page.refinements.${props.refinement.value}.label`,
              )}
              {" : "}
            </span>
            {isRefined ? (
              <RemainingItemsList
                limit={2}
                className="text-accent ml-0.5"
                items={currentRefinement?.refinements ?? []}
                renderItem={(item) =>
                  translateItemLabel(
                    props.refinement.key,
                    item as Omit<RefinementListItem, "attribute">,
                  )
                }
              />
            ) : (
              <span className="ml-0.5 block font-medium text-neutral-900">
                {t("search_projects_page.refinements.all.label")}
              </span>
            )}
          </div>
        </ButtonRebrand>
      </DropdownMenuTrigger>

      {isRefined && (
        <CustomClearRefinements
          values={[algoliaRefinementAttributes[props.refinement.key]]}
        >
          <XCircleIcon className="text-accent h-4 w-4" />
          <span className="sr-only">Clear</span>
        </CustomClearRefinements>
      )}
    </div>
  );
});
