import { useNavigate } from "react-router-dom";

import {
  SubscribeClubStepPage,
  subscribeClubStepsConfig,
} from "../models/subscribe-club-step";

interface NavigationToStepOptions {
  baseRoute?: string;
  wait?: number;
}

const useNavigateToSubscribeClubStep = () => {
  const navigate = useNavigate();

  return (step: SubscribeClubStepPage, opts?: NavigationToStepOptions) => {
    const path = subscribeClubStepsConfig[step].path;
    const baseRoute = opts?.baseRoute;
    const pathname = baseRoute ? `${baseRoute}/${path}` : `../${path}`;

    if (opts?.wait) {
      setTimeout(() => navigate(pathname), opts.wait);
    } else {
      navigate(pathname);
    }
  };
};

export { useNavigateToSubscribeClubStep };
