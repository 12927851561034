import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Skeleton } from "../../../../ui/skeleton";

export const ProjectCardSkeleton = ({
  className,
  imgClassName,
}: {
  className?: string;
  imgClassName?: string;
}) => {
  return (
    <div
      className={cn(
        "flex h-full w-full min-w-[320px] flex-col rounded-lg bg-white md:min-w-[360px]",
        className,
      )}
    >
      <Skeleton
        className={cn(
          "min-h-[240px] w-full rounded-b-none rounded-tl-lg rounded-tr-lg object-cover",
          imgClassName,
        )}
      />
      <div className="border-black-600 flex h-full flex-col justify-between rounded-b-lg border bg-transparent px-6 py-6">
        <div className="mb-2 flex h-[30px] items-center gap-2">
          <Skeleton className="h-full w-[30px] rounded-lg p-0.5" />
          <Skeleton className="h-full w-full" />
        </div>
        <div className="mb-4 flex h-[25px] flex-wrap items-center gap-2">
          <Skeleton className="h-full w-16" />
          <Skeleton className="h-full w-20" />
          <Skeleton className="h-full w-8" />
        </div>
        <Skeleton className="mb-4 h-[54px]" />
        <Skeleton className="mb-6 h-36 w-full" />
        <div className="flex items-center justify-between">
          <Skeleton className="h-6 w-[120px]" />
          <Skeleton className="h-6 w-[100px]" />
        </div>
      </div>
    </div>
  );
};
