import * as React from "react";
import intlTelInput from "intl-tel-input";
import { mergeRefs } from "react-merge-refs";
import PhoneInputWithCountrySelect, {
  Country,
  Props,
  Value,
} from "react-phone-number-input";
// @ts-expect-error Since we don"t have types for this package, we need to ignore this error
import fr from "react-phone-number-input/locale/fr";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { InputProps, TextInput } from "../text-input/text-input";

import "intl-tel-input/build/css/intlTelInput.css";
import "./phone-input.css";

import {
  CountrySelectInputPhoneNumber,
  FlagComponent,
} from "../country-select-input/country-select-input";
import {
  usePhoneNumberErrorMessage,
  validatePhoneNumber,
} from "./phone-input.utils";

export interface PhoneInputError {
  displayed: boolean;
  code: number | null;
}

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value"
> &
  Omit<Props<typeof PhoneInputWithCountrySelect>, "onChange"> & {
    onChange?: (value: Value) => void;
    onErrorChange?: (error: boolean) => void;
  } & Omit<intlTelInput.Options, "initialCountry">;

const PhoneInput = React.forwardRef<
  React.ElementRef<typeof PhoneInputWithCountrySelect>,
  PhoneInputProps
>((props, ref) => {
  const {
    className,
    value,
    disabled,
    defaultCountry = "FR",
    preferredCountries = ["fr", "ca"],
    nationalMode = true,
    separateDialCode = false,
    onChange,
    onErrorChange,
    ...rest
  } = props;

  const phoneInputRef = React.useRef<HTMLInputElement>(null);

  const [phoneInputState, setPhoneInputState] = React.useState<
    intlTelInput.Plugin | undefined
  >();

  const [error, setError] = React.useState<PhoneInputError>({
    displayed: false,
    code: 0,
  });

  React.useEffect(() => {
    if (phoneInputRef.current && !phoneInputState) {
      const opts = {
        utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
        initialCountry: defaultCountry,
        nationalMode,
        preferredCountries,
        separateDialCode,
        allowDropdown: false,
        autoPlaceholder: "polite",
      } satisfies intlTelInput.Options;

      const state = intlTelInput(phoneInputRef.current, opts);

      setPhoneInputState(state);
    }
  }, [
    defaultCountry,
    nationalMode,
    phoneInputState,
    preferredCountries,
    separateDialCode,
  ]);

  const handleValueChange = (value: Value) => {
    onChange?.((value || "") as Value);

    if (error.displayed && !value?.length) {
      setError({ displayed: false, code: null });
      onErrorChange?.(false);
    }
  };

  const handleCountryChange = (country: Country) => {
    if (country) {
      phoneInputState?.setCountry(country);
    }
  };

  const handleErrorOnBlur = () => {
    if (!phoneInputState) {
      return;
    }

    const { isError, errorCode } = validatePhoneNumber(phoneInputState);
    setError({ displayed: isError, code: errorCode });
    onErrorChange?.(isError);
  };

  return (
    <div className="flex flex-col gap-y-1">
      <PhoneInputWithCountrySelect
        ref={mergeRefs([ref, phoneInputRef as any])}
        className={cn(
          "flex",
          disabled &&
            "bg-light-1 text-dark-4 border-dark-3 overflow-hidden rounded-lg",
          className,
        )}
        labels={fr}
        flagComponent={FlagComponent}
        inputComponent={InputComponent}
        onBlur={handleErrorOnBlur}
        countrySelectComponent={CountrySelectInputPhoneNumber}
        onCountryChange={handleCountryChange}
        defaultCountry={defaultCountry}
        /**
         * react-phone-number might trigger the onChange event as undefined
         * when a valid phone number is not entered. To prevent this,
         * the value is coerced to an empty string.
         *
         * @param {E164Number | undefined} value - The entered value
         */
        onChange={handleValueChange}
        value={value}
        disabled={disabled}
        focusInputOnCountrySelection
        {...rest}
      />

      <ErrorMessage error={error} phoneInputRef={phoneInputRef} />
    </div>
  );
});

const ErrorMessage = ({
  error,
  phoneInputRef,
}: {
  error: PhoneInputError;
  phoneInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const expectedFormat = phoneInputRef.current?.placeholder;
  const errorMessage = usePhoneNumberErrorMessage(error, expectedFormat);

  if (!error.displayed) {
    return null;
  }

  return (
    <p
      className="text-error inline-block w-fit text-balance font-sans text-xs font-normal"
      dangerouslySetInnerHTML={{
        __html: errorMessage,
      }}
    />
  );
};

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => (
    <TextInput
      ref={ref}
      className={cn("rounded-e-lg rounded-s-none", className)}
      {...props}
    />
  ),
);

export { PhoneInput };
export type { PhoneInputProps };
