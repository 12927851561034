import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { useGetClubV2UsingIdPathParams } from "../../../shared-internal/hooks/use-get-club-v2-using-id-path-params";
import { ClubSubscriptionPrice } from "../club-subscription-price/club-subscription-price";

function PaymentAmount() {
  const club = useGetClubV2UsingIdPathParams();
  const { t } = useTranslation();

  const hasDiscountedPrice = Boolean(
    club?.configuration.introductorySubscriptionPrice,
  );

  return (
    <ul className="flex w-full flex-col gap-y-2">
      <li className="flex items-center justify-between">
        <h3 className="text-primary font-sans text-lg font-bold">
          {t("my_account.wep_app.clubs.membership")}
        </h3>
        <ClubSubscriptionPrice
          price={
            hasDiscountedPrice
              ? club?.configuration?.introductorySubscriptionPrice
                  ?.unitAmountInCents
              : club?.configuration?.subscriptionPrice?.unitAmountInCents
          }
          className="text-lg"
        />
      </li>

      {hasDiscountedPrice && (
        <li className="flex items-center justify-between ">
          <p className="text-primary font-sans text-[13px]">
            {t("my.account.web.app.clubs.membership_info.subscription", {
              subscriptionsCount:
                club?.configuration.maxIntroductorySubscriptions,
            })}
          </p>
          <ClubSubscriptionPrice
            asOldPrice
            price={club?.configuration.subscriptionPrice?.unitAmountInCents}
          />
        </li>
      )}
    </ul>
  );
}

export { PaymentAmount };
