import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useGetCircleMemberById } from "@tudigo-monorepo/web-tudigo-api-client";

const useGetCurrentClubMember = (params: {
  clubId?: string;
  clubMemberId?: string;
}) => {
  const { user } = useUserBusiness();

  const { data, isFetched, isFetching, isError } = useGetCircleMemberById(
    {
      clubId: params.clubId ?? "",
      clubMemberId: params.clubMemberId ?? "",
    },
    {
      enabled: !!user?.id && !!params.clubId,
    },
  );

  return { clubMember: data?.data, isFetched, isFetching, isError };
};

export { useGetCurrentClubMember };
