import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  getResourceFromTudigoResponse,
  TudigoError,
} from "@tudigo-monorepo/core-tudigo-api";
import { User } from "@tudigo-monorepo/core-tudigo-api-models";
import { Partial } from "@tudigo-monorepo/core-tudigo-utils";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { useImpersonateId } from "@tudigo-monorepo/web-tudigo-auth-store";

import { getApiClient } from "..";
import queryKeyFactory from "./query-key-factory";

const usersKeys = queryKeyFactory("users");

type GetUserByIdQueryParams = {
  userId: User["id"] | "me";
  query?: {
    fields?: string;
  };
};

const investorProfileFields =
  "id,qualification_data,financial_summary,engagement_type,strategy_type,annual_investment_capacity,preferred_investment_sectors,confirmed,confirmation_at,expiration_at,is_qualification_expired,qualification_version,statistics";

const wmaProfileFields = "id,statistics";

const projectHolderProfileFields = "id,statistics,projects";
const circlesFields =
  "id,content,industries,maturities,mentorings,territories,statistics,leaders,status";

const userDefaultFields = `id,first_name,last_name,email,phone_number,address,individual_organization.fields(id,name,type,logo,persons,internal_kyc_status,individual_information.fields(address)),app_roles,circles_owned_as_circle_leader,investor_profile.fields(${investorProfileFields}),wma_profile.fields(${wmaProfileFields}),project_holder_profile.fields(${projectHolderProfileFields}),investment_officer_profile,total_account_count,profile_image,statistics,notification_settings,locale,state,followed_project_leads.fields(id,email,ref_type,ref_id),my_circles.fields(${circlesFields}),pending_wma_invitations.fields(id)`;

export const useGetUserByIdQuery = (
  params: GetUserByIdQueryParams,
  options?: Partial<UseQueryOptions<User, TudigoError>>,
) => {
  const { userId, query } = params;

  const apiClient = getApiClient();

  const queryParam = {
    fields: query?.fields ?? userDefaultFields,
  };

  return useQuery({
    queryKey: usersKeys.detail(userId),
    queryFn: async () => {
      const response = await apiClient.apiV1.users.getUserById(
        userId,
        queryParam,
      );

      return getResourceFromTudigoResponse(response.data);
    },
    ...options,
  });
};
export type UsersRequestFilters = {
  status?: string;
  appRole?: string | string[];
  term?: string;
};

export const useInvalidateConnectedUser = () => {
  const queryClient = useQueryClient();
  const impersonateId = useImpersonateId();

  const { user } = useUserBusiness();

  return () => {
    if (impersonateId) {
      queryClient.invalidateQueries({
        queryKey: usersKeys.detail(impersonateId),
      });
    }
    if (user?.id) {
      queryClient.invalidateQueries({
        queryKey: usersKeys.detail(user?.id),
      });
      queryClient.invalidateQueries({
        queryKey: usersKeys.detail("me"),
      });
    }
  };
};
