import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { AnimateDot } from "./animate-dot";

export type DotSize = "M" | "S" | "XS";

type DotsLoaderProps = {
  className?: string;
  size?: DotSize;
  speed?: "slow" | "medium" | "fast";
};

const FIRST_STEP = 0;
const LAST_STEP = 6;

export function DotsLoader(props: DotsLoaderProps) {
  const { size = "S", speed = "fast", className } = props;

  const [step, setStep] = React.useState<number>(0);

  const timeoutDelay =
    speed === "slow"
      ? 350
      : speed === "medium"
        ? 250
        : speed === "fast"
          ? 150
          : 250;

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const newStep = step === LAST_STEP ? FIRST_STEP : step + 1;
      setStep(newStep);
    }, timeoutDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [step, timeoutDelay]);

  return (
    <div
      data-testid="dots-loader"
      className={cn("flex items-center gap-0.5", className)}
    >
      <AnimateDot size={size} up={step === 1} blink={step <= 2} />
      <AnimateDot size={size} up={step === 3} blink={step >= 3 && step < 5} />
      <AnimateDot size={size} up={step === 5} blink={step >= 5} />
    </div>
  );
}
