import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Location(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1618 11.9626C10.6175 11.9626 9.36099 10.7061 9.36099 9.16182C9.36099 7.61751 10.6175 6.36099 12.1618 6.36099C13.7061 6.36099 14.9627 7.61751 14.9627 9.16182C14.9627 10.7061 13.7061 11.9626 12.1618 11.9626Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1618 21C9.92116 17.639 6 13.083 6 9.16183C6 5.76419 8.7642 3 12.1618 3C15.5595 3 18.3237 5.76419 18.3237 9.16183C18.3237 13.083 14.4025 17.639 12.1618 21Z"
        stroke={primaryColor}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
