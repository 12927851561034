import { WebFlowCollection } from "@tudigo-monorepo/core-tudigo-api-models";

import { Request, RequestParams, TudigoResponse } from "../api";

export const webflowCollectionsEndpoints = (request: Request) => ({
  getCollectionItems: (
    collectionId: string,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<WebFlowCollection>, void>({
      path: `/api/v1/webflow/collection/${collectionId}/items`,
      method: "GET",
      secure: true,
      query,
      ...params,
    }),
});
