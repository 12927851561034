import { createContext, ReactNode, useContext } from "react";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";

export interface FormContextProps {
  children?: ReactNode;
  error: TudigoError | null;
  formData: any;
  setFormData: (formData: any) => void;
}

type FormContextType = {
  error: TudigoError | null;
  form: any;
  handleFormChange: (change: any) => void;
};

const FormContext = createContext<FormContextType>({
  form: {},
  error: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleFormChange: () => {},
});

export function FormContextProvider(props: FormContextProps) {
  const { formData, setFormData, error } = props;

  return (
    <FormContext.Provider
      value={{ form: formData, handleFormChange: setFormData, error: error }}
    >
      {props.children}
    </FormContext.Provider>
  );
}

export const useFormContext = () => {
  return useContext(FormContext);
};

export default FormContextProvider;
