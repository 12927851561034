import React, { useMemo } from "react";
import { EditorState, RichUtils, SelectionState } from "draft-js";

import { Modal } from "../../modal";
import { TextField } from "../../text-field";

export const useSelectionHasLink = (
  editorState: EditorState,
  selection: SelectionState,
) => {
  return useMemo(() => {
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

    return !!linkKey;
  }, [editorState, selection]);
};

export const useSelectionLink = (
  editorState: EditorState,
  selection: SelectionState,
) => {
  return useMemo(() => {
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);

      return linkInstance.getData().url;
    }

    return "";
  }, [editorState, selection]);
};

export const removeLink = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
) => {
  const selection = editorState.getSelection();
  setEditorState(RichUtils.toggleLink(editorState, selection, null));
};

type LinkModalProps = {
  urlToolState: { showURLInput: boolean; urlValue: string };
  setUrlToolState: (urlToolState: {
    showURLInput: boolean;
    urlValue: string;
  }) => void;
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
};

export const LinkModal = (props: LinkModalProps) => {
  const { urlToolState, setUrlToolState, editorState, setEditorState } = props;

  const confirmLink = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: urlToolState.urlValue },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });

    // Apply selection
    nextEditorState = RichUtils.toggleLink(
      nextEditorState,
      nextEditorState.getSelection(),
      entityKey,
    );

    setEditorState(nextEditorState);
    setUrlToolState({ urlValue: "", showURLInput: false });
  };

  return (
    <Modal
      title="Insert Link"
      isOpen={urlToolState.showURLInput}
      setIsOpen={(isOpen) =>
        setUrlToolState({ ...urlToolState, showURLInput: isOpen })
      }
      content={
        <TextField
          value={urlToolState.urlValue}
          label="URL"
          onChange={(e) =>
            setUrlToolState({ ...urlToolState, urlValue: e.target.value })
          }
        />
      }
      confirmAction={() => {
        confirmLink();
      }}
      confirmLabel="Set Link"
    />
  );
};
