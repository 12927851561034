import React, { forwardRef, InputHTMLAttributes } from "react";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroup, InputGroupProps } from "../input-group/input-group";

type InputPasswordType = "password" | "text";

type InputPasswordProps = {
  validated?: boolean;
} & InputGroupProps &
  InputHTMLAttributes<HTMLInputElement>;

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  (props: InputPasswordProps, ref) => {
    const {
      id,
      label,
      className,
      autoComplete,
      name,
      value = "",
      pattern,
      placeholder,
      autoFocus = false,
      disabled = false,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
      groupRef,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    const [type, setType] = React.useState<InputPasswordType>("password");

    const toggleInputType = (): void => {
      setType((prev) => (prev === "password" ? "text" : "password"));
    };

    const iconName = type === "password" ? "Hide" : "Show";

    return (
      <InputGroup
        ref={ref}
        id={inputId}
        groupRef={groupRef}
        className={cn(className, {
          "font-['Nunito_Sans']": type === "password" && value,
        })}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        iconRight={
          iconName && (
            <div onClick={toggleInputType}>
              <Icon
                name={iconName}
                primaryColor={themeColors["dark-2"]}
                size="S"
                height={18}
                width={18}
              />
            </div>
          )
        }
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated}
        renderInput={(inputProps) => (
          <input
            type={type}
            id={inputId}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            name={name}
            onKeyDown={onKeyDown}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            pattern={pattern}
            onFocus={onFocus}
            onChange={onChange}
            {...inputProps}
          />
        )}
      />
    );
  },
);
