import { Skeleton } from "../../../../ui/skeleton";
import {
  NavbarCard,
  NavbarCardDescription,
  NavbarCardHeading,
} from "./navbar-card";

export const NavbarCardSkeleton = (props: { heading: string }) => {
  return (
    <NavbarCard>
      <NavbarCardHeading>{props.heading}</NavbarCardHeading>
      <Skeleton className="h-[160px] max-w-[300px]" />
      <NavbarCardDescription>
        <Skeleton className="h-[16px] w-2/3" />
        <Skeleton className="h-[80px]" />
      </NavbarCardDescription>
      <Skeleton className="h-[16px] w-1/3" />
    </NavbarCard>
  );
};
