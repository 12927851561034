import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ClubMember } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient } from "../../..";
import { getQueryDetailKey } from "../../query-key-factory";

type GetClubMember = {
  clubId: string;
  clubMemberId: string;
  query?: {
    fields?: string;
  };
};

const defaultFields =
  "id,role,contract,user,status,joined_at,selected_price,created_at,updated_at,subscription,club";

export const getCircleMemberById = async (params: GetClubMember) => {
  const apiClient = getApiClient();

  const fields = params.query?.fields ?? defaultFields;

  const response = await apiClient.apiV1.clubs.getCircleMemberById({
    ...params,
    query: { fields },
  });

  return response.data;
};

const getCircleMemberQueryKey = (params: {
  clubId: string;
  clubMemberId: string;
}) => {
  return getQueryDetailKey({
    key: QUERY_KEYS.CLUB_MEMBER,
    id: `${params.clubId}-${params.clubMemberId}`,
  });
};

export const useInvalidateCircleMember = () => {
  const queryClient = useQueryClient();

  return async (params: { clubId: string; clubMemberId: string }) => {
    queryClient.invalidateQueries({
      queryKey: getCircleMemberQueryKey(params),
    });
  };
};

export const useGetCircleMemberById = (
  params: GetClubMember,
  options?: Partial<UseQueryOptions<TudigoResponse<ClubMember>, TudigoError>>,
) => {
  const { clubId, clubMemberId } = params;

  return useQuery({
    queryKey: getCircleMemberQueryKey({ clubId, clubMemberId }),
    queryFn: () => getCircleMemberById(params),
    ...options,
  });
};
