export function daysLeftFromNow(date: Date) {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  const dateNow = new Date();
  const utc2 = Date.UTC(
    dateNow.getFullYear(),
    dateNow.getMonth(),
    dateNow.getDate(),
  );

  return Math.floor((utc1 - utc2) / MS_PER_DAY);
}
