import { Configuration } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, TudigoResponse } from "../api";

export const configurationEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getConfiguration
   * @summary get global configuration
   * @request GET:/api/console/configuration
   * @secure
   */
  getConfiguration: (query?: { [key: string]: unknown }) =>
    request<TudigoResponse<Configuration>, void>({
      path: "/api/console/config",
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
    }),
});
