import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import type { AlgoliaRefinementAttributesKey } from "../../shared-internal/models/algolia-refinement-attributes";
import type { RefinementListItem } from "../components/search-projects/custom-refinement-list";

export const useTranslateRefinementItemLabel = () => {
  const { t } = useTranslation();

  return (
    key: AlgoliaRefinementAttributesKey,
    item: Omit<RefinementListItem, "attribute">,
  ) => {
    switch (key) {
      case "instrument":
        return t(`projects.mechanic.label.${item.label}`);
      case "tax_exemption":
        return t(
          `component.project_card.list.item.tax_exemption.${item.label}`,
        );
      case "maturity":
        return t(`projects.maturity.label.${item.label}`);
      case "category":
      default:
        return item.label;
    }
  };
};
