import * as React from "react";
import { ForwardedRef, forwardRef, ReactElement, ReactNode, Ref } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { Typography } from "../typography";
import { noBorderInputClasses, useFocus } from "./utils";

export type InputGroupProps = {
  className?: string;
  id?: string;
  errors?: string[];
  label?: string | ReactNode;
  labelPosition?: "top" | "left" | "right" | "inside" | "none" | "auto";
  disabled?: boolean;
  required?: boolean;
  valid?: boolean;
  active?: boolean;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  iconRight?: ReactElement;
  hasValue?: boolean;
  canBeReset?: boolean;
  reset?: () => void;
  groupRef?: Ref<HTMLDivElement>;
  fakeInputClassName?: string;
};

export type InputBaseProps = {
  renderInput?: (inputProps: {
    ref: ForwardedRef<HTMLInputElement>;
    className?: string;
  }) => ReactElement;
  renderTextarea?: (inputProps: {
    ref: ForwardedRef<HTMLTextAreaElement>;
    className?: string;
  }) => ReactElement;
};

export const InputGroup = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputGroupProps & InputBaseProps
>((props: InputGroupProps & InputBaseProps, ref) => {
  const {
    fakeInputClassName,
    renderTextarea,
    renderInput,
    reset,
    active,
    id,
    label,
    errors,
    labelPosition = "top",
    disabled,
    required,
    valid,
    hasValue,
    className,
    groupRef,
  } = props;

  const [inputRef, focusAutoComplete] = useFocus();

  return (
    <div
      className={cn(
        "flex w-full",
        {
          "flex-col items-start": ["top", "none"].includes(labelPosition),
          "flex-row items-center gap-2.5": labelPosition === "left",
          "flex-row-reverse place-content-start items-center gap-2.5":
            labelPosition === "right",
        },
        className,
      )}
    >
      {label &&
        (labelPosition === "top" ||
          labelPosition === "left" ||
          labelPosition === "right") && (
          <label htmlFor={id}>
            <Typography variant="body3-regular" className="text-dark-1">
              {label} {required && <span className="text-error">*</span>}
            </Typography>
          </label>
        )}
      <div
        onClick={() => focusAutoComplete()}
        ref={groupRef}
        className={cn(
          "border-dark-2 has-[:focus]:border-accent-medium relative flex min-h-[38px] w-full flex-row items-center gap-2.5 rounded-lg border bg-white px-4 outline-none transition-all",
          {
            "border-dark-3": !!disabled,
            "border-error": !!errors?.length,
            "border-success": !!valid,
            "text-dark-1": !!hasValue,
            "bg-light-1 text-dark-4": !!disabled,
            "border-accent-medium": active,
          },
          fakeInputClassName,
        )}
      >
        {labelPosition === "inside" && label && (
          <Typography
            variant="body3-regular"
            className="text-dark-4 whitespace-nowrap break-words text-[13px] leading-[17px]"
          >
            {label}:
          </Typography>
        )}
        {renderInput &&
          renderInput({
            ref: mergeRefs([ref, inputRef]),
            className: noBorderInputClasses,
          })}
        {renderTextarea &&
          renderTextarea({
            ref: mergeRefs([ref, inputRef]),
            className: noBorderInputClasses,
          })}
        {props.canBeReset && props.hasValue ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              reset && reset();
            }}
          >
            {" "}
            <Icon name="Cross" />
          </div>
        ) : (
          props.iconRight
        )}
      </div>
      {errors && errors.length > 0 && (
        <div className="mt-1 flex flex-col gap-2.5 text-start">
          {errors?.map((error, index) => (
            <Typography
              key={index}
              variant="body3-regular"
              className="text-error"
            >
              {error}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
});
