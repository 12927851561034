import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Dots(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_381_5491)">
        <g clipPath="url(#clip1_381_5491)">
          <path
            d="M9.16667 10.0002C9.16667 10.2212 9.25446 10.4332 9.41074 10.5895C9.56702 10.7458 9.77899 10.8336 10 10.8336C10.221 10.8336 10.433 10.7458 10.5893 10.5895C10.7455 10.4332 10.8333 10.2212 10.8333 10.0002C10.8333 9.77922 10.7455 9.56726 10.5893 9.41098C10.433 9.2547 10.221 9.1669 10 9.1669C9.77899 9.1669 9.56702 9.2547 9.41074 9.41098C9.25446 9.56726 9.16667 9.77922 9.16667 10.0002Z"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.16667 15.8336C9.16667 16.0546 9.25446 16.2666 9.41074 16.4228C9.56702 16.5791 9.77899 16.6669 10 16.6669C10.221 16.6669 10.433 16.5791 10.5893 16.4228C10.7455 16.2666 10.8333 16.0546 10.8333 15.8336C10.8333 15.6126 10.7455 15.4006 10.5893 15.2443C10.433 15.088 10.221 15.0002 10 15.0002C9.77899 15.0002 9.56702 15.088 9.41074 15.2443C9.25446 15.4006 9.16667 15.6126 9.16667 15.8336Z"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.16667 4.16692C9.16667 4.38793 9.25446 4.5999 9.41074 4.75618C9.56702 4.91246 9.77899 5.00025 10 5.00025C10.221 5.00025 10.433 4.91246 10.5893 4.75618C10.7455 4.5999 10.8333 4.38793 10.8333 4.16692C10.8333 3.94591 10.7455 3.73395 10.5893 3.57767C10.433 3.42139 10.221 3.33359 10 3.33359C9.77899 3.33359 9.56702 3.42139 9.41074 3.57767C9.25446 3.73395 9.16667 3.94591 9.16667 4.16692Z"
            fill={primaryColor}
            stroke={primaryColor}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_381_5491">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_381_5491">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
