import { getProjectMechanicSettings } from "@tudigo-monorepo/core-tudigo-api-models";
import {
  mixpanelTrackEvent,
  UI_INVESTMENT_STARTED,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useProjectContext } from "./use-project-context";

export function useInvestTracking() {
  const project = useProjectContext();

  const investmentStartedTracking = () => {
    if (!project) {
      return;
    }

    const { minimumTicketAmountInCents } = getProjectMechanicSettings(project);

    mixpanelTrackEvent(UI_INVESTMENT_STARTED, {
      investmentInitialAmount: minimumTicketAmountInCents / 100,
      investmentCurrency: project.currency,
      investmentProjectId: project.id,
      investmentClubId: project?.club?.id,
    });
  };

  return {
    investmentStartedTracking,
  };
}
