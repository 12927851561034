import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { TranslationProvider } from "@tudigo-monorepo/core-tudigo-translations";
import { queryClient } from "@tudigo-monorepo/core-tudigo-utils";
import { SessionProvider } from "@tudigo-monorepo/tudigo-session";

import router from "./router/router";

import "@tudigo-monorepo/core-tudigo-theme";

import { datadogRum } from "@datadog/browser-rum";

import {
  useInitAxeptio,
  useInitConvia,
  useInitGTM,
  useInitHubspot,
  useInitMixpanel,
} from "@tudigo-monorepo/web-tudigo-tracking";

window.axeptioSettings = {
  clientId: import.meta.env["VITE_AXEPTIO_CLIENT_ID"] as string,
  cookiesVersion: "fr-31-03-2023",
  googleConsentMode: {
    default: {
      analytics_storage: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      wait_for_update: 500,
    },
  },
};

if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: "37218f3e-eb38-4120-b00e-61b782b7e4e5",
    clientToken: "pube6e1d0bf00c69571f02bd21aa6d8d7cf",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: "tudigo-webapp",
    env: import.meta.env["VITE_ENV"],
    version: import.meta.env["VITE_VERSION"],
    sessionSampleRate: Number(
      import.meta.env["VITE_DATADOG_SESSION_SAMPLE_RATE"],
    ),
    sessionReplaySampleRate: Number(
      import.meta.env["VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE"],
    ),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

const hubspotAccount = import.meta.env["VITE_HUBSPOT_ACCOUNT"] as string;
const mixpanelToken = import.meta.env["VITE_MIXPANEL_TOKEN"] as string;
const gtmContainerId = import.meta.env["VITE_GTM_CONTAINER_ID"] as string;

export function App() {
  useInitHubspot(hubspotAccount);
  useInitMixpanel(mixpanelToken);
  useInitGTM(gtmContainerId);
  useInitAxeptio();
  useInitConvia();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <TranslationProvider>
        <SessionProvider>
          <RouterProvider router={router()} />
        </SessionProvider>
        <ToastContainer
          hideProgressBar
          closeOnClick={false}
          closeButton={false}
        />
      </TranslationProvider>
    </QueryClientProvider>
  );
}

export default App;
