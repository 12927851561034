import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Image(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.475 13.718L11.558 13.647C11.6847 13.5546 11.8371 13.5043 11.9939 13.503C12.1506 13.5018 12.3039 13.5497 12.432 13.64L12.525 13.718L19.453 20.518C18.9412 20.8338 18.3514 21.0008 17.75 21H6.25C5.64867 21.0011 5.05893 20.8345 4.547 20.519L11.475 13.718L11.558 13.647L11.475 13.717V13.718ZM17.75 3C18.612 3 19.4386 3.34241 20.0481 3.9519C20.6576 4.5614 21 5.38805 21 6.25V17.75C21 18.377 20.822 18.963 20.515 19.46L13.576 12.647L13.448 12.531C13.0437 12.1911 12.5328 12.0042 12.0046 12.0031C11.4764 12.002 10.9647 12.1867 10.559 12.525L10.424 12.648L3.485 19.459C3.16703 18.9458 2.99902 18.3538 3 17.75V6.25C3 5.38805 3.34241 4.5614 3.95191 3.9519C4.5614 3.34241 5.38805 3 6.25 3H17.75ZM15.752 6C15.4563 6 15.1634 6.05825 14.8902 6.17142C14.617 6.2846 14.3687 6.45048 14.1596 6.6596C13.9505 6.86871 13.7846 7.11697 13.6714 7.3902C13.5583 7.66342 13.5 7.95626 13.5 8.252C13.5 8.54774 13.5583 8.84058 13.6714 9.1138C13.7846 9.38703 13.9505 9.63529 14.1596 9.8444C14.3687 10.0535 14.617 10.2194 14.8902 10.3326C15.1634 10.4458 15.4563 10.504 15.752 10.504C16.3493 10.504 16.9221 10.2667 17.3444 9.8444C17.7667 9.42207 18.004 8.84927 18.004 8.252C18.004 7.65473 17.7667 7.08193 17.3444 6.6596C16.9221 6.23726 16.3493 6 15.752 6ZM15.752 7.5C15.8508 7.5 15.9485 7.51945 16.0398 7.55724C16.131 7.59503 16.2139 7.65043 16.2837 7.72026C16.3536 7.79009 16.409 7.87298 16.4468 7.96422C16.4846 8.05546 16.504 8.15325 16.504 8.252C16.504 8.35075 16.4846 8.44854 16.4468 8.53978C16.409 8.63101 16.3536 8.71391 16.2837 8.78374C16.2139 8.85357 16.131 8.90897 16.0398 8.94676C15.9485 8.98455 15.8508 9.004 15.752 9.004C15.5526 9.004 15.3613 8.92477 15.2203 8.78374C15.0792 8.64272 15 8.45144 15 8.252C15 8.05256 15.0792 7.86128 15.2203 7.72026C15.3613 7.57923 15.5526 7.5 15.752 7.5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
