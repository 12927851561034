import { useEffect } from "react";

declare global {
  interface Window {
    _hsq: any;
  }
}

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const useInitHubspot = (accountId: string) => {
  useEffect(() => {
    if (inIframe()) {
      return;
    }
    const script = document.createElement("script");
    script.src = `https://js.hs-scripts.com/${accountId}.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [accountId]);
};

window._hsq = window._hsq = window._hsq || [];

export const hubspotIdentify = (email: string) => {
  window._hsq.push(["identify", { email }]);
};

export const hubspotTrackPageView = () => {
  const currentPath = window.location.pathname + window.location.search;
  window._hsq.push(["setPath", currentPath]);
  window._hsq.push(["trackPageView"]);
};

export const hubspotTrackEvent = (
  name: string,
  properties: Record<string, string | undefined>,
) => {
  window._hsq.push([
    "trackCustomBehavioralEvent",
    { name: name, properties: properties },
  ]);
};

export enum HubspotEventEnum {
  PROJECT_DOCUMENT_DOWNLOADED = "project_document_download",
  PROJECT_NOTIFICATION_SUBSCRIBED = "project_notification_subscribed",
  PROJECT_NOTIFICATION_UNSUBSCRIBED = "project_notification_unsubscribed",
  PROJECT_SIMULATION_DONE = "project_simulation_done",
}

export enum HubspotEnvEnum {
  PROD = "prod",
  DEV = "dev",
}

const hubspotEventsName = {
  [HubspotEventEnum.PROJECT_DOCUMENT_DOWNLOADED]: {
    [HubspotEnvEnum.PROD]: "pe6032261_project_document_download",
    [HubspotEnvEnum.DEV]: "pe22087847_project_document_download",
  },
  [HubspotEventEnum.PROJECT_NOTIFICATION_SUBSCRIBED]: {
    [HubspotEnvEnum.PROD]: "pe6032261_project_notification_subscribed",
    [HubspotEnvEnum.DEV]: "pe22087847_project_notification_subscribed",
  },
  [HubspotEventEnum.PROJECT_NOTIFICATION_UNSUBSCRIBED]: {
    [HubspotEnvEnum.PROD]: "pe6032261_project_notification_unsubscribed",
    [HubspotEnvEnum.DEV]: "pe22087847_project_notification_unsubscribed",
  },
  [HubspotEventEnum.PROJECT_SIMULATION_DONE]: {
    [HubspotEnvEnum.PROD]: "pe6032261_project_simulation_done",
    [HubspotEnvEnum.DEV]: "pe22087847_project_simulation_done",
  },
};

const getHubspotEventNameByEnv = (env: HubspotEnvEnum) => {
  const eventNames = {} as Record<HubspotEventEnum, string>;
  Object.values(HubspotEventEnum).forEach((eventName) => {
    if (!hubspotEventsName[eventName as HubspotEventEnum]) {
      throw new Error(`Missing name for event ${eventName}`);
    }
    if (!hubspotEventsName[eventName as HubspotEventEnum][env]) {
      throw new Error(`Missing name for event ${eventName} and env ${env}`);
    }
    eventNames[eventName as HubspotEventEnum] =
      hubspotEventsName[eventName as HubspotEventEnum][env];
  });

  return eventNames;
};

export const currentEnvEventsName = getHubspotEventNameByEnv(
  import.meta.env["VITE_ENV"] === "prod"
    ? HubspotEnvEnum.PROD
    : HubspotEnvEnum.DEV,
);
