import * as React from "react";
import { LucideIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export const textInputClassName = cn(
  `
    border-input bg-background ring-offset-background
    placeholder:text-muted-foreground focus-visible:ring-ring
    disabled:bg-light-1 disabled:text-dark-4 flex h-10 w-full rounded-md border px-3 py-2
    text-sm file:border-0 file:bg-transparent
    file:text-sm file:font-medium
    focus-visible:outline-none focus-visible:ring-2
    focus-visible:ring-offset-2
    disabled:cursor-not-allowed
    `,
);

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: LucideIcon;
  endIcon?: LucideIcon;
}

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon, endIcon, ...props }, ref) => {
    const StartIcon = startIcon;
    const EndIcon = endIcon;

    return (
      <div className="relative w-full">
        {!!StartIcon && (
          <div className="absolute left-2.5 top-1/2 -translate-y-1/2 transform">
            <StartIcon size={18} className="text-muted-foreground" />
          </div>
        )}
        <input
          type={type}
          className={cn(
            textInputClassName,
            startIcon ? "pl-9" : "",
            endIcon ? "pr-9" : "",
            className,
          )}
          ref={ref}
          {...props}
        />
        {!!EndIcon && (
          <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            <EndIcon className="text-muted-foreground" size={18} />
          </div>
        )}
      </div>
    );
  },
);

export { TextInput };
