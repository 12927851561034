import { useState } from "react";

import { Project } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useSubscribeProjectNotificationsMutation,
  useUnsubscribeProjectNotificationsMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";
import {
  currentEnvEventsName,
  HubspotEventEnum,
  hubspotIdentify,
  hubspotTrackEvent,
} from "@tudigo-monorepo/web-tudigo-tracking";

import { useVisitorProjectNotificationsSubscription } from "./use-visitor-project-notifications-subscription";

export const useProjectNotificationsSubscription = (project: Project) => {
  const { user } = useUserBusiness();

  const {
    saveVisitorSubscribedProject,
    removeVisitorSubscribedProject,
    getVisitorSubscribedProjects,
  } = useVisitorProjectNotificationsSubscription();

  const subscribedProjects = user
    ? user.followedProjectLeads ?? []
    : getVisitorSubscribedProjects();

  const currentSubscribedProject = subscribedProjects.find(
    (sub) => project.id === sub.refId,
  );

  const [isSubscribed, setIsSubscribed] = useState(
    Boolean(currentSubscribedProject),
  );

  const { mutate: subscribeProjectMutation, isPending: isSubscribeLoading } =
    useSubscribeProjectNotificationsMutation({
      onSuccess: (res) => {
        setIsSubscribed(true);

        if (!user) {
          saveVisitorSubscribedProject({
            email: res.data.email,
            leadId: res.data.id,
            projectId: project.id,
          });
        }
      },
    });

  const {
    mutate: unsubscribeProjectMutation,
    isPending: isUnsubscribeLoading,
  } = useUnsubscribeProjectNotificationsMutation({
    onSuccess: (res) => {
      setIsSubscribed(false);

      if (!user) {
        removeVisitorSubscribedProject({
          leadId: res.data.id,
          projectId: project.id,
        });
      }
    },
  });

  const subscribeProject = (email?: string) => {
    subscribeProjectMutation(
      {
        data: { refId: project.id, email },
      },
      {
        onSuccess: () => {
          if (email) {
            hubspotIdentify(email);
          }
          hubspotTrackEvent(
            currentEnvEventsName[
              HubspotEventEnum.PROJECT_NOTIFICATION_SUBSCRIBED
            ],
            {
              project_id: project?.id,
              project_slug: project?.content?.slug,
            },
          );
        },
      },
    );
  };

  const unsubscribeProject = () => {
    if (currentSubscribedProject) {
      unsubscribeProjectMutation(
        {
          leadId: currentSubscribedProject.id,
        },
        {
          onSuccess: () => {
            hubspotTrackEvent(
              currentEnvEventsName[
                HubspotEventEnum.PROJECT_NOTIFICATION_UNSUBSCRIBED
              ],
              {
                project_id: project?.id,
                project_slug: project?.content?.slug,
              },
            );
          },
        },
      );
    }
  };

  return {
    isSubscribed,
    isLoading: isSubscribeLoading || isUnsubscribeLoading,
    subscribeProject,
    unsubscribeProject,
  };
};
