import { User, UserRole } from "./models";

type UserMock = Record<keyof typeof UserRole, User>;

export const mockUsers: UserMock = {
  INVESTOR: {
    id: "2684784",
    email: "antony@tudigo.co",
    phoneNumber: "+33618883322",
    firstName: "Antony",
    lastName: "cohen",
    job: "",
    employer: "",
    bio: null,
    address: null,
    profileImage: null,
    coverImage: null,
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    identityRoles: [
      {
        id: "1",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.INVESTOR,
      },
    ],
    appRoles: [UserRole.INVESTOR],
    investorProfile: {
      qualificationData: {
        "personal-information": {
          "first-name": "Antony",
          "last-name": "Cohen",
          email: "antony@tudigo.co",
          "phone-number": "+33618883322",
          "birth-date": "1990-10-10",
          "birth-place": "Paris",
        },
      },
      statistics: {
        totalInvestmentsAmountInCents: 0,
        totalInvestmentsCount: 0,
        totalSharesInvestmentsAmountInCents: 0,
        totalSharesInvestmentsCount: 0,
        totalBondsInvestmentsAmountInCents: 0,
        totalBondsInvestmentsCount: 0,
      },
    },
    projectHolderProfile: null,
    wmaProfile: null,
    investmentOfficerProfile: null,
    locale: "en",
    individualOrganization: null,
    followedProjectLeads: null,
  },
  CIRCLE_LEADER: {
    id: "2684784",
    email: "antony@tudigo.co",
    phoneNumber: "+33618883322",
    firstName: "Antony",
    lastName: "cohen",
    job: "",
    employer: "",
    bio: null,
    address: null,
    profileImage: null,
    coverImage: null,
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    identityRoles: [
      {
        id: "1",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.INVESTOR,
      },
    ],
    appRoles: [UserRole.INVESTOR],
    investorProfile: {
      qualificationData: {
        "personal-information": {
          "first-name": "Antony",
          "last-name": "Cohen",
          email: "antony@tudigo.co",
          "phone-number": "+33618883322",
          "birth-date": "1990-10-10",
          "birth-place": "Paris",
        },
      },
      statistics: {
        totalInvestmentsAmountInCents: 0,
        totalInvestmentsCount: 0,
        totalSharesInvestmentsAmountInCents: 0,
        totalSharesInvestmentsCount: 0,
        totalBondsInvestmentsAmountInCents: 0,
        totalBondsInvestmentsCount: 0,
      },
    },
    projectHolderProfile: null,
    wmaProfile: null,
    investmentOfficerProfile: null,
    locale: "en",
    individualOrganization: null,
    followedProjectLeads: null,
  },
  PROJECT_HOLDER: {
    id: "2684784",
    email: "johndoe@tudigo.co",
    phoneNumber: "+3312121212",
    firstName: "John",
    lastName: "Doe",
    job: "",
    employer: "",
    bio: null,
    address: null,
    locale: "en",
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    profileImage: {
      id: null,
      originalFilename: null,
      base64: null,
      url: "https://picsum.photos/200",
      resizedImages: null,
    },
    coverImage: null,
    identityRoles: [
      {
        id: "2",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.PROJECT_HOLDER,
      },
    ],
    appRoles: [UserRole.PROJECT_HOLDER],
    investorProfile: null,
    investmentOfficerProfile: null,
    projectHolderProfile: {
      id: "1",
      status: "active",
      statistics: {
        totalProjectsCount: 0,
        totalCollectedAmountInCents: 0,
      },
    },
    wmaProfile: null,
    individualOrganization: null,
    followedProjectLeads: null,
  },
  WMA: {
    id: "2684784",
    email: "wma_email@test.com",
    phoneNumber: "+3312121212",
    firstName: "WMA",
    lastName: "Test",
    job: "",
    employer: "",
    bio: null,
    address: null,
    locale: "en",
    individualOrganization: null,
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    profileImage: {
      id: null,
      originalFilename: null,
      base64: null,
      url: "https://picsum.photos/200",
      resizedImages: null,
    },
    coverImage: null,
    identityRoles: [
      {
        id: "3",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.WMA,
      },
    ],
    appRoles: [UserRole.WMA],
    investorProfile: null,
    investmentOfficerProfile: null,
    projectHolderProfile: null,
    wmaProfile: {
      id: "1",
      status: "active",
    },
    followedProjectLeads: null,
  },
  INVESTMENT_OFFICER: {
    id: "2684784",
    email: "investment_officer@email.com",
    phoneNumber: "+3312121212",
    firstName: "Investment",
    lastName: "Officer",
    job: "",
    employer: "",
    bio: null,
    address: null,
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    locale: "en",
    individualOrganization: null,
    profileImage: {
      id: null,
      originalFilename: null,
      base64: null,
      url: "https://picsum.photos/200",
      resizedImages: null,
    },
    coverImage: null,
    identityRoles: [
      {
        id: "4",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.INVESTMENT_OFFICER,
      },
    ],
    appRoles: [UserRole.INVESTMENT_OFFICER],
    investorProfile: null,
    projectHolderProfile: null,
    wmaProfile: null,
    investmentOfficerProfile: {
      id: "1",
      status: "active",
    },
    followedProjectLeads: null,
  },
  ADMIN: {
    id: "2684784",
    email: "admin@tudigo.co",
    phoneNumber: "+3312121212",
    firstName: "Admin",
    lastName: "Test",
    job: "",
    employer: "",
    bio: null,
    address: null,
    locale: "fr",
    notificationSettings: {
      notificationChannels: ["email"],
      newsletterOptIn: true,
      signatureVerificationChannel: "email",
    },
    individualOrganization: null,
    profileImage: {
      id: null,
      originalFilename: null,
      base64: null,
      url: "https://picsum.photos/200",
      resizedImages: null,
    },
    coverImage: null,
    identityRoles: [
      {
        id: "0",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.ADMIN,
      },
      {
        id: "1",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.INVESTOR,
      },
      {
        id: "2",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.PROJECT_HOLDER,
      },
      {
        id: "3",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.WMA,
      },
      {
        id: "4",
        resourceId: "*",
        resourceType: "APP",
        role: UserRole.INVESTMENT_OFFICER,
      },
    ],
    appRoles: [
      UserRole.ADMIN,
      UserRole.INVESTOR,
      UserRole.PROJECT_HOLDER,
      UserRole.WMA,
      UserRole.INVESTMENT_OFFICER,
    ],
    investorProfile: {
      statistics: {
        totalInvestmentsAmountInCents: 0,
        totalInvestmentsCount: 0,
        totalSharesInvestmentsAmountInCents: 0,
        totalSharesInvestmentsCount: 0,
        totalBondsInvestmentsAmountInCents: 0,
        totalBondsInvestmentsCount: 0,
      },
      qualificationData: {
        "personal-information": {
          "first-name": "Admin",
          "last-name": "Test",
          email: "admin@tudigo.co",
          "phone-number": "++3312121212",
        },
      },
    },
    investmentOfficerProfile: {
      id: "1",
      status: "active",
    },
    projectHolderProfile: {
      id: "1",
      status: "active",
      statistics: {
        totalProjectsCount: 0,
        totalCollectedAmountInCents: 0,
      },
    },
    wmaProfile: {
      id: "1",
      status: "active",
    },
    isAdmin: true,
    followedProjectLeads: null,
  },
};
