import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { TudigoError } from "@tudigo-monorepo/core-tudigo-api";
import { User, UserRole } from "@tudigo-monorepo/core-tudigo-api-models";

export type SessionStore = {
  user: User | null;
  sessionRole: UserRole;
  isLoading: boolean;
  error: TudigoError | null;
};

type SessionActions = {
  setUser: (user: User | null) => void;
  setSessionRole: (profile: SessionStore["sessionRole"]) => void;
  setIsLoading: (loading: boolean) => void;
  setError: (error: TudigoError | null) => void;
  reset: () => void;
};

const initialStore: SessionStore = {
  user: null,
  sessionRole: UserRole.INVESTOR,
  isLoading: false,
  error: null,
};

export const useSessionStore = create<SessionStore & SessionActions>()(
  persist(
    (set) => ({
      ...initialStore,
      setUser: (userResponse) => set(() => ({ user: userResponse })),
      setSessionRole: (profileResponse) =>
        set(() => ({ sessionRole: profileResponse })),
      setIsLoading: (isLoadingResponse) =>
        set(() => ({ isLoading: isLoadingResponse })),
      setError: (errorResponse) => set(() => ({ error: errorResponse })),
      reset: () => set(initialStore),
    }),
    {
      name: "session-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useConnectedUserRole = () =>
  useSessionStore((state) => state.sessionRole);
