import * as React from "react";
import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { InputConnectedProps } from "../../components/input.type";
import {
  SelectInput,
  SelectInputContent,
  SelectInputItem,
  SelectInputTrigger,
  SelectInputValue,
} from "./select-input";

interface SelectConnectedProps extends InputConnectedProps {
  options: {
    value: string;
    label: string;
  }[];
}

export const SelectInputConnected = ({
  name,
  label,
  placeholder,
  options,
  description,
  required,
  disabled,
}: SelectConnectedProps) => {
  const form = useFormContext();

  if (!form) {
    throw new Error("useFormField should be used within <FormField>");
  }

  return (
    <FormField
      control={form.control}
      name={name}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <FormItem>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <SelectInput
            onValueChange={field.onChange}
            defaultValue={field.value}
            disabled={disabled}
          >
            <FormControl>
              <SelectInputTrigger>
                <SelectInputValue placeholder={placeholder} />
              </SelectInputTrigger>
            </FormControl>
            <SelectInputContent>
              {options.map((item) => (
                <SelectInputItem key={item.value} value={item.value}>
                  {item.label}
                </SelectInputItem>
              ))}
            </SelectInputContent>
          </SelectInput>
          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
};
