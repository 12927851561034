import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CmsAnalyzeIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.75 4.5C20.2141 4.5 20.6592 4.68437 20.9874 5.01256C21.3156 5.34075 21.5 5.78587 21.5 6.25V17.755C21.5 18.2191 21.3156 18.6642 20.9874 18.9924C20.6592 19.3206 20.2141 19.505 19.75 19.505H4.25C4.02019 19.505 3.79262 19.4597 3.5803 19.3718C3.36798 19.2838 3.17506 19.1549 3.01256 18.9924C2.85006 18.8299 2.72116 18.637 2.63321 18.4247C2.54527 18.2124 2.5 17.9848 2.5 17.755V6.25C2.5 5.78587 2.68437 5.34075 3.01256 5.01256C3.34075 4.68437 3.78587 4.5 4.25 4.5H19.75Z"
        stroke="#32354C"
      />
      <path
        d="M7 15C7.79565 15 8.55871 14.6313 9.12132 13.9749C9.68393 13.3185 10 12.4283 10 11.5V8H7"
        stroke="#32354C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 15C14.0609 15 15.0783 14.6313 15.8284 13.9749C16.5786 13.3185 17 12.4283 17 11.5V8H13"
        stroke="#32354C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
