import { Category } from "./footer-data-rebrand";

export function FooterCategoryRebrandDesktop({
  category,
  className,
}: {
  category: Category;
  className?: string;
}) {
  return (
    <div className="links-bloc flex flex-col gap-y-4 ">
      <p className="font-medium">{category.title}</p>
      <ul className="flex flex-col gap-y-0.5">
        {category.links.map((link, linkIndex) => (
          <li key={linkIndex}>
            <a
              className="text-black-900  hover:text-primary-rebrand flex items-center gap-2 font-normal transition duration-300 ease-in "
              href={link.href}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
