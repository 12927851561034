import { mockClub2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon } from "@tudigo-monorepo/web-tudigo-components";

import { useGetClubV2UsingIdPathParams } from "../hooks/use-get-club-v2-using-id-path-params";

const ClubPremiumAdvantageList = () => {
  const club = useGetClubV2UsingIdPathParams();

  if (!club) {
    return null;
  }

  const advantages =
    club.content?.premiumAdvantages || mockClub2.content?.premiumAdvantages;

  return (
    <ul className="flex flex-col gap-y-2.5">
      {advantages?.map((advantage, index) => (
        <li key={index} className="flex items-start gap-4">
          <div className="bg-success-light flex min-h-[30px] min-w-[30px] items-center justify-center rounded-full">
            <Icon name="Checked" primaryColor={themeColors.success} size="S" />
          </div>
          <div className="flex flex-col gap-y-1.5">
            <h3 className="text-primary text-pretty font-sans text-sm font-semibold">
              {advantage.title}
            </h3>
            <p className="text-dark-1 font-sans text-sm">
              {advantage.description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export { ClubPremiumAdvantageList };
