import * as React from "react";
import { useEffect, useRef } from "react";

type SignatureIframeProps = {
  signatureLink: string;
  iframeContainerId?: string;
  onStarted?: () => void;
  onSuccess?: (signatureRequestId: string) => void;
  onError?: () => void;
  onPing?: () => void;
  onDeclined?: () => void;
};

export function SignatureIframe(props: SignatureIframeProps) {
  const {
    signatureLink,
    iframeContainerId = "signature-iframe-container",
    onStarted,
    onSuccess,
    onError,
    onPing,
    onDeclined,
  } = props;

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isSandbox = import.meta.env["VITE_YOUSIGN_IS_SANDBOX"] === "true";

  useEffect(() => {
    let sLink: URL;

    try {
      sLink = new URL(signatureLink);
    } catch {
      throw new Error("InvalidSignatureLink");
    }

    if (isSandbox) {
      sLink.searchParams.append("disable_domain_validation", "true");
    }

    const iframe = iframeRef.current;
    if (!iframe) {
      throw new Error("IframeRefNotDefined");
    }

    iframe.src = sLink.href;

    const messageHandler = (event: MessageEvent) => {
      const { origin, data } = event;

      if (!origin.match(/^https:\/\/yousign.app$/)) return;

      switch (data.event) {
        case "started":
          onStarted?.();
          break;
        case "success":
          onSuccess?.(data.signature_request_id);
          break;
        case "error":
          onError?.();
          break;
        case "ping":
          onPing?.();
          break;
        case "declined":
          onDeclined?.();
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [
    signatureLink,
    isSandbox,
    onStarted,
    onSuccess,
    onError,
    onPing,
    onDeclined,
  ]);

  return (
    <div id={iframeContainerId} className="h-full w-full">
      <iframe
        referrerPolicy="strict-origin-when-cross-origin"
        ref={iframeRef}
        id="signature-iframe"
        title="Signature"
        // footer height containing the sign button minus parent vertical padding
        className="h-[calc(100%-56px)] w-full"
      />
    </div>
  );
}
