import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Show(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = iconSizes.S,
    height = iconSizes.S,
  } = props;

  return (
    <svg
      className={className}
      style={{ minWidth: width, minHeight: height }}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fill={primaryColor}
        d="M11.9998 9.92308C14.2089 9.92308 15.9998 11.7139 15.9998 13.9231C15.9998 16.1322 14.2089 17.9231 11.9998 17.9231C9.79066 17.9231 7.99979 16.1322 7.99979 13.9231C7.99979 11.7139 9.79066 9.92308 11.9998 9.92308ZM11.9998 11.4231C10.6191 11.4231 9.49979 12.5424 9.49979 13.9231C9.49979 15.3038 10.6191 16.4231 11.9998 16.4231C13.3805 16.4231 14.4998 15.3038 14.4998 13.9231C14.4998 12.5424 13.3805 11.4231 11.9998 11.4231ZM11.9998 6.41846C16.6133 6.41846 20.5959 9.56846 21.7009 13.9828C21.8015 14.3847 21.5573 14.7919 21.1555 14.8925C20.7537 14.9931 20.3464 14.7489 20.2458 14.3471C19.3069 10.5964 15.9212 7.91846 11.9998 7.91846C8.07673 7.91846 4.68989 10.5987 3.75265 14.3516C3.65229 14.7535 3.24515 14.9979 2.84328 14.8975C2.44141 14.7972 2.19699 14.39 2.29735 13.9882C3.40044 9.57118 7.38428 6.41846 11.9998 6.41846Z"
      />
    </svg>
  );
}
