import { FC } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import {
  CMSChronologyBlock,
  CMSChronologyElement,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { ThinLayout, Typography } from "@tudigo-monorepo/web-tudigo-components";

import { EditorSettings } from "../../../types";

type ChronologyBlockProps = {
  block: CMSChronologyBlock;
  editMode?: boolean;
  editorSettings?: EditorSettings;
};

dayjs.extend(localizedFormat);

const DotAndBarDecoration: FC = () => (
  <>
    <div
      className={cn(
        "absolute h-4 w-4",
        "bg-dark-2 rounded-full",
        "border-2 border-white shadow-[0_4px_8px_0_rgba(0,0,0,0.15)]",
        "left-2 top-2",
        "md:left-0 md:top-2",
      )}
    />
    <div
      className={cn(
        "absolute h-[98.5%] w-0",
        "border-dark-1 border border-l",
        "left-4 top-8",
        "md:left-2 md:top-8",
      )}
    />
  </>
);

export function ChronologyBlock(props: ChronologyBlockProps) {
  const { block } = props;
  const { chronology } = block.data;

  const RightContent: FC<CMSChronologyElement> = ({
    title,
    text,
    imageCaption,
    image,
  }) => {
    const imgSrc = image?.base64 || image?.url;
    const hasImg = Boolean(imgSrc) && imgSrc !== ".";

    return (
      <>
        <h2 className="font-semibold">{title}</h2>

        {text && (
          <p
            className="text-dark2"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}

        <div>
          {hasImg && (
            <img
              src={imgSrc as string}
              alt={imageCaption || title}
              className="mt-1 max-h-[200px] w-auto rounded-lg"
            />
          )}
        </div>

        {image?.url && imageCaption && (
          <Typography variant="caption1-medium">{imageCaption}</Typography>
        )}
      </>
    );
  };

  return (
    <ThinLayout className="flex flex-col gap-6 pb-6">
      {chronology.map((element, index) => (
        <div
          key={index}
          className="@md:flex-row @md:gap-6 relative flex w-full flex-col gap-0"
        >
          <div className="text-primary text-nowrap pl-10 md:pl-0">
            {dayjs(element.date).format("LL")}
          </div>

          <div className="@md:relative">
            <DotAndBarDecoration />
          </div>

          <div className="@md:pl-4 flex w-full flex-col pl-10">
            <RightContent {...element} />
          </div>
        </div>
      ))}
    </ThinLayout>
  );
}
