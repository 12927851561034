import { Bank } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, TudigoResponse } from "../api";

export const banksEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getBanks
   * @summary get banks
   * @request GET:/api/banks
   * @secure
   */
  getBanks: (query?: { [key: string]: unknown }) =>
    request<TudigoResponse<Bank[]>, void>({
      path: `/api/v1/banks`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
    }),
});
