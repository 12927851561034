import { Todo } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const userTodosEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getTodoById
   * @summary get todo by id
   * @request GET:/api/v1/users/me/todos/:todoId
   * @secure
   */
  getTodoById: (
    todoId: Todo["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Todo>, void>({
      path: `/api/v1/users/me/todos/${todoId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getUserTodos
   * @summary get todos
   * @request GET:/api/v1/users/:userId/todos
   * @secure
   */
  getUserTodos: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Todo[]>, void>({
      path: `/api/v1/users/me/todos`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
