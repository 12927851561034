import type { ReactNode } from "react";
import { UserIcon } from "lucide-react";
import { Link } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import {
  PageEnum,
  useSpaceBusiness,
  useUserBusiness,
} from "@tudigo-monorepo/tudigo-session";

import { ButtonRebrand } from "../ui/button-rebrand";

export function UserSpaceLink(props: {
  children?: ReactNode;
  className?: string;
}) {
  const { children, className } = props;

  const { currentPagePaths } = useSpaceBusiness();
  const { user } = useUserBusiness();
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <Link to={currentPagePaths[PageEnum.DASHBOARD]}>
      <ButtonLayout className={className}>
        <span className="w-fit text-wrap sm:text-left">
          {`${t("my_account")} `}
          <span className="hidden xl:inline-block">
            ({`${user.firstName} ${user.lastName}`})
          </span>
        </span>
        {children}
      </ButtonLayout>
    </Link>
  );
}

function ButtonLayout(props: { children: ReactNode; className?: string }) {
  return (
    <>
      <ButtonRebrand
        variant="accent-light"
        size="lg"
        className={cn("font-roobert group gap-x-2 lg:hidden", props.className)}
      >
        <UserIcon className="text-primary-rebrand h-5 w-5" />
        {props.children}
      </ButtonRebrand>

      <ButtonRebrand
        variant="ghost"
        size="lg"
        className={cn(
          "font-roobert group hidden gap-x-2 lg:flex",
          props.className,
        )}
      >
        <UserIcon className="text-black-900 group-hover:text-primary-rebrand h-5 w-5 transition-colors" />
        {props.children}
      </ButtonRebrand>
    </>
  );
}
