import { ObjectDotNotation } from "@tudigo-monorepo/core-tudigo-utils";
import {
  CurrencyInput,
  CurrencyInputProps,
} from "@tudigo-monorepo/web-tudigo-components";

import { extractValueFromPath, updateObject } from "../../index";
import { useFormContext } from "../form-context/form-context";

interface SmartCurrencyFieldProps<T> extends CurrencyInputProps {
  name: ObjectDotNotation<T>;
}

export function SmartCurrencyField<T>(props: SmartCurrencyFieldProps<T>) {
  const { form, handleFormChange, error } = useFormContext();

  return (
    <CurrencyInput
      {...props}
      value={extractValueFromPath(form, props.name) || ""}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onChange={(value) => {
        handleFormChange({
          ...updateObject(form, props.name, value),
        });
      }}
    />
  );
}
