import { ProjectMaturity } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export const projectMaturitiesEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getProjectMaturities
   * @summary get project maturities
   * @request GET:/api/v1/project-maturities
   * @secure
   */
  getProjectMaturities: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<ProjectMaturity[]>, void>({
      path: `/api/v1/project-maturities`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
