import { CarouselSlidesToShow } from "./carousel";

const getAutoSlidesCount = (): number => {
  if (window.innerWidth <= 640) return 1;
  if (window.innerWidth > 640 && window.innerWidth <= 1280) return 2;
  if (window.innerWidth > 1280 && window.innerWidth <= 1440) return 3;
  else return 4;
};

const getCarouselSlidesCount = (slides: CarouselSlidesToShow): number => {
  const defaultCount = typeof slides === "number" ? slides : 1;
  let count = window.innerWidth <= 640 ? 1 : defaultCount;
  if (slides === "auto") {
    count = getAutoSlidesCount();
  }

  return count;
};

export { getCarouselSlidesCount };
