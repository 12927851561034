import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function SocialFacebook(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 21 21"
      fill={primaryColor}
      className={className}
    >
      <g clipPath="url(#clip0_3949_1952)">
        <path
          d="M20 10.084C20 4.56118 15.5228 0.0839844 10 0.0839844C4.4772 0.0839844 0 4.56118 0 10.084C0 14.7736 3.2288 18.7088 7.5844 19.7896V13.14H5.5224V10.084H7.5844V8.76718C7.5844 5.36358 9.1248 3.78598 12.4664 3.78598C13.1 3.78598 14.1932 3.91038 14.6404 4.03438V6.80438C14.4044 6.77958 13.9944 6.76718 13.4852 6.76718C11.8456 6.76718 11.212 7.38838 11.212 9.00318V10.084H14.4784L13.9172 13.14H11.212V20.0108C16.1636 19.4128 20.0004 15.1968 20.0004 10.084H20Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_3949_1952">
          <rect
            width={width || iconSizes[size]}
            height={height || iconSizes[size]}
            fill={primaryColor}
            transform="translate(0 0.0839844)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
