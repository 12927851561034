const defaultUrl = new URL("https://discord.com/invite/EufppyxKKj").href;

type DiscordCardProps = {
  url?: string;
};

const DiscordCard = ({ url }: DiscordCardProps) => {
  return (
    <a
      href={url ?? defaultUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="@container flex h-[99px] w-full items-center justify-end overflow-hidden rounded-lg border bg-white"
    >
      <div
        className="@md:hidden block h-[99px] w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/discord-mobile.png')",
        }}
      />
      <div
        className="@md:block hidden h-[99px] w-full rounded-lg bg-white bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://tudigo-prod.s3.eu-west-3.amazonaws.com/static/news/discord.png')",
        }}
      />
    </a>
  );
};

export { DiscordCard };
