import { IconElementsProps } from "../icon-elements-props.type";

export function TudigoLogoIcon(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#1A1A1A",
    secondaryColor = "#FFC300",
    width = 150,
    height = 29,
  } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width, minHeight: height }}
      width={width}
      height={height}
      viewBox="0 0 150 29"
      fill="none"
    >
      <path
        d="M146.844 2.77879C144.69 0.90945 141.354 0 136.642 0C131.931 0 128.593 0.90945 126.44 2.77879C124.385 4.59349 122.307 6.38156 120.264 8.21028L120.57 0.585747C118.012 0.18217 115.771 0 113.385 0C108.119 0 104.46 0.882824 102.195 2.69752C100.15 4.46597 98.1273 6.26525 96.089 8.0449C93.9799 9.85263 92.9939 12.6048 92.9939 16.677C92.9939 20.7491 94.0248 23.6428 96.239 25.4548C98.473 27.2835 102.065 28.1719 107.221 28.1719C110.122 28.1719 112.666 27.8888 114.979 27.3073C116.329 26.0937 117.715 24.9097 119.073 23.7045C119.485 24.3408 119.969 24.904 120.533 25.3931C122.686 27.2625 126.024 28.1719 130.735 28.1719C135.447 28.1719 138.783 27.2625 140.937 25.3931C142.905 23.6667 144.874 21.9389 146.841 20.2125C148.962 18.3711 149.993 15.5195 149.993 11.4949C149.993 7.47037 148.962 4.62012 146.841 2.77739L146.844 2.77879Z"
        fill={secondaryColor}
      />
      <path
        d="M20.462 5.83917H0V13.5407H4.86587V27.5118H15.5961V13.5407H20.462V5.83917Z"
        fill={primaryColor}
      />
      <path
        d="M48.1962 5.83911H37.8222V18.3528C37.8222 19.5369 37.8222 21.5212 35.9092 21.5212C33.9961 21.5212 33.9961 19.5383 33.9961 18.3528V5.83911H23.2988V18.3233C23.2988 22.1601 24.1172 24.5241 25.9519 25.9885C27.8181 27.4781 30.9607 28.1718 35.8445 28.1718C40.7283 28.1718 43.7567 27.5075 45.5996 26.0166C47.3944 24.5648 48.1949 22.1924 48.1949 18.3233V5.83911H48.1962Z"
        fill={primaryColor}
      />
      <path
        d="M74.5171 25.1071C76.5489 23.4844 77.4534 20.8836 77.4534 16.6754C77.4534 12.4673 76.5473 9.86644 74.5171 8.24374C72.4638 6.60282 69.0296 5.83911 63.7112 5.83911H51.3926V27.5117H63.7112C69.0296 27.5117 72.4638 26.748 74.5171 25.1071ZM62.1214 21.5212V11.8311H63.3221C65.733 11.8311 66.2048 13.9611 66.2048 16.6768C66.2048 19.3926 65.733 21.5225 63.3221 21.5225H62.1214V21.5212Z"
        fill={primaryColor}
      />
      <path
        d="M90.7265 5.83911H79.9961V27.5132H90.7265V5.83911Z"
        fill={primaryColor}
      />
      <path
        d="M102.196 2.69752C99.9466 4.501 98.9004 7.29661 98.9004 11.4963C98.9004 15.6961 99.9313 18.4623 102.145 20.2741C104.379 22.1028 107.972 22.9912 113.128 22.9912C116.028 22.9912 118.573 22.7082 120.886 22.1267V11.4627H114.135V14.9828H113.451C111.943 14.9828 110.012 13.7903 110.012 11.6939C110.012 8.99643 112.575 8.20747 115.197 8.20747C116.466 8.20747 118.363 8.29998 120.236 8.75255L120.571 0.585747C118.012 0.18217 115.771 0 113.385 0C108.119 0 104.459 0.882824 102.196 2.69752Z"
        fill={primaryColor}
      />
      <path
        d="M146.843 2.77879C144.689 0.90945 141.353 0 136.641 0C131.929 0 128.593 0.90945 126.439 2.77879C124.318 4.62012 123.287 7.47177 123.287 11.4963C123.287 15.5209 124.318 18.3711 126.439 20.2139C128.593 22.0832 131.929 22.9927 136.641 22.9927C141.353 22.9927 144.689 22.0832 146.843 20.2139C148.964 18.3726 149.996 15.5209 149.996 11.4963C149.996 7.47177 148.964 4.62152 146.843 2.77879ZM136.641 16.3407C134.535 16.3407 134.535 13.2031 134.535 11.4949C134.535 9.78677 134.535 6.64921 136.641 6.64921C138.748 6.64921 138.748 9.78677 138.748 11.4949C138.748 13.2031 138.748 16.3407 136.641 16.3407Z"
        fill={primaryColor}
      />
    </svg>
  );
}
