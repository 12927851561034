import { InputDateProps, InputDateRangeProps } from "./input-date";

const isRange = (b: InputDateProps): b is InputDateRangeProps => {
  return (b as InputDateRangeProps).range;
};

const formatDateToUTC = (date: Date, showTimeSelect?: boolean) => {
  if (showTimeSelect === true) {
    return date;
  }

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
};

export { isRange, formatDateToUTC };
