import { Label, PhoneInput } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export interface SmartPhoneFieldProps<T> extends SmartFieldCommonProps<T> {
  onErrorChange?: (isError: boolean) => void;
}

export function SmartPhoneField<T>(props: SmartPhoneFieldProps<T>) {
  const { form, handleFormChange } = useFormContext();

  const value = extractValueFromPath(form, props.name as string) || undefined;

  return (
    <div>
      <Label>{props.label}</Label>
      <PhoneInput
        value={value}
        onChange={(phone) => {
          handleFormChange({
            ...updateObject(form, props.name as string, phone),
          });
        }}
        onErrorChange={props.onErrorChange}
      />
    </div>
  );
}
