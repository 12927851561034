import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Audio(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3.5 11C3.5 8.74566 4.39553 6.58365 5.98959 4.98959C7.58365 3.39553 9.74566 2.5 12 2.5C14.2543 2.5 16.4163 3.39553 18.0104 4.98959C19.6045 6.58365 20.5 8.74566 20.5 11V13H18.25C18.0511 13 17.8603 13.079 17.7197 13.2197C17.579 13.3603 17.5 13.5511 17.5 13.75V20.25C17.5 20.664 17.836 21 18.25 21H19C19.7956 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7956 22 18V11C22 5.477 17.523 1 12 1C6.477 1 2 5.477 2 11V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H5.75C5.94891 21 6.13968 20.921 6.28033 20.7803C6.42098 20.6397 6.5 20.4489 6.5 20.25V13.75C6.5 13.5511 6.42098 13.3603 6.28033 13.2197C6.13968 13.079 5.94891 13 5.75 13H3.5V11ZM12.75 10.75C12.75 10.5511 12.671 10.3603 12.5303 10.2197C12.3897 10.079 12.1989 10 12 10C11.8011 10 11.6103 10.079 11.4697 10.2197C11.329 10.3603 11.25 10.5511 11.25 10.75V21.25C11.25 21.4489 11.329 21.6397 11.4697 21.7803C11.6103 21.921 11.8011 22 12 22C12.1989 22 12.3897 21.921 12.5303 21.7803C12.671 21.6397 12.75 21.4489 12.75 21.25V10.75ZM8.75 13C8.94891 13 9.13968 13.079 9.28033 13.2197C9.42098 13.3603 9.5 13.5511 9.5 13.75V18.25C9.5 18.4489 9.42098 18.6397 9.28033 18.7803C9.13968 18.921 8.94891 19 8.75 19C8.55109 19 8.36032 18.921 8.21967 18.7803C8.07902 18.6397 8 18.4489 8 18.25V13.75C8 13.5511 8.07902 13.3603 8.21967 13.2197C8.36032 13.079 8.55109 13 8.75 13ZM16 13.75C16 13.5511 15.921 13.3603 15.7803 13.2197C15.6397 13.079 15.4489 13 15.25 13C15.0511 13 14.8603 13.079 14.7197 13.2197C14.579 13.3603 14.5 13.5511 14.5 13.75V18.25C14.5 18.4489 14.579 18.6397 14.7197 18.7803C14.8603 18.921 15.0511 19 15.25 19C15.4489 19 15.6397 18.921 15.7803 18.7803C15.921 18.6397 16 18.4489 16 18.25V13.75Z"
        fill={primaryColor}
      />
    </svg>
  );
}
