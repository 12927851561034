import { mockProjects } from "@tudigo-monorepo/core-tudigo-api-models";

const mockProjectFlagLabel = "new";
const mockProjectFlagColor = "accent-light";

const mockTargetedYield = "> 10 en 3 ans";
const mockConversionHorizonInMonth = 20;

const defaultCoverObj = {
  id: null,
  base64: null,
  originalFilename: null,
  resizedImages: null,
};

const mockProjectShare = mockProjects[0];
const mockProjectBond = mockProjects[1];
const mockProjectConvertibleBond = mockProjects[2];

mockProjectShare.content!.cover = {
  ...defaultCoverObj,
  url: "https://picsum.photos/800",
};

mockProjectBond.content!.cover = {
  ...defaultCoverObj,
  url: "https://picsum.photos/802",
};

mockProjectConvertibleBond.content!.cover = {
  ...defaultCoverObj,
  url: "https://picsum.photos/804",
};

export {
  mockProjectShare,
  mockProjectBond,
  mockProjectConvertibleBond,
  mockProjectFlagLabel,
  mockProjectFlagColor,
  mockConversionHorizonInMonth,
  mockTargetedYield,
};
