import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function CardQuote(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "20",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M17.75 0.5C18.2141 0.5 18.6592 0.684375 18.9874 1.01256C19.3156 1.34075 19.5 1.78587 19.5 2.25V13.755C19.5 14.2191 19.3156 14.6642 18.9874 14.9924C18.6592 15.3206 18.2141 15.505 17.75 15.505H2.25C2.02019 15.505 1.79262 15.4597 1.5803 15.3718C1.36798 15.2838 1.17506 15.1549 1.01256 14.9924C0.850061 14.8299 0.721157 14.637 0.633211 14.4247C0.545265 14.2124 0.5 13.9848 0.5 13.755V2.25C0.5 1.78587 0.684374 1.34075 1.01256 1.01256C1.34075 0.684375 1.78587 0.5 2.25 0.5H17.75Z"
        stroke={primaryColor}
      />
      <path
        d="M8.86014 3L7.47552 7.94118L7.01399 6.08824C7.61538 6.08824 8.10489 6.2598 8.48252 6.60294C8.86014 6.94608 9.04895 7.41961 9.04895 8.02353C9.04895 8.61373 8.85315 9.09412 8.46154 9.46471C8.08392 9.82157 7.60839 10 7.03497 10C6.44755 10 5.95804 9.82157 5.56643 9.46471C5.18881 9.09412 5 8.61373 5 8.02353C5 7.8451 5.01399 7.67353 5.04196 7.50882C5.06993 7.33039 5.12587 7.12451 5.20979 6.89118C5.29371 6.65784 5.41259 6.34902 5.56643 5.96471L6.78322 3H8.86014ZM13.8112 3L12.4266 7.94118L11.965 6.08824C12.5664 6.08824 13.0559 6.2598 13.4336 6.60294C13.8112 6.94608 14 7.41961 14 8.02353C14 8.61373 13.8042 9.09412 13.4126 9.46471C13.035 9.82157 12.5594 10 11.986 10C11.3986 10 10.9091 9.82157 10.5175 9.46471C10.1399 9.09412 9.95105 8.61373 9.95105 8.02353C9.95105 7.8451 9.96503 7.67353 9.99301 7.50882C10.021 7.33039 10.0769 7.12451 10.1608 6.89118C10.2448 6.65784 10.3636 6.34902 10.5175 5.96471L11.7343 3H13.8112Z"
        fill={primaryColor}
      />
      <rect
        x="3.25"
        y="12.25"
        width="13.5"
        height="0.5"
        rx="0.25"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}
