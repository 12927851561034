import { MouseEventHandler, ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { Check } from "../check";

type InputSelectMenuItemProps<T> = {
  active: boolean;
  label: string;
  multiple: boolean;
  value?: T;
  onClick?: MouseEventHandler<HTMLSpanElement> | undefined;
  renderItem?: (item: T) => ReactNode;
};

export function InputSelectMenuItem<T>(props: InputSelectMenuItemProps<T>) {
  const { active, label, value, multiple, onClick, renderItem } = props;

  return (
    <span
      onClick={onClick}
      className={cn(
        "hover:border-accent-medium flex h-[38px] min-h-[38px] w-full cursor-pointer items-center rounded-lg border border-transparent bg-white px-4 transition-all",
        {
          "bg-accent-super-light border-accent-medium": active,
        },
      )}
    >
      {renderItem && value ? (
        renderItem(value)
      ) : multiple ? (
        <Check label={label} checked={active} />
      ) : (
        label
      )}
    </span>
  );
}
