import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Hamburger(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_526_12490)">
        <path d="M2 5H22" stroke={primaryColor} strokeWidth="2" />
        <path d="M2 12H22" stroke={primaryColor} strokeWidth="2" />
        <path d="M2 19H22" stroke={primaryColor} strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_526_12490">
          <rect
            width={width || iconSizes[size]}
            height={width || iconSizes[size]}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
