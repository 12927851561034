import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Folder(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={width || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 9.502V8.75C20 8.45453 19.9418 8.16195 19.8287 7.88896C19.7157 7.61598 19.5499 7.36794 19.341 7.15901C19.1321 6.95008 18.884 6.78435 18.611 6.67127C18.3381 6.5582 18.0455 6.5 17.75 6.5H12.025L9.645 4.52C9.24028 4.18345 8.73037 3.99944 8.204 4H4.25C3.65326 4 3.08097 4.23705 2.65901 4.65901C2.23705 5.08097 2 5.65326 2 6.25L1.996 17.75C1.996 18.0455 2.0542 18.3381 2.16727 18.611C2.28034 18.884 2.44608 19.1321 2.65501 19.341C2.86394 19.5499 3.11198 19.7157 3.38496 19.8287C3.65795 19.9418 3.95053 20 4.246 20H18.47C18.8602 20.0001 19.2393 19.8697 19.547 19.6297C19.8546 19.3896 20.0732 19.0536 20.168 18.675L21.918 11.677C21.9826 11.4189 21.9875 11.1495 21.9324 10.8893C21.8773 10.629 21.7635 10.3848 21.5998 10.1751C21.4361 9.96541 21.2267 9.7958 20.9877 9.67917C20.7486 9.56253 20.486 9.50194 20.22 9.502H20ZM4.25 5.5H8.206C8.3813 5.4998 8.55114 5.56101 8.686 5.673L11.274 7.827C11.4089 7.93899 11.5787 8.0002 11.754 8H17.75C17.9489 8 18.1397 8.07902 18.2803 8.21967C18.421 8.36032 18.5 8.55109 18.5 8.75V9.502H6.424C5.92222 9.50195 5.43483 9.66962 5.03929 9.97837C4.64375 10.2871 4.36276 10.7192 4.241 11.206L3.497 14.184L3.5 6.25C3.5 6.05109 3.57902 5.86032 3.71967 5.71967C3.86032 5.57902 4.05109 5.5 4.25 5.5ZM5.697 11.57C5.73756 11.4079 5.83112 11.2639 5.96283 11.161C6.09454 11.0581 6.25686 11.0021 6.424 11.002H20.22C20.2579 11.0021 20.2952 11.0108 20.3293 11.0275C20.3633 11.0441 20.3931 11.0683 20.4164 11.0982C20.4397 11.128 20.4559 11.1628 20.4638 11.1998C20.4717 11.2369 20.4711 11.2752 20.462 11.312L18.712 18.311C18.6984 18.3649 18.6673 18.4128 18.6234 18.447C18.5796 18.4813 18.5256 18.4999 18.47 18.5H4.285C4.24704 18.5 4.20956 18.4914 4.17542 18.4749C4.14127 18.4583 4.11136 18.4341 4.08794 18.4042C4.06452 18.3744 4.04821 18.3395 4.04025 18.3024C4.03229 18.2653 4.03289 18.2269 4.042 18.19L5.697 11.57Z"
        fill={primaryColor}
      />
    </svg>
  );
}
