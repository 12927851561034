import { Lead } from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface CreateLeadRequestBody {
  refId: string;
  email?: string;
}

export const leadsEndpoints = (request: Request) => ({
  subscribeProjectNotifications: (
    body: CreateLeadRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Lead>, void>({
      path: "/api/v1/leads/projects",
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  unsubscribeProjectNotifications: (
    leadId: string,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Lead>, void>({
      path: `/api/v1/leads/projects/${leadId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
