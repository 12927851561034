import { ReactNode } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export type TypographyVariant =
  | "body1"
  | "body2"
  | "body2-semibold"
  | "body3-semibold"
  | "body3-medium"
  | "body3-regular"
  | "caption1-regular"
  | "caption1-semibold"
  | "caption1-medium"
  | "caption2-semibold";

type TypographyProps = {
  children: ReactNode;
  variant?: TypographyVariant;
  className?: string;
  onClick?: () => void;
  dangerouslySetInnerHTML?: boolean;
};

export function Typography(props: TypographyProps) {
  const {
    variant = "body1",
    children,
    className,
    onClick,
    dangerouslySetInnerHTML,
  } = props;

  const styles: Record<TypographyVariant, string> = {
    body1: "font-montserrat text-base leading-[19.2px] font-normal",
    body2: "font-montserrat text-sm leading-5 font-normal",
    "body2-semibold": "font-montserrat text-sm leading-5 font-semibold",
    "body3-semibold":
      "font-montserrat text-[13px] leading-[22px] font-semibold",
    "body3-medium": "font-montserrat text-[13px] leading-[22px] font-medium",
    "body3-regular": "font-montserrat text-[13px] leading-[22px] font-normal",
    "caption1-regular": "font-montserrat text-[12px] leading-4 font-normal",
    "caption1-semibold":
      "font-montserrat text-xs  leading-[18px] font-semibold",
    "caption1-medium": "font-montserrat text-xs leading-[15px] font-normal",
    "caption2-semibold":
      "font-montserrat text-[10px] leading-[17px] font-normal",
  };

  const pClassName = cn(styles[variant], "text-primary inline", className, {
    "cursor-pointer": onClick,
  });

  if (dangerouslySetInnerHTML) {
    if (!children) return null;

    return (
      <p
        className={cn(pClassName, "inline-block")}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: children }}
      ></p>
    );
  }

  return (
    <p className={pClassName} onClick={onClick}>
      {children}
    </p>
  );
}
