/* eslint-disable no-console */
import { QueryCache, QueryClient } from "@tanstack/react-query";

export const queryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      keepPreviousData: true,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (error?.httpCode === 401) {
        return console.error("Unauthorized");
      }

      if (error?.message) {
        return console.log(error.message);
      }

      return console.error("An error occurred");
    },
  }),
};

export const queryClient = new QueryClient(queryClientConfig);
