import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Lead } from "@tudigo-monorepo/core-tudigo-api-models";

import { getApiClient, useInvalidateConnectedUser } from "../../..";

type Params = {
  leadId: string;
};

export const unsubscribeProjectNotifications = async (leadId: string) => {
  const apiClient = getApiClient();

  const response =
    await apiClient.apiV1.leadsEndpoints.unsubscribeProjectNotifications(
      leadId,
    );

  return response.data;
};

export const useUnsubscribeProjectNotificationsMutation = (
  options?: UseMutationOptions<TudigoResponse<Lead>, TudigoError, Params>,
) => {
  const invalidateUser = useInvalidateConnectedUser();

  return useMutation({
    ...options,
    mutationFn: (params: Params) =>
      unsubscribeProjectNotifications(params.leadId),
    onSuccess: (...props) => {
      invalidateUser();
      options?.onSuccess?.(...props);
    },
  });
};
