import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { Icon, Typography } from "@tudigo-monorepo/web-tudigo-components";

export type Step = {
  title: string;
  description: string;
  step: number;
  state?: "done";
};

type ClubNextStepsProps = {
  steps: Step[];
};

export const ClubNextSteps = ({ steps }: ClubNextStepsProps) => {
  return (
    <div className="space-y-3">
      {steps.map(({ step, title, description, state }) => {
        const isDone = state === "done";

        return (
          <div key={step} className="flex gap-4">
            <div
              className={cn(
                "bg-light-2 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-solid",
                {
                  "bg-success-light": isDone,
                },
              )}
            >
              {isDone ? (
                <Icon
                  className="m-auto"
                  name="Checked"
                  size="S"
                  primaryColor={themeColors.success}
                />
              ) : (
                <Typography variant="caption1-semibold">{step}</Typography>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="body3-semibold">{title}</Typography>
              <Typography variant="body3-regular">{description}</Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};
