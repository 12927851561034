import { User, UserRole } from "@tudigo-monorepo/core-tudigo-api-models";

export const getUserRoleByPriorityOrder = (user: User) => {
  if (
    window.location.hostname.startsWith("bo") &&
    user.appRoles.includes(UserRole.ADMIN)
  ) {
    return UserRole.ADMIN;
  }

  if (user.appRoles.includes(UserRole.WMA) || user.wmaProfile !== null) {
    return UserRole.WMA;
  }

  if (
    user.appRoles.includes(UserRole.PROJECT_HOLDER) ||
    user.projectHolderProfile !== null
  ) {
    return UserRole.PROJECT_HOLDER;
  }

  return UserRole.INVESTOR;
};
