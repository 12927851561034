import {
  BondsProject,
  isSharesProject,
  Project,
  SharesProject,
} from "@tudigo-monorepo/core-tudigo-api-models";
import { themeColors } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";
import { Tooltip } from "@tudigo-monorepo/web-tudigo-components";

import { Chip } from "../chip";
import { Icon } from "../icons/icon";

const Setting = (props: {
  label: string;
  value: string | number | string[] | number[];
  isLimitedAccess?: boolean;
}) => {
  const { label, value, isLimitedAccess = false } = props;

  return (
    <li className="flex flex-wrap items-center gap-2 py-2">
      <p className="font-montserrat text-dark-1 text-xs">{label} :</p>

      {isLimitedAccess ? (
        <Icon name="Cadenas" size="XS" primaryColor={themeColors["dark-2"]} />
      ) : (
        <p className="font-montserrat text-dark-1 text-xs font-semibold">
          {value}
        </p>
      )}
    </li>
  );
};

const ProjectCardSharesMechanicSettings = (props: {
  project: SharesProject;
  isLimitedAccess?: boolean;
}) => {
  const { project, isLimitedAccess = false } = props;
  const { sharesMechanicSettings, taxExemptionMechanics } = project;

  const { t } = useTranslation();

  if (!sharesMechanicSettings) {
    return null;
  }

  const { targetedYield } = sharesMechanicSettings;

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <Setting
          isLimitedAccess={isLimitedAccess}
          label={t("component.project_card.list.item.potential_yield")}
          value={`x${(targetedYield?.mediumInPercent ?? 0) / 100}`}
        />
      )}

      <div className="flex flex-wrap items-center gap-2 py-2">
        <p className="font-montserrat text-dark-1 text-xs">
          {t("component.project_card.list.item.tax_exemption")} :
        </p>
        <Tooltip
          placement="top"
          description={t("component.project_card.list.item.tax_exemption.info")}
        >
          <Icon name="Info" size="XS" primaryColor={themeColors["dark-1"]} />
        </Tooltip>
        <div className="flex flex-wrap gap-1">
          {taxExemptionMechanics?.map((tax, index) => (
            <Chip
              key={index}
              label={t(`component.project_card.list.item.tax_exemption.${tax}`)}
              size="S"
              type="secondary"
            />
          ))}
        </div>
      </div>
    </>
  );
};

const ProjectCardBondsMechanicSettings = (props: {
  project: BondsProject;
  isLimitedAccess?: boolean;
}) => {
  const { project, isLimitedAccess = false } = props;
  const { bondsMechanicSettings } = project;

  const { t } = useTranslation();

  if (!bondsMechanicSettings) {
    return null;
  }

  const {
    isConvertible,
    nonConversionBonusNoDecimalRate,
    conversionHorizonInMonth,
    annualInterestNoDecimalRate,
    durationInMonth,
    repaymentType,
  } = bondsMechanicSettings;

  if (isConvertible) {
    return (
      <>
        <Setting
          isLimitedAccess={isLimitedAccess}
          label={t(
            "component.project_card.list.item.non_conversion_bonus_rate",
          )}
          value={`${nonConversionBonusNoDecimalRate / 100} %`}
        />
        {typeof conversionHorizonInMonth === "number" && (
          <Setting
            isLimitedAccess={isLimitedAccess}
            label={t(
              "component.project_card.list.item.conversion_horizon_in_month",
            )}
            value={`${conversionHorizonInMonth} ${t(
              "component.project_card.list.item.months",
            )}`}
          />
        )}
      </>
    );
  }

  return (
    <>
      {project?.status !== "prefinished" && project?.status !== "finished" && (
        <Setting
          isLimitedAccess={isLimitedAccess}
          label={t("component.project_card.list.item.interest_rate")}
          value={`${annualInterestNoDecimalRate / 100} %`}
        />
      )}
      <Setting
        label={t("component.project_card.list.item.duration")}
        value={`${durationInMonth}
        ${t("component.project_card.list.item.months")}`}
      />
      <Setting
        label={t("component.project_card.list.item.repayment")}
        value={t(`repayment_type.${repaymentType}`)}
      />
    </>
  );
};

export const ProjectCardMechanicSettings = (props: {
  project: Project;
  isLimitedAccess?: boolean;
}) => {
  const { project, isLimitedAccess = false } = props;

  return (
    <ul className="flex w-full flex-col divide-y border-y">
      {isSharesProject(project) ? (
        <ProjectCardSharesMechanicSettings
          project={project}
          isLimitedAccess={isLimitedAccess}
        />
      ) : (
        <ProjectCardBondsMechanicSettings
          project={project}
          isLimitedAccess={isLimitedAccess}
        />
      )}
    </ul>
  );
};
