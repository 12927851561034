import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

export const NavbarMenuAbout = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/qui-sommes-nous"
          onClick={props.onLinkClick}
        >
          Qui sommes-nous ?
          <NavbarMenuLinkLegend>
            Une équipe d’experts engagés depuis 2018
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to="https://tudigo.co/partenaires"
          onClick={props.onLinkClick}
        >
          Nos partenaires
          <NavbarMenuLinkLegend>
            Un réseau de partenaires institutionnels et privés
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>

      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/performance"
          onClick={props.onLinkClick}
        >
          Notre performance
          <NavbarMenuLinkLegend>
            Chiffres clés, track record et performance
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink
          to="https://tudigo.co/presse"
          onClick={props.onLinkClick}
        >
          Espace Presse
          <NavbarMenuLinkLegend>
            Tudigo dans les journaux !
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
