import * as React from "react";
import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { InputConnectedProps } from "../../components/input.type";
import { PhoneInput } from "./phone-input";

export function PhoneInputConnected({
  name,
  description,
  label,
  required,
}: InputConnectedProps) {
  const form = useFormContext();

  if (!form) {
    throw new Error("PhoneInputConnected must be used within a Form component");
  }

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem>
          {label && <FormLabel required={required}>{label}</FormLabel>}
          <FormControl>
            <PhoneInput {...field} placeholder="Numéro de téléphone" />
          </FormControl>
          {fieldState?.error ? (
            <FormMessage />
          ) : (
            description && <FormDescription>{description}</FormDescription>
          )}
        </FormItem>
      )}
    />
  );
}
