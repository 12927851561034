import { useQuery } from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { ProjectTag } from "@tudigo-monorepo/core-tudigo-api-models";

import { CollectionRequestParams, getApiClient } from "..";
import queryKeyFactory from "./query-key-factory";

const projectTagsKeys = queryKeyFactory("project-tags");

export const useGetProjectTagsQuery = (
  params?: CollectionRequestParams<undefined, undefined>,
) => {
  const { pagination, filters, fields } = params ?? {};
  const apiClient = getApiClient();

  return useQuery<TudigoResponse<ProjectTag[]>, TudigoError>({
    refetchOnMount: false,
    queryKey: projectTagsKeys.list(fields, filters),
    queryFn: async () => {
      const response = await apiClient.apiV1.projectTags.getProjectTags({
        fields: fields,
        offset: pagination?.offset,
        limit: pagination?.limit,
      });

      return response.data;
    },
  });
};
