import React, { forwardRef, InputHTMLAttributes, useRef } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn, themeColors } from "@tudigo-monorepo/core-tudigo-theme";

import { Icon } from "../icons/icon";
import { InputGroup, InputGroupProps } from "../input-group/input-group";

type InputSearchProps = {
  value: string;
  validated?: boolean;
  onResetValue?: () => void;
} & InputGroupProps &
  InputHTMLAttributes<HTMLInputElement>;

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (props: InputSearchProps, ref) => {
    const {
      id,
      label,
      className,
      autoComplete,
      name,
      value = "",
      pattern,
      placeholder,
      autoFocus = false,
      disabled = false,
      required = false,
      onChange,
      onKeyDown,
      onFocus,
      errors,
      groupRef,
    } = props;

    let inputId = React.useId();
    inputId = id || inputId;

    const inputRef = useRef<HTMLInputElement>(null);

    const onClick = () => {
      onChange && onChange({ target: { value: "" } } as any);
    };

    return (
      <InputGroup
        groupRef={groupRef}
        ref={mergeRefs([inputRef, ref])}
        id={inputId}
        className={className}
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="top"
        valid={props.validated}
        iconRight={
          <div
            onClick={onClick}
            className={cn({
              "bg-accent-light rounded-lg": !!value,
            })}
          >
            <Icon
              name={value ? "Cross" : "Search"}
              primaryColor={
                value ? themeColors["accent"] : themeColors["dark-2"]
              }
              size="S"
              height={18}
              width={18}
            />
          </div>
        }
        renderInput={(inputProps) => (
          <input
            type="search"
            id={inputId}
            value={value}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            name={name}
            onKeyDown={onKeyDown}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            pattern={pattern}
            onFocus={onFocus}
            onChange={onChange}
            className={cn(
              inputProps.className,
              "search-cancel:appearance-none",
            )}
            ref={inputProps.ref}
          />
        )}
      />
    );
  },
);
