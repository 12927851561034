import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function Timeline(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width,
    height,
    size = "S",
  } = props;

  return (
    <svg
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8331 12.9299C15.6954 12.9299 16.3953 13.6289 16.3953 14.4922V18.5121C16.3953 18.9265 16.2307 19.3238 15.9377 19.6168C15.6448 19.9098 15.2474 20.0744 14.8331 20.0744H4.56673C4.15239 20.0744 3.75502 19.9098 3.46204 19.6168C3.16906 19.3238 3.00446 18.9265 3.00446 18.5121V14.4922C3.00446 13.6289 3.70347 12.9299 4.56673 12.9299H14.8331ZM19.9662 14.6216V19.4022C19.968 19.573 19.9043 19.7381 19.7883 19.8635C19.6723 19.989 19.5128 20.0653 19.3423 20.077C19.1719 20.0886 19.0034 20.0346 18.8714 19.9261C18.7395 19.8176 18.654 19.6627 18.6325 19.4932L18.6271 19.4022V14.6315C19.0671 14.7418 19.5278 14.7378 19.9662 14.6216ZM19.2967 10.3339C19.7484 10.3339 20.1817 10.5133 20.5011 10.8328C20.8205 11.1522 21 11.5854 21 12.0372C21 12.4889 20.8205 12.9222 20.5011 13.2416C20.1817 13.5611 19.7484 13.7405 19.2967 13.7405C18.8449 13.7405 18.4117 13.5611 18.0923 13.2416C17.7728 12.9222 17.5934 12.4889 17.5934 12.0372C17.5934 11.5854 17.7728 11.1522 18.0923 10.8328C18.4117 10.5133 18.8449 10.3339 19.2967 10.3339ZM14.8286 4C15.6919 4 16.3909 4.699 16.3909 5.56227V9.5822C16.3909 9.99654 16.2263 10.3939 15.9333 10.6869C15.6403 10.9799 15.2429 11.1445 14.8286 11.1445H4.56227C4.14793 11.1445 3.75056 10.9799 3.45758 10.6869C3.1646 10.3939 3 9.99654 3 9.5822V5.56227C2.99981 5.1699 3.14727 4.79182 3.41307 4.50319C3.67887 4.21457 4.04355 4.03653 4.43461 4.00446L4.56227 4H14.8286ZM19.2967 4.00268C19.4585 4.00269 19.6148 4.06128 19.7367 4.16762C19.8587 4.27397 19.938 4.42087 19.96 4.58116L19.9662 4.67222V9.44383C19.5278 9.32767 19.0671 9.3246 18.6271 9.43491V4.67222C18.6271 4.49465 18.6977 4.32435 18.8232 4.19878C18.9488 4.07322 19.1191 4.00268 19.2967 4.00268Z"
        fill={primaryColor}
      />
    </svg>
  );
}
