import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";

import { Image, Media } from "@tudigo-monorepo/core-tudigo-api-models";

import { MediaElement, MediaElementProps } from "./media-element";

type OptionalMediasProps = {
  medias: Media[];
  setMedias: (medias: Media[]) => void;
  onSwitchMediaType: (index: number) => void;
  deleteMedia: (index: number) => void;
  setImage: (image: Image, index: number) => void;
  setVideo: (url: string, index: number) => void;
};

const SortableItem = SortableElement<MediaElementProps & { position: number }>(
  (props: MediaElementProps & { position: number }) => (
    <MediaElement {...props} initialExpendedState index={props.position} />
  ),
);

const SortableList = SortableContainer<OptionalMediasProps>(
  (props: OptionalMediasProps) => {
    return (
      <div className="flex flex-row flex-wrap gap-6 overflow-y-auto">
        {props.medias?.map((media, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            position={index}
            media={{
              ...media,
            }}
            {...props}
          />
        ))}
      </div>
    );
  },
);

export function OptionalMedias(props: OptionalMediasProps) {
  const { setMedias, medias } = props;

  return (
    <div>
      <SortableList
        {...props}
        useDragHandle
        axis="xy"
        onSortEnd={(sort) => {
          setMedias(arrayMove(medias, sort.oldIndex, sort.newIndex));
        }}
      />
    </div>
  );
}
