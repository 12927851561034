import React from "react";
import { cva } from "class-variance-authority";
import { Bell, StarIcon } from "lucide-react";

import { ClubV2 } from "@tudigo-monorepo/core-tudigo-api-models";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type ClubProfilePictureProps = {
  club: ClubV2;
  size?: "XS" | "S" | "M";
  className?: string;
  text?: string;
  displayBadge?: boolean;
};

const sizeVariants = cva([], {
  variants: {
    size: {
      XS: "h-[32px] w-[32px]",
      S: "h-[54px] w-[54px]",
      M: "h-[94px] w-[94px]",
    },
  },
  defaultVariants: {
    size: "M",
  },
});

const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const getBrightness = ({
  r,
  g,
  b,
}: {
  r: number;
  g: number;
  b: number;
}): number => {
  return 0.299 * r + 0.587 * g + 0.114 * b;
};

const getTextColor = (backgroundColor: string): string => {
  const rgb = hexToRgb(backgroundColor);
  const brightness = getBrightness(rgb);

  return brightness > 186 ? "black" : "white";
};

export const ClubProfilePicture = ({
  club,
  size = "M",
  text,
  displayBadge = true,
  className,
}: ClubProfilePictureProps) => {
  const logoUrl = club.content?.logo?.url;
  const borderColor = club.iconBorderColor;

  return (
    <div
      style={{ "--borderColor": `${borderColor}` } as React.CSSProperties}
      className={cn(
        `relative flex flex-col items-center justify-center`,
        text ? `bg-[var(--borderColor)]` : "",
        className,
        {
          "flex w-fit flex-row justify-start gap-2 rounded-lg pr-4": !!text,
        },
      )}
    >
      <img
        src={logoUrl ?? undefined}
        alt="club logo"
        className={cn(
          sizeVariants({ size }),
          `rounded-lg border-2 border-[var(--borderColor)] `,
          className,
        )}
      />

      {size !== "XS" &&
        displayBadge &&
        club.myMembership &&
        club.myMembership.status === "active" && (
          <PremiumMembershipBadge club={club} />
        )}

      {size !== "XS" &&
        displayBadge &&
        club.myMembership &&
        club.myMembership.status === "following" && (
          <FollowingMembershipBadge club={club} />
        )}

      {text && (
        <p
          className={cn(`text-sm`, {
            "text-black": getTextColor(club.iconBorderColor) === "black",
            "text-white": getTextColor(club.iconBorderColor) === "white",
          })}
        >
          {text}
        </p>
      )}
    </div>
  );
};

const PremiumMembershipBadge = ({ club }: { club: ClubV2 }) => {
  return (
    <div
      className={cn("bg-success absolute -left-2 -top-2 rounded-full p-1", {
        "text-black": club.iconBorderColor
          ? getTextColor(club.iconBorderColor) === "black"
          : false,
        "text-white": club.iconBorderColor
          ? getTextColor(club.iconBorderColor) === "white"
          : false,
      })}
    >
      <StarIcon className="fill-white text-white" size={14} />
    </div>
  );
};

const FollowingMembershipBadge = ({ club }: { club: ClubV2 }) => {
  return (
    <div
      className={cn(
        "bg-primary-rebrand absolute -left-2 -top-2 rounded-full p-1",
        {
          "text-black": club.iconBorderColor
            ? getTextColor(club.iconBorderColor) === "black"
            : false,
          "text-white": club.iconBorderColor
            ? getTextColor(club.iconBorderColor) === "white"
            : false,
        },
      )}
    >
      <Bell className="fill-white text-white" size={14} />
    </div>
  );
};
