import { useEffect, useState } from "react";

import { Image } from "@tudigo-monorepo/core-tudigo-api-models";
import { ImageUploader } from "@tudigo-monorepo/web-tudigo-components";

import {
  extractValueFromPath,
  SmartFieldCommonProps,
  updateObject,
} from "../../index";
import { useFormContext } from "../form-context/form-context";

export function SmartImageField<T>(
  props: {
    ratio?: string;
    maxSizeInMB?: number;
    allowedExtensions?: string[];
  } & SmartFieldCommonProps<T>,
) {
  const { form, handleFormChange, error } = useFormContext();

  const [image, setImage] = useState<Image>({
    id: null,
    url: null,
    base64: null,
    resizedImages: null,
    originalFilename: null,
  });

  const formValue = extractValueFromPath(form, props.name);

  useEffect(() => {
    if (formValue) {
      setImage(formValue);
    }
  }, [formValue]);

  return (
    <ImageUploader
      {...props}
      image={image}
      errors={error?.parametersErrors?.[props.name] || props.errors}
      onImageChange={(image: Image) => {
        handleFormChange({
          ...updateObject(form, props.name, image),
        });
      }}
    />
  );
}
