import {
  isComingProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ProjectProgressBar } from "../../ui/project-progress-bar/project-progress-bar";
import { ProjectTotalAmountCollected } from "../../ui/project-total-amount-collected/project-total-amount-collected";
import { ProjectCardNotify } from "./project-card-notify";

export function ProjectCardFooter(props: { project: Project }) {
  const { project } = props;

  const {
    fundraisingProgressMaxGoal,
    fundraisingProgressMinGoal,
    marketingSettings,
    totalAmountCollectedInCents,
  } = project;

  return (
    <footer>
      {isComingProject(project) && <ProjectCardNotify project={project} />}

      {!isComingProject(project) &&
        marketingSettings?.gaugeVisibility === "visible" && (
          <ProjectProgressBar
            progressMaxGoal={fundraisingProgressMaxGoal}
            progressMinGoal={fundraisingProgressMinGoal}
          >
            <ProjectTotalAmountCollected amount={totalAmountCollectedInCents} />
          </ProjectProgressBar>
        )}
    </footer>
  );
}
