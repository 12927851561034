import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CMSBlock } from "@tudigo-monorepo/core-tudigo-api-models";
import { SmartField } from "@tudigo-monorepo/web-feature-form";

import { EditorPlugin, EditorSettings } from "../types";
import { generateBlockId } from "../utils/create-block";
import { defaultEditorSettings, EditorContext } from "./editor-context";

type EditorProviderProps = React.PropsWithChildren<{
  plugins: EditorPlugin<any, any, any>[];
  field: SmartField;
  context: any;
}>;

export function EditorProvider(props: EditorProviderProps) {
  const { children, plugins, field, context } = props;

  const [lastSavedVersion, setLastSavedVersion] = useState<CMSBlock[]>(
    field?.data ?? [],
  );

  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);
  const [selectedPlugin, setSelectedPlugin] = useState<EditorPlugin<
    any,
    any,
    any
  > | null>(null);
  const [settings, setSettings] = useState<EditorSettings>(
    defaultEditorSettings,
  );

  const content = field.data as CMSBlock[];

  const setContent = useCallback(
    (editorContent: CMSBlock[]) => {
      field?.onSave([...editorContent]);
    },
    [field],
  );

  const deleteBlockById = useCallback(
    (id: string) => {
      setContent(content.filter((block) => block.id !== id));
    },
    [content, setContent],
  );

  const addBlock = useCallback(
    (block: CMSBlock) => {
      setContent([...content, { ...block, id: generateBlockId() }]);
    },
    [content, setContent],
  );

  // Reset de l'éditeur avec le contenu initial qui est le contenu du field
  const resetEditor = useCallback(() => {
    setContent(lastSavedVersion);
    setSettings(defaultEditorSettings);
    setSelectedBlockId(null);
  }, [lastSavedVersion, setContent]);

  // Si l'éditeur est caché et que le contenu du field change, on met à jour le contenu "initial"
  // Cela permet de mettre a jours le reset de l'éditeur
  useEffect(() => {
    if (settings?.visible) return;
    if (!field?.data) return;
    setLastSavedVersion(field?.data);
  }, [settings?.visible, field?.data]);

  const getPluginFromBlockType = useCallback(
    (blockType: string): EditorPlugin<any, any, any> | null => {
      return plugins?.find((plugin) => plugin.blockType === blockType) || null;
    },
    [plugins],
  );

  const selectedBlockContent = useMemo(() => {
    if (!selectedBlockId) return null;
    const selectedBlock = content.find((block) => block.id === selectedBlockId);
    if (!selectedBlock) return null;

    return selectedBlock;
  }, [content, selectedBlockId]);

  const setSelectedBlockContent = useCallback(
    (newContent: CMSBlock) => {
      setContent(
        content.map((block) => {
          if (selectedBlockContent && block.id === selectedBlockContent.id) {
            return newContent;
          }

          return block;
        }),
      );
    },
    [content, selectedBlockContent, setContent],
  );

  const ctx = React.useMemo(() => {
    return {
      content,
      selectedPlugin,
      selectedBlockId,
      selectedBlockContent,
      plugins,
      settings,
      setSelectedBlockId,
      deleteBlockById,
      addBlock,
      setSelectedBlockContent,
      setContent,
      getPluginFromBlockType,
      setSettings,
      resetEditor,
      setSelectedPlugin,
      context,
    };
  }, [
    content,
    selectedPlugin,
    selectedBlockId,
    selectedBlockContent,
    plugins,
    settings,
    setSelectedBlockContent,
    addBlock,
    deleteBlockById,
    setContent,
    getPluginFromBlockType,
    resetEditor,
    context,
  ]);

  return (
    <EditorContext.Provider value={ctx}>{children}</EditorContext.Provider>
  );
}
