import { IconElementsProps } from "../icon-elements-props.type";
import { iconSizes } from "../icon-sizes";

export function ImageAndText(props: IconElementsProps) {
  const {
    className,
    primaryColor = "#00083C",
    width = "21",
    height = "16",
    size = "S",
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        minWidth: width || iconSizes[size],
        minHeight: height || iconSizes[size],
      }}
      width={width || iconSizes[size]}
      height={height || iconSizes[size]}
      viewBox="0 0 21 16"
      fill="none"
    >
      <rect
        x="13.566"
        y="4.7659"
        width="6.468"
        height="0.732"
        rx="0.366"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.732"
      />
      <rect
        x="13.566"
        y="8.366"
        width="6.468"
        height="0.732"
        rx="0.366"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.732"
      />
      <rect
        x="13.566"
        y="11.9661"
        width="6.468"
        height="0.732"
        rx="0.366"
        fill="white"
        stroke={primaryColor}
        strokeWidth="0.732"
      />
      <path
        d="M5.65 9.14533L5.70533 9.098C5.78978 9.03642 5.89141 9.00285 5.99592 9.00202C6.10043 9.00118 6.20258 9.03311 6.288 9.09333L6.35 9.14533L10.9687 13.6787C10.6274 13.8892 10.2343 14.0005 9.83333 14H2.16667C1.76578 14.0007 1.37262 13.8897 1.03134 13.6793L5.65 9.14533L5.70533 9.098L5.65 9.14466V9.14533ZM9.83333 2C10.408 2 10.9591 2.22827 11.3654 2.6346C11.7717 3.04093 12 3.59203 12 4.16667V11.8333C12 12.2513 11.8813 12.642 11.6767 12.9733L7.05067 8.43133L6.96533 8.354C6.69583 8.12737 6.35519 8.00279 6.00306 8.00205C5.65094 8.00132 5.30978 8.12449 5.03933 8.35L4.94934 8.432L0.323336 12.9727C0.11135 12.6305 -0.000650946 12.2358 2.84602e-06 11.8333V4.16667C2.84602e-06 3.59203 0.228276 3.04093 0.634605 2.6346C1.04093 2.22827 1.59203 2 2.16667 2H9.83333ZM8.50133 4C8.30418 4 8.10895 4.03883 7.9268 4.11428C7.74465 4.18973 7.57914 4.30032 7.43973 4.43973C7.30032 4.57914 7.18973 4.74465 7.11428 4.9268C7.03883 5.10895 7 5.30417 7 5.50133C7 5.69849 7.03883 5.89372 7.11428 6.07587C7.18973 6.25802 7.30032 6.42352 7.43973 6.56293C7.57914 6.70235 7.74465 6.81293 7.9268 6.88838C8.10895 6.96383 8.30418 7.00267 8.50133 7.00267C8.89951 7.00267 9.28138 6.84449 9.56294 6.56293C9.84449 6.28138 10.0027 5.89951 10.0027 5.50133C10.0027 5.10315 9.84449 4.72128 9.56294 4.43973C9.28138 4.15817 8.89951 4 8.50133 4ZM8.50133 5C8.56717 5 8.63236 5.01297 8.69319 5.03816C8.75401 5.06335 8.80928 5.10028 8.85583 5.14684C8.90238 5.19339 8.93931 5.24866 8.96451 5.30948C8.9897 5.3703 9.00267 5.4355 9.00267 5.50133C9.00267 5.56717 8.9897 5.63236 8.96451 5.69318C8.93931 5.75401 8.90238 5.80927 8.85583 5.85583C8.80928 5.90238 8.75401 5.93931 8.69319 5.9645C8.63236 5.9897 8.56717 6.00267 8.50133 6.00267C8.36837 6.00267 8.24086 5.94985 8.14684 5.85583C8.05282 5.76181 8 5.63429 8 5.50133C8 5.36837 8.05282 5.24085 8.14684 5.14684C8.24086 5.05282 8.36837 5 8.50133 5Z"
        fill={primaryColor}
      />
    </svg>
  );
}
